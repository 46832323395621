import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {fromLonLat, toLonLat} from 'ol/proj'
import GeoJSON from 'ol/format/GeoJSON';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';

import debounce from 'debounce';

import './elm-street.css';

// Usage:
//
// import ElmStreet from '../elm-street/src/elm-street.js';
// ElmStreet.init(app);

var app;

export default {
  init: function init(theapp) {
    app = theapp;
    app.ports.mapPort.subscribe(function mapPort(msg) {
      switch (msg.Cmd) {
        case 'Fly':
          fly(msg.lon, msg.lat, msg.zoom);
          place(msg.geoJson, false);
          break;
        case 'Render':
          place(msg.geoJson, false);
          break;
        case 'Fit':
          place(msg.geoJson, true);
          break;
      }
    });
  }
};

function fly(lon, lat, zoom) {
  getView((view) => {
    view.animate({
      center: fromLonLat([lon, lat]),
      zoom: zoom,
      duration: 300
    });
  });
}

const placesSource = new VectorSource();
const geoJsonFormat = new GeoJSON({featureProjection: 'EPSG:3857'});

function place(geoJson, fit) {
  placesSource.clear();
  if (geoJson) {
    placesSource.addFeatures(geoJsonFormat.readFeatures(geoJson));
    if (fit) {
      getView((view) => {
        view.fit(placesSource.getExtent(), {
          duration: 300,
          maxZoom: 18
        });
      });
    }
  }
}

function getView(callback) {
  getMap((map) => {
    callback(map.getView());
  });
}

var _map;
function getMap(callback) {
  const target = 'elm-street';
  function targetReady() {
    return !!document.getElementById(target);
  }
  if (_map && targetReady()) {
    return callback(_map);
  }
  const interval = setInterval(() => {
    if (targetReady()) {
      clearInterval(interval);
      _map = _initMap(target);
      callback(_map);
      // keep touching the visor icon from scrolling the app
      const iconVisor = document.getElementById('icon-visor');
      if (iconVisor) {
        iconVisor.addEventListener('touchmove', function iconVisorTouchmove(event) {
          event.preventDefault();
        });
      }
    }
  }, 1);
}

function _initMap(target) {
  const map = new Map({
    target: target,
    layers: [
        new TileLayer({source: new OSM()}),
        new VectorLayer({source: placesSource})
    ],
    view: new View({
      center: fromLonLat([0, 0]),
      zoom: 16
    })
  });
  
  map.on('singleclick', function singleclick(event) {
    const coordinate = toLonLat(event.coordinate);
    fly(coordinate[0], coordinate[1]);
  });
  
  map.on('moveend', debounce(function moveend() {
    const view = map.getView();
    const coordinate = toLonLat(view.getCenter());
    app.ports.mapMoved.send({
      center: {
        lon: coordinate[0],
        lat: coordinate[1]
      },
      zoom: view.getZoom()
    });
  }, 1000, true));

  return map;
}
