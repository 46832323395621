(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.de.bq === region.eg.bq)
	{
		return 'on line ' + region.de.bq;
	}
	return 'on lines ' + region.de.bq + ' through ' + region.eg.bq;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hQ,
		impl.iq,
		impl.ij,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		fu: func(record.fu),
		gE: record.gE,
		fW: record.fW
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.fu;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.gE;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.fW) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hQ,
		impl.iq,
		impl.ij,
		function(sendToApp, initialModel) {
			var view = impl.ix;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hQ,
		impl.iq,
		impl.ij,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.dd && impl.dd(sendToApp)
			var view = impl.ix;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.hw);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ik) && (_VirtualDom_doc.title = title = doc.ik);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.h1;
	var onUrlRequest = impl.h2;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		dd: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.f0 === next.f0
							&& curr.hO === next.hO
							&& curr.fT.a === next.fT.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		hQ: function(flags)
		{
			return A3(impl.hQ, flags, _Browser_getUrl(), key);
		},
		ix: impl.ix,
		iq: impl.iq,
		ij: impl.ij
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hN: 'hidden', hy: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hN: 'mozHidden', hy: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hN: 'msHidden', hy: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hN: 'webkitHidden', hy: 'webkitvisibilitychange' }
		: { hN: 'hidden', hy: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		gk: _Browser_getScene(),
		hb: {
			hp: _Browser_window.pageXOffset,
			hq: _Browser_window.pageYOffset,
			hn: _Browser_doc.documentElement.clientWidth,
			eH: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		hn: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		eH: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			gk: {
				hn: node.scrollWidth,
				eH: node.scrollHeight
			},
			hb: {
				hp: node.scrollLeft,
				hq: node.scrollTop,
				hn: node.clientWidth,
				eH: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			gk: _Browser_getScene(),
			hb: {
				hp: x,
				hq: y,
				hn: _Browser_doc.documentElement.clientWidth,
				eH: _Browser_doc.documentElement.clientHeight
			},
			hC: {
				hp: x + rect.left,
				hq: y + rect.top,
				hn: rect.width,
				eH: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.c8) { flags += 'm'; }
	if (options.hx) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.ei.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.ei.b, xhr)); });
		$elm$core$Maybe$isJust(request.g2) && _Http_track(router, xhr, request.g2.a);

		try {
			xhr.open(request.hZ, request.g6, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.g6));
		}

		_Http_configureRequest(xhr, request);

		request.hw.a && xhr.setRequestHeader('Content-Type', request.hw.a);
		xhr.send(request.hw.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.eF; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.dj.a || 0;
	xhr.responseType = request.ei.d;
	xhr.withCredentials = request.ht;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		g6: xhr.responseURL,
		$8: xhr.status,
		ig: xhr.statusText,
		eF: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			ic: event.loaded,
			gu: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			h8: event.loaded,
			gu: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Main$UrlChange = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$UrlRequest = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.l) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.m),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.m);
		} else {
			var treeLen = builder.l * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.n) : builder.n;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.l);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.m) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.m);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{n: nodeList, l: (len / $elm$core$Array$branchFactor) | 0, m: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ev: fragment, hO: host, fL: path, fT: port_, f0: protocol, f3: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Session$Development = 1;
var $author$project$Session$NoOp = {$: 0};
var $author$project$Session$Unknown = 0;
var $author$project$Data$Refdata$Refdata = F7(
	function (brugsoorten, landen, provincies, sluissoorten, statussen, statussenByGroep, toewijzingstypen) {
		return {dP: brugsoorten, e6: landen, f2: provincies, gy: sluissoorten, cE: statussen, gD: statussenByGroep, gW: toewijzingstypen};
	});
var $author$project$Data$Refdata$Brugsoort = F2(
	function (id, naam) {
		return {cc: id, aY: naam};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Data$Refdata$decodeBrugsoort = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Data$Refdata$Brugsoort)));
var $author$project$Data$Refdata$Land = F4(
	function (code, link, naam, sort) {
		return {dW: code, cn: link, aY: naam, gz: sort};
	});
var $author$project$Data$Refdata$decodeLand = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'sort',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'naam',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'link',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'code',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Data$Refdata$Land)))));
var $author$project$Data$Refdata$Provincie = F4(
	function (code, id, link, naam) {
		return {dW: code, cc: id, cn: link, aY: naam};
	});
var $author$project$Data$Refdata$decodeProvincie = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'link',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'code',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Data$Refdata$Provincie)))));
var $author$project$Data$Refdata$Sluissoort = F2(
	function (id, naam) {
		return {cc: id, aY: naam};
	});
var $author$project$Data$Refdata$decodeSluissoort = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Data$Refdata$Sluissoort)));
var $author$project$Data$Refdata$Status = F5(
	function (groep, id, link, naam, volgorde) {
		return {eC: groep, cc: id, cn: link, aY: naam, hd: volgorde};
	});
var $author$project$Data$Refdata$StatusGroep = F2(
	function (id, naam) {
		return {cc: id, aY: naam};
	});
var $author$project$Data$Refdata$decodeStatusGroep = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Data$Refdata$StatusGroep)));
var $author$project$Data$Refdata$decodeStatus = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'volgorde',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'naam',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'link',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'groep',
					$author$project$Data$Refdata$decodeStatusGroep,
					$elm$json$Json$Decode$succeed($author$project$Data$Refdata$Status))))));
var $author$project$Data$Refdata$StatusByGroep = F3(
	function (id, naam, statussen) {
		return {cc: id, aY: naam, cE: statussen};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Data$Refdata$decodeStatusByGroep = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'statussen',
	$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeStatus),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'naam',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Data$Refdata$StatusByGroep))));
var $author$project$Data$Toewijzing$ToewijzingToewijzing = F4(
	function (beschrijving, code, id, naam) {
		return {bS: beschrijving, dW: code, cc: id, aY: naam};
	});
var $author$project$Data$Toewijzing$decodeToewijzingToewijzing = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'code',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'beschrijving',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Data$Toewijzing$ToewijzingToewijzing)))));
var $author$project$Data$Refdata$decodeRefdata = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'toewijzingstypen',
	$elm$json$Json$Decode$list($author$project$Data$Toewijzing$decodeToewijzingToewijzing),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'statussenByGroep',
		$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeStatusByGroep),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'statussen',
			$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeStatus),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'sluissoorten',
				$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeSluissoort),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'provincies',
					$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeProvincie),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'landen',
						$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeLand),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'brugsoorten',
							$elm$json$Json$Decode$list($author$project$Data$Refdata$decodeBrugsoort),
							$elm$json$Json$Decode$succeed($author$project$Data$Refdata$Refdata))))))));
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$Material$defaultModel = {dQ: $elm$core$Dict$empty, dT: $elm$core$Dict$empty, dU: $elm$core$Dict$empty, d5: $elm$core$Dict$empty, cV: $elm$core$Dict$empty, ek: $elm$core$Dict$empty, eB: $elm$core$Dict$empty, eL: $elm$core$Dict$empty, p: $elm$core$Dict$empty, f4: $elm$core$Dict$empty, U: $elm$core$Dict$empty, gr: $elm$core$Dict$empty, gv: $elm$core$Dict$empty, bz: $elm$core$Dict$empty, gH: $elm$core$Dict$empty, X: $elm$core$Dict$empty, gR: $elm$core$Dict$empty, dk: $elm$core$Dict$empty, g1: $elm$core$Dict$empty};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $truqu$elm_base64$Base64$Decode$pad = function (input) {
	var _v0 = $elm$core$String$length(input) % 4;
	switch (_v0) {
		case 3:
			return input + '=';
		case 2:
			return input + '==';
		default:
			return input;
	}
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $truqu$elm_base64$Base64$Decode$charToInt = function (_char) {
	switch (_char) {
		case 'A':
			return 0;
		case 'B':
			return 1;
		case 'C':
			return 2;
		case 'D':
			return 3;
		case 'E':
			return 4;
		case 'F':
			return 5;
		case 'G':
			return 6;
		case 'H':
			return 7;
		case 'I':
			return 8;
		case 'J':
			return 9;
		case 'K':
			return 10;
		case 'L':
			return 11;
		case 'M':
			return 12;
		case 'N':
			return 13;
		case 'O':
			return 14;
		case 'P':
			return 15;
		case 'Q':
			return 16;
		case 'R':
			return 17;
		case 'S':
			return 18;
		case 'T':
			return 19;
		case 'U':
			return 20;
		case 'V':
			return 21;
		case 'W':
			return 22;
		case 'X':
			return 23;
		case 'Y':
			return 24;
		case 'Z':
			return 25;
		case 'a':
			return 26;
		case 'b':
			return 27;
		case 'c':
			return 28;
		case 'd':
			return 29;
		case 'e':
			return 30;
		case 'f':
			return 31;
		case 'g':
			return 32;
		case 'h':
			return 33;
		case 'i':
			return 34;
		case 'j':
			return 35;
		case 'k':
			return 36;
		case 'l':
			return 37;
		case 'm':
			return 38;
		case 'n':
			return 39;
		case 'o':
			return 40;
		case 'p':
			return 41;
		case 'q':
			return 42;
		case 'r':
			return 43;
		case 's':
			return 44;
		case 't':
			return 45;
		case 'u':
			return 46;
		case 'v':
			return 47;
		case 'w':
			return 48;
		case 'x':
			return 49;
		case 'y':
			return 50;
		case 'z':
			return 51;
		case '0':
			return 52;
		case '1':
			return 53;
		case '2':
			return 54;
		case '3':
			return 55;
		case '4':
			return 56;
		case '5':
			return 57;
		case '6':
			return 58;
		case '7':
			return 59;
		case '8':
			return 60;
		case '9':
			return 61;
		case '+':
			return 62;
		case '/':
			return 63;
		default:
			return 0;
	}
};
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $truqu$elm_base64$Base64$Decode$intToString = A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar);
var $truqu$elm_base64$Base64$Decode$add = F2(
	function (_char, _v0) {
		var curr = _v0.a;
		var need = _v0.b;
		var res = _v0.c;
		var shiftAndAdd = function (_int) {
			return (63 & _int) | (curr << 6);
		};
		return (!need) ? ((!(128 & _char)) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(_char))) : (((224 & _char) === 192) ? _Utils_Tuple3(31 & _char, 1, res) : (((240 & _char) === 224) ? _Utils_Tuple3(15 & _char, 2, res) : _Utils_Tuple3(7 & _char, 3, res)))) : ((need === 1) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(
					shiftAndAdd(_char)))) : _Utils_Tuple3(
			shiftAndAdd(_char),
			need - 1,
			res));
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $truqu$elm_base64$Base64$Decode$toUTF16 = F2(
	function (_char, acc) {
		return _Utils_Tuple3(
			0,
			0,
			A2(
				$truqu$elm_base64$Base64$Decode$add,
				255 & (_char >>> 0),
				A2(
					$truqu$elm_base64$Base64$Decode$add,
					255 & (_char >>> 8),
					A2($truqu$elm_base64$Base64$Decode$add, 255 & (_char >>> 16), acc))));
	});
var $truqu$elm_base64$Base64$Decode$chomp = F2(
	function (char_, _v0) {
		var curr = _v0.a;
		var cnt = _v0.b;
		var utf8ToUtf16 = _v0.c;
		var _char = $truqu$elm_base64$Base64$Decode$charToInt(char_);
		if (cnt === 3) {
			return A2($truqu$elm_base64$Base64$Decode$toUTF16, curr | _char, utf8ToUtf16);
		} else {
			return _Utils_Tuple3((_char << ((3 - cnt) * 6)) | curr, cnt + 1, utf8ToUtf16);
		}
	});
var $elm$core$String$foldl = _String_foldl;
var $truqu$elm_base64$Base64$Decode$initial = _Utils_Tuple3(
	0,
	0,
	_Utils_Tuple3(0, 0, ''));
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$endsWith = _String_endsWith;
var $truqu$elm_base64$Base64$Decode$stripNulls = F2(
	function (input, output) {
		return A2($elm$core$String$endsWith, '==', input) ? A2($elm$core$String$dropRight, 2, output) : (A2($elm$core$String$endsWith, '=', input) ? A2($elm$core$String$dropRight, 1, output) : output);
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {aV: index, hY: match, h0: number, ii: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{hx: false, c8: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $truqu$elm_base64$Base64$Decode$validBase64Regex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z0-9\\/+]{4})*([A-Za-z0-9\\/+]{2}[A-Za-z0-9\\/+=]{2})?$'));
var $truqu$elm_base64$Base64$Decode$validate = function (input) {
	return A2($elm$regex$Regex$contains, $truqu$elm_base64$Base64$Decode$validBase64Regex, input) ? $elm$core$Result$Ok(input) : $elm$core$Result$Err('Invalid base64');
};
var $truqu$elm_base64$Base64$Decode$wrapUp = function (_v0) {
	var _v1 = _v0.c;
	var need = _v1.b;
	var res = _v1.c;
	return (need > 0) ? $elm$core$Result$Err('Invalid UTF-16') : $elm$core$Result$Ok(res);
};
var $truqu$elm_base64$Base64$Decode$validateAndDecode = function (input) {
	return A2(
		$elm$core$Result$map,
		$truqu$elm_base64$Base64$Decode$stripNulls(input),
		A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$core$String$foldl, $truqu$elm_base64$Base64$Decode$chomp, $truqu$elm_base64$Base64$Decode$initial),
				$truqu$elm_base64$Base64$Decode$wrapUp),
			$truqu$elm_base64$Base64$Decode$validate(input)));
};
var $truqu$elm_base64$Base64$Decode$decode = A2($elm$core$Basics$composeR, $truqu$elm_base64$Base64$Decode$pad, $truqu$elm_base64$Base64$Decode$validateAndDecode);
var $truqu$elm_base64$Base64$decode = $truqu$elm_base64$Base64$Decode$decode;
var $author$project$Data$Token$Payload$Payload = F6(
	function (gebruikersnaam, aud, rollen, rechten, iss, id) {
		return {dx: aud, ew: gebruikersnaam, cc: id, eR: iss, f5: rechten, gf: rollen};
	});
var $author$project$Data$Token$Payload$decodePayload = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'iss',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'rechten',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'rollen',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'aud',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'gebruikersnaam',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Data$Token$Payload$Payload)))))));
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $author$project$Data$Token$Recht$All = 0;
var $author$project$Data$Token$Recht$parse = function (string) {
	if (string === 'all') {
		return $elm$core$Maybe$Just(0);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Data$Token$Rol$Consul = 2;
var $author$project$Data$Token$Rol$Editor = 4;
var $author$project$Data$Token$Rol$Gebruiker = 3;
var $author$project$Data$Token$Rol$SuperConsul = 1;
var $author$project$Data$Token$Rol$SuperUser = 0;
var $author$project$Data$Token$Rol$parse = function (string) {
	switch (string) {
		case 'SuperUser':
			return $elm$core$Maybe$Just(0);
		case 'SuperConsul':
			return $elm$core$Maybe$Just(1);
		case 'Consul':
			return $elm$core$Maybe$Just(2);
		case 'Gebruiker':
			return $elm$core$Maybe$Just(3);
		case 'Redacteur':
			return $elm$core$Maybe$Just(4);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$saveJwt = F2(
	function (jwt, model) {
		var _v0 = function () {
			var _v1 = A2($elm$core$String$split, '.', jwt);
			if (((_v1.b && _v1.b.b) && _v1.b.b.b) && (!_v1.b.b.b.b)) {
				var _v2 = _v1.b;
				var base64 = _v2.a;
				var _v3 = _v2.b;
				var _v4 = $truqu$elm_base64$Base64$decode(base64);
				if (!_v4.$) {
					var json = _v4.a;
					var _v5 = A2($elm$json$Json$Decode$decodeString, $author$project$Data$Token$Payload$decodePayload, json);
					if (!_v5.$) {
						var payload = _v5.a;
						return _Utils_Tuple3(
							A2(
								$elm$core$List$filterMap,
								function (string) {
									return $author$project$Data$Token$Recht$parse(string);
								},
								A2($elm$core$String$split, ';', payload.f5)),
							A2(
								$elm$core$List$filterMap,
								function (string) {
									return $author$project$Data$Token$Rol$parse(string);
								},
								A2($elm$core$String$split, ';', payload.gf)),
							_List_Nil);
					} else {
						var err = _v5.a;
						return _Utils_Tuple3(
							_List_Nil,
							_List_Nil,
							_List_fromArray(
								[
									$elm$json$Json$Decode$errorToString(err)
								]));
					}
				} else {
					var errString = _v4.a;
					return _Utils_Tuple3(
						_List_Nil,
						_List_Nil,
						_List_fromArray(
							[errString]));
				}
			} else {
				return _Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil);
			}
		}();
		var rechten = _v0.a;
		var rollen = _v0.b;
		var errors = _v0.c;
		return _Utils_update(
			model,
			{
				y: A2($elm$core$List$append, errors, model.y),
				hV: jwt,
				f5: rechten,
				gf: rollen
			});
	});
var $author$project$Session$defaultModel = F4(
	function (key, url, value, host) {
		var flags = function () {
			var _v5 = A2(
				$elm$json$Json$Decode$decodeValue,
				$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
				value);
			if (_v5.$ === 1) {
				return $elm$core$Dict$empty;
			} else {
				var dict = _v5.a;
				return dict;
			}
		}();
		var refdata = function () {
			var _v3 = A2($elm$core$Dict$get, 'refdata', flags);
			if (_v3.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var json = _v3.a;
				var _v4 = A2($elm$json$Json$Decode$decodeString, $author$project$Data$Refdata$decodeRefdata, json);
				if (!_v4.$) {
					var refdata_ = _v4.a;
					return $elm$core$Maybe$Just(refdata_);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}();
		var jwt = function () {
			if (refdata.$ === 1) {
				return '';
			} else {
				var _v2 = A2($elm$core$Dict$get, 'jwt', flags);
				if (_v2.$ === 1) {
					return '';
				} else {
					var jwt_ = _v2.a;
					return jwt_;
				}
			}
		}();
		var environment = function () {
			if (host === 'localhost') {
				return 1;
			} else {
				return 0;
			}
		}();
		var model = {aP: $elm$core$Maybe$Nothing, d8: false, hD: environment, y: _List_Nil, c_: flags, hV: '', hX: $author$project$Session$NoOp, c6: $author$project$Material$defaultModel, h$: key, h6: false, f5: _List_Nil, h9: refdata, gf: _List_Nil, g6: url};
		return A2($author$project$Session$saveJwt, jwt, model);
	});
var $author$project$Main$Home = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$HomeMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$Kunstwerk = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$KunstwerkMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$Kunstwerken = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$KunstwerkenMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$Login = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$LoginMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$Toewijzingen = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$ToewijzingenMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$Vaarweg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$VaarwegMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$Vaarwegen = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$VaarwegenMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$Voorziening = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$VoorzieningMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$Voorzieningen = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$VoorzieningenMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Home$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$Session = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Material$init = function (lift) {
	return $elm$core$Platform$Cmd$none;
};
var $author$project$Session$Mdc = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Session$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$author$project$Material$init($author$project$Session$Mdc));
};
var $author$project$Session$progress = F2(
	function (value, container) {
		var session = container.W;
		return _Utils_update(
			container,
			{
				W: _Utils_update(
					session,
					{h6: value})
			});
	});
var $author$project$Page$Home$init = function (session) {
	return _Utils_Tuple2(
		A2(
			$author$project$Session$progress,
			false,
			{c6: $author$project$Material$defaultModel, W: session}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Material$init($author$project$Page$Home$Mdc),
					$author$project$Session$init($author$project$Page$Home$Session)
				])));
};
var $author$project$Page$Kunstwerk$Field = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Kunstwerk$GotKunstwerk = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Kunstwerk$Mdc = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Kunstwerk$PostKunstwerk = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Kunstwerk$Session = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Kunstwerk$Street = function (a) {
	return {$: 4, a: a};
};
var $author$project$Field$defaultModel = function (fieldList) {
	return {
		aw: $elm$core$Dict$fromList(fieldList),
		c6: $author$project$Material$defaultModel
	};
};
var $author$project$Field$FieldModel = F7(
	function (label, typed, focused, required, select, format, validate) {
		return {c$: focused, b8: format, e2: label, dc: required, gr: select, dl: typed, g9: validate};
	});
var $author$project$Field$defaultFieldModel = F4(
	function (label, required, maybeFormat, maybeValidate) {
		return A7(
			$author$project$Field$FieldModel,
			label,
			'',
			false,
			required,
			true,
			A2(
				$elm$core$Maybe$withDefault,
				function (string) {
					return string;
				},
				maybeFormat),
			A2(
				$elm$core$Maybe$withDefault,
				function (string) {
					return true;
				},
				maybeValidate));
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$Basics$pow = _Basics_pow;
var $elm$core$Basics$round = _Basics_round;
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Field$floatFormat = F3(
	function (decimals, pad, input) {
		var _v0 = $elm$core$String$toFloat(input);
		if (_v0.$ === 1) {
			return '';
		} else {
			var _float = _v0.a;
			var _v1 = A2($elm$core$String$split, '.', input);
			if ((_v1.b && _v1.b.b) && (!_v1.b.b.b)) {
				var first = _v1.a;
				var _v2 = _v1.b;
				var last = _v2.a;
				var _v3 = _Utils_Tuple2(
					pad,
					_Utils_cmp(
						$elm$core$String$length(last),
						decimals) > 0);
				if (!_v3.b) {
					if (!_v3.a) {
						return input;
					} else {
						return first + ('.' + A3($elm$core$String$padRight, decimals, '0', last));
					}
				} else {
					var fraction = A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toFloat('0.1' + last));
					var decimals_ = $elm$core$Basics$round(
						fraction * A2($elm$core$Basics$pow, 10, decimals + 1));
					var front = function () {
						if (_Utils_eq(
							decimals_,
							2 * A2($elm$core$Basics$pow, 10, decimals))) {
							var num = A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(first));
							var next = $elm$core$Basics$abs(num) + 1;
							var val = (num < 0) ? (0 - next) : next;
							return $elm$core$String$fromInt(val);
						} else {
							return first;
						}
					}();
					var back = A2(
						$elm$core$String$dropLeft,
						1,
						$elm$core$String$fromInt(decimals_));
					return front + ('.' + function () {
						if (!pad) {
							return back;
						} else {
							return A3($elm$core$String$padRight, decimals, '0', back);
						}
					}());
				}
			} else {
				if (!pad) {
					return input;
				} else {
					return input + ('.' + A2($elm$core$String$repeat, decimals, '0'));
				}
			}
		}
	});
var $author$project$Field$floatFieldModel = F5(
	function (label, required, decimals, pad, maybeValidate) {
		return A7(
			$author$project$Field$FieldModel,
			label,
			'',
			false,
			required,
			false,
			A2($author$project$Field$floatFormat, decimals, pad),
			A2(
				$elm$core$Maybe$withDefault,
				function (string) {
					return true;
				},
				maybeValidate));
	});
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Street$validateLat = function (string) {
	var _v0 = $elm$core$String$toFloat(string);
	if (_v0.$ === 1) {
		return false;
	} else {
		var lat = _v0.a;
		return (_Utils_cmp(lat, -90) > -1) && (lat <= 90);
	}
};
var $author$project$Street$validateLon = function (string) {
	var _v0 = $elm$core$String$toFloat(string);
	if (_v0.$ === 1) {
		return false;
	} else {
		var lon = _v0.a;
		return (_Utils_cmp(lon, -180) > -1) && (lon <= 180);
	}
};
var $author$project$Street$defaultModel = function (maybeLonLatZoom) {
	var _v0 = function () {
		if (maybeLonLatZoom.$ === 1) {
			return _Utils_Tuple3(-73.5255, 41.04932, 17);
		} else {
			var _v2 = maybeLonLatZoom.a;
			var lon_ = _v2.a;
			var lat_ = _v2.b;
			var zoom_ = _v2.c;
			return _Utils_Tuple3(lon_, lat_, zoom_);
		}
	}();
	var lon = _v0.a;
	var lat = _v0.b;
	var zoom = _v0.c;
	return {
		cY: $author$project$Field$defaultModel(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'lon',
					A5(
						$author$project$Field$floatFieldModel,
						'Lengtegraad',
						false,
						5,
						false,
						$elm$core$Maybe$Just($author$project$Street$validateLon))),
					_Utils_Tuple2(
					'lat',
					A5(
						$author$project$Field$floatFieldModel,
						'Breedtegraad',
						false,
						5,
						false,
						$elm$core$Maybe$Just($author$project$Street$validateLat))),
					_Utils_Tuple2(
					'place',
					A4($author$project$Field$defaultFieldModel, '', false, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing))
				])),
		aT: true,
		I: $elm$core$Maybe$Nothing,
		fb: lat,
		fj: lon,
		c6: $author$project$Material$defaultModel,
		aX: false,
		an: '',
		aG: _List_Nil,
		as: zoom
	};
};
var $author$project$Api$Base = function (a) {
	return {$: 0, a: a};
};
var $author$project$Data$Kunstwerk$Kunstwerk = function (id) {
	return function (aangemaakt) {
		return function (adres) {
			return function (afstandBediend) {
				return function (anwbBrugNummer) {
					return function (beheerderID) {
						return function (eigenaar) {
							return function (kopieVan) {
								return function (email) {
									return function (kunstwerkType) {
										return function (land) {
											return function (marifoonkanaal) {
												return function (maxHoogte) {
													return function (maxBreedte) {
														return function (mobiel) {
															return function (naam) {
																return function (openingstijden) {
																	return function (openingstijdenType) {
																		return function (opmerkingen) {
																			return function (postcode) {
																				return function (provincie) {
																					return function (referentie) {
																						return function (toelichting) {
																							return function (verwijderd) {
																								return function (web) {
																									return function (woonplaats) {
																										return function (telefoon) {
																											return function (coordinaat) {
																												return function (sluis) {
																													return function (brug) {
																														return function (vin) {
																															return function (perioden) {
																																return function (link) {
																																	return {dp: aangemaakt, dr: adres, dt: afstandBediend, bP: anwbBrugNummer, dB: beheerderID, dO: brug, bZ: coordinaat, ee: eigenaar, ef: email, cc: id, e_: kopieVan, cj: kunstwerkType, e5: land, cn: link, fk: marifoonkanaal, fm: maxBreedte, fp: maxHoogte, fw: mobiel, aY: naam, fE: openingstijden, fF: openingstijdenType, fG: opmerkingen, fP: perioden, fV: postcode, f1: provincie, f6: referentie, gx: sluis, gO: telefoon, gU: toelichting, ha: verwijderd, hc: vin, hm: web, cK: woonplaats};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Data$Kunstwerk$KunstwerkBrug = F3(
	function (beweegbaar, openingen, soort) {
		return {dF: beweegbaar, fD: openingen, cC: soort};
	});
var $author$project$Data$Kunstwerk$decodeKunstwerkBrug = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'soort',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'openingen',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'beweegbaar',
			$elm$json$Json$Decode$bool,
			$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$KunstwerkBrug))));
var $author$project$Data$Kunstwerk$KunstwerkCoordinaat = F2(
	function (lat, lon) {
		return {fb: lat, fj: lon};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Data$Kunstwerk$decodeKunstwerkCoordinaat = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lon',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'lat',
		$elm$json$Json$Decode$float,
		$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$KunstwerkCoordinaat)));
var $author$project$Data$Kunstwerk$KunstwerkSluis = F4(
	function (ingangen, maxDiepte, maxLengte, soort) {
		return {eM: ingangen, fn: maxDiepte, fq: maxLengte, cC: soort};
	});
var $author$project$Data$Kunstwerk$decodeKunstwerkSluis = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'soort',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'maxLengte',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'maxDiepte',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ingangen',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$KunstwerkSluis)))));
var $author$project$Data$Kunstwerk$KunstwerkTelefoon = F2(
	function (abonnee, net) {
		return {bL: abonnee, cq: net};
	});
var $author$project$Data$Kunstwerk$decodeKunstwerkTelefoon = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'net',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'abonnee',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$KunstwerkTelefoon)));
var $author$project$Data$Kunstwerk$KunstwerkVin = F3(
	function (code, x, y) {
		return {dW: code, hp: x, hq: y};
	});
var $author$project$Data$Kunstwerk$decodeKunstwerkVin = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'y',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'x',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'code',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$KunstwerkVin))));
var $author$project$Data$Kunstwerk$Periode = function (id) {
	return function (eigenaar) {
		return function (aangemaakt) {
			return function (kunstwerkID) {
				return function (opmerkingen) {
					return function (periode) {
						return function (link) {
							return function (dagen) {
								return function (showDagen) {
									return function (dirty) {
										return {dp: aangemaakt, b0: dagen, d8: dirty, ee: eigenaar, cc: id, ci: kunstwerkID, cn: link, fG: opmerkingen, fN: periode, ie: showDagen};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Kunstwerk$Dag = function (id) {
	return function (eigenaar) {
		return function (aangemaakt) {
			return function (dagen) {
				return function (kunstwerkID) {
					return function (periodeID) {
						return function (opmerkingen) {
							return function (volgorde) {
								return function (tijden) {
									return function (link) {
										return function (dirty) {
											return {dp: aangemaakt, b0: dagen, d8: dirty, ee: eigenaar, cc: id, ci: kunstwerkID, cn: link, fG: opmerkingen, fO: periodeID, gS: tijden, hd: volgorde};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Data$Kunstwerk$decodeDag = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'dirty',
	$elm$json$Json$Decode$bool,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'link',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tijden',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'volgorde',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'opmerkingen',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'periodeID',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'kunstwerkID',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'dagen',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'aangemaakt',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'eigenaar',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$elm$json$Json$Decode$int,
											$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$Dag))))))))))));
var $author$project$Data$Kunstwerk$decodePeriode = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'dirty',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'showDagen',
		$elm$json$Json$Decode$bool,
		false,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'dagen',
			$elm$json$Json$Decode$list($author$project$Data$Kunstwerk$decodeDag),
			_List_Nil,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'link',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'periode',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'opmerkingen',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'kunstwerkID',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'aangemaakt',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'eigenaar',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$int,
										$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$Periode)))))))))));
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Data$Kunstwerk$decodeKunstwerk = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'link',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'perioden',
		$elm$json$Json$Decode$list($author$project$Data$Kunstwerk$decodePeriode),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'vin',
			$author$project$Data$Kunstwerk$decodeKunstwerkVin,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'brug',
				$author$project$Data$Kunstwerk$decodeKunstwerkBrug,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'sluis',
					$author$project$Data$Kunstwerk$decodeKunstwerkSluis,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'coordinaat',
						$author$project$Data$Kunstwerk$decodeKunstwerkCoordinaat,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'telefoon',
							$author$project$Data$Kunstwerk$decodeKunstwerkTelefoon,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'woonplaats',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'web',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'verwijderd',
										$elm$json$Json$Decode$bool,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'toelichting',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'referentie',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'provincie',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'postcode',
														$elm$json$Json$Decode$string,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'opmerkingen',
															$elm$json$Json$Decode$string,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'openingstijdenType',
																$elm$json$Json$Decode$string,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'openingstijden',
																	$elm$json$Json$Decode$string,
																	A3(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																		'naam',
																		$elm$json$Json$Decode$string,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'mobiel',
																			$elm$json$Json$Decode$string,
																			A3(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																				'maxBreedte',
																				$elm$json$Json$Decode$float,
																				A3(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																					'maxHoogte',
																					$elm$json$Json$Decode$float,
																					A3(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																						'marifoonkanaal',
																						$elm$json$Json$Decode$int,
																						A3(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																							'land',
																							$elm$json$Json$Decode$string,
																							A3(
																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																								'kunstwerkType',
																								$elm$json$Json$Decode$string,
																								A3(
																									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																									'email',
																									$elm$json$Json$Decode$string,
																									A3(
																										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																										'kopieVan',
																										$elm$json$Json$Decode$int,
																										A3(
																											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																											'eigenaar',
																											$elm$json$Json$Decode$int,
																											A3(
																												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																												'beheerderID',
																												$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																												A3(
																													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																													'anwbBrugNummer',
																													$elm$json$Json$Decode$string,
																													A3(
																														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																														'afstandBediend',
																														$elm$json$Json$Decode$bool,
																														A3(
																															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																															'adres',
																															$elm$json$Json$Decode$string,
																															A3(
																																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																'aangemaakt',
																																$elm$json$Json$Decode$string,
																																A3(
																																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																	'id',
																																	$elm$json$Json$Decode$int,
																																	$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$Kunstwerk))))))))))))))))))))))))))))))))));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.$8));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl = F2(
	function (method, url) {
		return {
			hw: $elm$http$Http$emptyBody,
			ei: $elm$http$Http$expectString(
				function (_v0) {
					return 0;
				}),
			eF: _List_Nil,
			hZ: method,
			dj: $elm$core$Maybe$Nothing,
			g6: url,
			a9: false
		};
	});
var $lukewestby$elm_http_builder$HttpBuilder$get = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('GET');
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {f9: reqs, gG: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.g2;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.f9));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.gG)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					ht: r.ht,
					hw: r.hw,
					ei: A2(_Http_mapExpect, func, r.ei),
					eF: r.eF,
					hZ: r.hZ,
					dj: r.dj,
					g2: r.g2,
					g6: r.g6
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ht: false, hw: r.hw, ei: r.ei, eF: r.eF, hZ: r.hZ, dj: r.dj, g2: r.g2, g6: r.g6}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ht: true, hw: r.hw, ei: r.ei, eF: r.eF, hZ: r.hZ, dj: r.dj, g2: r.g2, g6: r.g6}));
};
var $lukewestby$elm_http_builder$HttpBuilder$request = function (builder) {
	var req = builder.a9 ? $elm$http$Http$riskyRequest : $elm$http$Http$request;
	return req(
		{hw: builder.hw, ei: builder.ei, eF: builder.eF, hZ: builder.hZ, dj: builder.dj, g2: $elm$core$Maybe$Nothing, g6: builder.g6});
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $lukewestby$elm_http_builder$HttpBuilder$withBearerToken = F2(
	function (value, builder) {
		return _Utils_update(
			builder,
			{
				eF: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, 'Authorization', 'Bearer ' + value),
					builder.eF)
			});
	});
var $author$project$Api$send = F2(
	function (_v0, requestbuilder) {
		var jwt = _v0.hV;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2($lukewestby$elm_http_builder$HttpBuilder$withBearerToken, jwt, requestbuilder));
	});
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $author$project$Api$url = F2(
	function (_v0, api) {
		var environment = _v0.hD;
		var url_ = F2(
			function (pathComponents, queryParameters) {
				if (environment === 1) {
					return A3(
						$elm$url$Url$Builder$crossOrigin,
						'http://localhost:9090',
						A2($elm$core$List$cons, 'ANWB-API', pathComponents),
						queryParameters);
				} else {
					return A2(
						$elm$url$Url$Builder$absolute,
						A2(
							$elm$core$List$cons,
							'anwbwa',
							A2(
								$elm$core$List$cons,
								'api',
								A2($elm$core$List$cons, 'ANWB-API', pathComponents))),
						queryParameters);
				}
			});
		switch (api.$) {
			case 0:
				var link = api.a;
				var base = A2(url_, _List_Nil, _List_Nil);
				return _Utils_ap(
					A2($elm$core$String$endsWith, '/', base) ? A2($elm$core$String$dropRight, 1, base) : base,
					link);
			case 1:
				return A2(
					url_,
					_List_fromArray(
						['api', 'security', 'login']),
					_List_Nil);
			case 2:
				var email = api.a;
				return A2(
					url_,
					_List_fromArray(
						['api', 'security', 'wachtwoord', email]),
					_List_Nil);
			case 3:
				return A2(
					url_,
					_List_fromArray(
						['api', 'security', 'wachtwoord']),
					_List_Nil);
			case 4:
				return A2(
					url_,
					_List_fromArray(
						['api', 'refdata']),
					_List_Nil);
			case 5:
				return A2(
					url_,
					_List_fromArray(
						['api', 'voorzieningen']),
					_List_Nil);
			case 6:
				return A2(
					url_,
					_List_fromArray(
						['api', 'vaarwegen']),
					_List_Nil);
			case 7:
				return A2(
					url_,
					_List_fromArray(
						['api', 'kunstwerken']),
					_List_Nil);
			case 8:
				return A2(
					url_,
					_List_fromArray(
						['api', 'beheerders']),
					_List_Nil);
			case 9:
				return A2(
					url_,
					_List_fromArray(
						['api', 'toewijzingen']),
					_List_Nil);
			case 10:
				var who = api.a;
				return A2(
					url_,
					_List_fromArray(
						['api', 'flow', 'editie', 'mail', who]),
					_List_Nil);
			case 11:
				var id = api.a;
				return A2(
					url_,
					_List_fromArray(
						[
							'api',
							'voorzieningen',
							$elm$core$String$fromInt(id),
							'status'
						]),
					_List_Nil);
			case 12:
				var maybeID = api.a;
				if (maybeID.$ === 1) {
					return A2(
						url_,
						_List_fromArray(
							['api', 'perioden']),
						_List_Nil);
				} else {
					var id = maybeID.a;
					return A2(
						url_,
						_List_fromArray(
							[
								'api',
								'perioden',
								$elm$core$String$fromInt(id)
							]),
						_List_Nil);
				}
			default:
				var maybeID = api.a;
				if (maybeID.$ === 1) {
					return A2(
						url_,
						_List_fromArray(
							['api', 'dagen']),
						_List_Nil);
				} else {
					var id = maybeID.a;
					return A2(
						url_,
						_List_fromArray(
							[
								'api',
								'dagen',
								$elm$core$String$fromInt(id)
							]),
						_List_Nil);
				}
		}
	});
var $lukewestby$elm_http_builder$HttpBuilder$withExpect = F2(
	function (expect, builder) {
		return {hw: builder.hw, ei: expect, eF: builder.eF, hZ: builder.hZ, dj: builder.dj, g6: builder.g6, a9: builder.a9};
	});
var $author$project$Api$get = F4(
	function (msg, session, api, decoder) {
		return A2(
			$author$project$Api$send,
			session,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($elm$http$Http$expectJson, msg, decoder),
				$lukewestby$elm_http_builder$HttpBuilder$get(
					A2($author$project$Api$url, session, api))));
	});
var $author$project$Api$getKunstwerk = F3(
	function (msg, link, session) {
		return A4(
			$author$project$Api$get,
			msg,
			session,
			$author$project$Api$Base(link),
			$author$project$Data$Kunstwerk$decodeKunstwerk);
	});
var $author$project$Field$Mdc = function (a) {
	return {$: 0, a: a};
};
var $author$project$Field$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$author$project$Material$init($author$project$Field$Mdc));
};
var $author$project$Street$Field = function (a) {
	return {$: 2, a: a};
};
var $author$project$Street$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Street$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Material$init($author$project$Street$Mdc),
					$author$project$Field$init($author$project$Street$Field)
				])));
};
var $author$project$Api$Kunstwerken = {$: 7};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $lukewestby$elm_http_builder$HttpBuilder$post = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('POST');
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $lukewestby$elm_http_builder$HttpBuilder$withBody = F2(
	function (body, builder) {
		return _Utils_update(
			builder,
			{hw: body});
	});
var $lukewestby$elm_http_builder$HttpBuilder$withJsonBody = function (value) {
	return $lukewestby$elm_http_builder$HttpBuilder$withBody(
		$elm$http$Http$jsonBody(value));
};
var $author$project$Api$post = F5(
	function (msg, session, api, decoder, body) {
		return A2(
			$author$project$Api$send,
			session,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($elm$http$Http$expectJson, msg, decoder),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
					body,
					$lukewestby$elm_http_builder$HttpBuilder$post(
						A2($author$project$Api$url, session, api)))));
	});
var $author$project$Api$postKunstwerk = F2(
	function (msg, session) {
		return A5($author$project$Api$post, msg, session, $author$project$Api$Kunstwerken, $author$project$Data$Kunstwerk$decodeKunstwerk, $elm$json$Json$Encode$null);
	});
var $author$project$Page$Kunstwerk$init = F2(
	function (session, link) {
		return _Utils_Tuple2(
			A2(
				$author$project$Session$progress,
				true,
				{
					cY: $author$project$Field$defaultModel(_List_Nil),
					aU: true,
					k: $elm$core$Maybe$Nothing,
					c6: $author$project$Material$defaultModel,
					W: session,
					aH: false,
					df: $author$project$Street$defaultModel(
						$elm$core$Maybe$Just(
							_Utils_Tuple3(4.3276, 52.09702, 17.5)))
				}),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					function () {
						if (link === '') {
							return A2($author$project$Api$postKunstwerk, $author$project$Page$Kunstwerk$PostKunstwerk, session);
						} else {
							return A3($author$project$Api$getKunstwerk, $author$project$Page$Kunstwerk$GotKunstwerk, link, session);
						}
					}(),
					_List_fromArray(
						[
							$author$project$Material$init($author$project$Page$Kunstwerk$Mdc),
							$author$project$Field$init($author$project$Page$Kunstwerk$Field),
							$author$project$Session$init($author$project$Page$Kunstwerk$Session),
							$author$project$Street$init($author$project$Page$Kunstwerk$Street)
						]))));
	});
var $author$project$Page$Kunstwerken$Kunstwerken = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Kunstwerken$Model = F2(
	function (session, kunstwerken) {
		return {e1: kunstwerken, W: session};
	});
var $author$project$Page$Kunstwerken$Session = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Partial$Filter$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Partial$Filter$Value = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Internal$Options$Class = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$Options$cs = function (c) {
	return $author$project$Internal$Options$Class(c);
};
var $author$project$Material$Options$cs = $author$project$Internal$Options$cs;
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Internal$Dispatch$flatten = function (decoders) {
	var tryMergeStep = F3(
		function (value, decoder, result) {
			return A2(
				$elm$core$Maybe$withDefault,
				result,
				A2(
					$elm$core$Maybe$map,
					function (_v0) {
						var message = _v0.fu;
						var stopPropagation = _v0.gE;
						var preventDefault = _v0.fW;
						return {
							fu: A2($elm$core$List$cons, message, result.fu),
							fW: preventDefault || result.fW,
							gE: stopPropagation || result.gE
						};
					},
					$elm$core$Result$toMaybe(
						A2($elm$json$Json$Decode$decodeValue, decoder, value))));
		});
	var tryMerge = function (value) {
		return A3(
			$elm$core$List$foldl,
			tryMergeStep(value),
			{fu: _List_Nil, fW: false, gE: false},
			decoders);
	};
	return A2($elm$json$Json$Decode$map, tryMerge, $elm$json$Json$Decode$value);
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Internal$Dispatch$toAttributes = function (_v0) {
	var config = _v0;
	var _v1 = config.c4;
	if (!_v1.$) {
		var lift = _v1.a;
		return A2(
			$elm$core$List$map,
			function (_v3) {
				var event = _v3.a;
				var flatDecoder = _v3.b;
				return A2(
					$elm$html$Html$Events$custom,
					event,
					lift(flatDecoder));
			},
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$map,
					function (_v2) {
						return $author$project$Internal$Dispatch$flatten;
					},
					config.af)));
	} else {
		return A2(
			$elm$core$List$concatMap,
			function (_v4) {
				var event = _v4.a;
				var decoders = _v4.b;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$Events$custom(event),
					decoders);
			},
			$elm$core$Dict$toList(config.af));
	}
};
var $author$project$Internal$Options$addAttributes = F2(
	function (summary, attrs) {
		return _Utils_ap(
			summary.at,
			_Utils_ap(
				A2(
					$elm$core$List$map,
					function (_v0) {
						var key = _v0.a;
						var value = _v0.b;
						return A2($elm$html$Html$Attributes$style, key, value);
					},
					summary.av),
				_Utils_ap(
					A2($elm$core$List$map, $elm$html$Html$Attributes$class, summary.au),
					_Utils_ap(
						attrs,
						_Utils_ap(
							summary.aA,
							$author$project$Internal$Dispatch$toAttributes(summary.u))))));
	});
var $author$project$Internal$Options$Summary = F6(
	function (classes, css, attrs, internal, dispatch, config) {
		return {at: attrs, au: classes, dY: config, av: css, u: dispatch, aA: internal};
	});
var $author$project$Internal$Dispatch$Config = $elm$core$Basics$identity;
var $author$project$Internal$Dispatch$add = F3(
	function (event, decoder, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{
				af: A3(
					$elm$core$Dict$update,
					event,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$elm$core$List$cons(decoder)),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault(
								_List_fromArray(
									[decoder])),
							$elm$core$Maybe$Just)),
					config.af)
			});
	});
var $author$project$Internal$Dispatch$setLift = F2(
	function (lift, _v0) {
		var decoders = _v0.af;
		return {
			af: decoders,
			c4: $elm$core$Maybe$Just(lift)
		};
	});
var $author$project$Internal$Options$collect1_ = F2(
	function (options, acc) {
		switch (options.$) {
			case 0:
				var x = options.a;
				return _Utils_update(
					acc,
					{
						au: A2($elm$core$List$cons, x, acc.au)
					});
			case 1:
				var x = options.a;
				return _Utils_update(
					acc,
					{
						av: A2($elm$core$List$cons, x, acc.av)
					});
			case 2:
				var x = options.a;
				return _Utils_update(
					acc,
					{
						at: A2($elm$core$List$cons, x, acc.at)
					});
			case 3:
				var x = options.a;
				return _Utils_update(
					acc,
					{
						aA: A2($elm$core$List$cons, x, acc.aA)
					});
			case 6:
				var event = options.a;
				var decoder = options.b;
				return _Utils_update(
					acc,
					{
						u: A3($author$project$Internal$Dispatch$add, event, decoder, acc.u)
					});
			case 4:
				var opts = options.a;
				return A3($elm$core$List$foldl, $author$project$Internal$Options$collect1_, acc, opts);
			case 7:
				var lift = options.a;
				return _Utils_update(
					acc,
					{
						u: A2($author$project$Internal$Dispatch$setLift, lift, acc.u)
					});
			case 5:
				return acc;
			default:
				return acc;
		}
	});
var $author$project$Internal$Dispatch$defaultConfig = {af: $elm$core$Dict$empty, c4: $elm$core$Maybe$Nothing};
var $author$project$Internal$Options$collect_ = A2(
	$elm$core$List$foldl,
	$author$project$Internal$Options$collect1_,
	A6($author$project$Internal$Options$Summary, _List_Nil, _List_Nil, _List_Nil, _List_Nil, $author$project$Internal$Dispatch$defaultConfig, 0));
var $author$project$Internal$Options$styled = F2(
	function (ctor, props) {
		return ctor(
			A2(
				$author$project$Internal$Options$addAttributes,
				$author$project$Internal$Options$collect_(props),
				_List_Nil));
	});
var $author$project$Internal$LayoutGrid$Implementation$cell = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-layout-grid__cell'),
			options));
};
var $author$project$Material$LayoutGrid$cell = $author$project$Internal$LayoutGrid$Implementation$cell;
var $author$project$Page$Partial$Filter$fieldModel = F2(
	function (label, validateFilter) {
		return $author$project$Field$defaultModel(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'textFilter',
					A4(
						$author$project$Field$defaultFieldModel,
						label,
						false,
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just(validateFilter)))
				]));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Internal$LayoutGrid$Implementation$span = F2(
	function (device, value) {
		if (!device.$) {
			var device_ = device.a;
			return $author$project$Internal$Options$cs(
				'mdc-layout-grid__cell--span-' + ($elm$core$String$fromInt(value) + ('-' + device_)));
		} else {
			return $author$project$Internal$Options$cs(
				'mdc-layout-grid__cell--span-' + $elm$core$String$fromInt(value));
		}
	});
var $author$project$Internal$LayoutGrid$Implementation$span12 = A2($author$project$Internal$LayoutGrid$Implementation$span, $elm$core$Maybe$Nothing, 12);
var $author$project$Material$LayoutGrid$span12 = $author$project$Internal$LayoutGrid$Implementation$span12;
var $elm$core$String$toLower = _String_toLower;
var $author$project$Page$Partial$Filter$defaultModel = F6(
	function (maybeLabel, maybeCaption, maybeFilter, maybeValidateFilter, maybeSort, maybeControls) {
		var validateFilter = A2(
			$elm$core$Maybe$withDefault,
			function (_v8) {
				return true;
			},
			maybeValidateFilter);
		var label = A2($elm$core$Maybe$withDefault, 'Filter op naam', maybeLabel);
		var filter = A2(
			$elm$core$Maybe$withDefault,
			F3(
				function (textFilter, _v6, _v7) {
					var naam = _v7.aY;
					return A2(
						$elm$core$String$contains,
						$elm$core$String$toLower(textFilter),
						$elm$core$String$toLower(naam));
				}),
			maybeFilter);
		var controls = function () {
			if (maybeControls.$ === 1) {
				return F2(
					function (_v4, _v5) {
						return _List_Nil;
					});
			} else {
				var controls_ = maybeControls.a;
				return F2(
					function (mdc, values_) {
						return _List_fromArray(
							[
								A2(
								$author$project$Material$LayoutGrid$cell,
								_List_fromArray(
									[$author$project$Material$LayoutGrid$span12]),
								A2(controls_, mdc, values_))
							]);
					});
			}
		}();
		var caption = A2(
			$elm$core$Maybe$withDefault,
			function (_v2) {
				var naam = _v2.aY;
				return naam;
			},
			maybeCaption);
		var sort = A2(
			$elm$core$Maybe$withDefault,
			F3(
				function (_v0, _v1, list) {
					return A2(
						$elm$core$List$sortBy,
						function (item) {
							return $elm$core$String$toLower(
								caption(item));
						},
						list);
				}),
			maybeSort);
		return {
			cQ: caption,
			cS: controls,
			cY: A2($author$project$Page$Partial$Filter$fieldModel, label, validateFilter),
			b4: filter,
			bo: _List_Nil,
			c6: $author$project$Material$defaultModel,
			C: 0,
			cu: 75,
			by: _List_Nil,
			gz: sort,
			ao: '',
			dn: validateFilter,
			bH: $elm$core$Dict$empty
		};
	});
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Internal$Options$Listener = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Internal$Options$on = F2(
	function (event, decodeMessage) {
		return A2(
			$author$project$Internal$Options$Listener,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (message) {
					return {fu: message, fW: false, gE: false};
				},
				decodeMessage));
	});
var $author$project$Internal$Options$onClick = function (msg) {
	return A2(
		$author$project$Internal$Options$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Material$Options$onClick = $author$project$Internal$Options$onClick;
var $author$project$Internal$Options$Set = function (a) {
	return {$: 5, a: a};
};
var $author$project$Internal$Options$option = $author$project$Internal$Options$Set;
var $author$project$Internal$RadioButton$Implementation$selected = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{bG: true});
	});
var $author$project$Material$RadioButton$selected = $author$project$Internal$RadioButton$Implementation$selected;
var $author$project$Material$Options$styled = $author$project$Internal$Options$styled;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Internal$FormField$Implementation$view = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-form-field'),
			options));
};
var $author$project$Material$FormField$view = $author$project$Internal$FormField$Implementation$view;
var $author$project$Internal$Msg$RadioButtonMsg = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Internal$Ripple$Model$Idle = {$: 0};
var $author$project$Internal$Ripple$Model$defaultModel = {aM: 0, x: $author$project$Internal$Ripple$Model$Idle, c$: false};
var $author$project$Internal$RadioButton$Model$defaultModel = {hT: false, U: $author$project$Internal$Ripple$Model$defaultModel};
var $author$project$Internal$Component$indexed = F3(
	function (get_model, set_model, model0) {
		var set_ = F3(
			function (idx, store, model) {
				return A2(
					set_model,
					A3(
						$elm$core$Dict$insert,
						idx,
						model,
						get_model(store)),
					store);
			});
		var get_ = F2(
			function (idx, store) {
				return A2(
					$elm$core$Maybe$withDefault,
					model0,
					A2(
						$elm$core$Dict$get,
						idx,
						get_model(store)));
			});
		return {ey: get_, id: set_};
	});
var $author$project$Internal$RadioButton$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.f4;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{f4: x});
		}),
	$author$project$Internal$RadioButton$Model$defaultModel);
var $author$project$Internal$Options$internalId = function (id_) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{c3: id_});
		});
};
var $author$project$Internal$RadioButton$Model$NoOp = {$: 1};
var $author$project$Internal$RadioButton$Model$RippleMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$RadioButton$Model$SetFocus = function (a) {
	return {$: 2, a: a};
};
var $author$project$Internal$Options$collect1 = F2(
	function (opt, acc) {
		switch (opt.$) {
			case 0:
				var x = opt.a;
				return _Utils_update(
					acc,
					{
						au: A2($elm$core$List$cons, x, acc.au)
					});
			case 1:
				var x = opt.a;
				return _Utils_update(
					acc,
					{
						av: A2($elm$core$List$cons, x, acc.av)
					});
			case 2:
				var x = opt.a;
				return _Utils_update(
					acc,
					{
						at: A2($elm$core$List$cons, x, acc.at)
					});
			case 3:
				var x = opt.a;
				return _Utils_update(
					acc,
					{
						aA: A2($elm$core$List$cons, x, acc.aA)
					});
			case 4:
				var opts = opt.a;
				return A3($elm$core$List$foldl, $author$project$Internal$Options$collect1, acc, opts);
			case 5:
				var g = opt.a;
				return _Utils_update(
					acc,
					{
						dY: g(acc.dY)
					});
			case 6:
				var event = opt.a;
				var decoder = opt.b;
				return _Utils_update(
					acc,
					{
						u: A3($author$project$Internal$Dispatch$add, event, decoder, acc.u)
					});
			case 7:
				var lift = opt.a;
				return _Utils_update(
					acc,
					{
						u: A2($author$project$Internal$Dispatch$setLift, lift, acc.u)
					});
			default:
				return acc;
		}
	});
var $author$project$Internal$Options$recollect = $elm$core$List$foldl($author$project$Internal$Options$collect1);
var $author$project$Internal$Options$apply = F4(
	function (summary, ctor, options, attrs) {
		return ctor(
			A2(
				$author$project$Internal$Options$addAttributes,
				A2($author$project$Internal$Options$recollect, summary, options),
				attrs));
	});
var $author$project$Internal$Dispatch$clear = function (_v0) {
	var config = _v0;
	return _Utils_update(
		config,
		{af: $elm$core$Dict$empty});
};
var $author$project$Internal$Options$applyNativeControl = F3(
	function (summary, ctor, options) {
		return ctor(
			A2(
				$author$project$Internal$Options$addAttributes,
				A2(
					$author$project$Internal$Options$recollect,
					{
						at: _List_Nil,
						au: _List_Nil,
						dY: 0,
						av: _List_Nil,
						u: $author$project$Internal$Dispatch$clear(summary.u),
						aA: _List_Nil
					},
					_Utils_ap(summary.dY.fz, options)),
				_List_Nil));
	});
var $author$project$Internal$Options$Attribute = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $author$project$Internal$Options$attribute = A2(
	$elm$core$Basics$composeL,
	$author$project$Internal$Options$Attribute,
	$elm$html$Html$Attributes$map($elm$core$Basics$never));
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Internal$Options$collect = A2(
	$elm$core$Basics$composeR,
	A5($author$project$Internal$Options$Summary, _List_Nil, _List_Nil, _List_Nil, _List_Nil, $author$project$Internal$Dispatch$defaultConfig),
	$author$project$Internal$Options$recollect);
var $author$project$Internal$RadioButton$Implementation$defaultConfig = {aQ: false, c3: '', fz: _List_Nil, bG: false};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Internal$Options$id = A2($elm$core$Basics$composeL, $author$project$Internal$Options$Attribute, $elm$html$Html$Attributes$id);
var $elm$html$Html$input = _VirtualDom_node('input');
var $author$project$Internal$Options$Many = function (a) {
	return {$: 4, a: a};
};
var $author$project$Internal$Options$many = $author$project$Internal$Options$Many;
var $author$project$Internal$Options$onBlur = function (msg) {
	return A2(
		$author$project$Internal$Options$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Internal$Options$onFocus = function (msg) {
	return A2(
		$author$project$Internal$Options$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Internal$Options$onWithOptions = function (evt) {
	return $author$project$Internal$Options$Listener(evt);
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Internal$Ripple$Model$Blur = {$: 1};
var $author$project$Internal$Ripple$Model$Deactivate = {$: 6};
var $author$project$Internal$Ripple$Model$Focus = {$: 0};
var $author$project$Internal$Ripple$Model$activationEventTypes = _List_fromArray(
	['touchstart', 'pointerdown', 'mousedown']);
var $author$project$Internal$Ripple$Model$cssClasses = {hv: 'mdc-ripple-upgraded--background-focused', hG: 'mdc-ripple-upgraded--foreground-activation', hH: 'mdc-ripple-upgraded--foreground-deactivation', ib: 'mdc-ripple-upgraded', ip: 'mdc-rippe-upgraded--unbounded'};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $truqu$elm_md5$MD5$emptyWords = A2($elm$core$Array$repeat, 16, 0);
var $truqu$elm_md5$MD5$addUnsigned = F2(
	function (x, y) {
		return 4294967295 & (x + y);
	});
var $truqu$elm_md5$MD5$rotateLeft = F2(
	function (bits, input) {
		return (input << bits) | (input >>> (32 - bits));
	});
var $truqu$elm_md5$MD5$cmn = F8(
	function (fun, a, b, c, d, x, s, ac) {
		return A2(
			$truqu$elm_md5$MD5$addUnsigned,
			b,
			A2(
				$truqu$elm_md5$MD5$rotateLeft,
				s,
				A2(
					$truqu$elm_md5$MD5$addUnsigned,
					a,
					A2(
						$truqu$elm_md5$MD5$addUnsigned,
						ac,
						A2(
							$truqu$elm_md5$MD5$addUnsigned,
							A3(fun, b, c, d),
							x)))));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $truqu$elm_md5$MD5$f = F3(
	function (x, y, z) {
		return z ^ (x & (y ^ z));
	});
var $truqu$elm_md5$MD5$ff = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$f, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$g = F3(
	function (x, y, z) {
		return y ^ (z & (x ^ y));
	});
var $truqu$elm_md5$MD5$gg = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$g, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$h = F3(
	function (x, y, z) {
		return z ^ (x ^ y);
	});
var $truqu$elm_md5$MD5$hh = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$h, a, b, c, d, x, s, ac);
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $truqu$elm_md5$MD5$i = F3(
	function (x, y, z) {
		return y ^ (x | (~z));
	});
var $truqu$elm_md5$MD5$ii = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$i, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$hex_ = F2(
	function (xs, acc) {
		var a = acc.bJ;
		var b = acc.bQ;
		var c = acc.bV;
		var d = acc.b$;
		if ((((((((((((((((xs.b && xs.b.b) && xs.b.b.b) && xs.b.b.b.b) && xs.b.b.b.b.b) && xs.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && (!xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b)) {
			var x0 = xs.a;
			var _v1 = xs.b;
			var x1 = _v1.a;
			var _v2 = _v1.b;
			var x2 = _v2.a;
			var _v3 = _v2.b;
			var x3 = _v3.a;
			var _v4 = _v3.b;
			var x4 = _v4.a;
			var _v5 = _v4.b;
			var x5 = _v5.a;
			var _v6 = _v5.b;
			var x6 = _v6.a;
			var _v7 = _v6.b;
			var x7 = _v7.a;
			var _v8 = _v7.b;
			var x8 = _v8.a;
			var _v9 = _v8.b;
			var x9 = _v9.a;
			var _v10 = _v9.b;
			var x10 = _v10.a;
			var _v11 = _v10.b;
			var x11 = _v11.a;
			var _v12 = _v11.b;
			var x12 = _v12.a;
			var _v13 = _v12.b;
			var x13 = _v13.a;
			var _v14 = _v13.b;
			var x14 = _v14.a;
			var _v15 = _v14.b;
			var x15 = _v15.a;
			var s44 = 21;
			var s43 = 15;
			var s42 = 10;
			var s41 = 6;
			var s34 = 23;
			var s33 = 16;
			var s32 = 11;
			var s31 = 4;
			var s24 = 20;
			var s23 = 14;
			var s22 = 9;
			var s21 = 5;
			var s14 = 22;
			var s13 = 17;
			var s12 = 12;
			var s11 = 7;
			var d00 = d;
			var c00 = c;
			var b00 = b;
			var a00 = a;
			var a01 = A7($truqu$elm_md5$MD5$ff, a00, b00, c00, d00, x0, s11, 3614090360);
			var d01 = A7($truqu$elm_md5$MD5$ff, d00, a01, b00, c00, x1, s12, 3905402710);
			var c01 = A7($truqu$elm_md5$MD5$ff, c00, d01, a01, b00, x2, s13, 606105819);
			var b01 = A7($truqu$elm_md5$MD5$ff, b00, c01, d01, a01, x3, s14, 3250441966);
			var a02 = A7($truqu$elm_md5$MD5$ff, a01, b01, c01, d01, x4, s11, 4118548399);
			var d02 = A7($truqu$elm_md5$MD5$ff, d01, a02, b01, c01, x5, s12, 1200080426);
			var c02 = A7($truqu$elm_md5$MD5$ff, c01, d02, a02, b01, x6, s13, 2821735955);
			var b02 = A7($truqu$elm_md5$MD5$ff, b01, c02, d02, a02, x7, s14, 4249261313);
			var a03 = A7($truqu$elm_md5$MD5$ff, a02, b02, c02, d02, x8, s11, 1770035416);
			var d03 = A7($truqu$elm_md5$MD5$ff, d02, a03, b02, c02, x9, s12, 2336552879);
			var c03 = A7($truqu$elm_md5$MD5$ff, c02, d03, a03, b02, x10, s13, 4294925233);
			var b03 = A7($truqu$elm_md5$MD5$ff, b02, c03, d03, a03, x11, s14, 2304563134);
			var a04 = A7($truqu$elm_md5$MD5$ff, a03, b03, c03, d03, x12, s11, 1804603682);
			var d04 = A7($truqu$elm_md5$MD5$ff, d03, a04, b03, c03, x13, s12, 4254626195);
			var c04 = A7($truqu$elm_md5$MD5$ff, c03, d04, a04, b03, x14, s13, 2792965006);
			var b04 = A7($truqu$elm_md5$MD5$ff, b03, c04, d04, a04, x15, s14, 1236535329);
			var a05 = A7($truqu$elm_md5$MD5$gg, a04, b04, c04, d04, x1, s21, 4129170786);
			var d05 = A7($truqu$elm_md5$MD5$gg, d04, a05, b04, c04, x6, s22, 3225465664);
			var c05 = A7($truqu$elm_md5$MD5$gg, c04, d05, a05, b04, x11, s23, 643717713);
			var b05 = A7($truqu$elm_md5$MD5$gg, b04, c05, d05, a05, x0, s24, 3921069994);
			var a06 = A7($truqu$elm_md5$MD5$gg, a05, b05, c05, d05, x5, s21, 3593408605);
			var d06 = A7($truqu$elm_md5$MD5$gg, d05, a06, b05, c05, x10, s22, 38016083);
			var c06 = A7($truqu$elm_md5$MD5$gg, c05, d06, a06, b05, x15, s23, 3634488961);
			var b06 = A7($truqu$elm_md5$MD5$gg, b05, c06, d06, a06, x4, s24, 3889429448);
			var a07 = A7($truqu$elm_md5$MD5$gg, a06, b06, c06, d06, x9, s21, 568446438);
			var d07 = A7($truqu$elm_md5$MD5$gg, d06, a07, b06, c06, x14, s22, 3275163606);
			var c07 = A7($truqu$elm_md5$MD5$gg, c06, d07, a07, b06, x3, s23, 4107603335);
			var b07 = A7($truqu$elm_md5$MD5$gg, b06, c07, d07, a07, x8, s24, 1163531501);
			var a08 = A7($truqu$elm_md5$MD5$gg, a07, b07, c07, d07, x13, s21, 2850285829);
			var d08 = A7($truqu$elm_md5$MD5$gg, d07, a08, b07, c07, x2, s22, 4243563512);
			var c08 = A7($truqu$elm_md5$MD5$gg, c07, d08, a08, b07, x7, s23, 1735328473);
			var b08 = A7($truqu$elm_md5$MD5$gg, b07, c08, d08, a08, x12, s24, 2368359562);
			var a09 = A7($truqu$elm_md5$MD5$hh, a08, b08, c08, d08, x5, s31, 4294588738);
			var d09 = A7($truqu$elm_md5$MD5$hh, d08, a09, b08, c08, x8, s32, 2272392833);
			var c09 = A7($truqu$elm_md5$MD5$hh, c08, d09, a09, b08, x11, s33, 1839030562);
			var b09 = A7($truqu$elm_md5$MD5$hh, b08, c09, d09, a09, x14, s34, 4259657740);
			var a10 = A7($truqu$elm_md5$MD5$hh, a09, b09, c09, d09, x1, s31, 2763975236);
			var d10 = A7($truqu$elm_md5$MD5$hh, d09, a10, b09, c09, x4, s32, 1272893353);
			var c10 = A7($truqu$elm_md5$MD5$hh, c09, d10, a10, b09, x7, s33, 4139469664);
			var b10 = A7($truqu$elm_md5$MD5$hh, b09, c10, d10, a10, x10, s34, 3200236656);
			var a11 = A7($truqu$elm_md5$MD5$hh, a10, b10, c10, d10, x13, s31, 681279174);
			var d11 = A7($truqu$elm_md5$MD5$hh, d10, a11, b10, c10, x0, s32, 3936430074);
			var c11 = A7($truqu$elm_md5$MD5$hh, c10, d11, a11, b10, x3, s33, 3572445317);
			var b11 = A7($truqu$elm_md5$MD5$hh, b10, c11, d11, a11, x6, s34, 76029189);
			var a12 = A7($truqu$elm_md5$MD5$hh, a11, b11, c11, d11, x9, s31, 3654602809);
			var d12 = A7($truqu$elm_md5$MD5$hh, d11, a12, b11, c11, x12, s32, 3873151461);
			var c12 = A7($truqu$elm_md5$MD5$hh, c11, d12, a12, b11, x15, s33, 530742520);
			var b12 = A7($truqu$elm_md5$MD5$hh, b11, c12, d12, a12, x2, s34, 3299628645);
			var a13 = A7($truqu$elm_md5$MD5$ii, a12, b12, c12, d12, x0, s41, 4096336452);
			var d13 = A7($truqu$elm_md5$MD5$ii, d12, a13, b12, c12, x7, s42, 1126891415);
			var c13 = A7($truqu$elm_md5$MD5$ii, c12, d13, a13, b12, x14, s43, 2878612391);
			var b13 = A7($truqu$elm_md5$MD5$ii, b12, c13, d13, a13, x5, s44, 4237533241);
			var a14 = A7($truqu$elm_md5$MD5$ii, a13, b13, c13, d13, x12, s41, 1700485571);
			var d14 = A7($truqu$elm_md5$MD5$ii, d13, a14, b13, c13, x3, s42, 2399980690);
			var c14 = A7($truqu$elm_md5$MD5$ii, c13, d14, a14, b13, x10, s43, 4293915773);
			var b14 = A7($truqu$elm_md5$MD5$ii, b13, c14, d14, a14, x1, s44, 2240044497);
			var a15 = A7($truqu$elm_md5$MD5$ii, a14, b14, c14, d14, x8, s41, 1873313359);
			var d15 = A7($truqu$elm_md5$MD5$ii, d14, a15, b14, c14, x15, s42, 4264355552);
			var c15 = A7($truqu$elm_md5$MD5$ii, c14, d15, a15, b14, x6, s43, 2734768916);
			var b15 = A7($truqu$elm_md5$MD5$ii, b14, c15, d15, a15, x13, s44, 1309151649);
			var a16 = A7($truqu$elm_md5$MD5$ii, a15, b15, c15, d15, x4, s41, 4149444226);
			var d16 = A7($truqu$elm_md5$MD5$ii, d15, a16, b15, c15, x11, s42, 3174756917);
			var c16 = A7($truqu$elm_md5$MD5$ii, c15, d16, a16, b15, x2, s43, 718787259);
			var b16 = A7($truqu$elm_md5$MD5$ii, b15, c16, d16, a16, x9, s44, 3951481745);
			var b17 = A2($truqu$elm_md5$MD5$addUnsigned, b00, b16);
			var c17 = A2($truqu$elm_md5$MD5$addUnsigned, c00, c16);
			var d17 = A2($truqu$elm_md5$MD5$addUnsigned, d00, d16);
			var a17 = A2($truqu$elm_md5$MD5$addUnsigned, a00, a16);
			return {bJ: a17, bQ: b17, bV: c17, b$: d17};
		} else {
			return acc;
		}
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $truqu$elm_md5$MD5$iget = F2(
	function (index, array) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Array$get, index, array));
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $truqu$elm_md5$MD5$consume = F2(
	function (_char, _v0) {
		var hashState = _v0.a;
		var _v1 = _v0.b;
		var byteCount = _v1.a;
		var words = _v1.b;
		var totalByteCount = _v0.c;
		var wordCount = (byteCount / 4) | 0;
		var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
		var bytePosition = 8 * (byteCount % 4);
		var code = _char << bytePosition;
		var newWord = oldWord | code;
		var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
		return (byteCount === 63) ? _Utils_Tuple3(
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState),
			_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
			totalByteCount + 1) : _Utils_Tuple3(
			hashState,
			_Utils_Tuple2(byteCount + 1, newWords),
			totalByteCount + 1);
	});
var $truqu$elm_md5$MD5$finishUp = function (_v0) {
	var hashState = _v0.a;
	var _v1 = _v0.b;
	var byteCount = _v1.a;
	var words = _v1.b;
	var totalByteCount = _v0.c;
	var wordCount = (byteCount / 4) | 0;
	var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
	var bytePosition = 8 * (byteCount % 4);
	var code = 128 << bytePosition;
	var newWord = oldWord | code;
	var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
	return (wordCount < 14) ? function (x) {
		return A2($truqu$elm_md5$MD5$hex_, x, hashState);
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, newWords)))) : function (x) {
		return A2(
			$truqu$elm_md5$MD5$hex_,
			x,
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState));
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, $truqu$elm_md5$MD5$emptyWords))));
};
var $zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8 = F3(
	function (add, _char, acc) {
		return (_char < 128) ? A2(add, _char, acc) : ((_char < 2048) ? A2(
			add,
			128 | (63 & _char),
			A2(add, 192 | (_char >>> 6), acc)) : ((_char < 65536) ? A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(add, 224 | (_char >>> 12), acc))) : A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(
					add,
					128 | (63 & (_char >>> 12)),
					A2(add, 240 | (_char >>> 18), acc))))));
	});
var $zwilias$elm_utf_tools$String$UTF8$foldl = F3(
	function (op, initialAcc, input) {
		return A3(
			$elm$core$String$foldl,
			F2(
				function (_char, acc) {
					return A3(
						$zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8,
						op,
						$elm$core$Char$toCode(_char),
						acc);
				}),
			initialAcc,
			input);
	});
var $truqu$elm_md5$MD5$State = F4(
	function (a, b, c, d) {
		return {bJ: a, bQ: b, bV: c, b$: d};
	});
var $truqu$elm_md5$MD5$initialHashState = A4($truqu$elm_md5$MD5$State, 1732584193, 4023233417, 2562383102, 271733878);
var $truqu$elm_md5$MD5$hash = function (input) {
	return $truqu$elm_md5$MD5$finishUp(
		A3(
			$zwilias$elm_utf_tools$String$UTF8$foldl,
			$truqu$elm_md5$MD5$consume,
			_Utils_Tuple3(
				$truqu$elm_md5$MD5$initialHashState,
				_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
				0),
			input));
};
var $truqu$elm_md5$MD5$bytes = function (string) {
	var _v0 = $truqu$elm_md5$MD5$hash(string);
	var a = _v0.bJ;
	var b = _v0.bQ;
	var c = _v0.bV;
	var d = _v0.b$;
	return _List_fromArray(
		[a & 255, (a >>> 8) & 255, (a >>> 16) & 255, (a >>> 24) & 255, b & 255, (b >>> 8) & 255, (b >>> 16) & 255, (b >>> 24) & 255, c & 255, (c >>> 8) & 255, (c >>> 16) & 255, (c >>> 24) & 255, d & 255, (d >>> 8) & 255, (d >>> 16) & 255, (d >>> 24) & 255]);
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $truqu$elm_md5$MD5$toHex = function (_byte) {
	switch (_byte) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return 'a';
		case 11:
			return 'b';
		case 12:
			return 'c';
		case 13:
			return 'd';
		case 14:
			return 'e';
		case 15:
			return 'f';
		default:
			return _Utils_ap(
				$truqu$elm_md5$MD5$toHex((_byte / 16) | 0),
				$truqu$elm_md5$MD5$toHex(_byte % 16));
	}
};
var $truqu$elm_md5$MD5$hex = function (s) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (b, acc) {
				return _Utils_ap(
					acc,
					A3(
						$elm$core$String$padLeft,
						2,
						'0',
						$truqu$elm_md5$MD5$toHex(b)));
			}),
		'',
		$truqu$elm_md5$MD5$bytes(s));
};
var $author$project$Internal$Ripple$Model$strings = {ir: '--mdc-ripple-fg-scale', is: '--mdc-ripple-fg-size', it: '--mdc-ripple-fg-translate-end', iu: '--mdc-ripple-fg-translate-start', iv: '--mdc-ripple-left', iw: '--mdc-ripple-top'};
var $author$project$Internal$Ripple$Implementation$cssVariables = F2(
	function (isUnbounded, _v0) {
		var fgSize = _v0.cX;
		var fgScale = _v0.ag;
		var translateStart = _v0.aq;
		var translateEnd = _v0.ap;
		var initialSize = _v0.J;
		var frame = _v0.ai;
		var unboundedCoords = isUnbounded ? {
			fc: $elm$core$Basics$round((frame.hn - initialSize) / 2),
			g0: $elm$core$Basics$round((frame.eH - initialSize) / 2)
		} : {fc: 0, g0: 0};
		var className = 'mdc-ripple--' + $truqu$elm_md5$MD5$hex(
			$elm$core$String$concat(
				_List_fromArray(
					[
						fgSize,
						$elm$core$String$fromFloat(fgScale),
						$elm$core$String$fromFloat(unboundedCoords.g0),
						$elm$core$String$fromFloat(unboundedCoords.fc),
						translateStart,
						translateEnd
					])));
		var text = function (someString) {
			return '.' + (className + ('{' + (someString + '}')));
		}(
			A3(
				$elm$core$Basics$composeL,
				$elm$core$String$concat,
				$elm$core$List$map(
					function (_v1) {
						var key = _v1.a;
						var value = _v1.b;
						return key + (':' + (value + ' !important;'));
					}),
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								_Utils_Tuple2($author$project$Internal$Ripple$Model$strings.is, fgSize),
								_Utils_Tuple2(
								$author$project$Internal$Ripple$Model$strings.ir,
								$elm$core$String$fromFloat(fgScale))
							]),
							isUnbounded ? _List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$Internal$Ripple$Model$strings.iw,
								$elm$core$String$fromFloat(unboundedCoords.g0) + 'px'),
								_Utils_Tuple2(
								$author$project$Internal$Ripple$Model$strings.iv,
								$elm$core$String$fromFloat(unboundedCoords.fc) + 'px')
							]) : _List_fromArray(
							[
								_Utils_Tuple2($author$project$Internal$Ripple$Model$strings.iu, translateStart),
								_Utils_Tuple2($author$project$Internal$Ripple$Model$strings.it, translateEnd)
							])
						]))));
		return {cR: className, dh: text};
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Internal$Options$data = F2(
	function (key, val) {
		return $author$project$Internal$Options$Attribute(
			A2($elm$html$Html$Attributes$attribute, 'data-' + key, val));
	});
var $author$project$Internal$Ripple$Model$Activate0 = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Internal$Ripple$Model$Event = F2(
	function (eventType, pagePoint) {
		return {hE: eventType, h5: pagePoint};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Internal$Ripple$Implementation$decodeActivate = function (_v0) {
	var domId = _v0.ea;
	var isUnbounded = _v0.bn;
	var isActivated = _v0.eO;
	var previousActivationEvent = _v0.fX;
	var decodePagePoint = A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (pageX, pageY) {
				return {fH: pageX, fI: pageY};
			}),
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['pageX']),
			$elm$json$Json$Decode$float),
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['pageY']),
			$elm$json$Json$Decode$float));
	var firstChangedTouch = A2(
		$elm$json$Json$Decode$andThen,
		function (changedTouches) {
			var _v3 = $elm$core$List$head(changedTouches);
			if (!_v3.$) {
				var pagePoint = _v3.a;
				return $elm$json$Json$Decode$succeed(pagePoint);
			} else {
				return $elm$json$Json$Decode$fail('');
			}
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['changedTouches']),
			$elm$json$Json$Decode$list(decodePagePoint)));
	var decodeIsSurfaceDisabled = $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$elm$core$Basics$always(true),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['disabled']),
					$elm$json$Json$Decode$string)),
				$elm$json$Json$Decode$succeed(false)
			]));
	var decodeEventType = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['type']),
		$elm$json$Json$Decode$string);
	var decodeIsSameInteraction = function () {
		if (previousActivationEvent.$ === 1) {
			return $elm$json$Json$Decode$succeed(false);
		} else {
			var event = previousActivationEvent.a;
			return A2(
				$elm$json$Json$Decode$map,
				$elm$core$Basics$eq(event.hE),
				decodeEventType);
		}
	}();
	var decodeEvent = A2(
		$elm$json$Json$Decode$andThen,
		function (eventType) {
			if (eventType === 'touchstart') {
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Internal$Ripple$Model$Event(eventType),
					firstChangedTouch);
			} else {
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Internal$Ripple$Model$Event(eventType),
					decodePagePoint);
			}
		},
		decodeEventType);
	return A2(
		$elm$json$Json$Decode$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($elm$json$Json$Decode$succeed),
			$elm$core$Maybe$withDefault(
				$elm$json$Json$Decode$fail(''))),
		A4(
			$elm$json$Json$Decode$map3,
			F3(
				function (isSurfaceDisabled, isSameInteraction, event) {
					return (isActivated || (isSurfaceDisabled || isSameInteraction)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
						A2(
							$author$project$Internal$Ripple$Model$Activate0,
							domId,
							{cW: event, hU: false, bn: isUnbounded, $7: false}));
				}),
			decodeIsSurfaceDisabled,
			decodeIsSameInteraction,
			decodeEvent));
};
var $author$project$Internal$Ripple$Implementation$defaultConfig = {bY: $elm$core$Maybe$Nothing};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Internal$Options$None = {$: 8};
var $author$project$Internal$Options$nop = $author$project$Internal$Options$None;
var $elm$core$Basics$not = _Basics_not;
var $author$project$Internal$Ripple$Model$pointerDeactivationEvents = _List_fromArray(
	['touchend', 'pointerup', 'mouseup']);
var $author$project$Internal$Options$when = F2(
	function (guard, prop) {
		return guard ? prop : $author$project$Internal$Options$nop;
	});
var $author$project$Internal$Ripple$Implementation$view = F5(
	function (isUnbounded, domId, lift, model, options) {
		var noStyle = A3(
			$elm$html$Html$node,
			'style',
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('text/css')
				]),
			_List_Nil);
		var focusHandler = A2(
			$author$project$Internal$Options$on,
			'focus',
			$elm$json$Json$Decode$succeed(
				lift($author$project$Internal$Ripple$Model$Focus)));
		var deactivateHandler = function (event) {
			return $author$project$Internal$Options$many(
				A2(
					$elm$core$List$map,
					function (tipe) {
						return A2(
							$author$project$Internal$Options$on,
							tipe,
							$elm$json$Json$Decode$succeed(
								lift($author$project$Internal$Ripple$Model$Deactivate)));
					},
					$author$project$Internal$Ripple$Model$pointerDeactivationEvents));
		};
		var blurHandler = A2(
			$author$project$Internal$Options$on,
			'blur',
			$elm$json$Json$Decode$succeed(
				lift($author$project$Internal$Ripple$Model$Blur)));
		var baseProperties = $author$project$Internal$Options$many(
			_List_fromArray(
				[
					$author$project$Internal$Options$id(domId),
					$author$project$Internal$Options$cs($author$project$Internal$Ripple$Model$cssClasses.ib),
					A2(
					$author$project$Internal$Options$when,
					isUnbounded,
					$author$project$Internal$Options$cs($author$project$Internal$Ripple$Model$cssClasses.ip)),
					model.c$ ? $author$project$Internal$Options$cs($author$project$Internal$Ripple$Model$cssClasses.hv) : $author$project$Internal$Options$nop
				]));
		var baseInteractionHandler = $author$project$Internal$Options$many(
			_List_fromArray(
				[focusHandler, blurHandler]));
		var activateHandler = $author$project$Internal$Options$many(
			A2(
				$elm$core$List$map,
				function (tipe) {
					return A2(
						$author$project$Internal$Options$on,
						tipe,
						A2(
							$elm$json$Json$Decode$map,
							lift,
							$author$project$Internal$Ripple$Implementation$decodeActivate(
								{
									ea: domId,
									eO: function () {
										var _v2 = model.x;
										if (_v2.$ === 1) {
											var activationState = _v2.a;
											return !activationState.b1;
										} else {
											return false;
										}
									}(),
									bn: isUnbounded,
									fX: function () {
										var _v3 = model.x;
										if (_v3.$ === 1) {
											var activationEvent = _v3.a.aK;
											return activationEvent;
										} else {
											return $elm$core$Maybe$Nothing;
										}
									}()
								})));
				},
				$author$project$Internal$Ripple$Model$activationEventTypes));
		var _v0 = A2($author$project$Internal$Options$collect, $author$project$Internal$Ripple$Implementation$defaultConfig, options);
		var config = _v0.dY;
		var _v1 = model.x;
		switch (_v1.$) {
			case 0:
				var style = noStyle;
				var properties = baseProperties;
				var interactionHandler = $author$project$Internal$Options$many(
					_List_fromArray(
						[baseInteractionHandler, activateHandler]));
				return {hS: interactionHandler, h7: properties, ih: style};
			case 1:
				var activatedData = _v1.a;
				var interactionHandler = $author$project$Internal$Options$many(
					_List_fromArray(
						[
							baseInteractionHandler,
							activateHandler,
							deactivateHandler(activatedData.aK)
						]));
				var cssVars = A2(
					$author$project$Internal$Ripple$Implementation$cssVariables,
					isUnbounded,
					{
						ag: activatedData.ag,
						cX: $elm$core$String$fromFloat(activatedData.J) + 'px',
						ai: activatedData.ai,
						J: activatedData.J,
						ap: activatedData.ap,
						aq: activatedData.aq
					});
				var properties = $author$project$Internal$Options$many(
					_List_fromArray(
						[
							baseProperties,
							$author$project$Internal$Options$cs(cssVars.cR),
							$author$project$Internal$Options$cs($author$project$Internal$Ripple$Model$cssClasses.hG),
							A2(
							$author$project$Internal$Options$when,
							isUnbounded,
							A2($author$project$Internal$Options$data, 'mdc-ripple-is-unbounded', '1'))
						]));
				var style = A3(
					$elm$html$Html$node,
					'style',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text/css')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(cssVars.dh)
						]));
				return {hS: interactionHandler, h7: properties, ih: style};
			default:
				var activatedData = _v1.a;
				var interactionHandler = $author$project$Internal$Options$many(
					_List_fromArray(
						[baseInteractionHandler, activateHandler]));
				var cssVars = A2(
					$author$project$Internal$Ripple$Implementation$cssVariables,
					isUnbounded,
					{
						ag: activatedData.ag,
						cX: $elm$core$String$fromFloat(activatedData.J) + 'px',
						ai: activatedData.ai,
						J: activatedData.J,
						ap: activatedData.ap,
						aq: activatedData.aq
					});
				var properties = $author$project$Internal$Options$many(
					_List_fromArray(
						[
							baseProperties,
							$author$project$Internal$Options$cs(cssVars.cR),
							$author$project$Internal$Options$cs($author$project$Internal$Ripple$Model$cssClasses.hH)
						]));
				var style = A3(
					$elm$html$Html$node,
					'style',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text/css')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(cssVars.dh)
						]));
				return {hS: interactionHandler, h7: properties, ih: style};
		}
	});
var $author$project$Internal$RadioButton$Implementation$radioButton = F5(
	function (domId, lift, model, options, _v0) {
		var ripple = A5(
			$author$project$Internal$Ripple$Implementation$view,
			true,
			domId,
			A2($elm$core$Basics$composeL, lift, $author$project$Internal$RadioButton$Model$RippleMsg),
			model.U,
			_List_Nil);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$RadioButton$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-radio'),
					$author$project$Internal$Options$many(
					_List_fromArray(
						[ripple.hS, ripple.h7]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Internal$Options$applyNativeControl,
					summary,
					$elm$html$Html$input,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-radio__native-control'),
							$author$project$Internal$Options$id(config.c3),
							$author$project$Internal$Options$attribute(
							$elm$html$Html$Attributes$type_('radio')),
							$author$project$Internal$Options$attribute(
							$elm$html$Html$Attributes$checked(config.bG)),
							$author$project$Internal$Options$onFocus(
							lift(
								$author$project$Internal$RadioButton$Model$SetFocus(true))),
							$author$project$Internal$Options$onBlur(
							lift(
								$author$project$Internal$RadioButton$Model$SetFocus(false))),
							A2(
							$author$project$Internal$Options$onWithOptions,
							'click',
							$elm$json$Json$Decode$succeed(
								{
									fu: lift($author$project$Internal$RadioButton$Model$NoOp),
									fW: true,
									gE: false
								})),
							A3(
							$elm$core$Basics$composeL,
							$author$project$Internal$Options$when(config.aQ),
							$author$project$Internal$Options$many,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-radio--disabled'),
									$author$project$Internal$Options$attribute(
									$elm$html$Html$Attributes$disabled(true))
								]))
						]),
					_List_Nil),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-radio__background')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-radio__inner-circle')
								]),
							_List_Nil),
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-radio__outer-circle')
								]),
							_List_Nil)
						])),
					ripple.ih
				]));
	});
var $author$project$Internal$Msg$Dispatch = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$Options$Lift = function (a) {
	return {$: 7, a: a};
};
var $author$project$Internal$Options$dispatch = function (lift) {
	return $author$project$Internal$Options$Lift(
		$elm$json$Json$Decode$map(
			function (_v0) {
				var message = _v0.fu;
				var stopPropagation = _v0.gE;
				var preventDefault = _v0.fW;
				return {
					fu: lift(
						$author$project$Internal$Msg$Dispatch(message)),
					fW: preventDefault,
					gE: stopPropagation
				};
			}));
};
var $author$project$Internal$Component$render = F3(
	function (get_model, view, ctor) {
		return F4(
			function (lift, idx, store, options) {
				return A3(
					view,
					A2(
						$elm$core$Basics$composeL,
						lift,
						ctor(idx)),
					A2(get_model, idx, store),
					A2(
						$elm$core$List$cons,
						$author$project$Internal$Options$dispatch(lift),
						options));
			});
	});
var $author$project$Internal$RadioButton$Implementation$view = F4(
	function (lift, domId, store, options) {
		return A7(
			$author$project$Internal$Component$render,
			$author$project$Internal$RadioButton$Implementation$getSet.ey,
			$author$project$Internal$RadioButton$Implementation$radioButton(domId),
			$author$project$Internal$Msg$RadioButtonMsg,
			lift,
			domId,
			store,
			A2(
				$elm$core$List$cons,
				$author$project$Internal$Options$internalId(domId),
				options));
	});
var $author$project$Material$RadioButton$view = $author$project$Internal$RadioButton$Implementation$view;
var $author$project$Material$Options$when = $author$project$Internal$Options$when;
var $author$project$Page$Partial$Kunstwerken$defaultModel = function () {
	var sort = F3(
		function (textFilter, _v5, list) {
			return A2(
				$elm$core$List$sortBy,
				function () {
					var _v6 = $elm$core$String$toInt(textFilter);
					if (_v6.$ === 1) {
						return function (_v7) {
							var naam = _v7.aY;
							return $elm$core$String$toLower(naam);
						};
					} else {
						return function (_v8) {
							var anwbBrugNummer = _v8.bP;
							return $elm$core$String$toLower(anwbBrugNummer);
						};
					}
				}(),
				list);
		});
	var label_ = 'Filter op naam, nummer of vaarweg';
	var filter = F3(
		function (textFilter, values, _v3) {
			var naam = _v3.aY;
			var anwbBrugNummer = _v3.bP;
			var vaarweg = _v3.g7;
			var kunstwerkType = _v3.cj;
			return function () {
				var _v4 = values('type');
				if (_v4 === '') {
					return true;
				} else {
					var typeValue = _v4;
					return _Utils_eq(kunstwerkType, typeValue);
				}
			}() && (A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(textFilter),
				$elm$core$String$toLower(naam)) || (A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(textFilter),
				$elm$core$String$toLower(vaarweg)) || A2(
				$elm$core$String$startsWith,
				$elm$core$String$toLower(textFilter),
				$elm$core$String$toLower(anwbBrugNummer))));
		});
	var controls = F2(
		function (mdc, values) {
			var filterRadio = F2(
				function (typeFilter, caption_) {
					return A2(
						$author$project$Material$FormField$view,
						_List_fromArray(
							[
								$author$project$Material$Options$cs('field')
							]),
						_List_fromArray(
							[
								A5(
								$author$project$Material$RadioButton$view,
								$author$project$Page$Partial$Filter$Mdc,
								'filter-type-' + caption_,
								mdc,
								_List_fromArray(
									[
										A2(
										$author$project$Material$Options$when,
										_Utils_eq(
											values('type'),
											typeFilter),
										$author$project$Material$RadioButton$selected),
										$author$project$Material$Options$onClick(
										A2($author$project$Page$Partial$Filter$Value, 'type', typeFilter))
									]),
								_List_Nil),
								A3(
								$author$project$Material$Options$styled,
								$elm$html$Html$label,
								_List_fromArray(
									[
										$author$project$Material$Options$onClick(
										A2($author$project$Page$Partial$Filter$Value, 'type', typeFilter))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(caption_)
									]))
							]));
				});
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(filterRadio, 'b', 'Bruggen'),
							A2(filterRadio, 's', 'Sluizen'),
							A2(filterRadio, '', 'Beide')
						]))
				]);
		});
	var caption = function (_v0) {
		var naam = _v0.aY;
		var anwbBrugNummer = _v0.bP;
		var vaarweg = _v0.g7;
		return _Utils_ap(
			naam,
			_Utils_ap(
				function () {
					if (anwbBrugNummer === '') {
						return '';
					} else {
						return ' - ' + anwbBrugNummer;
					}
				}(),
				function () {
					if (vaarweg === '') {
						return '';
					} else {
						return ' - ' + vaarweg;
					}
				}()));
	};
	return {
		b4: A6(
			$author$project$Page$Partial$Filter$defaultModel,
			$elm$core$Maybe$Just(label_),
			$elm$core$Maybe$Just(caption),
			$elm$core$Maybe$Just(filter),
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Just(sort),
			$elm$core$Maybe$Just(controls))
	};
}();
var $author$project$Page$Partial$Kunstwerken$Filter = $elm$core$Basics$identity;
var $author$project$Page$Partial$Filter$GotItems = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Partial$Filter$get = F3(
	function (lift, api, container) {
		var session = container.W;
		return _Utils_Tuple2(
			A2($author$project$Session$progress, true, container),
			A2(
				$elm$core$Platform$Cmd$map,
				lift,
				A2(api, $author$project$Page$Partial$Filter$GotItems, session)));
	});
var $author$project$Data$Kunstwerk$KunstwerkKort = F7(
	function (anwbBrugNummer, coordinaat, id, kunstwerkType, link, naam, vaarweg) {
		return {bP: anwbBrugNummer, bZ: coordinaat, cc: id, cj: kunstwerkType, cn: link, aY: naam, g7: vaarweg};
	});
var $author$project$Data$Kunstwerk$decodeKunstwerkKort = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'vaarweg',
	$elm$json$Json$Decode$string,
	'',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'naam',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'link',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'kunstwerkType',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'coordinaat',
						$author$project$Data$Kunstwerk$decodeKunstwerkCoordinaat,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'anwbBrugNummer',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Data$Kunstwerk$KunstwerkKort))))))));
var $author$project$Data$Kunstwerk$list = $elm$json$Json$Decode$list($author$project$Data$Kunstwerk$decodeKunstwerkKort);
var $author$project$Api$getKunstwerken = F2(
	function (msg, session) {
		return A4($author$project$Api$get, msg, session, $author$project$Api$Kunstwerken, $author$project$Data$Kunstwerk$list);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Page$Partial$Kunstwerken$get = F2(
	function (lift, container) {
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A3($author$project$Page$Partial$Filter$get, $elm$core$Basics$identity, $author$project$Api$getKunstwerken, container));
	});
var $author$project$Page$Partial$Filter$Field = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Partial$Filter$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Material$init($author$project$Page$Partial$Filter$Mdc),
					$author$project$Field$init($author$project$Page$Partial$Filter$Field)
				])));
};
var $author$project$Page$Partial$Kunstwerken$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$Partial$Filter$init($elm$core$Basics$identity)
				])));
};
var $author$project$Page$Kunstwerken$init = function (session) {
	return A2(
		$elm$core$Tuple$mapSecond,
		function (cmd) {
			return $elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					cmd,
					_List_fromArray(
						[
							$author$project$Session$init($author$project$Page$Kunstwerken$Session),
							$author$project$Page$Partial$Kunstwerken$init($author$project$Page$Kunstwerken$Kunstwerken)
						])));
		},
		A2(
			$author$project$Page$Partial$Kunstwerken$get,
			$author$project$Page$Kunstwerken$Kunstwerken,
			A2($author$project$Page$Kunstwerken$Model, session, $author$project$Page$Partial$Kunstwerken$defaultModel)));
};
var $author$project$Page$Login$Field = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Login$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$Session = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$LoginAction = 0;
var $author$project$Page$Login$PasswordChangeAction = 2;
var $author$project$Page$Login$defaultModel = F2(
	function (session, maybeToken) {
		var _v0 = function () {
			if (maybeToken.$ === 1) {
				return _Utils_Tuple2(0, '');
			} else {
				var token_ = maybeToken.a;
				return _Utils_Tuple2(2, token_);
			}
		}();
		var action = _v0.a;
		var token = _v0.b;
		return {
			cL: action,
			ef: '',
			cY: $author$project$Field$defaultModel(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'email',
						A4($author$project$Field$defaultFieldModel, 'E-mailadres', true, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)),
						_Utils_Tuple2(
						'password',
						A4($author$project$Field$defaultFieldModel, 'Wachtwoord', true, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)),
						_Utils_Tuple2(
						'password2',
						A4($author$project$Field$defaultFieldModel, 'Wachtwoord nogmaals', true, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing))
					])),
			c6: $author$project$Material$defaultModel,
			fu: '',
			aZ: '',
			cv: '',
			W: session,
			gX: token
		};
	});
var $author$project$Page$Login$init = F2(
	function (session, maybeToken) {
		return _Utils_Tuple2(
			A2($author$project$Page$Login$defaultModel, session, maybeToken),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Material$init($author$project$Page$Login$Mdc),
						$author$project$Session$init($author$project$Page$Login$Session),
						$author$project$Field$init($author$project$Page$Login$Field)
					])));
	});
var $author$project$Page$Toewijzingen$Filter = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Toewijzingen$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Toewijzingen$Session = function (a) {
	return {$: 2, a: a};
};
var $author$project$Material$Options$attribute = $author$project$Internal$Options$attribute;
var $author$project$Internal$Options$CSS = function (a) {
	return {$: 1, a: a};
};
var $author$project$Internal$Options$css = F2(
	function (key, value) {
		return $author$project$Internal$Options$CSS(
			_Utils_Tuple2(key, value));
	});
var $author$project$Material$Options$css = $author$project$Internal$Options$css;
var $author$project$Internal$Button$Implementation$ripple = $author$project$Internal$Options$option(
	function (options) {
		return _Utils_update(
			options,
			{U: true});
	});
var $author$project$Material$Button$ripple = $author$project$Internal$Button$Implementation$ripple;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Internal$Msg$ButtonMsg = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Internal$Button$Model$Click = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Internal$Button$Model$RippleMsg = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Internal$Button$Implementation$defaultConfig = {aQ: false, ca: $elm$core$Maybe$Nothing, cn: $elm$core$Maybe$Nothing, cs: $elm$core$Maybe$Nothing, U: false};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Internal$Options$aria = F2(
	function (key, val) {
		return $author$project$Internal$Options$Attribute(
			A2($elm$html$Html$Attributes$attribute, 'aria-' + key, val));
	});
var $author$project$Internal$Icon$Implementation$defaultConfig = {cr: 'i'};
var $author$project$Internal$Icon$Implementation$view = F2(
	function (options, name) {
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Icon$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$node(config.cr),
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('material-icons'),
					A2($author$project$Internal$Options$aria, 'hidden', 'true')
				]),
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(name)
				]));
	});
var $author$project$Internal$Button$Implementation$button = F5(
	function (domId, lift, model, options, nodes) {
		var rippleInterface = A5(
			$author$project$Internal$Ripple$Implementation$view,
			false,
			domId,
			A2($elm$core$Basics$composeL, lift, $author$project$Internal$Button$Model$RippleMsg),
			model.U,
			_List_Nil);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Button$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			(!_Utils_eq(config.cn, $elm$core$Maybe$Nothing)) ? $elm$html$Html$a : $elm$html$Html$button,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-button'),
					$author$project$Internal$Options$cs('mdc-js-button'),
					A2(
					$author$project$Internal$Options$when,
					summary.dY.U,
					$author$project$Internal$Options$cs('mdc-js-ripple-effect')),
					A2($author$project$Internal$Options$css, 'box-sizing', 'border-box'),
					A2(
					$author$project$Internal$Options$when,
					(!_Utils_eq(config.cn, $elm$core$Maybe$Nothing)) && (!config.aQ),
					$author$project$Internal$Options$attribute(
						$elm$html$Html$Attributes$href(
							A2($elm$core$Maybe$withDefault, '', config.cn)))),
					A2(
					$author$project$Internal$Options$when,
					config.aQ,
					$author$project$Internal$Options$attribute(
						$elm$html$Html$Attributes$disabled(true))),
					A2(
					$author$project$Internal$Options$when,
					config.aQ,
					$author$project$Internal$Options$cs('mdc-button--disabled')),
					A3(
					$elm$core$Basics$composeL,
					$author$project$Internal$Options$when(config.U),
					$author$project$Internal$Options$many,
					_List_fromArray(
						[rippleInterface.hS, rippleInterface.h7])),
					A2(
					$elm$core$Maybe$withDefault,
					$author$project$Internal$Options$nop,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Internal$Options$onClick, lift),
							$author$project$Internal$Button$Model$Click(config.U)),
						config.cs))
				]),
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (icon_) {
								return _List_fromArray(
									[
										A2(
										$author$project$Internal$Icon$Implementation$view,
										_List_fromArray(
											[
												$author$project$Internal$Options$cs('mdc-button__icon')
											]),
										icon_)
									]);
							},
							config.ca)),
						nodes,
						_List_fromArray(
						[rippleInterface.ih])
					])));
	});
var $author$project$Internal$Button$Model$defaultModel = {U: $author$project$Internal$Ripple$Model$defaultModel};
var $author$project$Internal$Button$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.dQ;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{dQ: x});
		}),
	$author$project$Internal$Button$Model$defaultModel);
var $author$project$Internal$Button$Implementation$view = F2(
	function (lift, index) {
		return A5(
			$author$project$Internal$Component$render,
			$author$project$Internal$Button$Implementation$getSet.ey,
			$author$project$Internal$Button$Implementation$button(index),
			$author$project$Internal$Msg$ButtonMsg,
			lift,
			index);
	});
var $author$project$Material$Button$view = $author$project$Internal$Button$Implementation$view;
var $author$project$Page$Toewijzingen$filtermodel = function () {
	var typeValue = function (_v10) {
		var naam = _v10.aY;
		return naam;
	};
	var sort = F3(
		function (_v8, _v9, list) {
			return A2(
				$elm$core$List$sortBy,
				function (_v6) {
					var fout = _v6.eu;
					var naam = _v6.aY;
					return $elm$core$String$toLower(
						function () {
							var _v7 = fout.aY;
							if (_v7.$ === 1) {
								return 'zzzzzz' + naam;
							} else {
								var foutnaam = _v7.a;
								return _Utils_ap(foutnaam, naam);
							}
						}());
				},
				list);
		});
	var foutValue = function (_v5) {
		var fout = _v5.eu;
		var _v4 = fout.cc;
		if (!_v4) {
			return 'no';
		} else {
			return 'yes';
		}
	};
	var filter = F3(
		function (textFilter, values, record) {
			var toewijzing = record.gV;
			var naam = record.aY;
			return function () {
				var _v2 = values('type');
				if (_v2 === '') {
					return true;
				} else {
					var value = _v2;
					return _Utils_eq(
						typeValue(toewijzing),
						value);
				}
			}() && (function () {
				var _v3 = values('fout');
				if (_v3 === '') {
					return true;
				} else {
					var value = _v3;
					return _Utils_eq(
						foutValue(record),
						value);
				}
			}() && A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(textFilter),
				$elm$core$String$toLower(naam)));
		});
	var controls = F2(
		function (mdc, values) {
			var filterRadio = F3(
				function (key, value, caption_) {
					return A2(
						$author$project$Material$FormField$view,
						_List_fromArray(
							[
								$author$project$Material$Options$cs('field'),
								A2($author$project$Material$Options$css, 'margin-right', '1rem')
							]),
						_List_fromArray(
							[
								A5(
								$author$project$Material$RadioButton$view,
								$author$project$Page$Partial$Filter$Mdc,
								'filter-' + (key + ('-' + value)),
								mdc,
								_List_fromArray(
									[
										A2(
										$author$project$Material$Options$when,
										_Utils_eq(
											values(key),
											value),
										$author$project$Material$RadioButton$selected),
										$author$project$Material$Options$onClick(
										A2($author$project$Page$Partial$Filter$Value, key, value))
									]),
								_List_Nil),
								A3(
								$author$project$Material$Options$styled,
								$elm$html$Html$label,
								_List_fromArray(
									[
										$author$project$Material$Options$onClick(
										A2($author$project$Page$Partial$Filter$Value, key, value))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(caption_)
									]))
							]));
				});
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(filterRadio, 'type', 'eigenbeheer', 'Voorziening'),
							A3(filterRadio, 'type', 'consul', 'Consul'),
							A3(filterRadio, 'type', 'anwb', 'ANWB'),
							A3(filterRadio, 'type', 'onbekend', 'Onbekend'),
							A3(filterRadio, 'type', '', 'Alle')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(filterRadio, 'fout', 'yes', 'Met fouten'),
							A3(filterRadio, 'fout', 'no', 'Zonder fouten'),
							A3(filterRadio, 'fout', '', 'Alle')
						])),
					A5(
					$author$project$Material$Button$view,
					$author$project$Page$Partial$Filter$Mdc,
					'button.pick.all',
					mdc,
					_List_fromArray(
						[
							$author$project$Material$Button$ripple,
							$author$project$Material$Options$onClick(
							A2($author$project$Page$Partial$Filter$Value, 'pick', 'all')),
							$author$project$Material$Options$attribute(
							$elm$html$Html$Attributes$title('In één keer alle toewijzingen wijzigen die voldoen aan het filter (inclusief die op volgende pagina\'s)'))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Wijzig hele set')
						]))
				]);
		});
	var caption = function (_v1) {
		var fout = _v1.eu;
		var naam = _v1.aY;
		var _v0 = fout.aY;
		if (_v0.$ === 1) {
			return naam;
		} else {
			var foutnaam = _v0.a;
			return foutnaam + (' - ' + naam);
		}
	};
	return A6(
		$author$project$Page$Partial$Filter$defaultModel,
		$elm$core$Maybe$Nothing,
		$elm$core$Maybe$Just(caption),
		$elm$core$Maybe$Just(filter),
		$elm$core$Maybe$Nothing,
		$elm$core$Maybe$Just(sort),
		$elm$core$Maybe$Just(controls));
}();
var $author$project$Api$Toewijzingen = {$: 9};
var $author$project$Data$Toewijzing$Toewijzing = F8(
	function (anwbID, beheerder, coordinaat, fout, id, naam, toewijzing, token) {
		return {dw: anwbID, dA: beheerder, bZ: coordinaat, eu: fout, cc: id, aY: naam, gV: toewijzing, gX: token};
	});
var $author$project$Data$Toewijzing$ToewijzingBeheerder = F3(
	function (email, id, naam) {
		return {ef: email, cc: id, aY: naam};
	});
var $author$project$Data$Toewijzing$decodeToewijzingBeheerder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'email',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Data$Toewijzing$ToewijzingBeheerder))));
var $author$project$Data$Toewijzing$ToewijzingCoordinaat = F2(
	function (lat, lon) {
		return {fb: lat, fj: lon};
	});
var $author$project$Data$Toewijzing$decodeToewijzingCoordinaat = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lon',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'lat',
		$elm$json$Json$Decode$float,
		$elm$json$Json$Decode$succeed($author$project$Data$Toewijzing$ToewijzingCoordinaat)));
var $author$project$Data$Toewijzing$ToewijzingFout = F4(
	function (beschrijving, code, id, naam) {
		return {bS: beschrijving, dW: code, cc: id, aY: naam};
	});
var $author$project$Data$Toewijzing$decodeToewijzingFout = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'naam',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'code',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'beschrijving',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Data$Toewijzing$ToewijzingFout)))));
var $author$project$Data$Toewijzing$decodeToewijzing = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'toewijzing',
		$author$project$Data$Toewijzing$decodeToewijzingToewijzing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'naam',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'fout',
					$author$project$Data$Toewijzing$decodeToewijzingFout,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'coordinaat',
						$author$project$Data$Toewijzing$decodeToewijzingCoordinaat,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'beheerder',
							$elm$json$Json$Decode$maybe($author$project$Data$Toewijzing$decodeToewijzingBeheerder),
							$elm$core$Maybe$Nothing,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'anwbID',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Data$Toewijzing$Toewijzing)))))))));
var $author$project$Data$Toewijzing$list = $elm$json$Json$Decode$list($author$project$Data$Toewijzing$decodeToewijzing);
var $author$project$Api$getToewijzingen = F2(
	function (msg, session) {
		return A4($author$project$Api$get, msg, session, $author$project$Api$Toewijzingen, $author$project$Data$Toewijzing$list);
	});
var $author$project$Page$Toewijzingen$init = function (session) {
	var _v0 = A3(
		$author$project$Page$Partial$Filter$get,
		$author$project$Page$Toewijzingen$Filter,
		$author$project$Api$getToewijzingen,
		{d5: false, b4: $author$project$Page$Toewijzingen$filtermodel, c6: $author$project$Material$defaultModel, p: false, am: $elm$core$Maybe$Nothing, W: session});
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Material$init($author$project$Page$Toewijzingen$Mdc),
					$author$project$Session$init($author$project$Page$Toewijzingen$Session),
					$author$project$Page$Partial$Filter$init($author$project$Page$Toewijzingen$Filter),
					cmd
				])));
};
var $author$project$Page$Vaarweg$Beheerders = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Vaarweg$Field = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Vaarweg$GotVaarweg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Vaarweg$Kunstwerken = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Vaarweg$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Vaarweg$PostVaarweg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Vaarweg$Session = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Partial$Beheerders$defaultModel = function () {
	var filter = F3(
		function (textFilter, _v2, _v3) {
			var naam = _v3.aY;
			var woonplaats = _v3.cK;
			return A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(textFilter),
				$elm$core$String$toLower(naam)) || A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(textFilter),
				$elm$core$String$toLower(woonplaats));
		});
	var caption = function (_v0) {
		var naam = _v0.aY;
		var woonplaats = _v0.cK;
		if (woonplaats === '') {
			return naam;
		} else {
			return naam + (' - ' + woonplaats);
		}
	};
	return {
		b4: A6(
			$author$project$Page$Partial$Filter$defaultModel,
			$elm$core$Maybe$Just('Filter op naam of woonplaats'),
			$elm$core$Maybe$Just(caption),
			$elm$core$Maybe$Just(filter),
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing)
	};
}();
var $author$project$Data$Vaarweg$Vaarweg = function (id) {
	return function (aangemaakt) {
		return function (algemeen) {
			return function (eigenaar) {
				return function (land) {
					return function (naam) {
						return function (opmerkingen) {
							return function (verwijderd) {
								return function (kaart) {
									return function (details) {
										return function (kunstwerken) {
											return function (beheerders) {
												return function (link) {
													return function (pdf) {
														return {dp: aangemaakt, dv: algemeen, dC: beheerders, d4: details, ee: eigenaar, cc: id, eW: kaart, e1: kunstwerken, e5: land, cn: link, aY: naam, fG: opmerkingen, fM: pdf, ha: verwijderd};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Beheerder$BeheerderKort = F4(
	function (id, woonplaats, link, naam) {
		return {cc: id, cn: link, aY: naam, cK: woonplaats};
	});
var $author$project$Data$Beheerder$decodeBeheerderKort = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'link',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'woonplaats',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Data$Beheerder$BeheerderKort)))));
var $author$project$Data$Vaarweg$Detail = F8(
	function (id, naam, volgorde, eigenaar, aangemaakt, link, bewaar, detail) {
		return {dp: aangemaakt, dE: bewaar, d3: detail, ee: eigenaar, cc: id, cn: link, aY: naam, hd: volgorde};
	});
var $author$project$Data$Vaarweg$DetailDetail = F7(
	function (id, waterwegID, labelID, omschrijving, eigenaar, aangemaakt, link) {
		return {dp: aangemaakt, ee: eigenaar, cc: id, e4: labelID, cn: link, fB: omschrijving, hl: waterwegID};
	});
var $author$project$Data$Vaarweg$decodeDetailDetail = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'link',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'aangemaakt',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'eigenaar',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'omschrijving',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'labelID',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'waterwegID',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Data$Vaarweg$DetailDetail))))))));
var $author$project$Data$Vaarweg$decodeDetail = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'detail',
	$author$project$Data$Vaarweg$decodeDetailDetail,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'bewaar',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'link',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'aangemaakt',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'eigenaar',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'volgorde',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'naam',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Data$Vaarweg$Detail)))))))));
var $author$project$Data$Vaarweg$VaarwegKaart = F4(
	function (hoogte, url, jpg, legenda) {
		return {eJ: hoogte, eV: jpg, fd: legenda, g6: url};
	});
var $author$project$Data$Vaarweg$decodeVaarwegKaart = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'legenda',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'jpg',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'url',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'hoogte',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Data$Vaarweg$VaarwegKaart)))));
var $author$project$Data$Vaarweg$decodeVaarweg = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'pdf',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'link',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'beheerders',
			$elm$json$Json$Decode$list($author$project$Data$Beheerder$decodeBeheerderKort),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'kunstwerken',
				$elm$json$Json$Decode$list($author$project$Data$Kunstwerk$decodeKunstwerkKort),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'details',
					$elm$json$Json$Decode$list($author$project$Data$Vaarweg$decodeDetail),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'kaart',
						$author$project$Data$Vaarweg$decodeVaarwegKaart,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'verwijderd',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'opmerkingen',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'naam',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'land',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'eigenaar',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'algemeen',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'aangemaakt',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'id',
														$elm$json$Json$Decode$int,
														$elm$json$Json$Decode$succeed($author$project$Data$Vaarweg$Vaarweg)))))))))))))));
var $author$project$Api$getVaarweg = F3(
	function (msg, link, session) {
		return A4(
			$author$project$Api$get,
			msg,
			session,
			$author$project$Api$Base(link),
			$author$project$Data$Vaarweg$decodeVaarweg);
	});
var $author$project$Page$Partial$Beheerders$Filter = $elm$core$Basics$identity;
var $author$project$Page$Partial$Beheerders$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$Partial$Filter$init($elm$core$Basics$identity)
				])));
};
var $author$project$Api$Vaarwegen = {$: 6};
var $author$project$Api$postVaarweg = F2(
	function (msg, session) {
		return A5($author$project$Api$post, msg, session, $author$project$Api$Vaarwegen, $author$project$Data$Vaarweg$decodeVaarweg, $elm$json$Json$Encode$null);
	});
var $author$project$Page$Vaarweg$init = F2(
	function (session, link) {
		return _Utils_Tuple2(
			A2(
				$author$project$Session$progress,
				true,
				{
					bR: false,
					dC: $author$project$Page$Partial$Beheerders$defaultModel,
					aO: false,
					cY: $author$project$Field$defaultModel(_List_Nil),
					e0: false,
					e1: $author$project$Page$Partial$Kunstwerken$defaultModel,
					aW: false,
					c6: $author$project$Material$defaultModel,
					W: session,
					g7: $elm$core$Maybe$Nothing
				}),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					function () {
						if (link === '') {
							return A2($author$project$Api$postVaarweg, $author$project$Page$Vaarweg$PostVaarweg, session);
						} else {
							return A3($author$project$Api$getVaarweg, $author$project$Page$Vaarweg$GotVaarweg, link, session);
						}
					}(),
					_List_fromArray(
						[
							$author$project$Material$init($author$project$Page$Vaarweg$Mdc),
							$author$project$Field$init($author$project$Page$Vaarweg$Field),
							$author$project$Session$init($author$project$Page$Vaarweg$Session),
							$author$project$Page$Partial$Kunstwerken$init($author$project$Page$Vaarweg$Kunstwerken),
							$author$project$Page$Partial$Beheerders$init($author$project$Page$Vaarweg$Beheerders)
						]))));
	});
var $author$project$Page$Vaarwegen$Model = F2(
	function (session, vaarwegen) {
		return {W: session, g8: vaarwegen};
	});
var $author$project$Page$Vaarwegen$Session = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Vaarwegen$Vaarwegen = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Partial$Vaarwegen$defaultModel = {
	b4: A6($author$project$Page$Partial$Filter$defaultModel, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)
};
var $author$project$Page$Partial$Vaarwegen$Filter = $elm$core$Basics$identity;
var $author$project$Data$Vaarweg$VaarwegKort = F3(
	function (id, link, naam) {
		return {cc: id, cn: link, aY: naam};
	});
var $author$project$Data$Vaarweg$decodeVaarwegKort = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'naam',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'link',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Data$Vaarweg$VaarwegKort))));
var $author$project$Data$Vaarweg$list = $elm$json$Json$Decode$list($author$project$Data$Vaarweg$decodeVaarwegKort);
var $author$project$Api$getVaarwegen = F2(
	function (msg, session) {
		return A4($author$project$Api$get, msg, session, $author$project$Api$Vaarwegen, $author$project$Data$Vaarweg$list);
	});
var $author$project$Page$Partial$Vaarwegen$get = F2(
	function (lift, container) {
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A3($author$project$Page$Partial$Filter$get, $elm$core$Basics$identity, $author$project$Api$getVaarwegen, container));
	});
var $author$project$Page$Partial$Vaarwegen$init = function (lift) {
	return A2(
		$elm$core$Platform$Cmd$map,
		lift,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$Partial$Filter$init($elm$core$Basics$identity)
				])));
};
var $author$project$Page$Vaarwegen$init = function (session) {
	return A2(
		$elm$core$Tuple$mapSecond,
		function (cmd) {
			return $elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					cmd,
					_List_fromArray(
						[
							$author$project$Session$init($author$project$Page$Vaarwegen$Session),
							$author$project$Page$Partial$Vaarwegen$init($author$project$Page$Vaarwegen$Vaarwegen)
						])));
		},
		A2(
			$author$project$Page$Partial$Vaarwegen$get,
			$author$project$Page$Vaarwegen$Vaarwegen,
			A2($author$project$Page$Vaarwegen$Model, session, $author$project$Page$Partial$Vaarwegen$defaultModel)));
};
var $author$project$Page$Voorziening$Field = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Voorziening$GotVoorziening = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Page$Voorziening$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Voorziening$Session = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Voorziening$Street = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Voorziening$Vaarwegen = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$simpleFloatfield = F5(
	function (required, decimals, pad, label, name) {
		return _Utils_Tuple2(
			name,
			A5($author$project$Field$floatFieldModel, label, required, decimals, pad, $elm$core$Maybe$Nothing));
	});
var $author$project$Field$intFormat = function (input) {
	var _v0 = $elm$core$String$toInt(input);
	if (_v0.$ === 1) {
		return '';
	} else {
		var _int = _v0.a;
		return input;
	}
};
var $author$project$Field$intFieldModel = F3(
	function (label, required, maybeValidate) {
		return A7(
			$author$project$Field$FieldModel,
			label,
			'',
			false,
			required,
			false,
			$author$project$Field$intFormat,
			A2(
				$elm$core$Maybe$withDefault,
				function (string) {
					return true;
				},
				maybeValidate));
	});
var $author$project$Page$simpleIntfield = F3(
	function (required, label, name) {
		return _Utils_Tuple2(
			name,
			A3($author$project$Field$intFieldModel, label, required, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$simpleTextfield = F3(
	function (required, label, name) {
		return _Utils_Tuple2(
			name,
			A4($author$project$Field$defaultFieldModel, label, required, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$Voorziening$fieldModel = $author$project$Field$defaultModel(
	_List_fromArray(
		[
			A3($author$project$Page$simpleIntfield, true, 'ID-nummer', 'anwbID'),
			A3($author$project$Page$simpleTextfield, true, 'Indexnaam', 'indexNaam'),
			A3($author$project$Page$simpleTextfield, false, 'Interne opmerkingen (komt niet in de almanak)', 'opmerkingen'),
			A3($author$project$Page$simpleTextfield, true, 'Waterkaart', 'locatie.waterkaart'),
			A3($author$project$Page$simpleTextfield, true, 'Plaatsnaam Almanak', 'locatie.plaatsAlmanak'),
			A3($author$project$Page$simpleTextfield, true, 'Consul', 'consul.naam'),
			A3($author$project$Page$simpleTextfield, true, 'Laatste bezoek', 'consul.bezoek'),
			_Utils_Tuple2(
			'consul.bezoek.dag',
			A4(
				$author$project$Field$defaultFieldModel,
				'Laatste bezoek: dag',
				true,
				$elm$core$Maybe$Just(
					function (s) {
						var _v0 = $elm$core$String$toInt(s);
						if (_v0.$ === 1) {
							return '';
						} else {
							var _v1 = $elm$core$String$length(s);
							if (_v1 === 1) {
								return '0' + s;
							} else {
								return s;
							}
						}
					}),
				$elm$core$Maybe$Just(
					function (s) {
						var _v2 = $elm$core$String$toInt(s);
						if (_v2.$ === 1) {
							return false;
						} else {
							var v = _v2.a;
							return (v > 0) && (v < 32);
						}
					}))),
			_Utils_Tuple2(
			'consul.bezoek.maand',
			A4(
				$author$project$Field$defaultFieldModel,
				'Laatste bezoek: maand',
				true,
				$elm$core$Maybe$Just(
					function (s) {
						var _v3 = $elm$core$String$toInt(s);
						if (_v3.$ === 1) {
							return '';
						} else {
							var _v4 = $elm$core$String$length(s);
							if (_v4 === 1) {
								return '0' + s;
							} else {
								return s;
							}
						}
					}),
				$elm$core$Maybe$Just(
					function (s) {
						var _v5 = $elm$core$String$toInt(s);
						if (_v5.$ === 1) {
							return false;
						} else {
							var v = _v5.a;
							return (v > 0) && (v < 13);
						}
					}))),
			_Utils_Tuple2(
			'consul.bezoek.jaar',
			A4(
				$author$project$Field$defaultFieldModel,
				'Laatste bezoek: jaar',
				true,
				$elm$core$Maybe$Nothing,
				$elm$core$Maybe$Just(
					function (s) {
						var _v6 = $elm$core$String$toInt(s);
						if (_v6.$ === 1) {
							return false;
						} else {
							var v = _v6.a;
							return (v > 999) && (v < 10000);
						}
					}))),
			A3($author$project$Page$simpleTextfield, true, 'Naam', 'bedrijf.naam'),
			A3($author$project$Page$simpleTextfield, false, 'Ter attentie van', 'bedrijf.tav'),
			A3($author$project$Page$simpleTextfield, true, 'Adres', 'bedrijf.adres'),
			A3($author$project$Page$simpleTextfield, true, 'Postcode', 'bedrijf.postcode'),
			A3($author$project$Page$simpleTextfield, true, 'Woonplaats', 'bedrijf.woonplaats'),
			A3($author$project$Page$simpleTextfield, true, 'Website', 'bedrijf.web'),
			A3($author$project$Page$simpleTextfield, false, 'E-mailadres', 'bedrijf.email'),
			A3($author$project$Page$simpleTextfield, true, 'Tel netnr', 'bedrijf.telefoon.net'),
			A3($author$project$Page$simpleTextfield, true, 'Tel abonneenr', 'bedrijf.telefoon.abonnee'),
			A3($author$project$Page$simpleTextfield, false, 'Fax netnr', 'bedrijf.fax.net'),
			A3($author$project$Page$simpleTextfield, false, 'Fax abonneenr', 'bedrijf.fax.abonnee'),
			A3($author$project$Page$simpleTextfield, true, 'Naam', 'jachthaven.naam'),
			A3($author$project$Page$simpleTextfield, true, 'Ligging', 'jachthaven.ligging'),
			A3($author$project$Page$simpleTextfield, true, 'Havenmeester', 'jachthaven.havenmeester'),
			A3($author$project$Page$simpleIntfield, true, 'Marifoonkanaal', 'jachthaven.kanaalMarifoon'),
			A3($author$project$Page$simpleTextfield, false, 'Bijzonderheden', 'jachthaven.bijzHaveningang'),
			A3($author$project$Page$simpleTextfield, true, 'Netnr', 'jachthaven.telefoonHavenkantoor.net'),
			A3($author$project$Page$simpleTextfield, true, 'Abonneenr Havenkantoor', 'jachthaven.telefoonHavenkantoor.abonnee'),
			A3($author$project$Page$simpleTextfield, false, 'Netnr', 'jachthaven.telefoonHavenmeester.net'),
			A3($author$project$Page$simpleTextfield, false, 'Abonneenr Havenmeester', 'jachthaven.telefoonHavenmeester.abonnee'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Kosten (€)', 'algemeen.vuilwatertank.kosten'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Kosten (€)', 'primair.drinkwater.kosten'),
			A3($author$project$Page$simpleIntfield, true, 'Aantal vast', 'primair.ligplaatsen.aantal'),
			A3($author$project$Page$simpleIntfield, true, 'Aantal vrij', 'primair.ligplaatsen.vrij'),
			A3($author$project$Page$simpleIntfield, true, 'Aantal', 'primair.passantenligplaatsen.aantal'),
			A3($author$project$Page$simpleTextfield, false, 'Afwijkend tarief', 'primair.passantenligplaatsen.afwijkendTarief'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max diepgang (m)', 'primair.passantenligplaatsen.diepgangMax'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max lengte (m)', 'primair.passantenligplaatsen.lengteMax'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max breedte (m)', 'primair.passantenligplaatsen.maxBreedte'),
			A5($author$project$Page$simpleFloatfield, false, 2, true, 'Tarief (€/m)', 'primair.passantenligplaatsen.tarief'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max diepgang (m)', 'technisch.botenlift.maxDiepgang'),
			A3($author$project$Page$simpleIntfield, true, 'Max vermogen (kg)', 'technisch.botenlift.maxGewicht'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max diepgang (m)', 'technisch.hefkraan.maxDiepgang'),
			A3($author$project$Page$simpleIntfield, true, 'Max vermogen (kg)', 'technisch.hefkraan.maxGewicht'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max mastlengte (m)', 'technisch.mastenkraan.maxLengte'),
			A3($author$project$Page$simpleIntfield, true, 'Max vermogen (kg)', 'technisch.mastenkraan.maxGewicht'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max diepgang (m)', 'technisch.scheepshelling.maxDiepgang'),
			A3($author$project$Page$simpleIntfield, true, 'Max gewicht (kg)', 'technisch.scheepshelling.maxGewicht'),
			A5($author$project$Page$simpleFloatfield, true, 2, true, 'Max diepgang (m)', 'technisch.trailerhelling.maxDiepgang'),
			A3($author$project$Page$simpleIntfield, true, 'Max gewicht (kg)', 'technisch.trailerhelling.maxGewicht')
		]));
var $author$project$Data$Voorziening$VoorzieningStates = F3(
	function (lastYear, edit, chosen) {
		return {dV: chosen, ec: edit, fa: lastYear};
	});
var $author$project$Data$Voorziening$Voorziening = function (aangemaakt) {
	return function (anwbID) {
		return function (bedrijf) {
			return function (consul) {
				return function (eigenaar) {
					return function (gebruikerID) {
						return function (id) {
							return function (indexNaam) {
								return function (jachthaven) {
									return function (lastChanged) {
										return function (lastViewed) {
											return function (link) {
												return function (locatie) {
													return function (opmerkingen) {
														return function (remarks) {
															return function (vaarwegen) {
																return function (verwijderd) {
																	return {dp: aangemaakt, dw: anwbID, dz: bedrijf, d_: consul, ee: eigenaar, b9: gebruikerID, cc: id, cd: indexNaam, eT: jachthaven, e7: lastChanged, e9: lastViewed, cn: link, fi: locatie, fG: opmerkingen, f7: remarks, g8: vaarwegen, ha: verwijderd};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Voorziening$VoorzieningBedrijf = function (adres) {
	return function (categorieen) {
		return function (data) {
			return function (email) {
				return function (fax) {
					return function (hiswaLid) {
						return function (land) {
							return function (naam) {
								return function (postcode) {
									return function (provincie) {
										return function (tav) {
											return function (telefoon) {
												return function (web) {
													return function (woonplaats) {
														return {dr: adres, bW: categorieen, ae: data, ef: email, el: fax, eI: hiswaLid, e5: land, aY: naam, fV: postcode, f1: provincie, gM: tav, gO: telefoon, hm: web, cK: woonplaats};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Voorziening$decodeDictBool = $elm$json$Json$Decode$dict(
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$bool,
				$elm$json$Json$Decode$null(false),
				$elm$json$Json$Decode$succeed(false)
			])));
var $author$project$Data$Voorziening$VoorzieningBedrijfData = function (bootverhuurder) {
	return {dK: bootverhuurder};
};
var $author$project$Data$Voorziening$VoorzieningBedrijfDataBootverhuurder = F5(
	function (motorjacht, zeiljacht, openZeilboot, sloep, kano) {
		return {eY: kano, fy: motorjacht, fC: openZeilboot, gw: sloep, hs: zeiljacht};
	});
var $author$project$Data$Voorziening$decodeVoorzieningBedrijfDataBootverhuurder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'kano',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'sloep',
		$elm$json$Json$Decode$bool,
		false,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'openZeilboot',
			$elm$json$Json$Decode$bool,
			false,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'zeiljacht',
				$elm$json$Json$Decode$bool,
				false,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'motorjacht',
					$elm$json$Json$Decode$bool,
					false,
					$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningBedrijfDataBootverhuurder))))));
var $author$project$Data$Voorziening$decodeVoorzieningBedrijfData = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'bootverhuurder',
	$author$project$Data$Voorziening$decodeVoorzieningBedrijfDataBootverhuurder,
	$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningBedrijfData));
var $author$project$Data$Voorziening$VoorzieningBedrijfFax = F2(
	function (abonnee, net) {
		return {bL: abonnee, cq: net};
	});
var $author$project$Data$Voorziening$decodeVoorzieningBedrijfFax = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'net',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'abonnee',
		$elm$json$Json$Decode$string,
		'',
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningBedrijfFax)));
var $author$project$Data$Voorziening$VoorzieningBedrijfTelefoon = F2(
	function (abonnee, net) {
		return {bL: abonnee, cq: net};
	});
var $author$project$Data$Voorziening$decodeVoorzieningBedrijfTelefoon = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'net',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'abonnee',
		$elm$json$Json$Decode$string,
		'',
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningBedrijfTelefoon)));
var $author$project$Data$Voorziening$decodeVoorzieningBedrijf = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'woonplaats',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'web',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'telefoon',
			$author$project$Data$Voorziening$decodeVoorzieningBedrijfTelefoon,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'tav',
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'provincie',
					$elm$json$Json$Decode$int,
					0,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'postcode',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'naam',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'land',
								$elm$json$Json$Decode$string,
								'',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'hiswaLid',
									$elm$json$Json$Decode$bool,
									false,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'fax',
										$author$project$Data$Voorziening$decodeVoorzieningBedrijfFax,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'email',
											$elm$json$Json$Decode$string,
											'',
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'data',
												$author$project$Data$Voorziening$decodeVoorzieningBedrijfData,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'categorieen',
													$author$project$Data$Voorziening$decodeDictBool,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'adres',
														$elm$json$Json$Decode$string,
														'',
														$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningBedrijf)))))))))))))));
var $author$project$Data$Voorziening$VoorzieningConsul = F4(
	function (bezoek, kring, naam, provincie) {
		return {dG: bezoek, e$: kring, aY: naam, f1: provincie};
	});
var $author$project$Data$Voorziening$decodeVoorzieningConsul = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'provincie',
	$elm$json$Json$Decode$int,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'naam',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'kring',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'bezoek',
				$elm$json$Json$Decode$string,
				'',
				$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningConsul)))));
var $author$project$Data$Voorziening$VoorzieningJachthaven = F9(
	function (bijzHaveningang, blauweVlag, havenmeester, kanaalMarifoon, ligging, naam, telefoonHavenkantoor, telefoonHavenmeester, voorzieningen) {
		return {dH: bijzHaveningang, dJ: blauweVlag, eE: havenmeester, eX: kanaalMarifoon, ff: ligging, aY: naam, gP: telefoonHavenkantoor, gQ: telefoonHavenmeester, he: voorzieningen};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenTelefoonHavenkantoor = F2(
	function (abonnee, net) {
		return {bL: abonnee, cq: net};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenTelefoonHavenkantoor = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'net',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'abonnee',
		$elm$json$Json$Decode$string,
		'',
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenTelefoonHavenkantoor)));
var $author$project$Data$Voorziening$VoorzieningJachthavenTelefoonHavenmeester = F2(
	function (abonnee, net) {
		return {bL: abonnee, cq: net};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenTelefoonHavenmeester = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'net',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'abonnee',
		$elm$json$Json$Decode$string,
		'',
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenTelefoonHavenmeester)));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningen = F3(
	function (algemeen, primair, technisch) {
		return {dv: algemeen, fY: primair, gN: technisch};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenAlgemeen = F2(
	function (aanwezig, data) {
		return {aJ: aanwezig, ae: data};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenAlgemeenData = function (vuilwatertank) {
	return {hg: vuilwatertank};
};
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenAlgemeenDataVuilwatertank = function (kosten) {
	return {ch: kosten};
};
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenAlgemeenDataVuilwatertank = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'kosten',
	$elm$json$Json$Decode$float,
	0,
	$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenAlgemeenDataVuilwatertank));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenAlgemeenData = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'vuilwatertank',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenAlgemeenDataVuilwatertank,
	$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenAlgemeenData));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenAlgemeen = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'data',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenAlgemeenData,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'aanwezig',
		$author$project$Data$Voorziening$decodeDictBool,
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenAlgemeen)));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimair = F2(
	function (aanwezig, data) {
		return {aJ: aanwezig, ae: data};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairData = F4(
	function (brandstof, drinkwater, ligplaatsen, passantenligplaatsen) {
		return {dN: brandstof, eb: drinkwater, fh: ligplaatsen, fK: passantenligplaatsen};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataBrandstof = F3(
	function (diesel, benzine, gtl) {
		return {dD: benzine, d7: diesel, eD: gtl};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataBrandstof = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'gtl',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'benzine',
		$elm$json$Json$Decode$bool,
		false,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'diesel',
			$elm$json$Json$Decode$bool,
			false,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataBrandstof))));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataDrinkwater = function (kosten) {
	return {ch: kosten};
};
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataDrinkwater = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'kosten',
	$elm$json$Json$Decode$float,
	0,
	$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataDrinkwater));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataLigplaatsen = F2(
	function (aantal, vrij) {
		return {bK: aantal, hf: vrij};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataLigplaatsen = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'vrij',
	$elm$json$Json$Decode$int,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'aantal',
		$elm$json$Json$Decode$int,
		0,
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataLigplaatsen)));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataPassantenligplaatsen = F8(
	function (aantal, afwijkendTarief, diepgangMax, lengteMax, maxBreedte, maxVerblijf, tarief, tariefPassantAanvraag) {
		return {bK: aantal, du: afwijkendTarief, d6: diepgangMax, fe: lengteMax, fm: maxBreedte, ft: maxVerblijf, w: tarief, gL: tariefPassantAanvraag};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataPassantenligplaatsen = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tariefPassantAanvraag',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'tarief',
		$elm$json$Json$Decode$float,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxVerblijf',
			$elm$json$Json$Decode$int,
			0,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'maxBreedte',
				$elm$json$Json$Decode$float,
				0,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'lengteMax',
					$elm$json$Json$Decode$float,
					0,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'diepgangMax',
						$elm$json$Json$Decode$float,
						0,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'afwijkendTarief',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'aantal',
								$elm$json$Json$Decode$int,
								0,
								$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairDataPassantenligplaatsen)))))))));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairData = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'passantenligplaatsen',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataPassantenligplaatsen,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'ligplaatsen',
		$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataLigplaatsen,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'drinkwater',
			$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataDrinkwater,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'brandstof',
				$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairDataBrandstof,
				$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimairData)))));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimair = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'data',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimairData,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'aanwezig',
		$author$project$Data$Voorziening$decodeDictBool,
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenPrimair)));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnisch = F2(
	function (aanwezig, data) {
		return {aJ: aanwezig, ae: data};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischData = F5(
	function (botenlift, hefkraan, mastenkraan, scheepshelling, trailerhelling) {
		return {dL: botenlift, eG: hefkraan, fl: mastenkraan, gl: scheepshelling, g3: trailerhelling};
	});
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataBotenlift = F4(
	function (mast, maxDiepgang, maxGewicht, tarief) {
		return {co: mast, aj: maxDiepgang, K: maxGewicht, w: tarief};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataBotenlift = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tarief',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maxGewicht',
		$elm$json$Json$Decode$int,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxDiepgang',
			$elm$json$Json$Decode$float,
			0,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'mast',
				$elm$json$Json$Decode$bool,
				false,
				$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataBotenlift)))));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataHefkraan = F4(
	function (mast, maxDiepgang, maxGewicht, tarief) {
		return {co: mast, aj: maxDiepgang, K: maxGewicht, w: tarief};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataHefkraan = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tarief',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maxGewicht',
		$elm$json$Json$Decode$int,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxDiepgang',
			$elm$json$Json$Decode$float,
			0,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'mast',
				$elm$json$Json$Decode$bool,
				false,
				$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataHefkraan)))));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataMastenkraan = F3(
	function (maxGewicht, maxLengte, tarief) {
		return {K: maxGewicht, fq: maxLengte, w: tarief};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataMastenkraan = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tarief',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maxLengte',
		$elm$json$Json$Decode$float,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxGewicht',
			$elm$json$Json$Decode$int,
			0,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataMastenkraan))));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataScheepshelling = F3(
	function (maxDiepgang, maxGewicht, tarief) {
		return {aj: maxDiepgang, K: maxGewicht, w: tarief};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataScheepshelling = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tarief',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maxGewicht',
		$elm$json$Json$Decode$int,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxDiepgang',
			$elm$json$Json$Decode$float,
			0,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataScheepshelling))));
var $author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataTrailerhelling = F3(
	function (maxDiepgang, maxGewicht, tarief) {
		return {aj: maxDiepgang, K: maxGewicht, w: tarief};
	});
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataTrailerhelling = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tarief',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maxGewicht',
		$elm$json$Json$Decode$int,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxDiepgang',
			$elm$json$Json$Decode$float,
			0,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischDataTrailerhelling))));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischData = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'trailerhelling',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataTrailerhelling,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'scheepshelling',
		$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataScheepshelling,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'mastenkraan',
			$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataMastenkraan,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'hefkraan',
				$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataHefkraan,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'botenlift',
					$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischDataBotenlift,
					$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnischData))))));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnisch = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'data',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnischData,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'aanwezig',
		$author$project$Data$Voorziening$decodeDictBool,
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningenTechnisch)));
var $author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningen = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'technisch',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenTechnisch,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'primair',
		$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenPrimair,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'algemeen',
			$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningenAlgemeen,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthavenVoorzieningen))));
var $author$project$Data$Voorziening$decodeVoorzieningJachthaven = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'voorzieningen',
	$author$project$Data$Voorziening$decodeVoorzieningJachthavenVoorzieningen,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'telefoonHavenmeester',
		$author$project$Data$Voorziening$decodeVoorzieningJachthavenTelefoonHavenmeester,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'telefoonHavenkantoor',
			$author$project$Data$Voorziening$decodeVoorzieningJachthavenTelefoonHavenkantoor,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'naam',
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'ligging',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'kanaalMarifoon',
						$elm$json$Json$Decode$int,
						0,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'havenmeester',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'blauweVlag',
								$elm$json$Json$Decode$bool,
								false,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'bijzHaveningang',
									$elm$json$Json$Decode$string,
									'',
									$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningJachthaven))))))))));
var $author$project$Data$Voorziening$VoorzieningLocatie = F3(
	function (coordinaat, plaatsAlmanak, waterkaart) {
		return {bZ: coordinaat, fS: plaatsAlmanak, hk: waterkaart};
	});
var $author$project$Data$Voorziening$Coordinaat = F2(
	function (lat, lon) {
		return {fb: lat, fj: lon};
	});
var $author$project$Data$Voorziening$decodeCoordinaat = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'lon',
	$elm$json$Json$Decode$float,
	4.32773,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'lat',
		$elm$json$Json$Decode$float,
		52.09688,
		$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$Coordinaat)));
var $author$project$Data$Voorziening$decodeVoorzieningLocatie = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'waterkaart',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'plaatsAlmanak',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'coordinaat',
			$author$project$Data$Voorziening$decodeCoordinaat,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningLocatie))));
var $author$project$Data$Voorziening$decodeVoorziening = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'verwijderd',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'vaarwegen',
		$author$project$Data$Vaarweg$list,
		_List_Nil,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'remarks',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'opmerkingen',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'locatie',
					$author$project$Data$Voorziening$decodeVoorzieningLocatie,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'link',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'lastViewed',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'lastChanged',
								$elm$json$Json$Decode$string,
								'',
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'jachthaven',
									$author$project$Data$Voorziening$decodeVoorzieningJachthaven,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'indexNaam',
										$elm$json$Json$Decode$string,
										'',
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'id',
											$elm$json$Json$Decode$int,
											0,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'gebruikerID',
												$elm$json$Json$Decode$int,
												0,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'eigenaar',
													$elm$json$Json$Decode$int,
													0,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'consul',
														$author$project$Data$Voorziening$decodeVoorzieningConsul,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'bedrijf',
															$author$project$Data$Voorziening$decodeVoorzieningBedrijf,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'anwbID',
																$elm$json$Json$Decode$int,
																0,
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'aangemaakt',
																	$elm$json$Json$Decode$string,
																	'',
																	$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$Voorziening))))))))))))))))));
var $author$project$Data$Voorziening$decodeVoorzieningStates = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'chosen',
	$author$project$Data$Voorziening$decodeVoorziening,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'edit',
		$author$project$Data$Voorziening$decodeVoorziening,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'lastYear',
			$author$project$Data$Voorziening$decodeVoorziening,
			$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningStates))));
var $author$project$Api$getVoorziening = F3(
	function (msg, link, session) {
		return A4(
			$author$project$Api$get,
			msg,
			session,
			$author$project$Api$Base(link),
			$author$project$Data$Voorziening$decodeVoorzieningStates);
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Api$Voorzieningen = {$: 5};
var $author$project$Api$postVoorziening = F2(
	function (msg, session) {
		return A5($author$project$Api$post, msg, session, $author$project$Api$Voorzieningen, $author$project$Data$Voorziening$decodeVoorzieningStates, $elm$json$Json$Encode$null);
	});
var $author$project$Page$Voorziening$init = F2(
	function (session, link) {
		return _Utils_Tuple2(
			A2(
				$author$project$Session$progress,
				true,
				{
					cY: $author$project$Page$Voorziening$fieldModel,
					c6: $author$project$Material$defaultModel,
					br: false,
					W: session,
					a6: false,
					df: $author$project$Street$defaultModel(
						$elm$core$Maybe$Just(
							_Utils_Tuple3(4.3276, 52.09702, 17.5))),
					cF: $elm$time$Time$millisToPosix(0),
					g8: $author$project$Page$Partial$Vaarwegen$defaultModel,
					a7: false,
					i: $elm$core$Maybe$Nothing,
					bI: $elm$core$Maybe$Nothing
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Material$init($author$project$Page$Voorziening$Mdc),
						$author$project$Field$init($author$project$Page$Voorziening$Field),
						$author$project$Street$init($author$project$Page$Voorziening$Street),
						$author$project$Session$init($author$project$Page$Voorziening$Session),
						$author$project$Page$Partial$Vaarwegen$init($author$project$Page$Voorziening$Vaarwegen),
						function () {
						if (link === '') {
							return A2(
								$author$project$Api$postVoorziening,
								$author$project$Page$Voorziening$GotVoorziening(false),
								session);
						} else {
							return A3(
								$author$project$Api$getVoorziening,
								$author$project$Page$Voorziening$GotVoorziening(false),
								link,
								session);
						}
					}()
					])));
	});
var $author$project$Page$Voorzieningen$Filter = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Voorzieningen$Mdc = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Voorzieningen$Session = function (a) {
	return {$: 2, a: a};
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Internal$Select$Implementation$label = function (stringLabel) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{e2: stringLabel});
		});
};
var $author$project$Material$Select$label = $author$project$Internal$Select$Implementation$label;
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $author$project$Internal$Options$onChange = function (f) {
	return A2(
		$author$project$Internal$Options$on,
		'change',
		A2($elm$json$Json$Decode$map, f, $elm$html$Html$Events$targetValue));
};
var $author$project$Material$Options$onChange = $author$project$Internal$Options$onChange;
var $elm$html$Html$option = _VirtualDom_node('option');
var $author$project$Internal$Select$Implementation$option = $author$project$Internal$Options$styled($elm$html$Html$option);
var $author$project$Material$Select$option = $author$project$Internal$Select$Implementation$option;
var $author$project$Internal$Select$Implementation$preselected = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{bt: true});
	});
var $author$project$Material$Select$preselected = $author$project$Internal$Select$Implementation$preselected;
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Internal$Select$Implementation$selected = $author$project$Internal$Options$attribute(
	$elm$html$Html$Attributes$selected(true));
var $author$project$Material$Select$selected = $author$project$Internal$Select$Implementation$selected;
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Internal$Select$Implementation$value = A2($elm$core$Basics$composeL, $author$project$Internal$Options$attribute, $elm$html$Html$Attributes$value);
var $author$project$Material$Select$value = $author$project$Internal$Select$Implementation$value;
var $author$project$Internal$Msg$SelectMsg = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Internal$Select$Model$defaultModel = {c$: false, eP: false, U: $author$project$Internal$Ripple$Model$defaultModel};
var $author$project$Internal$Select$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.gr;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{gr: x});
		}),
	$author$project$Internal$Select$Model$defaultModel);
var $author$project$Internal$Select$Model$Blur = {$: 0};
var $author$project$Internal$Select$Model$Change = function (a) {
	return {$: 2, a: a};
};
var $author$project$Internal$Select$Model$Focus = {$: 1};
var $author$project$Internal$Select$Implementation$defaultConfig = {dM: false, aQ: false, c3: '', e2: '', bt: false};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $author$project$Internal$Options$for = A2($elm$core$Basics$composeL, $author$project$Internal$Options$Attribute, $elm$html$Html$Attributes$for);
var $author$project$Internal$Options$role = function (value) {
	return $author$project$Internal$Options$Attribute(
		A2($elm$html$Html$Attributes$attribute, 'role', value));
};
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$Internal$Select$Implementation$select = F4(
	function (lift, model, options, items_) {
		var isDirty = model.eP;
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Select$Implementation$defaultConfig, options);
		var config = summary.dY;
		var focused = model.c$ && (!config.aQ);
		var items = config.bt ? items_ : A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(''),
						$elm$html$Html$Attributes$disabled(true),
						$elm$html$Html$Attributes$selected(true)
					]),
				_List_Nil),
			items_);
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-select'),
					A2(
					$author$project$Internal$Options$when,
					config.aQ,
					$author$project$Internal$Options$cs('mdc-select--disabled')),
					$author$project$Internal$Options$role('listbox')
				]),
			_List_fromArray(
				[
					$elm$html$Html$Attributes$tabindex(0)
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$select,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-select__native-control'),
							$author$project$Internal$Options$id(config.c3),
							$author$project$Internal$Options$onFocus(
							lift($author$project$Internal$Select$Model$Focus)),
							$author$project$Internal$Options$onBlur(
							lift($author$project$Internal$Select$Model$Blur)),
							$author$project$Internal$Options$onChange(
							A2($elm$core$Basics$composeL, lift, $author$project$Internal$Select$Model$Change)),
							A2(
							$author$project$Internal$Options$when,
							config.aQ,
							$author$project$Internal$Options$attribute(
								$elm$html$Html$Attributes$disabled(true)))
						]),
					items),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$label,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-floating-label'),
							$author$project$Internal$Options$for(config.c3),
							A2(
							$author$project$Internal$Options$when,
							focused || (isDirty || config.bt),
							$author$project$Internal$Options$cs('mdc-floating-label--float-above'))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(config.e2)
						])),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-line-ripple'),
							A2(
							$author$project$Internal$Options$when,
							focused,
							$author$project$Internal$Options$cs('mdc-line-ripple--active'))
						]),
					_List_Nil)
				]));
	});
var $author$project$Internal$Select$Implementation$view = F4(
	function (lift, index, store, options) {
		return A7(
			$author$project$Internal$Component$render,
			$author$project$Internal$Select$Implementation$getSet.ey,
			$author$project$Internal$Select$Implementation$select,
			$author$project$Internal$Msg$SelectMsg,
			lift,
			index,
			store,
			A2(
				$elm$core$List$cons,
				$author$project$Internal$Options$internalId(index),
				options));
	});
var $author$project$Material$Select$view = $author$project$Internal$Select$Implementation$view;
var $author$project$Page$Voorzieningen$filtermodel = function (_v0) {
	var refdata = _v0.h9;
	var sort = F3(
		function (_v21, _v22, list) {
			return A2(
				$elm$core$List$sortBy,
				function (_v20) {
					var indexNaam = _v20.cd;
					var naam = _v20.aY;
					return $elm$core$String$toLower(indexNaam + ('          ' + naam));
				},
				list);
		});
	var label = 'Filter op plaatsnaam/id/naam/woonplaats/vaarwater';
	var filter = F3(
		function (textFilter, values, _v19) {
			var indexNaam = _v19.cd;
			var id = _v19.cc;
			var naam = _v19.aY;
			var woonplaats = _v19.cK;
			var categorieen = _v19.bW;
			var provincie = _v19.f1;
			var statusID = _v19.gC;
			var edited = _v19.ed;
			var match = function (textValue) {
				return A2(
					$elm$core$String$contains,
					$elm$core$String$toLower(textFilter),
					$elm$core$String$toLower(textValue));
			};
			var categorie = function (categorieValue) {
				var _v18 = A2($elm$core$Dict$get, categorieValue, categorieen);
				if (_v18.$ === 1) {
					return false;
				} else {
					var bool = _v18.a;
					return bool;
				}
			};
			return function () {
				var _v13 = values('categorie');
				if (_v13 === '') {
					return true;
				} else {
					var value = _v13;
					return categorie(value);
				}
			}() && (function () {
				var _v14 = values('provincie');
				if (_v14 === '') {
					return true;
				} else {
					var value = _v14;
					return _Utils_eq(
						$elm$core$String$fromInt(provincie),
						value);
				}
			}() && (function () {
				var _v15 = values('status');
				if (_v15 === '') {
					return true;
				} else {
					var value = _v15;
					return _Utils_eq(
						$elm$core$String$fromInt(statusID),
						value);
				}
			}() && (function () {
				var _v16 = values('edited');
				if (_v16 === '') {
					return true;
				} else {
					var value = _v16;
					if (value === 'true') {
						return edited;
					} else {
						return !edited;
					}
				}
			}() && (match(naam) || (match(indexNaam) || (match(woonplaats) || match(
				$elm$core$String$fromInt(id))))))));
		});
	var controls = F2(
		function (mdc, values) {
			var selectOption = F2(
				function (value, caption_) {
					return A2(
						$author$project$Material$Select$option,
						_List_fromArray(
							[
								$author$project$Material$Select$value(value),
								A2($author$project$Material$Options$when, value === '', $author$project$Material$Select$selected)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(caption_)
							]));
				});
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A5(
							$author$project$Material$Select$view,
							$author$project$Page$Partial$Filter$Mdc,
							'categorie-select',
							mdc,
							_List_fromArray(
								[
									$author$project$Material$Select$label('Categorie'),
									$author$project$Material$Select$preselected,
									$author$project$Material$Options$onChange(
									$author$project$Page$Partial$Filter$Value('categorie'))
								]),
							_List_fromArray(
								[
									A2(selectOption, '', 'Alle'),
									A2(selectOption, 'gemeente', 'Gemeente'),
									A2(selectOption, 'jachthaven', 'Jachthaven'),
									A2(selectOption, 'bootverhuurder', 'Bootverhuurder'),
									A2(selectOption, 'jachtwerf', 'Jachtwerf'),
									A2(selectOption, 'nautischeBoekhandel', 'Nautische Boekhandel'),
									A2(selectOption, 'vaarschool', 'Vaarschool'),
									A2(selectOption, 'waterEnOfRectratieschap', 'Water- en/of Recreatieschap'),
									A2(selectOption, 'watersportvereniging', 'Watersportvereniging'),
									A2(selectOption, 'overig', 'Overig')
								])),
							A5(
							$author$project$Material$Select$view,
							$author$project$Page$Partial$Filter$Mdc,
							'provincie-select',
							mdc,
							_List_fromArray(
								[
									$author$project$Material$Select$label('Provincie'),
									A2($author$project$Material$Options$css, 'margin-left', '1rem'),
									$author$project$Material$Select$preselected,
									$author$project$Material$Options$onChange(
									$author$project$Page$Partial$Filter$Value('provincie'))
								]),
							A2(
								$elm$core$List$cons,
								A2(selectOption, '', 'Alle'),
								A2(
									$elm$core$List$map,
									function (_v5) {
										var id = _v5.a;
										var naam = _v5.b;
										return A2(
											selectOption,
											$elm$core$String$fromInt(id),
											naam);
									},
									function () {
										if (refdata.$ === 1) {
											return _List_Nil;
										} else {
											var provincies = refdata.a.f2;
											return A2(
												$elm$core$List$map,
												function (_v7) {
													var id = _v7.cc;
													var naam = _v7.aY;
													return _Utils_Tuple2(id, naam);
												},
												A2(
													$elm$core$List$sortBy,
													function ($) {
														return $.aY;
													},
													provincies));
										}
									}()))),
							A5(
							$author$project$Material$Select$view,
							$author$project$Page$Partial$Filter$Mdc,
							'status-select',
							mdc,
							_List_fromArray(
								[
									$author$project$Material$Select$label('Status'),
									A2($author$project$Material$Options$css, 'margin-left', '1rem'),
									$author$project$Material$Select$preselected,
									$author$project$Material$Options$onChange(
									$author$project$Page$Partial$Filter$Value('status'))
								]),
							A2(
								$elm$core$List$cons,
								A2(selectOption, '', 'Alle'),
								A2(
									$elm$core$List$map,
									function (_v8) {
										var id = _v8.a;
										var naam = _v8.b;
										return A2(
											selectOption,
											$elm$core$String$fromInt(id),
											naam);
									},
									function () {
										if (refdata.$ === 1) {
											return _List_Nil;
										} else {
											var statussenByGroep = refdata.a.gD;
											var _v10 = $elm$core$List$head(
												A2(
													$elm$core$List$filter,
													function (_v11) {
														var naam = _v11.aY;
														return naam === 'Edit';
													},
													statussenByGroep));
											if (_v10.$ === 1) {
												return _List_Nil;
											} else {
												var statussen = _v10.a.cE;
												return A2(
													$elm$core$List$map,
													function (_v12) {
														var id = _v12.cc;
														var naam = _v12.aY;
														return _Utils_Tuple2(id, naam);
													},
													A2(
														$elm$core$List$sortBy,
														function ($) {
															return $.hd;
														},
														statussen));
											}
										}
									}()))),
							function () {
							var filterRadio = F3(
								function (filterName, filterValue, caption_) {
									return A2(
										$author$project$Material$FormField$view,
										_List_fromArray(
											[
												$author$project$Material$Options$cs('field')
											]),
										_List_fromArray(
											[
												A5(
												$author$project$Material$RadioButton$view,
												$author$project$Page$Partial$Filter$Mdc,
												'filter-' + (filterName + ('-' + caption_)),
												mdc,
												_List_fromArray(
													[
														A2(
														$author$project$Material$Options$when,
														_Utils_eq(
															values(filterName),
															filterValue),
														$author$project$Material$RadioButton$selected),
														$author$project$Material$Options$onClick(
														A2($author$project$Page$Partial$Filter$Value, filterName, filterValue))
													]),
												_List_Nil),
												A3(
												$author$project$Material$Options$styled,
												$elm$html$Html$label,
												_List_fromArray(
													[
														$author$project$Material$Options$onClick(
														A2($author$project$Page$Partial$Filter$Value, filterName, filterValue))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(caption_)
													]))
											]));
								});
							return A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										A3(filterRadio, 'edited', 'true', 'Bewerkt'),
										A3(filterRadio, 'edited', 'false2', 'Onbewerkt'),
										A3(filterRadio, 'edited', '', 'Alle')
									]));
						}()
						]))
				]);
		});
	var caption = function (_v4) {
		var indexNaam = _v4.cd;
		var id = _v4.cc;
		var naam = _v4.aY;
		var woonplaats = _v4.cK;
		return _Utils_ap(
			function () {
				if (indexNaam === '') {
					return $elm$core$String$fromInt(id);
				} else {
					var string = indexNaam;
					return string + (' ' + ($elm$core$String$fromInt(id) + ' |'));
				}
			}(),
			_Utils_ap(
				function () {
					if (naam === '') {
						return '';
					} else {
						var string = naam;
						return ' ' + string;
					}
				}(),
				function () {
					if (woonplaats === '') {
						return '';
					} else {
						var string = woonplaats;
						return ', ' + string;
					}
				}()));
	};
	return A6(
		$author$project$Page$Partial$Filter$defaultModel,
		$elm$core$Maybe$Just(label),
		$elm$core$Maybe$Just(caption),
		$elm$core$Maybe$Just(filter),
		$elm$core$Maybe$Nothing,
		$elm$core$Maybe$Just(sort),
		$elm$core$Maybe$Just(controls));
};
var $author$project$Data$Voorziening$VoorzieningKort = function (anwbID) {
	return function (categorieen) {
		return function (coordinaat) {
			return function (gebruikerID) {
				return function (id) {
					return function (indexNaam) {
						return function (link) {
							return function (naam) {
								return function (provincie) {
									return function (woonplaats) {
										return function (statusID) {
											return function (edited) {
												return {dw: anwbID, bW: categorieen, bZ: coordinaat, ed: edited, b9: gebruikerID, cc: id, cd: indexNaam, cn: link, aY: naam, f1: provincie, gC: statusID, cK: woonplaats};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Voorziening$decodeVoorzieningKort = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'edited',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'statusID',
		$elm$json$Json$Decode$int,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'woonplaats',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'provincie',
				$elm$json$Json$Decode$int,
				0,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'naam',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'link',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'indexNaam',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'id',
								$elm$json$Json$Decode$int,
								0,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'gebruikerID',
									$elm$json$Json$Decode$int,
									0,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'coordinaat',
										$author$project$Data$Voorziening$decodeCoordinaat,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'categorieen',
											$author$project$Data$Voorziening$decodeDictBool,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'anwbID',
												$elm$json$Json$Decode$int,
												0,
												$elm$json$Json$Decode$succeed($author$project$Data$Voorziening$VoorzieningKort)))))))))))));
var $author$project$Data$Voorziening$list = $elm$json$Json$Decode$list($author$project$Data$Voorziening$decodeVoorzieningKort);
var $author$project$Api$getVoorzieningen = F2(
	function (msg, session) {
		return A4($author$project$Api$get, msg, session, $author$project$Api$Voorzieningen, $author$project$Data$Voorziening$list);
	});
var $author$project$Page$Voorzieningen$init = function (session) {
	var _v0 = A3(
		$author$project$Page$Partial$Filter$get,
		$author$project$Page$Voorzieningen$Filter,
		$author$project$Api$getVoorzieningen,
		{
			b4: $author$project$Page$Voorzieningen$filtermodel(session),
			c6: $author$project$Material$defaultModel,
			W: session
		});
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Material$init($author$project$Page$Voorzieningen$Mdc),
					$author$project$Session$init($author$project$Page$Voorzieningen$Session),
					$author$project$Page$Partial$Filter$init($author$project$Page$Voorzieningen$Filter),
					cmd
				])));
};
var $author$project$Route$Home = {$: 0};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {ay: frag, aF: params, ar: unvisited, bG: value, aI: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.ar;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.bG);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.bG);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.fL),
					$elm$url$Url$Parser$prepareQuery(url.f3),
					url.ev,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Kunstwerk = function (a) {
	return {$: 6, a: a};
};
var $author$project$Route$Login = {$: 1};
var $author$project$Route$Password = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$Toewijzing = {$: 5};
var $author$project$Route$Vaarweg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Route$Voorziening = function (a) {
	return {$: 4, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aI;
		var unvisited = _v0.ar;
		var params = _v0.aF;
		var frag = _v0.ay;
		var value = _v0.bG;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aI;
			var unvisited = _v1.ar;
			var params = _v1.aF;
			var frag = _v1.ay;
			var value = _v1.bG;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aI;
		var unvisited = _v1.ar;
		var params = _v1.aF;
		var frag = _v1.ay;
		var value = _v1.bG;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aI;
		var unvisited = _v0.ar;
		var params = _v0.aF;
		var frag = _v0.ay;
		var value = _v0.bG;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Home,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				$elm$url$Url$Parser$s('app'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('app'),
					$elm$url$Url$Parser$s('login')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Password,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('app'),
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('password'),
						$elm$url$Url$Parser$Query$string('token'))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Voorziening,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('app'),
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('voorziening'),
						$elm$url$Url$Parser$Query$string('link'))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Toewijzing,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('app'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('voorziening'),
						$elm$url$Url$Parser$s('toewijzing'))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Vaarweg,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('app'),
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('vaarweg'),
						$elm$url$Url$Parser$Query$string('link'))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Kunstwerk,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('anwbwa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('app'),
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('kunstwerk'),
						$elm$url$Url$Parser$Query$string('link')))))
		]));
var $author$project$Route$parse = function (url) {
	var _v0 = A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url);
	if (_v0.$ === 1) {
		return $author$project$Route$Home;
	} else {
		var route = _v0.a;
		return route;
	}
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Session$rol = F2(
	function (value, model) {
		return A2(
			$elm$core$List$any,
			function (item) {
				return _Utils_eq(item, value);
			},
			model.gf);
	});
var $author$project$Main$updateWith = F3(
	function (toModel, toMsg, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Main$route = F2(
	function (url, session) {
		var voorziening = function (link) {
			return A3(
				$author$project$Main$updateWith,
				$author$project$Main$Voorziening,
				$author$project$Main$VoorzieningMsg,
				A2($author$project$Page$Voorziening$init, session, link));
		};
		var login = function (maybeToken) {
			return A3(
				$author$project$Main$updateWith,
				$author$project$Main$Login,
				$author$project$Main$LoginMsg,
				A2($author$project$Page$Login$init, session, maybeToken));
		};
		var home = A3(
			$author$project$Main$updateWith,
			$author$project$Main$Home,
			$author$project$Main$HomeMsg,
			$author$project$Page$Home$init(session));
		var _v0 = session.hV;
		if (_v0 === '') {
			var _v1 = $author$project$Route$parse(url);
			if (_v1.$ === 2) {
				var maybeToken = _v1.a;
				return login(maybeToken);
			} else {
				return login($elm$core$Maybe$Nothing);
			}
		} else {
			var _v2 = A2($author$project$Session$rol, 0, session);
			if (!_v2) {
				var _v3 = $author$project$Route$parse(url);
				if (_v3.$ === 4) {
					var maybeLink = _v3.a;
					if (!maybeLink.$) {
						var link = maybeLink.a;
						return voorziening(link);
					} else {
						return home;
					}
				} else {
					return home;
				}
			} else {
				var _v5 = $author$project$Route$parse(url);
				switch (_v5.$) {
					case 0:
						return home;
					case 1:
						return login($elm$core$Maybe$Nothing);
					case 2:
						var maybeToken = _v5.a;
						return login(maybeToken);
					case 5:
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Toewijzingen,
							$author$project$Main$ToewijzingenMsg,
							$author$project$Page$Toewijzingen$init(session));
					case 4:
						var maybeLink = _v5.a;
						if (maybeLink.$ === 1) {
							return A3(
								$author$project$Main$updateWith,
								$author$project$Main$Voorzieningen,
								$author$project$Main$VoorzieningenMsg,
								$author$project$Page$Voorzieningen$init(session));
						} else {
							var link = maybeLink.a;
							return voorziening(link);
						}
					case 3:
						var maybeLink = _v5.a;
						if (maybeLink.$ === 1) {
							return A3(
								$author$project$Main$updateWith,
								$author$project$Main$Vaarwegen,
								$author$project$Main$VaarwegenMsg,
								$author$project$Page$Vaarwegen$init(session));
						} else {
							var link = maybeLink.a;
							return A3(
								$author$project$Main$updateWith,
								$author$project$Main$Vaarweg,
								$author$project$Main$VaarwegMsg,
								A2($author$project$Page$Vaarweg$init, session, link));
						}
					default:
						var maybeLink = _v5.a;
						if (maybeLink.$ === 1) {
							return A3(
								$author$project$Main$updateWith,
								$author$project$Main$Kunstwerken,
								$author$project$Main$KunstwerkenMsg,
								$author$project$Page$Kunstwerken$init(session));
						} else {
							var link = maybeLink.a;
							return A3(
								$author$project$Main$updateWith,
								$author$project$Main$Kunstwerk,
								$author$project$Main$KunstwerkMsg,
								A2($author$project$Page$Kunstwerk$init, session, link));
						}
				}
			}
		}
	});
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.f0;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.ev,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.f3,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.fT,
					_Utils_ap(http, url.hO)),
				url.fL)));
};
var $author$project$Main$init = F3(
	function (flags, url, key) {
		return A2(
			$author$project$Main$route,
			url,
			A4(
				$author$project$Session$defaultModel,
				key,
				$elm$url$Url$toString(url),
				flags,
				url.hO));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Internal$Msg$DrawerMsg = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $author$project$Internal$Component$subs = F5(
	function (ctor, get, subscriptions, lift, model) {
		return $elm$core$Platform$Sub$batch(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (idx, state, ss) {
						return A2(
							$elm$core$List$cons,
							A2(
								$elm$core$Platform$Sub$map,
								A2(
									$elm$core$Basics$composeL,
									lift,
									ctor(idx)),
								subscriptions(state)),
							ss);
					}),
				_List_Nil,
				get(model)));
	});
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Internal$Drawer$Implementation$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Internal$Drawer$Implementation$subs = A3(
	$author$project$Internal$Component$subs,
	$author$project$Internal$Msg$DrawerMsg,
	function ($) {
		return $.cV;
	},
	$author$project$Internal$Drawer$Implementation$subscriptions);
var $author$project$Internal$Msg$MenuMsg = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Internal$Menu$Model$DocumentClick = {$: 7};
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {fR: pids, gG: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {cW: event, eZ: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.fR,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.eZ;
		var event = _v0.cW;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.gG);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $author$project$Internal$Menu$Implementation$subscriptions = function (model) {
	return (model.L && (!_Utils_eq(model.c0, $elm$core$Maybe$Nothing))) ? $elm$browser$Browser$Events$onClick(
		$elm$json$Json$Decode$succeed($author$project$Internal$Menu$Model$DocumentClick)) : $elm$core$Platform$Sub$none;
};
var $author$project$Internal$Menu$Implementation$subs = A3(
	$author$project$Internal$Component$subs,
	$author$project$Internal$Msg$MenuMsg,
	function ($) {
		return $.p;
	},
	$author$project$Internal$Menu$Implementation$subscriptions);
var $author$project$Material$subscriptions = F2(
	function (lift, model) {
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					A2($author$project$Internal$Drawer$Implementation$subs, lift, model.c6),
					A2($author$project$Internal$Menu$Implementation$subs, lift, model.c6)
				]));
	});
var $author$project$Session$subscriptions_ = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Session$Mdc, model)
			]));
};
var $author$project$Session$subscriptions = F2(
	function (lift, container) {
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Session$subscriptions_(container.W));
	});
var $author$project$Page$Home$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Home$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Home$Session, model)
			]));
};
var $author$project$Field$subscriptions_ = function (model) {
	return A2($author$project$Material$subscriptions, $author$project$Field$Mdc, model);
};
var $author$project$Field$subscriptions = F2(
	function (lift, container) {
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Field$subscriptions_(container.cY));
	});
var $author$project$Street$MapMoved = function (a) {
	return {$: 3, a: a};
};
var $author$project$Street$mapMoved = _Platform_incomingPort(
	'mapMoved',
	A2(
		$elm$json$Json$Decode$andThen,
		function (zoom) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (center) {
					return $elm$json$Json$Decode$succeed(
						{H: center, as: zoom});
				},
				A2(
					$elm$json$Json$Decode$field,
					'center',
					A2(
						$elm$json$Json$Decode$andThen,
						function (lon) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (lat) {
									return $elm$json$Json$Decode$succeed(
										{fb: lat, fj: lon});
								},
								A2($elm$json$Json$Decode$field, 'lat', $elm$json$Json$Decode$float));
						},
						A2($elm$json$Json$Decode$field, 'lon', $elm$json$Json$Decode$float))));
		},
		A2($elm$json$Json$Decode$field, 'zoom', $elm$json$Json$Decode$float)));
var $author$project$Street$subscriptions_ = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Street$Mdc, model),
				A2($author$project$Field$subscriptions, $author$project$Street$Field, model),
				$author$project$Street$mapMoved($author$project$Street$MapMoved)
			]));
};
var $author$project$Street$subscriptions = F2(
	function (lift, _v0) {
		var street = _v0.df;
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Street$subscriptions_(street));
	});
var $author$project$Page$Kunstwerk$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Kunstwerk$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Kunstwerk$Session, model),
				A2($author$project$Field$subscriptions, $author$project$Page$Kunstwerk$Field, model),
				A2($author$project$Street$subscriptions, $author$project$Page$Kunstwerk$Street, model)
			]));
};
var $author$project$Page$Partial$Filter$subscriptions_ = function (_v0) {
	var filter = _v0.b4;
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Partial$Filter$Mdc, filter),
				A2($author$project$Field$subscriptions, $author$project$Page$Partial$Filter$Field, filter)
			]));
};
var $author$project$Page$Partial$Filter$subscriptions = F2(
	function (lift, container) {
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Page$Partial$Filter$subscriptions_(container));
	});
var $author$project$Page$Partial$Kunstwerken$subscriptions_ = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Page$Partial$Filter$subscriptions, $elm$core$Basics$identity, model)
			]));
};
var $author$project$Page$Partial$Kunstwerken$subscriptions = F2(
	function (lift, _v0) {
		var kunstwerken = _v0.e1;
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Page$Partial$Kunstwerken$subscriptions_(kunstwerken));
	});
var $author$project$Page$Kunstwerken$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Session$subscriptions, $author$project$Page$Kunstwerken$Session, model),
				A2($author$project$Page$Partial$Kunstwerken$subscriptions, $author$project$Page$Kunstwerken$Kunstwerken, model)
			]));
};
var $author$project$Page$Login$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Login$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Login$Session, model),
				A2($author$project$Field$subscriptions, $author$project$Page$Login$Field, model)
			]));
};
var $author$project$Page$Toewijzingen$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Toewijzingen$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Toewijzingen$Session, model),
				A2($author$project$Page$Partial$Filter$subscriptions, $author$project$Page$Toewijzingen$Filter, model)
			]));
};
var $author$project$Page$Vaarweg$EditorValue = function (a) {
	return {$: 16, a: a};
};
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $author$project$Page$Vaarweg$editorValue = _Platform_incomingPort(
	'editorValue',
	A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (_v1) {
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(_v0, _v1));
				},
				A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string)));
var $author$project$Page$Partial$Beheerders$subscriptions_ = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Page$Partial$Filter$subscriptions, $elm$core$Basics$identity, model)
			]));
};
var $author$project$Page$Partial$Beheerders$subscriptions = F2(
	function (lift, _v0) {
		var beheerders = _v0.dC;
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Page$Partial$Beheerders$subscriptions_(beheerders));
	});
var $author$project$Page$Vaarweg$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Vaarweg$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Vaarweg$Session, model),
				A2($author$project$Field$subscriptions, $author$project$Page$Vaarweg$Field, model),
				A2($author$project$Page$Partial$Kunstwerken$subscriptions, $author$project$Page$Vaarweg$Kunstwerken, model),
				A2($author$project$Page$Partial$Beheerders$subscriptions, $author$project$Page$Vaarweg$Beheerders, model),
				$author$project$Page$Vaarweg$editorValue($author$project$Page$Vaarweg$EditorValue)
			]));
};
var $author$project$Page$Partial$Vaarwegen$subscriptions_ = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Page$Partial$Filter$subscriptions, $elm$core$Basics$identity, model)
			]));
};
var $author$project$Page$Partial$Vaarwegen$subscriptions = F2(
	function (lift, _v0) {
		var vaarwegen = _v0.g8;
		return A2(
			$elm$core$Platform$Sub$map,
			lift,
			$author$project$Page$Partial$Vaarwegen$subscriptions_(vaarwegen));
	});
var $author$project$Page$Vaarwegen$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Session$subscriptions, $author$project$Page$Vaarwegen$Session, model),
				A2($author$project$Page$Partial$Vaarwegen$subscriptions, $author$project$Page$Vaarwegen$Vaarwegen, model)
			]));
};
var $author$project$Page$Voorziening$Tick = function (a) {
	return {$: 16, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {f$: processes, gI: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.f$;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.gI);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Page$Voorziening$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Voorziening$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Voorziening$Session, model),
				A2($author$project$Field$subscriptions, $author$project$Page$Voorziening$Field, model),
				A2($author$project$Street$subscriptions, $author$project$Page$Voorziening$Street, model),
				A2($elm$time$Time$every, 1000, $author$project$Page$Voorziening$Tick)
			]));
};
var $author$project$Page$Voorzieningen$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Material$subscriptions, $author$project$Page$Voorzieningen$Mdc, model),
				A2($author$project$Session$subscriptions, $author$project$Page$Voorzieningen$Session, model),
				A2($author$project$Page$Partial$Filter$subscriptions, $author$project$Page$Voorzieningen$Filter, model)
			]));
};
var $author$project$Main$subscriptions = function (topModel) {
	switch (topModel.$) {
		case 0:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$HomeMsg,
				$author$project$Page$Home$subscriptions(model));
		case 1:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$LoginMsg,
				$author$project$Page$Login$subscriptions(model));
		case 2:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$ToewijzingenMsg,
				$author$project$Page$Toewijzingen$subscriptions(model));
		case 3:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$VoorzieningenMsg,
				$author$project$Page$Voorzieningen$subscriptions(model));
		case 4:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$VoorzieningMsg,
				$author$project$Page$Voorziening$subscriptions(model));
		case 5:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$VaarwegenMsg,
				$author$project$Page$Vaarwegen$subscriptions(model));
		case 6:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$VaarwegMsg,
				$author$project$Page$Vaarweg$subscriptions(model));
		case 7:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$KunstwerkenMsg,
				$author$project$Page$Kunstwerken$subscriptions(model));
		default:
			var model = topModel.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$KunstwerkMsg,
				$author$project$Page$Kunstwerk$subscriptions(model));
	}
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Main$toSession = function (topModel) {
	switch (topModel.$) {
		case 0:
			var model = topModel.a;
			return model.W;
		case 1:
			var model = topModel.a;
			return model.W;
		case 2:
			var model = topModel.a;
			return model.W;
		case 3:
			var model = topModel.a;
			return model.W;
		case 4:
			var model = topModel.a;
			return model.W;
		case 5:
			var model = topModel.a;
			return model.W;
		case 6:
			var model = topModel.a;
			return model.W;
		case 7:
			var model = topModel.a;
			return model.W;
		default:
			var model = topModel.a;
			return model.W;
	}
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Session$store = _Platform_outgoingPort('store', $elm$core$Basics$identity);
var $author$project$Session$token = F2(
	function (value, container) {
		var session = container.W;
		return _Utils_Tuple2(
			_Utils_update(
				container,
				{
					W: A2($author$project$Session$saveJwt, value, session)
				}),
			$author$project$Session$store(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'key',
							$elm$json$Json$Encode$string('jwt')),
							_Utils_Tuple2(
							'value',
							$elm$json$Json$Encode$string(value))
						]))));
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Internal$Dispatch$forward = function (msgs) {
	return $elm$core$Platform$Cmd$batch(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Task$perform($elm$core$Basics$identity),
				$elm$core$Task$succeed),
			msgs));
};
var $author$project$Internal$Component$react = F8(
	function (get, set, ctor, update, lift, msg, idx, store) {
		return A2(
			$elm$core$Tuple$mapFirst,
			$elm$core$Maybe$map(
				A2(set, idx, store)),
			A3(
				update,
				A2(
					$elm$core$Basics$composeL,
					lift,
					ctor(idx)),
				msg,
				A2(get, idx, store)));
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Internal$Helpers$delayedCmd = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(time));
	});
var $author$project$Internal$Ripple$Model$Activate = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Internal$Ripple$Model$Activated = function (a) {
	return {$: 1, a: a};
};
var $author$project$Internal$Ripple$Model$ActivationEnded = function (a) {
	return {$: 5, a: a};
};
var $author$project$Internal$Ripple$Model$Deactivated = function (a) {
	return {$: 2, a: a};
};
var $author$project$Internal$Ripple$Model$DeactivationEnded = function (a) {
	return {$: 7, a: a};
};
var $author$project$Internal$Ripple$Model$Reactivate = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Internal$Ripple$Implementation$normalizedEventCoords = F3(
	function (event, pageOffset, clientRect) {
		var _v0 = pageOffset;
		var x = _v0.hp;
		var y = _v0.hq;
		var documentX = x + clientRect.hp;
		var documentY = x + clientRect.hq;
		var _v1 = event.h5;
		var pageX = _v1.fH;
		var pageY = _v1.fI;
		return {hp: pageX - documentX, hq: pageY - documentY};
	});
var $author$project$Internal$Ripple$Implementation$fgTranslationCoords = F2(
	function (isUnbounded, _v0) {
		var frame = _v0.ai;
		var activationEvent = _v0.aK;
		var windowPageOffset = _v0.ho;
		var wasActivatedByPointer = _v0.hi;
		var maxDimension = A2($elm$core$Basics$max, frame.hn, frame.eH);
		var initialSize = maxDimension * 0.6;
		var startPoint = function () {
			var _v1 = A3($author$project$Internal$Ripple$Implementation$normalizedEventCoords, activationEvent, windowPageOffset, frame);
			var x = _v1.hp;
			var y = _v1.hq;
			return {hp: x - (initialSize / 2), hq: y - (initialSize / 2)};
		}();
		var endPoint = {hp: (frame.hn - initialSize) / 2, hq: (frame.eH - initialSize) / 2};
		return {eh: endPoint, gB: startPoint};
	});
var $author$project$Internal$Ripple$Implementation$animateActivation = F4(
	function (isUnbounded, frame, windowPageOffset, activationEvent) {
		var wasActivatedByPointer = true;
		var _v0 = A2(
			$author$project$Internal$Ripple$Implementation$fgTranslationCoords,
			isUnbounded,
			{aK: activationEvent, ai: frame, hi: wasActivatedByPointer, ho: windowPageOffset});
		var startPoint = _v0.gB;
		var endPoint = _v0.eh;
		var translateEnd = isUnbounded ? '' : ($elm$core$String$fromFloat(endPoint.hp) + ('px, ' + ($elm$core$String$fromFloat(endPoint.hq) + 'px')));
		var translateStart = isUnbounded ? '' : ($elm$core$String$fromFloat(startPoint.hp) + ('px, ' + ($elm$core$String$fromFloat(startPoint.hq) + 'px')));
		return {ap: translateEnd, aq: translateStart};
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $author$project$Internal$Ripple$Model$numbers = {hB: 225, hI: 150, hR: 0.6, h4: 10, gJ: 300};
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $author$project$Internal$Ripple$Implementation$layoutInternal = F2(
	function (isUnbounded, frame) {
		var maxDim = A2($elm$core$Basics$max, frame.hn, frame.eH);
		var initialSize = maxDim * $author$project$Internal$Ripple$Model$numbers.hR;
		var hypotenuse = $elm$core$Basics$sqrt(
			A2($elm$core$Basics$pow, frame.hn, 2) + A2($elm$core$Basics$pow, frame.eH, 2));
		var boundedRadius = hypotenuse + $author$project$Internal$Ripple$Model$numbers.h4;
		var maxRadius = isUnbounded ? maxDim : boundedRadius;
		var fgScale = maxRadius / initialSize;
		return {ag: fgScale, J: initialSize};
	});
var $author$project$Internal$Ripple$Implementation$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.x);
		_v0$10:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c$: true}),
						$elm$core$Platform$Cmd$none);
				case 1:
					var _v2 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c$: false}),
						$elm$core$Platform$Cmd$none);
				case 2:
					if (!_v0.b.$) {
						var _v3 = _v0.a;
						var domId = _v3.a;
						var activateData = _v3.b;
						var _v4 = _v0.b;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$core$Task$attempt,
								$author$project$Internal$Ripple$Model$Activate(activateData),
								$elm$browser$Browser$Dom$getElement(domId)));
					} else {
						var _v5 = _v0.a;
						var domId = _v5.a;
						var activateData = _v5.b;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$core$Task$attempt,
								$author$project$Internal$Ripple$Model$Reactivate(activateData),
								$elm$browser$Browser$Dom$getElement(domId)));
					}
				case 4:
					var _v6 = _v0.a;
					var activateData = _v6.a;
					var element = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{x: $author$project$Internal$Ripple$Model$Idle}),
						A2(
							$elm$core$Task$perform,
							function (_v7) {
								return A2($author$project$Internal$Ripple$Model$Activate, activateData, element);
							},
							$elm$core$Task$succeed(0)));
				case 3:
					if (_v0.a.b.$ === 1) {
						var _v8 = _v0.a;
						var activateData = _v8.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var _v9 = _v0.a;
						var activateData = _v9.a;
						var element = _v9.b.a.hC;
						var viewport = _v9.b.a.hb;
						var newAnimationCounter = model.aM + 1;
						var _v10 = A4($author$project$Internal$Ripple$Implementation$animateActivation, activateData.bn, element, viewport, activateData.cW);
						var translateStart = _v10.aq;
						var translateEnd = _v10.ap;
						var _v11 = A2($author$project$Internal$Ripple$Implementation$layoutInternal, activateData.bn, element);
						var fgScale = _v11.ag;
						var initialSize = _v11.J;
						var activatedData = {
							aK: $elm$core$Maybe$Just(activateData.cW),
							cN: false,
							b1: false,
							ag: fgScale,
							ai: {eH: element.eH, fc: element.hp, g0: element.hq, hn: element.hn},
							J: initialSize,
							ap: translateEnd,
							aq: translateStart,
							$7: activateData.$7
						};
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aM: newAnimationCounter,
									x: $author$project$Internal$Ripple$Model$Activated(activatedData)
								}),
							A2(
								$elm$core$Task$perform,
								function (_v12) {
									return $author$project$Internal$Ripple$Model$ActivationEnded(newAnimationCounter);
								},
								$elm$core$Process$sleep($author$project$Internal$Ripple$Model$numbers.hB)));
					}
				case 5:
					if (_v0.b.$ === 1) {
						var animationCount = _v0.a.a;
						var activatedData = _v0.b.a;
						if (_Utils_eq(animationCount, model.aM)) {
							if (activatedData.b1) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											x: $author$project$Internal$Ripple$Model$Deactivated(activatedData)
										}),
									A2(
										$elm$core$Task$perform,
										function (_v13) {
											return $author$project$Internal$Ripple$Model$DeactivationEnded(model.aM);
										},
										$elm$core$Process$sleep($author$project$Internal$Ripple$Model$numbers.gJ)));
							} else {
								var newActivatedData = _Utils_update(
									activatedData,
									{cN: true});
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											x: $author$project$Internal$Ripple$Model$Activated(newActivatedData)
										}),
									$elm$core$Platform$Cmd$none);
							}
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						break _v0$10;
					}
				case 6:
					if (_v0.b.$ === 1) {
						var _v14 = _v0.a;
						var activatedData = _v0.b.a;
						if (activatedData.cN) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										x: $author$project$Internal$Ripple$Model$Deactivated(activatedData)
									}),
								A2(
									$elm$core$Task$perform,
									function (_v15) {
										return $author$project$Internal$Ripple$Model$DeactivationEnded(model.aM);
									},
									$elm$core$Process$sleep($author$project$Internal$Ripple$Model$numbers.gJ)));
						} else {
							var newActivatedData = _Utils_update(
								activatedData,
								{aK: $elm$core$Maybe$Nothing, b1: true});
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										x: $author$project$Internal$Ripple$Model$Activated(newActivatedData)
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						break _v0$10;
					}
				default:
					if (_v0.b.$ === 2) {
						var animationCount = _v0.a.a;
						return _Utils_eq(animationCount, model.aM) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{x: $author$project$Internal$Ripple$Model$Idle}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						break _v0$10;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Internal$Button$Implementation$update = F3(
	function (lift, msg, model) {
		if (!msg.$) {
			var msg_ = msg.a;
			var _v1 = A2($author$project$Internal$Ripple$Implementation$update, msg_, model.U);
			var rippleState = _v1.a;
			var rippleCmd = _v1.b;
			return _Utils_Tuple2(
				$elm$core$Maybe$Just(
					_Utils_update(
						model,
						{U: rippleState})),
				A2(
					$elm$core$Platform$Cmd$map,
					A2($elm$core$Basics$composeL, lift, $author$project$Internal$Button$Model$RippleMsg),
					rippleCmd));
		} else {
			var doRipple = msg.a;
			var msg_ = msg.b;
			return _Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Internal$Helpers$delayedCmd,
					doRipple ? 150 : 0,
					msg_));
		}
	});
var $author$project$Internal$Button$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Button$Implementation$getSet.ey, $author$project$Internal$Button$Implementation$getSet.id, $author$project$Internal$Msg$ButtonMsg, $author$project$Internal$Button$Implementation$update);
var $author$project$Internal$Msg$CheckboxMsg = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Internal$Checkbox$Model$defaultModel = {bO: $elm$core$Maybe$Nothing, hT: false, cl: $elm$core$Maybe$Nothing};
var $author$project$Internal$Checkbox$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.dT;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{dT: x});
		}),
	$author$project$Internal$Checkbox$Model$defaultModel);
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Internal$Checkbox$Model$CheckedIndeterminate = 3;
var $author$project$Internal$Checkbox$Model$CheckedUnchecked = 2;
var $author$project$Internal$Checkbox$Model$IndeterminateChecked = 4;
var $author$project$Internal$Checkbox$Model$IndeterminateUnchecked = 5;
var $author$project$Internal$Checkbox$Model$UncheckedChecked = 0;
var $author$project$Internal$Checkbox$Model$UncheckedIndeterminate = 1;
var $author$project$Internal$Checkbox$Implementation$animationState = F2(
	function (oldState, state) {
		var _v0 = _Utils_Tuple2(oldState, state);
		_v0$6:
		while (true) {
			if (_v0.a.$ === 1) {
				if (!_v0.b.$) {
					if (!_v0.b.a) {
						var _v5 = _v0.a;
						var _v6 = _v0.b.a;
						return $elm$core$Maybe$Just(4);
					} else {
						var _v7 = _v0.a;
						var _v8 = _v0.b.a;
						return $elm$core$Maybe$Just(5);
					}
				} else {
					break _v0$6;
				}
			} else {
				if (_v0.a.a === 1) {
					if (_v0.b.$ === 1) {
						var _v1 = _v0.a.a;
						var _v2 = _v0.b;
						return $elm$core$Maybe$Just(1);
					} else {
						if (!_v0.b.a) {
							var _v9 = _v0.a.a;
							var _v10 = _v0.b.a;
							return $elm$core$Maybe$Just(0);
						} else {
							break _v0$6;
						}
					}
				} else {
					if (_v0.b.$ === 1) {
						var _v3 = _v0.a.a;
						var _v4 = _v0.b;
						return $elm$core$Maybe$Just(3);
					} else {
						if (_v0.b.a === 1) {
							var _v11 = _v0.a.a;
							var _v12 = _v0.b.a;
							return $elm$core$Maybe$Just(2);
						} else {
							break _v0$6;
						}
					}
				}
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $author$project$Internal$Checkbox$Implementation$update = F3(
	function (_v0, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
			case 2:
				var focus = msg.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{hT: focus})),
					$elm$core$Platform$Cmd$none);
			case 1:
				var lastKnownState = msg.a;
				var state = msg.b;
				var animation = A2(
					$elm$core$Maybe$andThen,
					function (lastKnownState_) {
						return A2($author$project$Internal$Checkbox$Implementation$animationState, lastKnownState_, state);
					},
					lastKnownState);
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{
								bO: animation,
								cl: $elm$core$Maybe$Just(state)
							})),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{bO: $elm$core$Maybe$Nothing})),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Checkbox$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Checkbox$Implementation$getSet.ey, $author$project$Internal$Checkbox$Implementation$getSet.id, $author$project$Internal$Msg$CheckboxMsg, $author$project$Internal$Checkbox$Implementation$update);
var $author$project$Internal$Msg$ChipMsg = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Internal$Chip$Model$defaultModel = {U: $author$project$Internal$Ripple$Model$defaultModel};
var $author$project$Internal$Chip$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.dU;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{dU: x});
		}),
	$author$project$Internal$Chip$Model$defaultModel);
var $author$project$Internal$Chip$Model$RippleMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$Chip$Implementation$update = F3(
	function (lift, msg, model) {
		if (!msg.$) {
			var msg_ = msg.a;
			var _v1 = A2($author$project$Internal$Ripple$Implementation$update, msg_, model.U);
			var ripple = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				$elm$core$Maybe$Just(
					_Utils_update(
						model,
						{U: ripple})),
				A2(
					$elm$core$Platform$Cmd$map,
					A2($elm$core$Basics$composeL, lift, $author$project$Internal$Chip$Model$RippleMsg),
					cmd));
		} else {
			var msg_ = msg.a;
			return _Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				A2($author$project$Internal$Helpers$delayedCmd, 150, msg_));
		}
	});
var $author$project$Internal$Chip$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Chip$Implementation$getSet.ey, $author$project$Internal$Chip$Implementation$getSet.id, $author$project$Internal$Msg$ChipMsg, $author$project$Internal$Chip$Implementation$update);
var $author$project$Internal$Msg$DialogMsg = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Internal$Dialog$Model$defaultModel = {cO: false, L: false};
var $author$project$Internal$Dialog$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.d5;
	},
	F2(
		function (x, c) {
			return _Utils_update(
				c,
				{d5: x});
		}),
	$author$project$Internal$Dialog$Model$defaultModel);
var $author$project$Internal$Dialog$Implementation$update = F3(
	function (lift, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
			case 1:
				var isOpen = msg.a;
				return (!_Utils_eq(isOpen, model.L)) ? _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{cO: true, L: isOpen})),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
			case 2:
				var isOpen = msg.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{L: isOpen})),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{cO: false})),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Dialog$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Dialog$Implementation$getSet.ey, $author$project$Internal$Dialog$Implementation$getSet.id, $author$project$Internal$Msg$DialogMsg, $author$project$Internal$Dialog$Implementation$update);
var $author$project$Internal$Drawer$Model$defaultModel = {cO: false, L: false, fQ: false, cD: $elm$core$Maybe$Nothing};
var $author$project$Internal$Drawer$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.cV;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{cV: x});
		}),
	$author$project$Internal$Drawer$Model$defaultModel);
var $author$project$Internal$Drawer$Implementation$update = F3(
	function (lift, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{
								cO: false,
								cD: $elm$core$Maybe$Just(model.L)
							})),
					$elm$core$Platform$Cmd$none);
			default:
				var _v1 = msg.a;
				var isOpen = _v1.a;
				var persistent = _v1.b;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{cO: true, L: isOpen, fQ: persistent, cD: $elm$core$Maybe$Nothing})),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Drawer$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Drawer$Implementation$getSet.ey, $author$project$Internal$Drawer$Implementation$getSet.id, $author$project$Internal$Msg$DrawerMsg, $author$project$Internal$Drawer$Implementation$update);
var $author$project$Internal$Msg$FabMsg = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Internal$Component$generalise = F4(
	function (update, lift, msg, model) {
		return A2(
			$elm$core$Tuple$mapSecond,
			$elm$core$Platform$Cmd$map(lift),
			A2(
				$elm$core$Tuple$mapFirst,
				$elm$core$Maybe$Just,
				A2(update, msg, model)));
	});
var $author$project$Internal$Fab$Model$defaultModel = {U: $author$project$Internal$Ripple$Model$defaultModel};
var $author$project$Internal$Fab$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.ek;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{ek: x});
		}),
	$author$project$Internal$Fab$Model$defaultModel);
var $author$project$Internal$Fab$Model$RippleMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$Fab$Implementation$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var msg_ = msg.a;
			var _v1 = A2($author$project$Internal$Ripple$Implementation$update, msg_, model.U);
			var rippleState = _v1.a;
			var rippleCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{U: rippleState}),
				A2($elm$core$Platform$Cmd$map, $author$project$Internal$Fab$Model$RippleMsg, rippleCmd));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Fab$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$Fab$Implementation$getSet.ey,
	$author$project$Internal$Fab$Implementation$getSet.id,
	$author$project$Internal$Msg$FabMsg,
	$author$project$Internal$Component$generalise($author$project$Internal$Fab$Implementation$update));
var $author$project$Internal$Msg$GridListMsg = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Internal$GridList$Model$defaultModel = {dZ: false, c0: $elm$core$Maybe$Nothing, e8: 0, gb: true, gd: false};
var $author$project$Internal$GridList$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.eB;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{eB: x});
		}),
	$author$project$Internal$GridList$Model$defaultModel);
var $author$project$Internal$GridList$Implementation$update = F2(
	function (msg, model) {
		var geometry = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c0: $elm$core$Maybe$Just(geometry)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Internal$GridList$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$GridList$Implementation$getSet.ey,
	$author$project$Internal$GridList$Implementation$getSet.id,
	$author$project$Internal$Msg$GridListMsg,
	$author$project$Internal$Component$generalise($author$project$Internal$GridList$Implementation$update));
var $author$project$Internal$Msg$IconToggleMsg = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Internal$IconToggle$Model$defaultModel = {t: false, U: $author$project$Internal$Ripple$Model$defaultModel};
var $author$project$Internal$IconToggle$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.eL;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{eL: x});
		}),
	$author$project$Internal$IconToggle$Model$defaultModel);
var $author$project$Internal$IconToggle$Model$RippleMsg = $elm$core$Basics$identity;
var $author$project$Internal$IconToggle$Implementation$update = F2(
	function (msg, model) {
		var msg_ = msg;
		var _v1 = A2($author$project$Internal$Ripple$Implementation$update, msg_, model.U);
		var ripple = _v1.a;
		var effects = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{U: ripple}),
			A2($elm$core$Platform$Cmd$map, $elm$core$Basics$identity, effects));
	});
var $author$project$Internal$IconToggle$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$IconToggle$Implementation$getSet.ey,
	$author$project$Internal$IconToggle$Implementation$getSet.id,
	$author$project$Internal$Msg$IconToggleMsg,
	$author$project$Internal$Component$generalise($author$project$Internal$IconToggle$Implementation$update));
var $author$project$Internal$Menu$Model$defaultModel = {cO: false, R: $elm$core$Maybe$Nothing, c0: $elm$core$Maybe$Nothing, aV: $elm$core$Maybe$Nothing, cg: false, L: false, D: $elm$core$Maybe$Nothing};
var $author$project$Internal$Menu$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.p;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{p: x});
		}),
	$author$project$Internal$Menu$Model$defaultModel);
var $author$project$Internal$Menu$Model$AnimationEnd = {$: 2};
var $author$project$Internal$Menu$Model$Close = {$: 4};
var $author$project$Internal$Menu$Model$Open = {$: 3};
var $author$project$Internal$Helpers$cmd = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$Internal$Menu$Implementation$update = F3(
	function (lift, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				case 5:
					var $temp$lift = lift,
						$temp$msg = model.L ? $author$project$Internal$Menu$Model$Close : $author$project$Internal$Menu$Model$Open,
						$temp$model = model;
					lift = $temp$lift;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 3:
					var doQuickOpen = A2($elm$core$Maybe$withDefault, false, model.D);
					return (!model.L) ? _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{cO: true, c0: $elm$core$Maybe$Nothing, L: true})),
						(!doQuickOpen) ? A2(
							$author$project$Internal$Helpers$delayedCmd,
							120,
							lift($author$project$Internal$Menu$Model$AnimationEnd)) : $author$project$Internal$Helpers$cmd(
							lift($author$project$Internal$Menu$Model$AnimationEnd))) : _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				case 4:
					var doQuickOpen = A2($elm$core$Maybe$withDefault, false, model.D);
					return model.L ? _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{cO: true, R: $elm$core$Maybe$Nothing, L: false, D: $elm$core$Maybe$Nothing})),
						(!doQuickOpen) ? A2(
							$author$project$Internal$Helpers$delayedCmd,
							70,
							lift($author$project$Internal$Menu$Model$AnimationEnd)) : $author$project$Internal$Helpers$cmd(
							lift($author$project$Internal$Menu$Model$AnimationEnd))) : _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				case 6:
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						A2(
							$author$project$Internal$Helpers$delayedCmd,
							50,
							lift($author$project$Internal$Menu$Model$Close)));
				case 1:
					var config = msg.a;
					var geometry = msg.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									R: config.aV,
									c0: $elm$core$Maybe$Just(geometry),
									D: $elm$core$Maybe$Just(config.D)
								})),
						$elm$core$Platform$Cmd$none);
				case 2:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{cO: false})),
						$elm$core$Platform$Cmd$none);
				case 7:
					var $temp$lift = lift,
						$temp$msg = $author$project$Internal$Menu$Model$Close,
						$temp$model = model;
					lift = $temp$lift;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 8:
					var numItems = msg.a;
					var shiftKey = msg.b.cB;
					var altKey = msg.b.bN;
					var ctrlKey = msg.b.b_;
					var metaKey = msg.b.cp;
					var key = msg.c;
					var keyCode = msg.d;
					var lastItemIndex = numItems - 1;
					var isTab = (key === 'Tab') || (keyCode === 9);
					var isSpace = (key === 'Space') || (keyCode === 32);
					var isEnter = (key === 'Enter') || (keyCode === 13);
					var keyDownWithinMenu = isEnter || isSpace;
					var isArrowUp = (key === 'ArrowUp') || (keyCode === 38);
					var isArrowDown = (key === 'ArrowDown') || (keyCode === 40);
					var focusedItemIndex = A2($elm$core$Maybe$withDefault, 0, model.R);
					return A2(
						$elm$core$Tuple$mapFirst,
						$elm$core$Maybe$map(
							function (updatedModel) {
								return _Utils_update(
									updatedModel,
									{cg: keyDownWithinMenu});
							}),
						(altKey || (ctrlKey || metaKey)) ? _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none) : (isArrowUp ? _Utils_Tuple2(
							$elm$core$Maybe$Just(
								(!focusedItemIndex) ? _Utils_update(
									model,
									{
										R: $elm$core$Maybe$Just(lastItemIndex)
									}) : _Utils_update(
									model,
									{
										R: $elm$core$Maybe$Just(focusedItemIndex - 1)
									})),
							$elm$core$Platform$Cmd$none) : (isArrowDown ? _Utils_Tuple2(
							$elm$core$Maybe$Just(
								_Utils_eq(focusedItemIndex, lastItemIndex) ? _Utils_update(
									model,
									{
										R: $elm$core$Maybe$Just(0)
									}) : _Utils_update(
									model,
									{
										R: $elm$core$Maybe$Just(focusedItemIndex + 1)
									})),
							$elm$core$Platform$Cmd$none) : ((isSpace || isEnter) ? _Utils_Tuple2(
							$elm$core$Maybe$Just(model),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none)))));
				case 9:
					var shiftKey = msg.a.cB;
					var altKey = msg.a.bN;
					var ctrlKey = msg.a.b_;
					var metaKey = msg.a.cp;
					var key = msg.b;
					var keyCode = msg.c;
					var isSpace = (key === 'Space') || (keyCode === 32);
					var isEscape = (key === 'Escape') || (keyCode === 27);
					var isEnter = (key === 'Enter') || (keyCode === 13);
					return A2(
						$elm$core$Tuple$mapFirst,
						$elm$core$Maybe$map(
							function (updatedModel) {
								return ((isEnter || isSpace) && updatedModel.cg) ? _Utils_update(
									updatedModel,
									{cg: false}) : updatedModel;
							}),
						(altKey || (ctrlKey || metaKey)) ? _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none) : ((isEscape || ((isSpace || isEnter) && model.cg)) ? A3($author$project$Internal$Menu$Implementation$update, lift, $author$project$Internal$Menu$Model$Close, model) : _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none)));
				default:
					var focusedItemAtIndex = msg.a;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									R: $elm$core$Maybe$Just(focusedItemAtIndex)
								})),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Internal$Menu$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Menu$Implementation$getSet.ey, $author$project$Internal$Menu$Implementation$getSet.id, $author$project$Internal$Msg$MenuMsg, $author$project$Internal$Menu$Implementation$update);
var $author$project$Internal$RadioButton$Implementation$update = F3(
	function (lift, msg, model) {
		switch (msg.$) {
			case 0:
				var msg_ = msg.a;
				var _v1 = A2($author$project$Internal$Ripple$Implementation$update, msg_, model.U);
				var ripple = _v1.a;
				var effects = _v1.b;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{U: ripple})),
					A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeL, lift, $author$project$Internal$RadioButton$Model$RippleMsg),
						effects));
			case 1:
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
			default:
				var focus = msg.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{hT: focus})),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$RadioButton$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$RadioButton$Implementation$getSet.ey, $author$project$Internal$RadioButton$Implementation$getSet.id, $author$project$Internal$Msg$RadioButtonMsg, $author$project$Internal$RadioButton$Implementation$update);
var $author$project$Internal$Msg$RippleMsg = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Internal$Ripple$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.U;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{U: x});
		}),
	$author$project$Internal$Ripple$Model$defaultModel);
var $author$project$Internal$Ripple$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$Ripple$Implementation$getSet.ey,
	$author$project$Internal$Ripple$Implementation$getSet.id,
	$author$project$Internal$Msg$RippleMsg,
	$author$project$Internal$Component$generalise($author$project$Internal$Ripple$Implementation$update));
var $author$project$Internal$Select$Model$RippleMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Internal$Select$Implementation$update = F3(
	function (lift, msg, model) {
		switch (msg.$) {
			case 2:
				var changedValue = msg.a;
				var dirty = changedValue !== '';
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{eP: dirty})),
					$elm$core$Platform$Cmd$none);
			case 0:
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{c$: false})),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{c$: true})),
					$elm$core$Platform$Cmd$none);
			default:
				var msg_ = msg.a;
				var _v1 = A2($author$project$Internal$Ripple$Implementation$update, msg_, model.U);
				var ripple = _v1.a;
				var effects = _v1.b;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{U: ripple})),
					A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeL, lift, $author$project$Internal$Select$Model$RippleMsg),
						effects));
		}
	});
var $author$project$Internal$Select$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Select$Implementation$getSet.ey, $author$project$Internal$Select$Implementation$getSet.id, $author$project$Internal$Msg$SelectMsg, $author$project$Internal$Select$Implementation$update);
var $author$project$Internal$Msg$SliderMsg = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Internal$Slider$Model$defaultModel = {F: false, ba: $elm$core$Maybe$Nothing, b7: false, c0: $elm$core$Maybe$Nothing, bl: false, cw: false};
var $author$project$Internal$Slider$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.gv;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{gv: x});
		}),
	$author$project$Internal$Slider$Model$defaultModel);
var $author$project$Internal$Slider$Model$ActualUp = {$: 11};
var $author$project$Internal$Slider$Model$Init = function (a) {
	return {$: 1, a: a};
};
var $author$project$Internal$Slider$Model$defaultGeometry = {
	Q: false,
	o: 100,
	h: 0,
	bu: {fc: 0, hn: 0},
	N: $elm$core$Maybe$Nothing
};
var $author$project$Internal$Slider$Implementation$valueFromPageX = F2(
	function (geometry, pageX) {
		var xPos = pageX - geometry.bu.fc;
		var isRtl = false;
		var pctComplete = isRtl ? (1 - (xPos / geometry.bu.hn)) : (xPos / geometry.bu.hn);
		return geometry.h + (pctComplete * (geometry.o - geometry.h));
	});
var $author$project$Internal$Slider$Implementation$update = F3(
	function (lift, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				case 5:
					return (!model.cw) ? _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{b7: true})),
						$elm$core$Platform$Cmd$none) : _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				case 6:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{b7: false, cw: false})),
						$elm$core$Platform$Cmd$none);
				case 8:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{bl: false})),
						$elm$core$Platform$Cmd$none);
				case 3:
					var pageX = msg.b.fH;
					var geometry = A2($elm$core$Maybe$withDefault, $author$project$Internal$Slider$Model$defaultGeometry, model.c0);
					var activeValue = A2($author$project$Internal$Slider$Implementation$valueFromPageX, geometry, pageX);
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									F: true,
									ba: $elm$core$Maybe$Just(activeValue),
									bl: true,
									cw: true
								})),
						$elm$core$Platform$Cmd$none);
				case 7:
					var pageX = msg.b.fH;
					var geometry = A2($elm$core$Maybe$withDefault, $author$project$Internal$Slider$Model$defaultGeometry, model.c0);
					var activeValue = A2($author$project$Internal$Slider$Implementation$valueFromPageX, geometry, pageX);
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									F: true,
									ba: $elm$core$Maybe$Just(activeValue),
									bl: false,
									cw: true
								})),
						$elm$core$Platform$Cmd$none);
				case 9:
					var pageX = msg.a.fH;
					if (model.F) {
						var geometry = A2($elm$core$Maybe$withDefault, $author$project$Internal$Slider$Model$defaultGeometry, model.c0);
						var activeValue = A2($author$project$Internal$Slider$Implementation$valueFromPageX, geometry, pageX);
						return _Utils_Tuple2(
							$elm$core$Maybe$Just(
								_Utils_update(
									model,
									{
										ba: $elm$core$Maybe$Just(activeValue),
										bl: false
									})),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				case 1:
					var geometry = msg.a;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									c0: $elm$core$Maybe$Just(geometry)
								})),
						$elm$core$Platform$Cmd$none);
				case 2:
					var geometry = msg.a;
					var $temp$lift = lift,
						$temp$msg = $author$project$Internal$Slider$Model$Init(geometry),
						$temp$model = model;
					lift = $temp$lift;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 4:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{b7: true})),
						$elm$core$Platform$Cmd$none);
				case 10:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(model),
						A2(
							$elm$core$Task$perform,
							lift,
							$elm$core$Task$succeed($author$project$Internal$Slider$Model$ActualUp)));
				default:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{F: false, ba: $elm$core$Maybe$Nothing})),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Internal$Slider$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Slider$Implementation$getSet.ey, $author$project$Internal$Slider$Implementation$getSet.id, $author$project$Internal$Msg$SliderMsg, $author$project$Internal$Slider$Implementation$update);
var $author$project$Internal$Msg$SnackbarMsg = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Internal$Snackbar$Model$Inert = {$: 0};
var $author$project$Internal$Snackbar$Model$defaultModel = {cy: _List_Nil, a2: -1, cD: $author$project$Internal$Snackbar$Model$Inert};
var $author$project$Internal$Snackbar$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.bz;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{bz: x});
		}),
	$author$project$Internal$Snackbar$Model$defaultModel);
var $author$project$Internal$Snackbar$Model$Clicked = 1;
var $author$project$Internal$Snackbar$Model$Move = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Internal$Snackbar$Model$Fading = function (a) {
	return {$: 2, a: a};
};
var $author$project$Internal$Snackbar$Model$Timeout = 0;
var $author$project$Internal$Snackbar$Implementation$next = function (model) {
	return $elm$core$Platform$Cmd$map(
		$author$project$Internal$Snackbar$Model$Move(model.a2));
};
var $author$project$Internal$Snackbar$Model$Active = function (a) {
	return {$: 1, a: a};
};
var $author$project$Internal$Snackbar$Implementation$tryDequeue = function (model) {
	var _v0 = _Utils_Tuple2(model.cD, model.cy);
	if ((!_v0.a.$) && _v0.b.b) {
		var _v1 = _v0.a;
		var _v2 = _v0.b;
		var c = _v2.a;
		var cs = _v2.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					cy: cs,
					a2: model.a2 + 1,
					cD: $author$project$Internal$Snackbar$Model$Active(c)
				}),
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Internal$Snackbar$Model$Move(model.a2 + 1),
				A2($author$project$Internal$Helpers$delayedCmd, c.dj, 0)));
	} else {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$Internal$Snackbar$Implementation$move = F2(
	function (transition, model) {
		var _v0 = _Utils_Tuple2(model.cD, transition);
		if (_v0.b === 1) {
			if (_v0.a.$ === 1) {
				var contents = _v0.a.a;
				var _v3 = _v0.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cD: $author$project$Internal$Snackbar$Model$Fading(contents)
						}),
					A2(
						$author$project$Internal$Snackbar$Implementation$next,
						model,
						A2($author$project$Internal$Helpers$delayedCmd, contents.b3, 0)));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return $author$project$Internal$Snackbar$Implementation$tryDequeue(model);
				case 1:
					var contents = _v0.a.a;
					var _v4 = _v0.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cD: $author$project$Internal$Snackbar$Model$Fading(contents)
							}),
						A2(
							$author$project$Internal$Snackbar$Implementation$next,
							model,
							A2($author$project$Internal$Helpers$delayedCmd, contents.b3, 0)));
				default:
					var contents = _v0.a.a;
					var _v5 = _v0.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cD: $author$project$Internal$Snackbar$Model$Inert}),
						A2(
							$author$project$Internal$Snackbar$Implementation$next,
							model,
							$author$project$Internal$Helpers$cmd(0)));
			}
		}
	});
var $author$project$Internal$Snackbar$Implementation$update = F3(
	function (fwd, msg, model) {
		if (!msg.$) {
			var seq = msg.a;
			var transition = msg.b;
			return _Utils_eq(seq, model.a2) ? A2(
				$elm$core$Tuple$mapSecond,
				$elm$core$Platform$Cmd$map(fwd),
				A2($author$project$Internal$Snackbar$Implementation$move, transition, model)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			var dismissOnAction = msg.a;
			var actionOnDismiss = msg.b;
			var fwdEffect = function () {
				if (!actionOnDismiss.$) {
					var msg_ = actionOnDismiss.a;
					return $author$project$Internal$Helpers$cmd(msg_);
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			}();
			return A2(
				$elm$core$Tuple$mapSecond,
				function (cmd) {
					return $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[cmd, fwdEffect]));
				},
				dismissOnAction ? A3(
					$author$project$Internal$Snackbar$Implementation$update,
					fwd,
					A2($author$project$Internal$Snackbar$Model$Move, model.a2, 1),
					model) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Internal$Snackbar$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$Snackbar$Implementation$getSet.ey,
	$author$project$Internal$Snackbar$Implementation$getSet.id,
	$author$project$Internal$Msg$SnackbarMsg,
	F3(
		function (fwd, msg, model) {
			return A2(
				$elm$core$Tuple$mapFirst,
				$elm$core$Maybe$Just,
				A3($author$project$Internal$Snackbar$Implementation$update, fwd, msg, model));
		}));
var $author$project$Internal$Msg$SwitchMsg = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Internal$Switch$Model$defaultModel = {hT: false};
var $author$project$Internal$Switch$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.gH;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{gH: x});
		}),
	$author$project$Internal$Switch$Model$defaultModel);
var $author$project$Internal$Switch$Implementation$update = F3(
	function (_v0, msg, model) {
		if (!msg.$) {
			var focus = msg.a;
			return _Utils_Tuple2(
				$elm$core$Maybe$Just(
					_Utils_update(
						model,
						{hT: focus})),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Switch$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Switch$Implementation$getSet.ey, $author$project$Internal$Switch$Implementation$getSet.id, $author$project$Internal$Msg$SwitchMsg, $author$project$Internal$Switch$Implementation$update);
var $author$project$Internal$Msg$TabsMsg = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$Internal$Tabs$Model$defaultModel = {ad: false, ah: false, c0: $elm$core$Maybe$Nothing, ce: false, cA: $elm$core$Dict$empty, gm: 0, ab: 0};
var $author$project$Internal$Tabs$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.X;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{X: x});
		}),
	$author$project$Internal$Tabs$Model$defaultModel);
var $author$project$Internal$Tabs$Model$RippleMsg = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Internal$Tabs$Model$ScrollBack = function (a) {
	return {$: 4, a: a};
};
var $author$project$Internal$Tabs$Model$ScrollForward = function (a) {
	return {$: 3, a: a};
};
var $author$project$Internal$Tabs$Model$SetIndicatorShown = {$: 7};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$Internal$Tabs$Implementation$update = F3(
	function (lift, msg, model) {
		update:
		while (true) {
			var isRtl = false;
			var scrollToTabAtIndex = F2(
				function (geometry, i) {
					var tab_ = A2(
						$elm$core$Maybe$withDefault,
						{al: 0, g: 0},
						$elm$core$List$head(
							A2($elm$core$List$drop, i, geometry.X)));
					var tabBarWidth = geometry.bA.g;
					var scrollTargetOffsetWidth = tab_.g;
					var scrollTargetOffsetLeft = tab_.al;
					var normalizeForRtl = F2(
						function (left, width) {
							return isRtl ? (tabBarWidth - (left + width)) : left;
						});
					return A2(normalizeForRtl, scrollTargetOffsetLeft, scrollTargetOffsetWidth);
				});
			var indicatorEnabledStates = F2(
				function (geometry, translateOffset) {
					var tabBarWidth = geometry.bA.g;
					var scrollFrameWidth = geometry.bw.g;
					var remainingTabBarWidth = tabBarWidth - translateOffset;
					var forwardIndicator = _Utils_cmp(remainingTabBarWidth, scrollFrameWidth) > 0;
					var backIndicator = !(!translateOffset);
					return {ad: backIndicator, ah: forwardIndicator};
				});
			switch (msg.$) {
				case 5:
					var index = msg.a;
					var msg_ = msg.b;
					var _v1 = A2(
						$author$project$Internal$Ripple$Implementation$update,
						msg_,
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Internal$Ripple$Model$defaultModel,
							A2($elm$core$Dict$get, index, model.cA)));
					var ripple = _v1.a;
					var effects = _v1.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									cA: A3($elm$core$Dict$insert, index, ripple, model.cA)
								})),
						A2(
							$elm$core$Platform$Cmd$map,
							A2(
								$elm$core$Basics$composeL,
								lift,
								$author$project$Internal$Tabs$Model$RippleMsg(index)),
							effects));
				case 1:
					var msgs = msg.a;
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						$author$project$Internal$Dispatch$forward(msgs));
				case 0:
					return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				case 2:
					var geometry = msg.a;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									c0: $elm$core$Maybe$Just(geometry)
								})),
						$elm$core$Platform$Cmd$none);
				case 4:
					var geometry = msg.a;
					var tabBarWidth = geometry.bA.g;
					var scrollFrameWidth = geometry.bw.g;
					var currentTranslateOffset = model.ab;
					var loop = F2(
						function (_v3, result) {
							var i = _v3.a;
							var tab_ = _v3.b;
							if (!_Utils_eq(result.V, $elm$core$Maybe$Nothing)) {
								return result;
							} else {
								var tabOffsetLeft = tab_.al;
								var tabBarWidthLessTabOffsetLeft = tabBarWidth - tabOffsetLeft;
								var tabIsNotOccluded = (!isRtl) ? (_Utils_cmp(tabOffsetLeft, currentTranslateOffset) > 0) : (_Utils_cmp(tabBarWidthLessTabOffsetLeft, currentTranslateOffset) > 0);
								if (tabIsNotOccluded) {
									return result;
								} else {
									var newTabWidthAccumulator = result.dg + tab_.g;
									var scrollTargetDetermined = _Utils_cmp(newTabWidthAccumulator, scrollFrameWidth) > 0;
									var newScrollTargetIndex = scrollTargetDetermined ? $elm$core$Maybe$Just(
										isRtl ? (i + 1) : i) : $elm$core$Maybe$Nothing;
									return {V: newScrollTargetIndex, dg: newTabWidthAccumulator};
								}
							}
						});
					var scrollTargetIndex = A3(
						$elm$core$Basics$composeL,
						$elm$core$Maybe$withDefault(0),
						function ($) {
							return $.V;
						},
						A3(
							$elm$core$List$foldl,
							loop,
							{V: $elm$core$Maybe$Nothing, dg: 0},
							$elm$core$List$reverse(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (index, tab_) {
											return _Utils_Tuple2(index, tab_);
										}),
									geometry.X))));
					var translateOffset = A2(scrollToTabAtIndex, geometry, scrollTargetIndex);
					var _v2 = A2(indicatorEnabledStates, geometry, translateOffset);
					var forwardIndicator = _v2.ah;
					var backIndicator = _v2.ad;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									ad: backIndicator,
									ah: forwardIndicator,
									c0: $elm$core$Maybe$Just(geometry),
									ab: translateOffset
								})),
						$elm$core$Platform$Cmd$none);
				case 3:
					var geometry = msg.a;
					var currentTranslationOffset = model.ab;
					var scrollFrameWidth = geometry.bw.g + currentTranslationOffset;
					var loop = F2(
						function (_v5, result) {
							var i = _v5.a;
							var tab_ = _v5.b;
							if (!_Utils_eq(result.V, $elm$core$Maybe$Nothing)) {
								return result;
							} else {
								var tabOffsetLeftAndWidth = tab_.al + tab_.g;
								var scrollTargetDetermined = function () {
									if (!isRtl) {
										return _Utils_cmp(tabOffsetLeftAndWidth, scrollFrameWidth) > 0;
									} else {
										var tabRightOffset = tab_.g - tabOffsetLeftAndWidth;
										var frameOffsetAndTabWidth = scrollFrameWidth - tab_.g;
										return _Utils_cmp(tabRightOffset, frameOffsetAndTabWidth) > 0;
									}
								}();
								return scrollTargetDetermined ? {
									V: $elm$core$Maybe$Just(i)
								} : {V: $elm$core$Maybe$Nothing};
							}
						});
					var scrollTargetIndex = A3(
						$elm$core$Basics$composeL,
						$elm$core$Maybe$withDefault(0),
						function ($) {
							return $.V;
						},
						A3(
							$elm$core$List$foldl,
							loop,
							{V: $elm$core$Maybe$Nothing},
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (index, tab_) {
										return _Utils_Tuple2(index, tab_);
									}),
								geometry.X)));
					var translateOffset = A2(scrollToTabAtIndex, geometry, scrollTargetIndex);
					var _v4 = A2(indicatorEnabledStates, geometry, translateOffset);
					var forwardIndicator = _v4.ah;
					var backIndicator = _v4.ad;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{
									ad: backIndicator,
									ah: forwardIndicator,
									c0: $elm$core$Maybe$Just(geometry),
									ab: translateOffset
								})),
						$elm$core$Platform$Cmd$none);
				case 8:
					var i = msg.a;
					var geometry = msg.b;
					var tab_ = A2(
						$elm$core$Maybe$withDefault,
						{al: 0, g: 0},
						$elm$core$List$head(
							A2($elm$core$List$drop, i, geometry.X)));
					var tabBarWidth = geometry.bA.g;
					var scrollFrameWidth = geometry.bw.g;
					var resetAmt = isRtl ? model.gm : 0;
					var leftEdge = tab_.al;
					var normalizedLeftOffset = tabBarWidth - leftEdge;
					var rightEdge = leftEdge + tab_.g;
					var currentTranslateOffset = model.ab;
					var shouldScrollBack = (!isRtl) ? (_Utils_cmp(rightEdge, currentTranslateOffset) < 1) : (_Utils_cmp(leftEdge, tabBarWidth - currentTranslateOffset) > -1);
					var shouldScrollForward = (!isRtl) ? (_Utils_cmp(rightEdge, currentTranslateOffset + scrollFrameWidth) > 0) : (_Utils_cmp(normalizedLeftOffset, scrollFrameWidth + currentTranslateOffset) > 0);
					if (shouldScrollForward) {
						var $temp$lift = lift,
							$temp$msg = $author$project$Internal$Tabs$Model$ScrollForward(geometry),
							$temp$model = model;
						lift = $temp$lift;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						if (shouldScrollBack) {
							var $temp$lift = lift,
								$temp$msg = $author$project$Internal$Tabs$Model$ScrollBack(geometry),
								$temp$model = model;
							lift = $temp$lift;
							msg = $temp$msg;
							model = $temp$model;
							continue update;
						} else {
							return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
						}
					}
				case 6:
					var geometry = msg.a;
					return _Utils_Tuple2(
						function () {
							var tabBarWidth = geometry.bA.g;
							var scrollFrameWidth = geometry.bw.g;
							var isOverflowing = _Utils_cmp(tabBarWidth, scrollFrameWidth) > 0;
							var translateOffset = (!isOverflowing) ? 0 : model.ab;
							var _v6 = A2(indicatorEnabledStates, geometry, translateOffset);
							var forwardIndicator = _v6.ah;
							var backIndicator = _v6.ad;
							return $elm$core$Maybe$Just(
								_Utils_update(
									model,
									{
										ad: backIndicator,
										ah: forwardIndicator,
										c0: $elm$core$Maybe$Just(geometry),
										ce: false || model.ce,
										ab: translateOffset
									}));
						}(),
						A2(
							$author$project$Internal$Helpers$delayedCmd,
							0,
							lift($author$project$Internal$Tabs$Model$SetIndicatorShown)));
				default:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(
							_Utils_update(
								model,
								{ce: true})),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Internal$Tabs$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Tabs$Implementation$getSet.ey, $author$project$Internal$Tabs$Implementation$getSet.id, $author$project$Internal$Msg$TabsMsg, $author$project$Internal$Tabs$Implementation$update);
var $author$project$Internal$Msg$TextfieldMsg = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Internal$Textfield$Model$defaultModel = {c$: false, c0: $elm$core$Maybe$Nothing, eP: false, bG: $elm$core$Maybe$Nothing};
var $author$project$Internal$Textfield$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.gR;
	},
	F2(
		function (x, c) {
			return _Utils_update(
				c,
				{gR: x});
		}),
	$author$project$Internal$Textfield$Model$defaultModel);
var $author$project$Internal$Textfield$Implementation$update = F3(
	function (lift, msg, model) {
		switch (msg.$) {
			case 2:
				var str = msg.a;
				var dirty = str !== '';
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{
								eP: dirty,
								bG: $elm$core$Maybe$Just(str)
							})),
					$elm$core$Platform$Cmd$none);
			case 0:
				var geometry = function () {
					var _v1 = model.bG;
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						return model.c0;
					}
				}();
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{c$: false, c0: geometry})),
					$elm$core$Platform$Cmd$none);
			case 1:
				var geometry = msg.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						_Utils_update(
							model,
							{
								c$: true,
								c0: $elm$core$Maybe$Just(geometry)
							})),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(model),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Textfield$Implementation$react = A4($author$project$Internal$Component$react, $author$project$Internal$Textfield$Implementation$getSet.ey, $author$project$Internal$Textfield$Implementation$getSet.id, $author$project$Internal$Msg$TextfieldMsg, $author$project$Internal$Textfield$Implementation$update);
var $author$project$Internal$Msg$ToolbarMsg = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$Internal$Toolbar$Model$defaultModel = {bc: $elm$core$Maybe$Nothing, dY: $elm$core$Maybe$Nothing, c0: $elm$core$Maybe$Nothing, gp: 0};
var $author$project$Internal$Toolbar$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.dk;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{dk: x});
		}),
	$author$project$Internal$Toolbar$Model$defaultModel);
var $author$project$Internal$Toolbar$Model$defaultCalculations = {b6: 0, ep: 0, c5: 0, fs: 0, gn: 0, go: 0, gY: 0, g_: 0, cG: 0};
var $author$project$Internal$Toolbar$Implementation$initKeyRatio = F2(
	function (config, geometry) {
		var toolbarRowHeight = geometry.c1;
		var toolbarRatio = (!toolbarRowHeight) ? 0 : (geometry.eA / toolbarRowHeight);
		var firstRowMaxRatio = (!toolbarRowHeight) ? 0 : (geometry.ez / toolbarRowHeight);
		var flexibleExpansionRatio_ = firstRowMaxRatio - 1;
		var maxTranslateYRatio = config.bi ? (toolbarRatio - firstRowMaxRatio) : 0;
		var scrollThresholdRatio = config.bi ? (toolbarRatio - 1) : (firstRowMaxRatio - 1);
		return _Utils_update(
			$author$project$Internal$Toolbar$Model$defaultCalculations,
			{ep: flexibleExpansionRatio_, fs: maxTranslateYRatio, go: scrollThresholdRatio, g_: toolbarRatio});
	});
var $author$project$Internal$Toolbar$Implementation$setKeyHeights = F2(
	function (geometry, calculations) {
		var toolbarRowHeight = geometry.c1;
		var toolbarHeight = calculations.g_ * toolbarRowHeight;
		var scrollThreshold = calculations.go * toolbarRowHeight;
		var maxTranslateYDistance = calculations.fs * toolbarRowHeight;
		var flexibleExpansionHeight = calculations.ep * toolbarRowHeight;
		return _Utils_update(
			calculations,
			{b6: flexibleExpansionHeight, c5: maxTranslateYDistance, gn: scrollThreshold, gY: toolbarHeight, cG: toolbarRowHeight});
	});
var $author$project$Internal$Toolbar$Implementation$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var config = msg.a;
				var geometry = msg.b;
				var calculations = A2(
					$author$project$Internal$Toolbar$Implementation$setKeyHeights,
					geometry,
					A2($author$project$Internal$Toolbar$Implementation$initKeyRatio, config, geometry));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bc: $elm$core$Maybe$Just(calculations),
							dY: $elm$core$Maybe$Just(config),
							c0: $elm$core$Maybe$Just(geometry)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var config = msg.a;
				var geometry = msg.b;
				var calculations = A2(
					$elm$core$Maybe$map,
					$author$project$Internal$Toolbar$Implementation$setKeyHeights(geometry),
					model.bc);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bc: calculations,
							dY: $elm$core$Maybe$Just(config),
							c0: $elm$core$Maybe$Just(geometry)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var config = msg.a;
				var scrollTop = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dY: $elm$core$Maybe$Just(config),
							gp: scrollTop
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$Toolbar$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$Toolbar$Implementation$getSet.ey,
	$author$project$Internal$Toolbar$Implementation$getSet.id,
	$author$project$Internal$Msg$ToolbarMsg,
	$author$project$Internal$Component$generalise($author$project$Internal$Toolbar$Implementation$update));
var $author$project$Internal$Msg$TopAppBarMsg = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Internal$TopAppBar$Model$defaultModel = {P: 0, eQ: true, bp: $elm$core$Maybe$Nothing, gF: $elm$core$Maybe$Nothing, E: $elm$core$Maybe$Nothing, cJ: true};
var $author$project$Internal$TopAppBar$Implementation$getSet = A3(
	$author$project$Internal$Component$indexed,
	function ($) {
		return $.g1;
	},
	F2(
		function (x, y) {
			return _Utils_update(
				y,
				{g1: x});
		}),
	$author$project$Internal$TopAppBar$Model$defaultModel);
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Internal$TopAppBar$Implementation$checkForUpdate = function (model) {
	return A2(
		$elm$core$Maybe$map,
		function (topAppBarHeight) {
			var offscreenBoundaryTop = -topAppBarHeight;
			var hasAnyPixelsOnscreen = _Utils_cmp(model.P, offscreenBoundaryTop) > 0;
			var hasAnyPixelsOffscreen = model.P < 0;
			var partiallyShowing = hasAnyPixelsOffscreen && hasAnyPixelsOnscreen;
			return partiallyShowing ? _Utils_Tuple2(
				_Utils_update(
					model,
					{cJ: false}),
				true) : ((!model.cJ) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{cJ: true}),
				true) : ((!_Utils_eq(model.eQ, hasAnyPixelsOnscreen)) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{eQ: hasAnyPixelsOnscreen}),
				true) : _Utils_Tuple2(model, false)));
		},
		model.E);
};
var $author$project$Internal$TopAppBar$Implementation$moveTopAppBar = function (model) {
	return A2(
		$elm$core$Maybe$andThen,
		function (_v0) {
			var updatedModel = _v0.a;
			var partiallyShowing = _v0.b;
			return partiallyShowing ? A2(
				$elm$core$Maybe$map,
				function (topAppBarHeight) {
					var styleTop = function () {
						var maxTopAppBarHeight = 128;
						return (_Utils_cmp(
							$elm$core$Basics$abs(updatedModel.P),
							topAppBarHeight) > 0) ? (-maxTopAppBarHeight) : updatedModel.P;
					}();
					return _Utils_update(
						updatedModel,
						{
							gF: $elm$core$Maybe$Just(styleTop)
						});
				},
				updatedModel.E) : $elm$core$Maybe$Just(updatedModel);
		},
		$author$project$Internal$TopAppBar$Implementation$checkForUpdate(model));
};
var $author$project$Internal$TopAppBar$Implementation$topAppBarScrollHandler = F2(
	function (scrollPosition, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Internal$TopAppBar$Implementation$moveTopAppBar,
				A2(
					$elm$core$Maybe$map,
					function (_v0) {
						var topAppBarHeight = _v0.a;
						var lastScrollPosition = _v0.b;
						var isCurrentlyBeingResized = false;
						var currentScrollPosition = A2($elm$core$Basics$max, scrollPosition, 0);
						var diff = currentScrollPosition - lastScrollPosition;
						var currentAppBarOffsetTop = model.P - diff;
						var updatedAppBarOffsetTop = (!isCurrentlyBeingResized) ? ((currentAppBarOffsetTop > 0) ? 0 : ((_Utils_cmp(
							$elm$core$Basics$abs(currentAppBarOffsetTop),
							topAppBarHeight) > 0) ? (-topAppBarHeight) : currentAppBarOffsetTop)) : model.P;
						var updatedModel = _Utils_update(
							model,
							{
								P: updatedAppBarOffsetTop,
								bp: $elm$core$Maybe$Just(currentScrollPosition)
							});
						return A2(
							$elm$core$Maybe$withDefault,
							updatedModel,
							$author$project$Internal$TopAppBar$Implementation$moveTopAppBar(updatedModel));
					},
					A3(
						$elm$core$Maybe$map2,
						F2(
							function (topAppBarHeight, lastScrollPosition) {
								return _Utils_Tuple2(topAppBarHeight, lastScrollPosition);
							}),
						model.E,
						model.bp))));
	});
var $author$project$Internal$TopAppBar$Implementation$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var scrollPosition = msg.a.a1;
				var topAppBarHeight = msg.a.E;
				return _Utils_Tuple2(
					A2(
						$author$project$Internal$TopAppBar$Implementation$topAppBarScrollHandler,
						scrollPosition,
						_Utils_update(
							model,
							{
								bp: $elm$core$Maybe$Just(scrollPosition),
								E: $elm$core$Maybe$Just(topAppBarHeight)
							})),
					$elm$core$Platform$Cmd$none);
			case 2:
				var scrollPosition = msg.a.a1;
				return _Utils_Tuple2(
					A2($author$project$Internal$TopAppBar$Implementation$topAppBarScrollHandler, scrollPosition, model),
					$elm$core$Platform$Cmd$none);
			default:
				var scrollPosition = msg.a.a1;
				var topAppBarHeight = msg.a.E;
				var currentHeight = topAppBarHeight;
				var currentAppBarOffsetTop = model.P - (topAppBarHeight - currentHeight);
				var updatedModel = (!_Utils_eq(
					$elm$core$Maybe$Just(topAppBarHeight),
					model.E)) ? _Utils_update(
					model,
					{
						P: currentAppBarOffsetTop,
						E: $elm$core$Maybe$Just(currentHeight),
						cJ: false
					}) : model;
				return _Utils_Tuple2(
					A2($author$project$Internal$TopAppBar$Implementation$topAppBarScrollHandler, scrollPosition, updatedModel),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Internal$TopAppBar$Implementation$react = A4(
	$author$project$Internal$Component$react,
	$author$project$Internal$TopAppBar$Implementation$getSet.ey,
	$author$project$Internal$TopAppBar$Implementation$getSet.id,
	$author$project$Internal$Msg$TopAppBarMsg,
	$author$project$Internal$Component$generalise($author$project$Internal$TopAppBar$Implementation$update));
var $author$project$Material$update_ = F3(
	function (lift, msg, store) {
		switch (msg.$) {
			case 0:
				var msgs = msg.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$author$project$Internal$Dispatch$forward(msgs));
			case 1:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Button$Implementation$react, lift, msg_, idx, store);
			case 2:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Checkbox$Implementation$react, lift, msg_, idx, store);
			case 3:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Chip$Implementation$react, lift, msg_, idx, store);
			case 4:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Dialog$Implementation$react, lift, msg_, idx, store);
			case 5:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Drawer$Implementation$react, lift, msg_, idx, store);
			case 6:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Fab$Implementation$react, lift, msg_, idx, store);
			case 7:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$GridList$Implementation$react, lift, msg_, idx, store);
			case 8:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$IconToggle$Implementation$react, lift, msg_, idx, store);
			case 9:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Menu$Implementation$react, lift, msg_, idx, store);
			case 10:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$RadioButton$Implementation$react, lift, msg_, idx, store);
			case 11:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Ripple$Implementation$react, lift, msg_, idx, store);
			case 12:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Select$Implementation$react, lift, msg_, idx, store);
			case 13:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Slider$Implementation$react, lift, msg_, idx, store);
			case 14:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Snackbar$Implementation$react, lift, msg_, idx, store);
			case 15:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Switch$Implementation$react, lift, msg_, idx, store);
			case 16:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Tabs$Implementation$react, lift, msg_, idx, store);
			case 17:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Textfield$Implementation$react, lift, msg_, idx, store);
			case 18:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$Toolbar$Implementation$react, lift, msg_, idx, store);
			default:
				var idx = msg.a;
				var msg_ = msg.b;
				return A4($author$project$Internal$TopAppBar$Implementation$react, lift, msg_, idx, store);
		}
	});
var $author$project$Material$update = F3(
	function (lift, msg, container) {
		return A2(
			$elm$core$Tuple$mapFirst,
			$elm$core$Maybe$withDefault(container),
			A2(
				$elm$core$Tuple$mapFirst,
				$elm$core$Maybe$map(
					function (mdc) {
						return _Utils_update(
							container,
							{c6: mdc});
					}),
				A3(
					$author$project$Material$update_,
					lift,
					msg,
					function ($) {
						return $.c6;
					}(container))));
	});
var $author$project$Session$Confirmed = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Navigation$back = F2(
	function (key, n) {
		return A2(_Browser_go, key, -n);
	});
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Session$leave = function (model) {
	var _v0 = model.hX;
	switch (_v0.$) {
		case 8:
			var url = _v0.a;
			return A2($elm$browser$Browser$Navigation$pushUrl, model.h$, url);
		case 7:
			var url = _v0.a;
			return A2($elm$browser$Browser$Navigation$replaceUrl, model.h$, url);
		case 9:
			var num = _v0.a;
			return A2($elm$browser$Browser$Navigation$back, model.h$, num);
		default:
			return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Session$update_ = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Session$Mdc, msg_, model);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: function () {
								var _v1 = model.y;
								if (_v1.b) {
									var tail = _v1.b;
									return tail;
								} else {
									return _List_Nil;
								}
							}()
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var proceed = msg.a;
				if (!proceed) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hX: $author$project$Session$NoOp}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$Session$leave(model));
				}
			case 10:
				var key = msg.a;
				var maybeID = msg.b;
				var bool = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: $elm$core$Maybe$Nothing}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(
							A3($author$project$Session$Confirmed, key, maybeID, bool))));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Session$update = F3(
	function (lift, msg, container) {
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (model) {
					return _Utils_update(
						container,
						{W: model});
				},
				A2($author$project$Session$update_, msg, container.W)));
	});
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Home$Mdc, msg_, model);
			case 2:
				var msg_ = msg.a;
				if ((msg_.$ === 3) && (msg_.a === 'power_settings_new')) {
					return A2($author$project$Session$token, '', model);
				} else {
					return A3($author$project$Session$update, $author$project$Page$Home$Session, msg_, model);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Kunstwerk$DeleteDag = F2(
	function (a, b) {
		return {$: 23, a: a, b: b};
	});
var $author$project$Page$Kunstwerk$DeleteKunstwerk = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Kunstwerk$DeletePeriode = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$Page$Kunstwerk$Pdf = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Kunstwerk$PostDag = function (a) {
	return {$: 20, a: a};
};
var $author$project$Page$Kunstwerk$PostPeriode = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Kunstwerk$PutDag = function (a) {
	return {$: 21, a: a};
};
var $author$project$Session$Back = function (a) {
	return {$: 9, a: a};
};
var $author$project$Session$maybeLeave = F2(
	function (container, msg) {
		var session = container.W;
		var session_ = _Utils_update(
			session,
			{hX: msg});
		var _v0 = session.d8;
		if (!_v0) {
			return _Utils_Tuple2(
				container,
				$author$project$Session$leave(session_));
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					container,
					{W: session_}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Session$back = F2(
	function (num, container) {
		return A2(
			$author$project$Session$maybeLeave,
			container,
			$author$project$Session$Back(num));
	});
var $author$project$Session$confirm = F4(
	function (key, maybeID, prompt, container) {
		var session = container.W;
		return _Utils_update(
			container,
			{
				W: _Utils_update(
					session,
					{
						aP: $elm$core$Maybe$Just(
							_Utils_Tuple3(key, maybeID, prompt))
					})
			});
	});
var $author$project$Api$Dagen = function (a) {
	return {$: 13, a: a};
};
var $lukewestby$elm_http_builder$HttpBuilder$delete = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('DELETE');
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$Api$delete = F3(
	function (msg, session, api) {
		return A2(
			$author$project$Api$send,
			session,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$elm$http$Http$expectWhatever(msg),
				$lukewestby$elm_http_builder$HttpBuilder$delete(
					A2($author$project$Api$url, session, api))));
	});
var $author$project$Api$deleteDag = F3(
	function (msg, dagID, session) {
		return A3(
			$author$project$Api$delete,
			msg(dagID),
			session,
			$author$project$Api$Dagen(
				$elm$core$Maybe$Just(dagID)));
	});
var $author$project$Api$deleteKunstwerk = F3(
	function (msg, link, session) {
		return A3(
			$author$project$Api$delete,
			msg,
			session,
			$author$project$Api$Base(link));
	});
var $author$project$Api$Perioden = function (a) {
	return {$: 12, a: a};
};
var $author$project$Api$deletePeriode = F3(
	function (msg, periodeID, session) {
		return A3(
			$author$project$Api$delete,
			msg(periodeID),
			session,
			$author$project$Api$Perioden(
				$elm$core$Maybe$Just(periodeID)));
	});
var $author$project$Session$dirty = F2(
	function (value, container) {
		var session = container.W;
		return _Utils_update(
			container,
			{
				W: _Utils_update(
					session,
					{d8: value})
			});
	});
var $author$project$Page$Kunstwerk$dirtyPeriodenDagen = function (model) {
	var _v0 = model.k;
	if (_v0.$ === 1) {
		return _Utils_Tuple3(_List_Nil, _List_Nil, false);
	} else {
		var perioden = _v0.a.fP;
		return _Utils_Tuple3(
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var dirty = _v1.d8;
					return dirty;
				},
				perioden),
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (_v2) {
						var dagen = _v2.b0;
						return A2(
							$elm$core$List$filter,
							function (_v3) {
								var dirty = _v3.d8;
								return dirty;
							},
							dagen);
					},
					perioden)),
			model.W.d8);
	}
};
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $author$project$Api$download = F3(
	function (filename, mimetype, bytes) {
		return A3($elm$file$File$Download$bytes, filename, mimetype, bytes);
	});
var $author$project$HttpErrorMessage$httpErrorMessage = F2(
	function (err, base) {
		switch (err.$) {
			case 0:
				var url = err.a;
				return 'Ongeldige url bij ' + (base + (' ' + url));
			case 1:
				return 'Timeout bij ' + base;
			case 2:
				return 'Netwerkfout bij ' + base;
			case 3:
				var code = err.a;
				return 'Foutcode ' + ($elm$core$String$fromInt(code) + (' bij ' + base));
			default:
				var message = err.a;
				return 'Datafout bij ' + (base + (': ' + message));
		}
	});
var $author$project$Session$error = F3(
	function (err, base, container) {
		var session = container.W;
		return A2(
			$author$project$Session$progress,
			false,
			_Utils_update(
				container,
				{
					W: _Utils_update(
						session,
						{
							y: A2(
								$elm$core$List$cons,
								A2($author$project$HttpErrorMessage$httpErrorMessage, err, base),
								session.y)
						})
				}));
	});
var $author$project$Api$bytesValue = function (msg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		msg,
		function (response) {
			switch (response.$) {
				case 0:
					var url_ = response.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadUrl(url_));
				case 1:
					return $elm$core$Result$Err($elm$http$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($elm$http$Http$NetworkError);
				case 3:
					var metadata = response.a;
					var body = response.b;
					return $elm$core$Result$Err(
						$elm$http$Http$BadStatus(metadata.$8));
				default:
					var body = response.b;
					return $elm$core$Result$Ok(body);
			}
		});
};
var $author$project$Api$arbitraryFile = F3(
	function (msg, url_, session) {
		return A2(
			$author$project$Api$send,
			session,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$author$project$Api$bytesValue(msg),
				$lukewestby$elm_http_builder$HttpBuilder$get(url_)));
	});
var $author$project$Api$file = F3(
	function (msg, link, session) {
		return A3(
			$author$project$Api$arbitraryFile,
			msg,
			A2(
				$author$project$Api$url,
				session,
				$author$project$Api$Base(link)),
			session);
	});
var $author$project$Page$Kunstwerk$idFieldName = F4(
	function (separator, base, id, field) {
		return _Utils_ap(
			base,
			_Utils_ap(
				separator,
				_Utils_ap(
					$elm$core$String$fromInt(id),
					_Utils_ap(separator, field))));
	});
var $author$project$Page$Kunstwerk$periodeFieldName = F2(
	function (id, field) {
		return A4($author$project$Page$Kunstwerk$idFieldName, '.', 'periode', id, field);
	});
var $author$project$Page$Kunstwerk$dagFieldName = F3(
	function (periodeID, id, field) {
		return A2(
			$author$project$Page$Kunstwerk$periodeFieldName,
			periodeID,
			A4($author$project$Page$Kunstwerk$idFieldName, '|', 'dag', id, field));
	});
var $author$project$Page$Kunstwerk$dagFields = function (_v0) {
	var periodeID = _v0.fO;
	var id = _v0.cc;
	return _List_fromArray(
		[
			A3(
			$author$project$Page$simpleTextfield,
			true,
			'Dagen',
			A3($author$project$Page$Kunstwerk$dagFieldName, periodeID, id, 'dagen')),
			A3(
			$author$project$Page$simpleTextfield,
			true,
			'Tijden',
			A3($author$project$Page$Kunstwerk$dagFieldName, periodeID, id, 'tijden')),
			A3(
			$author$project$Page$simpleTextfield,
			false,
			'Opmerkingen dagen',
			A3($author$project$Page$Kunstwerk$dagFieldName, periodeID, id, 'opmerkingen'))
		]);
};
var $author$project$Page$Kunstwerk$periodeFields = function (_v0) {
	var id = _v0.cc;
	var dagen = _v0.b0;
	return _Utils_ap(
		_List_fromArray(
			[
				A3(
				$author$project$Page$simpleTextfield,
				true,
				'Periode',
				A2($author$project$Page$Kunstwerk$periodeFieldName, id, 'periode')),
				A3(
				$author$project$Page$simpleTextfield,
				false,
				'Opmerkingen periode',
				A2($author$project$Page$Kunstwerk$periodeFieldName, id, 'opmerkingen'))
			]),
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (dag) {
					return $author$project$Page$Kunstwerk$dagFields(dag);
				},
				dagen)));
};
var $author$project$Page$Kunstwerk$fieldModel = function (kunstwerk) {
	var _default = _List_fromArray(
		[
			A3($author$project$Page$simpleTextfield, true, 'Naam', 'naam'),
			A3($author$project$Page$simpleTextfield, true, 'ID-nummer', 'anwbBrugNummer'),
			A3($author$project$Page$simpleTextfield, true, 'Vaarwater/plaatsnaam Almanak', 'referentie'),
			A3($author$project$Page$simpleTextfield, false, 'Adres', 'adres'),
			A3($author$project$Page$simpleTextfield, false, 'Postcode', 'postcode'),
			A3($author$project$Page$simpleTextfield, false, 'Woonplaats', 'woonplaats'),
			A3($author$project$Page$simpleTextfield, false, 'Netnr', 'telefoon.net'),
			A3($author$project$Page$simpleTextfield, false, 'Tel netnr', 'telefoon.net'),
			A3($author$project$Page$simpleTextfield, false, 'Tel abonneenr', 'telefoon.abonnee'),
			A3($author$project$Page$simpleIntfield, false, 'Marifoonkanaal', 'marifoonkanaal'),
			A3($author$project$Page$simpleTextfield, false, 'Emailadres', 'email'),
			A3($author$project$Page$simpleTextfield, false, 'Website', 'web'),
			A5($author$project$Page$simpleFloatfield, false, 2, true, 'Max breedte (m)', 'maxBreedte'),
			A5($author$project$Page$simpleFloatfield, false, 2, true, 'Max hoogte (m)', 'maxHoogte'),
			A3($author$project$Page$simpleTextfield, false, 'Toelichting', 'toelichting'),
			A3($author$project$Page$simpleIntfield, false, 'Aantal openingen', 'brug.openingen'),
			A3($author$project$Page$simpleIntfield, false, 'Aantal ingangen', 'sluis.ingangen'),
			A5($author$project$Page$simpleFloatfield, false, 2, true, 'Max diepte (m)', 'sluis.maxDiepte'),
			A5($author$project$Page$simpleFloatfield, false, 2, true, 'Max lengte (m)', 'sluis.maxLengte'),
			A3($author$project$Page$simpleTextfield, false, 'Opmerkingen voor beheer', 'opmerkingen')
		]);
	return $author$project$Field$defaultModel(
		A2(
			$elm$core$List$append,
			_default,
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (periode) {
						return $author$project$Page$Kunstwerk$periodeFields(periode);
					},
					kunstwerk.fP))));
};
var $author$project$Page$Kunstwerk$updateFieldModel = function (model) {
	var _v0 = model.k;
	if (_v0.$ === 1) {
		return model;
	} else {
		var kunstwerk = _v0.a;
		return _Utils_update(
			model,
			{
				cY: $author$project$Page$Kunstwerk$fieldModel(kunstwerk)
			});
	}
};
var $author$project$Page$Kunstwerk$initKunstwerk = F2(
	function (kunstwerk, model) {
		return A2(
			$author$project$Session$progress,
			false,
			A2(
				$author$project$Session$dirty,
				false,
				$author$project$Page$Kunstwerk$updateFieldModel(
					_Utils_update(
						model,
						{
							k: $elm$core$Maybe$Just(kunstwerk)
						}))));
	});
var $author$project$Page$Kunstwerk$kw = F2(
	function (updateKunstwerk, model) {
		var _v0 = model.k;
		if (_v0.$ === 1) {
			return model;
		} else {
			var kunstwerk = _v0.a;
			return _Utils_update(
				model,
				{
					k: $elm$core$Maybe$Just(
						updateKunstwerk(kunstwerk))
				});
		}
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Kunstwerk$pio = F2(
	function (mapPeriode, model) {
		return A2(
			$author$project$Page$Kunstwerk$kw,
			function (kunstwerk) {
				return _Utils_update(
					kunstwerk,
					{
						fP: A2($elm$core$List$map, mapPeriode, kunstwerk.fP)
					});
			},
			model);
	});
var $author$project$Api$postDag = F3(
	function (msg, periodeID, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$Dagen(
				$elm$core$Maybe$Just(periodeID)),
			$author$project$Data$Kunstwerk$decodeDag,
			$elm$json$Json$Encode$null);
	});
var $author$project$Api$postPeriode = F3(
	function (msg, kunstwerkID, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$Perioden(
				$elm$core$Maybe$Just(kunstwerkID)),
			$author$project$Data$Kunstwerk$decodePeriode,
			$elm$json$Json$Encode$null);
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Data$Kunstwerk$encodeDag = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'dagen',
				$elm$json$Json$Encode$string(record.b0)),
				_Utils_Tuple2(
				'kunstwerkID',
				$elm$json$Json$Encode$int(record.ci)),
				_Utils_Tuple2(
				'periodeID',
				$elm$json$Json$Encode$int(record.fO)),
				_Utils_Tuple2(
				'opmerkingen',
				$elm$json$Json$Encode$string(record.fG)),
				_Utils_Tuple2(
				'volgorde',
				$elm$json$Json$Encode$int(record.hd)),
				_Utils_Tuple2(
				'tijden',
				$elm$json$Json$Encode$string(record.gS)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn))
			]));
};
var $lukewestby$elm_http_builder$HttpBuilder$put = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('PUT');
var $author$project$Api$put = F5(
	function (msg, session, api, decoder, body) {
		return A2(
			$author$project$Api$send,
			session,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($elm$http$Http$expectJson, msg, decoder),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
					body,
					$lukewestby$elm_http_builder$HttpBuilder$put(
						A2($author$project$Api$url, session, api)))));
	});
var $author$project$Api$putDag = F3(
	function (msg, dag, session) {
		return A5(
			$author$project$Api$put,
			msg,
			session,
			$author$project$Api$Dagen($elm$core$Maybe$Nothing),
			$author$project$Data$Kunstwerk$decodeDag,
			$author$project$Data$Kunstwerk$encodeDag(dag));
	});
var $author$project$Page$Kunstwerk$PutKunstwerk = function (a) {
	return {$: 7, a: a};
};
var $author$project$Data$Kunstwerk$encodeKunstwerkBrug = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'beweegbaar',
				$elm$json$Json$Encode$bool(record.dF)),
				_Utils_Tuple2(
				'openingen',
				$elm$json$Json$Encode$int(record.fD)),
				_Utils_Tuple2(
				'soort',
				$elm$json$Json$Encode$int(record.cC))
			]));
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Data$Kunstwerk$encodeKunstwerkCoordinaat = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'lat',
				$elm$json$Json$Encode$float(record.fb)),
				_Utils_Tuple2(
				'lon',
				$elm$json$Json$Encode$float(record.fj))
			]));
};
var $author$project$Data$Kunstwerk$encodeKunstwerkSluis = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'ingangen',
				$elm$json$Json$Encode$int(record.eM)),
				_Utils_Tuple2(
				'maxDiepte',
				$elm$json$Json$Encode$float(record.fn)),
				_Utils_Tuple2(
				'maxLengte',
				$elm$json$Json$Encode$float(record.fq)),
				_Utils_Tuple2(
				'soort',
				$elm$json$Json$Encode$int(record.cC))
			]));
};
var $author$project$Data$Kunstwerk$encodeKunstwerkTelefoon = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'abonnee',
				$elm$json$Json$Encode$string(record.bL)),
				_Utils_Tuple2(
				'net',
				$elm$json$Json$Encode$string(record.cq))
			]));
};
var $author$project$Data$Kunstwerk$encodeKunstwerkVin = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(record.dW)),
				_Utils_Tuple2(
				'x',
				$elm$json$Json$Encode$string(record.hp)),
				_Utils_Tuple2(
				'y',
				$elm$json$Json$Encode$string(record.hq))
			]));
};
var $author$project$Data$Kunstwerk$encodeMaybe = F2(
	function (encoder, maybeValue) {
		if (maybeValue.$ === 1) {
			return $elm$json$Json$Encode$null;
		} else {
			var value = maybeValue.a;
			return encoder(value);
		}
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Data$Kunstwerk$encodePeriode = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'kunstwerkID',
				$elm$json$Json$Encode$int(record.ci)),
				_Utils_Tuple2(
				'opmerkingen',
				$elm$json$Json$Encode$string(record.fG)),
				_Utils_Tuple2(
				'periode',
				$elm$json$Json$Encode$string(record.fN)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'dagen',
				A2($elm$json$Json$Encode$list, $author$project$Data$Kunstwerk$encodeDag, record.b0))
			]));
};
var $author$project$Data$Kunstwerk$encodeKunstwerk = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'adres',
				$elm$json$Json$Encode$string(record.dr)),
				_Utils_Tuple2(
				'afstandBediend',
				$elm$json$Json$Encode$bool(record.dt)),
				_Utils_Tuple2(
				'anwbBrugNummer',
				$elm$json$Json$Encode$string(record.bP)),
				_Utils_Tuple2(
				'beheerderID',
				A2($author$project$Data$Kunstwerk$encodeMaybe, $elm$json$Json$Encode$int, record.dB)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'kopieVan',
				$elm$json$Json$Encode$int(record.e_)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(record.ef)),
				_Utils_Tuple2(
				'kunstwerkType',
				$elm$json$Json$Encode$string(record.cj)),
				_Utils_Tuple2(
				'land',
				$elm$json$Json$Encode$string(record.e5)),
				_Utils_Tuple2(
				'marifoonkanaal',
				$elm$json$Json$Encode$int(record.fk)),
				_Utils_Tuple2(
				'maxHoogte',
				$elm$json$Json$Encode$float(record.fp)),
				_Utils_Tuple2(
				'maxBreedte',
				$elm$json$Json$Encode$float(record.fm)),
				_Utils_Tuple2(
				'mobiel',
				$elm$json$Json$Encode$string(record.fw)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'openingstijden',
				$elm$json$Json$Encode$string(record.fE)),
				_Utils_Tuple2(
				'openingstijdenType',
				$elm$json$Json$Encode$string(record.fF)),
				_Utils_Tuple2(
				'opmerkingen',
				$elm$json$Json$Encode$string(record.fG)),
				_Utils_Tuple2(
				'postcode',
				$elm$json$Json$Encode$string(record.fV)),
				_Utils_Tuple2(
				'provincie',
				$elm$json$Json$Encode$string(record.f1)),
				_Utils_Tuple2(
				'referentie',
				$elm$json$Json$Encode$string(record.f6)),
				_Utils_Tuple2(
				'toelichting',
				$elm$json$Json$Encode$string(record.gU)),
				_Utils_Tuple2(
				'verwijderd',
				$elm$json$Json$Encode$bool(record.ha)),
				_Utils_Tuple2(
				'web',
				$elm$json$Json$Encode$string(record.hm)),
				_Utils_Tuple2(
				'woonplaats',
				$elm$json$Json$Encode$string(record.cK)),
				_Utils_Tuple2(
				'telefoon',
				$author$project$Data$Kunstwerk$encodeKunstwerkTelefoon(record.gO)),
				_Utils_Tuple2(
				'coordinaat',
				$author$project$Data$Kunstwerk$encodeKunstwerkCoordinaat(record.bZ)),
				_Utils_Tuple2(
				'sluis',
				$author$project$Data$Kunstwerk$encodeKunstwerkSluis(record.gx)),
				_Utils_Tuple2(
				'brug',
				$author$project$Data$Kunstwerk$encodeKunstwerkBrug(record.dO)),
				_Utils_Tuple2(
				'vin',
				$author$project$Data$Kunstwerk$encodeKunstwerkVin(record.hc)),
				_Utils_Tuple2(
				'perioden',
				A2($elm$json$Json$Encode$list, $author$project$Data$Kunstwerk$encodePeriode, record.fP)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn))
			]));
};
var $author$project$Api$putKunstwerk = F3(
	function (msg, kunstwerk, session) {
		return A5(
			$author$project$Api$put,
			msg,
			session,
			$author$project$Api$Kunstwerken,
			$author$project$Data$Kunstwerk$decodeKunstwerk,
			$author$project$Data$Kunstwerk$encodeKunstwerk(kunstwerk));
	});
var $author$project$Page$Kunstwerk$putKunstwerk = function (model) {
	var _v0 = model.k;
	if (_v0.$ === 1) {
		return $elm$core$Platform$Cmd$none;
	} else {
		var kunstwerk = _v0.a;
		return A3($author$project$Api$putKunstwerk, $author$project$Page$Kunstwerk$PutKunstwerk, kunstwerk, model.W);
	}
};
var $author$project$Session$ReplaceUrl = function (a) {
	return {$: 7, a: a};
};
var $author$project$Session$replaceUrl = F2(
	function (url, container) {
		return A2(
			$author$project$Session$maybeLeave,
			container,
			$author$project$Session$ReplaceUrl(url));
	});
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $mgold$elm_geojson$GeoJson$encodeBbox = function (bbox) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (b) {
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'bbox',
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$float, b))
					]);
			},
			bbox));
};
var $mgold$elm_geojson$GeoJson$encodePosition = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var c = _v0.c;
	var coordinates = (!c) ? _List_fromArray(
		[a, b]) : _List_fromArray(
		[a, b, c]);
	return A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$float, coordinates);
};
var $mgold$elm_geojson$GeoJson$encodeGeometry = function (geom) {
	switch (geom.$) {
		case 0:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('Point')),
					_Utils_Tuple2(
					'coordinates',
					$mgold$elm_geojson$GeoJson$encodePosition(data))
				]);
		case 1:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('MultiPoint')),
					_Utils_Tuple2(
					'coordinates',
					A2($elm$json$Json$Encode$list, $mgold$elm_geojson$GeoJson$encodePosition, data))
				]);
		case 2:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('LineString')),
					_Utils_Tuple2(
					'coordinates',
					A2($elm$json$Json$Encode$list, $mgold$elm_geojson$GeoJson$encodePosition, data))
				]);
		case 3:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('MultiLineString')),
					_Utils_Tuple2(
					'coordinates',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$list($mgold$elm_geojson$GeoJson$encodePosition),
						data))
				]);
		case 4:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('Polygon')),
					_Utils_Tuple2(
					'coordinates',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$list($mgold$elm_geojson$GeoJson$encodePosition),
						data))
				]);
		case 5:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('MultiPolygon')),
					_Utils_Tuple2(
					'coordinates',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$list(
							$elm$json$Json$Encode$list($mgold$elm_geojson$GeoJson$encodePosition)),
						data))
				]);
		default:
			var data = geom.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('GeometryCollection')),
					_Utils_Tuple2(
					'geometries',
					A2(
						$elm$json$Json$Encode$list,
						A2($elm$core$Basics$composeR, $mgold$elm_geojson$GeoJson$encodeGeometry, $elm$json$Json$Encode$object),
						data))
				]);
	}
};
var $mgold$elm_geojson$GeoJson$encodeFeature = function (_v0) {
	var geometry = _v0.c0;
	var properties = _v0.h7;
	var id = _v0.cc;
	var encodedId = function () {
		if (id.$ === 1) {
			return _List_Nil;
		} else {
			var theId = id.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(theId))
				]);
		}
	}();
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('Feature')),
				_Utils_Tuple2(
				'geometry',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $mgold$elm_geojson$GeoJson$encodeGeometry, $elm$json$Json$Encode$object),
						geometry))),
				_Utils_Tuple2('properties', properties)
			]),
		encodedId);
};
var $mgold$elm_geojson$GeoJson$encodeGeoJson = function (geojson) {
	switch (geojson.$) {
		case 1:
			var feature = geojson.a;
			return $mgold$elm_geojson$GeoJson$encodeFeature(feature);
		case 2:
			var features = geojson.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('FeatureCollection')),
					_Utils_Tuple2(
					'features',
					A2(
						$elm$json$Json$Encode$list,
						A2($elm$core$Basics$composeR, $mgold$elm_geojson$GeoJson$encodeFeature, $elm$json$Json$Encode$object),
						features))
				]);
		default:
			var geometry = geojson.a;
			return $mgold$elm_geojson$GeoJson$encodeGeometry(geometry);
	}
};
var $mgold$elm_geojson$GeoJson$encode = function (_v0) {
	var geojson = _v0.a;
	var bbox = _v0.b;
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			$mgold$elm_geojson$GeoJson$encodeGeoJson(geojson),
			$mgold$elm_geojson$GeoJson$encodeBbox(bbox)));
};
var $author$project$Street$geoJsonValue = function (model) {
	var _v0 = model.I;
	if (_v0.$ === 1) {
		return $elm$json$Json$Encode$null;
	} else {
		var geoJson = _v0.a;
		return $mgold$elm_geojson$GeoJson$encode(geoJson);
	}
};
var $author$project$Street$mapPort = _Platform_outgoingPort('mapPort', $elm$core$Basics$identity);
var $author$project$Street$mapFly = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var _v1 = model.aT;
	if (!_v1) {
		return _Utils_Tuple2(model, cmd);
	} else {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aT: false, aX: true}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						$author$project$Street$mapPort(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Cmd',
									$elm$json$Json$Encode$string('Fly')),
									_Utils_Tuple2(
									'lon',
									$elm$json$Json$Encode$float(model.fj)),
									_Utils_Tuple2(
									'lat',
									$elm$json$Json$Encode$float(model.fb)),
									_Utils_Tuple2(
									'zoom',
									$elm$json$Json$Encode$float(model.as)),
									_Utils_Tuple2(
									'geoJson',
									$author$project$Street$geoJsonValue(model))
								])))
					])));
	}
};
var $author$project$Street$Reverse = function (a) {
	return {$: 6, a: a};
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{hw: $elm$http$Http$emptyBody, ei: r.ei, eF: _List_Nil, hZ: 'GET', dj: $elm$core$Maybe$Nothing, g2: $elm$core$Maybe$Nothing, g6: r.g6});
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$Street$PlaceModel = F4(
	function (lon, lat, displayName, geoJson) {
		return {b2: displayName, I: geoJson, fb: lat, fj: lon};
	});
var $mgold$elm_geojson$GeoJson$decodeBbox = $elm$json$Json$Decode$list($elm$json$Json$Decode$float);
var $mgold$elm_geojson$GeoJson$Feature = function (a) {
	return {$: 1, a: a};
};
var $mgold$elm_geojson$GeoJson$FeatureCollection = function (a) {
	return {$: 2, a: a};
};
var $mgold$elm_geojson$GeoJson$Geometry = function (a) {
	return {$: 0, a: a};
};
var $mgold$elm_geojson$GeoJson$FeatureObject = F3(
	function (geometry, properties, id) {
		return {c0: geometry, cc: id, h7: properties};
	});
var $mgold$elm_geojson$GeoJson$GeometryCollection = function (a) {
	return {$: 6, a: a};
};
var $mgold$elm_geojson$GeoJson$LineString = function (a) {
	return {$: 2, a: a};
};
var $mgold$elm_geojson$GeoJson$MultiLineString = function (a) {
	return {$: 3, a: a};
};
var $mgold$elm_geojson$GeoJson$MultiPoint = function (a) {
	return {$: 1, a: a};
};
var $mgold$elm_geojson$GeoJson$MultiPolygon = function (a) {
	return {$: 5, a: a};
};
var $mgold$elm_geojson$GeoJson$Point = function (a) {
	return {$: 0, a: a};
};
var $mgold$elm_geojson$GeoJson$Polygon = function (a) {
	return {$: 4, a: a};
};
var $mgold$elm_geojson$GeoJson$decodePosition = function () {
	var errorString = function (adj) {
		return 'Array has too ' + (adj + ' numbers to make a position');
	};
	var listToTuple = function (ps) {
		if (!ps.b) {
			return $elm$json$Json$Decode$fail(
				errorString('few'));
		} else {
			if (!ps.b.b) {
				return $elm$json$Json$Decode$fail(
					errorString('few'));
			} else {
				if (!ps.b.b.b) {
					var p1 = ps.a;
					var _v1 = ps.b;
					var p2 = _v1.a;
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple3(p1, p2, 0));
				} else {
					if (!ps.b.b.b.b) {
						var p1 = ps.a;
						var _v2 = ps.b;
						var p2 = _v2.a;
						var _v3 = _v2.b;
						var p3 = _v3.a;
						return $elm$json$Json$Decode$succeed(
							_Utils_Tuple3(p1, p2, p3));
					} else {
						return $elm$json$Json$Decode$fail(
							errorString('many'));
					}
				}
			}
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		listToTuple,
		$elm$json$Json$Decode$list($elm$json$Json$Decode$float));
}();
function $mgold$elm_geojson$GeoJson$cyclic$decodeGeometry() {
	var helper = function (tipe) {
		switch (tipe) {
			case 'Point':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$Point,
					A2($elm$json$Json$Decode$field, 'coordinates', $mgold$elm_geojson$GeoJson$decodePosition));
			case 'MultiPoint':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$MultiPoint,
					A2(
						$elm$json$Json$Decode$field,
						'coordinates',
						$elm$json$Json$Decode$list($mgold$elm_geojson$GeoJson$decodePosition)));
			case 'LineString':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$LineString,
					A2(
						$elm$json$Json$Decode$field,
						'coordinates',
						$elm$json$Json$Decode$list($mgold$elm_geojson$GeoJson$decodePosition)));
			case 'MultiLineString':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$MultiLineString,
					A2(
						$elm$json$Json$Decode$field,
						'coordinates',
						$elm$json$Json$Decode$list(
							$elm$json$Json$Decode$list($mgold$elm_geojson$GeoJson$decodePosition))));
			case 'Polygon':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$Polygon,
					A2(
						$elm$json$Json$Decode$field,
						'coordinates',
						$elm$json$Json$Decode$list(
							$elm$json$Json$Decode$list($mgold$elm_geojson$GeoJson$decodePosition))));
			case 'MultiPolygon':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$MultiPolygon,
					A2(
						$elm$json$Json$Decode$field,
						'coordinates',
						$elm$json$Json$Decode$list(
							$elm$json$Json$Decode$list(
								$elm$json$Json$Decode$list($mgold$elm_geojson$GeoJson$decodePosition)))));
			case 'GeometryCollection':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$GeometryCollection,
					A2(
						$elm$json$Json$Decode$field,
						'geometries',
						$elm$json$Json$Decode$list(
							$mgold$elm_geojson$GeoJson$cyclic$decodeGeometry())));
			default:
				return $elm$json$Json$Decode$fail('Unrecognized \'type\': ' + tipe);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		helper,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}
var $mgold$elm_geojson$GeoJson$decodeGeometry = $mgold$elm_geojson$GeoJson$cyclic$decodeGeometry();
$mgold$elm_geojson$GeoJson$cyclic$decodeGeometry = function () {
	return $mgold$elm_geojson$GeoJson$decodeGeometry;
};
var $mgold$elm_geojson$GeoJson$decodeFeature = A4(
	$elm$json$Json$Decode$map3,
	$mgold$elm_geojson$GeoJson$FeatureObject,
	A2(
		$elm$json$Json$Decode$field,
		'geometry',
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
					A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $mgold$elm_geojson$GeoJson$decodeGeometry)
				]))),
	A2($elm$json$Json$Decode$field, 'properties', $elm$json$Json$Decode$value),
	$elm$json$Json$Decode$maybe(
		A2(
			$elm$json$Json$Decode$field,
			'id',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$string,
						A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int)
					])))));
var $mgold$elm_geojson$GeoJson$decodeGeoJson = function () {
	var helper = function (h_tipe) {
		switch (h_tipe) {
			case 'Feature':
				return A2($elm$json$Json$Decode$map, $mgold$elm_geojson$GeoJson$Feature, $mgold$elm_geojson$GeoJson$decodeFeature);
			case 'FeatureCollection':
				return A2(
					$elm$json$Json$Decode$map,
					$mgold$elm_geojson$GeoJson$FeatureCollection,
					A2(
						$elm$json$Json$Decode$field,
						'features',
						$elm$json$Json$Decode$list($mgold$elm_geojson$GeoJson$decodeFeature)));
			default:
				return A2($elm$json$Json$Decode$map, $mgold$elm_geojson$GeoJson$Geometry, $mgold$elm_geojson$GeoJson$decodeGeometry);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		helper,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $mgold$elm_geojson$GeoJson$decoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	$mgold$elm_geojson$GeoJson$decodeGeoJson,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'bbox', $mgold$elm_geojson$GeoJson$decodeBbox)));
var $elm$json$Json$Decode$map4 = _Json_map4;
var $elm_community$json_extra$Json$Decode$Extra$fromMaybe = F2(
	function (error, val) {
		if (!val.$) {
			var v = val.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			return $elm$json$Json$Decode$fail(error);
		}
	});
var $elm_community$json_extra$Json$Decode$Extra$parseFloat = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toFloat,
		$elm_community$json_extra$Json$Decode$Extra$fromMaybe('failed to parse as float')),
	$elm$json$Json$Decode$string);
var $author$project$Street$placeDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Street$PlaceModel,
	A2($elm$json$Json$Decode$field, 'lon', $elm_community$json_extra$Json$Decode$Extra$parseFloat),
	A2($elm$json$Json$Decode$field, 'lat', $elm_community$json_extra$Json$Decode$Extra$parseFloat),
	A2($elm$json$Json$Decode$field, 'display_name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'geojson', $mgold$elm_geojson$GeoJson$decoder));
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Street$reverse = function (model) {
	var url = A3(
		$elm$url$Url$Builder$crossOrigin,
		'https://nominatim.openstreetmap.org',
		_List_fromArray(
			['reverse']),
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'lon',
				$elm$core$String$fromFloat(model.fj)),
				A2(
				$elm$url$Url$Builder$string,
				'lat',
				$elm$core$String$fromFloat(model.fb)),
				A2(
				$elm$url$Url$Builder$int,
				'zoom',
				$elm$core$Basics$round(model.as)),
				A2($elm$url$Url$Builder$string, 'format', 'json'),
				A2($elm$url$Url$Builder$int, 'polygon_geojson', 1)
			]));
	return $elm$http$Http$get(
		{
			ei: A2($elm$http$Http$expectJson, $author$project$Street$Reverse, $author$project$Street$placeDecoder),
			g6: url
		});
};
var $author$project$Street$reverseGeocode_ = function (model) {
	return $author$project$Street$mapFly(
		_Utils_Tuple2(
			model,
			$author$project$Street$reverse(model)));
};
var $author$project$Street$reverseGeocode = F3(
	function (lift, maybeLonLat, container) {
		var street = container.df;
		return A3(
			$elm$core$Tuple$mapBoth,
			function (model) {
				return _Utils_update(
					container,
					{df: model});
			},
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			function () {
				if (maybeLonLat.$ === 1) {
					return $author$project$Street$reverseGeocode_(street);
				} else {
					var _v1 = maybeLonLat.a;
					var lon = _v1.a;
					var lat = _v1.b;
					var _v2 = _Utils_Tuple2(
						$author$project$Street$validateLon(
							$elm$core$String$fromFloat(lon)),
						$author$project$Street$validateLat(
							$elm$core$String$fromFloat(lat)));
					if (_v2.a && _v2.b) {
						return $author$project$Street$reverseGeocode_(
							_Utils_update(
								street,
								{aT: true, fb: lat, fj: lon}));
					} else {
						return _Utils_Tuple2(street, $elm$core$Platform$Cmd$none);
					}
				}
			}());
	});
var $author$project$Page$Kunstwerk$PutPeriode = function (a) {
	return {$: 16, a: a};
};
var $author$project$Api$putPeriode = F3(
	function (msg, periode, session) {
		return A5(
			$author$project$Api$put,
			msg,
			session,
			$author$project$Api$Perioden($elm$core$Maybe$Nothing),
			$author$project$Data$Kunstwerk$decodePeriode,
			$author$project$Data$Kunstwerk$encodePeriode(periode));
	});
var $author$project$Page$Kunstwerk$save = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		model,
		function () {
			var _v1 = $author$project$Page$Kunstwerk$dirtyPeriodenDagen(model);
			if (((!_v1.a.b) && (!_v1.b.b)) && _v1.c) {
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							cmd,
							$author$project$Page$Kunstwerk$putKunstwerk(model)
						]));
			} else {
				var dirtyPerioden = _v1.a;
				var dirtyDagen = _v1.b;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[cmd]),
						$elm$core$List$concat(
							_List_fromArray(
								[
									A2(
									$elm$core$List$map,
									function (periode) {
										return A3($author$project$Api$putPeriode, $author$project$Page$Kunstwerk$PutPeriode, periode, model.W);
									},
									dirtyPerioden),
									A2(
									$elm$core$List$map,
									function (dag) {
										return A3($author$project$Api$putDag, $author$project$Page$Kunstwerk$PutDag, dag, model.W);
									},
									dirtyDagen)
								]))));
			}
		}());
};
var $author$project$Internal$Snackbar$Implementation$enqueue = F2(
	function (contents, model) {
		return _Utils_update(
			model,
			{
				cy: A2(
					$elm$core$List$append,
					model.cy,
					_List_fromArray(
						[contents]))
			});
	});
var $author$project$Internal$Snackbar$Implementation$add = F4(
	function (lift, idx, contents, store) {
		var component_ = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Internal$Snackbar$Model$defaultModel,
			A2($elm$core$Dict$get, idx, store.bz));
		var _v0 = A2(
			$elm$core$Tuple$mapSecond,
			$elm$core$Platform$Cmd$map(
				A2(
					$elm$core$Basics$composeL,
					lift,
					$author$project$Internal$Msg$SnackbarMsg(idx))),
			$author$project$Internal$Snackbar$Implementation$tryDequeue(
				A2($author$project$Internal$Snackbar$Implementation$enqueue, contents, component_)));
		var component = _v0.a;
		var effects = _v0.b;
		var updatedStore = _Utils_update(
			store,
			{
				bz: A3($elm$core$Dict$insert, idx, component, store.bz)
			});
		return _Utils_Tuple2(updatedStore, effects);
	});
var $author$project$Material$Snackbar$add = $author$project$Internal$Snackbar$Implementation$add;
var $author$project$Internal$Snackbar$Implementation$toast = F2(
	function (onDismiss, message) {
		return {cL: $elm$core$Maybe$Nothing, cM: false, d9: true, b3: 250, fu: message, c8: false, da: onDismiss, dj: 2750};
	});
var $author$project$Material$Snackbar$toast = $author$project$Internal$Snackbar$Implementation$toast;
var $author$project$Session$toast_ = F2(
	function (message, model) {
		var contents = A2($author$project$Material$Snackbar$toast, $elm$core$Maybe$Nothing, message);
		var _v0 = A4($author$project$Material$Snackbar$add, $author$project$Session$Mdc, 'my-snackbar', contents, model.c6);
		var mdc = _v0.a;
		var effects = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{c6: mdc}),
			effects);
	});
var $author$project$Session$toast = F3(
	function (lift, message, container) {
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (model) {
					return _Utils_update(
						container,
						{W: model});
				},
				A2($author$project$Session$toast_, message, container.W)));
	});
var $author$project$Field$Changed = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Field$NoOp = {$: 1};
var $elm$browser$Browser$Dom$blur = _Browser_call('blur');
var $author$project$Field$blur = function (field) {
	return A2(
		$elm$core$Task$attempt,
		function (_v0) {
			return $author$project$Field$NoOp;
		},
		$elm$browser$Browser$Dom$blur('textfield-native-' + field));
};
var $author$project$Field$focusField = F3(
	function (field, focused, model) {
		return _Utils_update(
			model,
			{
				aw: A3(
					$elm$core$Dict$update,
					field,
					function (maybeFieldModel) {
						if (maybeFieldModel.$ === 1) {
							return $elm$core$Maybe$Nothing;
						} else {
							var fieldModel = maybeFieldModel.a;
							return $elm$core$Maybe$Just(
								_Utils_update(
									fieldModel,
									{c$: focused}));
						}
					},
					model.aw)
			});
	});
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$A = {$: 0};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add = {$: 85};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt = {$: 32};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous = function (a) {
	return {$: 89, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$B = {$: 1};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace = {$: 38};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$C = {$: 2};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock = {$: 34};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch = {$: 59};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command = {$: 58};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl = function (a) {
	return {$: 31, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$D = {$: 3};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal = {$: 87};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete = {$: 39};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide = {$: 88};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down = {$: 29};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$E = {$: 4};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight = {$: 52};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$End = {$: 42};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter = {$: 37};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape = {$: 36};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F = {$: 5};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1 = {$: 62};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10 = {$: 71};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11 = {$: 72};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12 = {$: 73};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2 = {$: 63};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3 = {$: 64};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4 = {$: 65};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5 = {$: 66};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6 = {$: 67};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7 = {$: 68};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8 = {$: 69};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9 = {$: 70};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five = {$: 49};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four = {$: 48};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$G = {$: 6};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$H = {$: 7};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home = {$: 43};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$I = {$: 8};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert = {$: 54};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$J = {$: 9};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$K = {$: 10};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$L = {$: 11};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left = {$: 26};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$M = {$: 12};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply = {$: 84};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$N = {$: 13};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine = {$: 53};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock = {$: 60};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight = {$: 82};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive = {$: 79};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour = {$: 78};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine = {$: 83};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne = {$: 75};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven = {$: 81};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix = {$: 80};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree = {$: 77};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo = {$: 76};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero = {$: 74};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$O = {$: 14};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$One = {$: 45};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$P = {$: 15};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown = {$: 41};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp = {$: 40};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak = {$: 56};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen = {$: 55};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q = {$: 16};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$R = {$: 17};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right = {$: 27};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$S = {$: 18};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock = {$: 61};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven = {$: 51};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift = function (a) {
	return {$: 30, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six = {$: 50};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar = {$: 35};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract = {$: 86};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$T = {$: 19};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab = {$: 33};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three = {$: 47};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two = {$: 46};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$U = {$: 20};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown = function (a) {
	return {$: 90, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up = {$: 28};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$V = {$: 21};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$W = {$: 22};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows = {$: 57};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$X = {$: 23};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y = {$: 24};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z = {$: 25};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero = {$: 44};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode = function (keyCode) {
	switch (keyCode) {
		case 8:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace;
		case 9:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab;
		case 13:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter;
		case 16:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift($elm$core$Maybe$Nothing);
		case 17:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl($elm$core$Maybe$Nothing);
		case 18:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt;
		case 19:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak;
		case 20:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock;
		case 27:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape;
		case 32:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar;
		case 33:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp;
		case 34:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown;
		case 35:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$End;
		case 36:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home;
		case 37:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left;
		case 38:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up;
		case 39:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right;
		case 40:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down;
		case 44:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen;
		case 45:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert;
		case 46:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete;
		case 48:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero;
		case 49:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$One;
		case 50:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two;
		case 51:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three;
		case 52:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four;
		case 53:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five;
		case 54:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six;
		case 55:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven;
		case 56:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight;
		case 57:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine;
		case 65:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$A;
		case 66:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$B;
		case 67:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$C;
		case 68:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$D;
		case 69:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$E;
		case 70:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F;
		case 71:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$G;
		case 72:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$H;
		case 73:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$I;
		case 74:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$J;
		case 75:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$K;
		case 76:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$L;
		case 77:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$M;
		case 78:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$N;
		case 79:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$O;
		case 80:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$P;
		case 81:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q;
		case 82:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$R;
		case 83:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$S;
		case 84:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$T;
		case 85:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$U;
		case 86:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$V;
		case 87:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$W;
		case 88:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$X;
		case 89:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y;
		case 90:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z;
		case 91:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous(
				_List_fromArray(
					[$SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command, $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch]));
		case 96:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero;
		case 97:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne;
		case 98:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo;
		case 99:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree;
		case 100:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour;
		case 101:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive;
		case 102:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix;
		case 103:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven;
		case 104:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight;
		case 105:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine;
		case 106:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply;
		case 107:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add;
		case 109:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract;
		case 110:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal;
		case 111:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide;
		case 112:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1;
		case 113:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2;
		case 114:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3;
		case 115:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4;
		case 116:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5;
		case 117:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6;
		case 118:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7;
		case 119:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8;
		case 120:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9;
		case 121:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10;
		case 122:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11;
		case 123:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12;
		case 144:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock;
		case 145:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock;
		default:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown(keyCode);
	}
};
var $author$project$Field$getField = F2(
	function (field, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			A4($author$project$Field$defaultFieldModel, '', false, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
			A2($elm$core$Dict$get, field, model.aw));
	});
var $author$project$Field$dom = _Platform_outgoingPort('dom', $elm$core$Basics$identity);
var $author$project$Field$selectText = function (field) {
	return $author$project$Field$dom(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Cmd',
					$elm$json$Json$Encode$string('SelectText')),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string('textfield-native-' + field))
				])));
};
var $author$project$Field$updateField = F3(
	function (field, value, model) {
		return _Utils_update(
			model,
			{
				aw: A3(
					$elm$core$Dict$update,
					field,
					function (maybeFieldModel) {
						if (maybeFieldModel.$ === 1) {
							return $elm$core$Maybe$Nothing;
						} else {
							var fieldModel = maybeFieldModel.a;
							return $elm$core$Maybe$Just(
								_Utils_update(
									fieldModel,
									{dl: value}));
						}
					},
					model.aw)
			});
	});
var $author$project$Field$valid = F2(
	function (fieldModel, value) {
		var valid_ = function (formatted_) {
			return fieldModel.g9(formatted_);
		};
		var formatted = fieldModel.b8(value);
		var _v0 = fieldModel.dc;
		if (_v0) {
			return (formatted !== '') && valid_(formatted);
		} else {
			return (formatted === '') || valid_(formatted);
		}
	});
var $author$project$Field$update_ = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Field$Mdc, msg_, model);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var field = msg.a;
				var value = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Field$updateField,
						field,
						value,
						A3($author$project$Field$focusField, field, true, model)),
					function () {
						var _v1 = A2($author$project$Field$getField, field, model).gr;
						if (_v1) {
							return $author$project$Field$selectText(field);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 3:
				var field = msg.a;
				return _Utils_Tuple2(
					A3($author$project$Field$focusField, field, false, model),
					$elm$core$Platform$Cmd$none);
			case 4:
				var field = msg.a;
				var saved = msg.b;
				var multiline = msg.c;
				var code = msg.d;
				var _v2 = $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode(code);
				switch (_v2.$) {
					case 37:
						return _Utils_Tuple2(
							model,
							function () {
								if (multiline) {
									return $elm$core$Platform$Cmd$none;
								} else {
									return $author$project$Field$blur(field);
								}
							}());
					case 36:
						return _Utils_Tuple2(
							A3($author$project$Field$updateField, field, saved, model),
							$author$project$Field$blur(field));
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var field = msg.a;
				var typed = msg.b;
				return _Utils_Tuple2(
					A3($author$project$Field$updateField, field, typed, model),
					$elm$core$Platform$Cmd$none);
			case 6:
				var field = msg.a;
				var saved = msg.b;
				var typed = msg.c;
				var fieldModel = A2($author$project$Field$getField, field, model);
				var formatted = fieldModel.b8(typed);
				return _Utils_eq(formatted, saved) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : (A2($author$project$Field$valid, fieldModel, formatted) ? _Utils_Tuple2(
					A3($author$project$Field$updateField, field, formatted, model),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Field$blur(field),
								A2(
								$elm$core$Task$perform,
								$elm$core$Basics$identity,
								$elm$core$Task$succeed(
									A2($author$project$Field$Changed, field, formatted)))
							]))) : _Utils_Tuple2(
					A3($author$project$Field$updateField, field, saved, model),
					$elm$core$Platform$Cmd$none));
			default:
				var field = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Field$update = F3(
	function (lift, msg, container) {
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (model) {
					return _Utils_update(
						container,
						{cY: model});
				},
				A2($author$project$Field$update_, msg, container.cY)));
	});
var $author$project$Street$Changed = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Street$changed = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmd,
					A2(
					$elm$core$Task$perform,
					$elm$core$Basics$identity,
					$elm$core$Task$succeed(
						A2($author$project$Street$Changed, model.fj, model.fb)))
				])));
};
var $author$project$Street$mapRender = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmd,
					$author$project$Street$mapPort(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Cmd',
								$elm$json$Json$Encode$string('Render')),
								_Utils_Tuple2(
								'geoJson',
								$author$project$Street$geoJsonValue(model))
							])))
				])));
};
var $author$project$Street$mapFit = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{aX: true}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmd,
					$author$project$Street$mapPort(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Cmd',
								$elm$json$Json$Encode$string('Fit')),
								_Utils_Tuple2(
								'geoJson',
								$author$project$Street$geoJsonValue(model))
							])))
				])));
};
var $author$project$Street$selectPlace = F2(
	function (place, model) {
		return $author$project$Street$mapFit(
			$author$project$Street$changed(
				_Utils_Tuple2(
					_Utils_update(
						model,
						{
							I: $elm$core$Maybe$Just(place.I),
							an: place.b2
						}),
					$elm$core$Platform$Cmd$none)));
	});
var $author$project$Street$toast = F2(
	function (message, model) {
		var contents = A2($author$project$Material$Snackbar$toast, $elm$core$Maybe$Nothing, message);
		var _v0 = A4($author$project$Material$Snackbar$add, $author$project$Street$Mdc, 'my-snackbar', contents, model.c6);
		var mdc = _v0.a;
		var effects = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{c6: mdc}),
			effects);
	});
var $author$project$Street$unselectPlace = F2(
	function (message, model) {
		return $author$project$Street$mapRender(
			A2(
				$author$project$Street$toast,
				message,
				_Utils_update(
					model,
					{I: $elm$core$Maybe$Nothing, aG: _List_Nil})));
	});
var $author$project$Street$Search = function (a) {
	return {$: 4, a: a};
};
var $author$project$Street$search = function (q) {
	var url = A3(
		$elm$url$Url$Builder$crossOrigin,
		'https://nominatim.openstreetmap.org',
		_List_fromArray(
			['search']),
		_List_fromArray(
			[
				A2($elm$url$Url$Builder$string, 'q', q),
				A2($elm$url$Url$Builder$string, 'format', 'json'),
				A2($elm$url$Url$Builder$int, 'polygon_geojson', 1)
			]));
	return $elm$http$Http$get(
		{
			ei: A2(
				$elm$http$Http$expectJson,
				$author$project$Street$Search,
				$elm$json$Json$Decode$list($author$project$Street$placeDecoder)),
			g6: url
		});
};
var $author$project$Street$updateField = F3(
	function (field, value, model) {
		var lonlat = function (model_) {
			return $author$project$Street$changed(
				$author$project$Street$reverseGeocode_(
					_Utils_update(
						model_,
						{aT: true})));
		};
		switch (field) {
			case 'lon':
				return lonlat(
					_Utils_update(
						model,
						{
							fj: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toFloat(value))
						}));
			case 'lat':
				return lonlat(
					_Utils_update(
						model,
						{
							fb: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toFloat(value))
						}));
			case 'place':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{an: value}),
					$author$project$Street$search(value));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Street$update_ = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Street$Mdc, msg_, model);
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var field = msg_.a;
					var value = msg_.b;
					return A3($author$project$Street$updateField, field, value, model);
				} else {
					return A3($author$project$Field$update, $author$project$Street$Field, msg_, model);
				}
			case 3:
				var mapView = msg.a;
				var newmodel = _Utils_update(
					model,
					{fb: mapView.H.fb, fj: mapView.H.fj, aX: false, as: mapView.as});
				var _v2 = model.aX;
				if (_v2) {
					return _Utils_Tuple2(newmodel, $elm$core$Platform$Cmd$none);
				} else {
					return function (tuple) {
						return _Utils_eq(
							_Utils_Tuple2(newmodel.fj, newmodel.fb),
							_Utils_Tuple2(model.fj, model.fb)) ? tuple : $author$project$Street$changed(tuple);
					}(
						$author$project$Street$reverseGeocode_(newmodel));
				}
			case 4:
				var result = msg.a;
				if (!result.$) {
					var places = result.a;
					var _v4 = $elm$core$List$head(places);
					if (_v4.$ === 1) {
						return A2($author$project$Street$unselectPlace, 'Niets gevonden', model);
					} else {
						var place = _v4.a;
						var _v5 = $elm$core$List$length(places);
						if (_v5 === 1) {
							return A2($author$project$Street$selectPlace, place, model);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{aG: places}),
								$elm$core$Platform$Cmd$none);
						}
					}
				} else {
					var err = result.a;
					return A2(
						$author$project$Street$unselectPlace,
						A2($author$project$HttpErrorMessage$httpErrorMessage, err, 'geocoderen'),
						model);
				}
			case 5:
				var place = msg.a;
				return A2($author$project$Street$selectPlace, place, model);
			default:
				var result = msg.a;
				var newmodel = _Utils_update(
					model,
					{I: $elm$core$Maybe$Nothing, an: '', aG: _List_Nil});
				return $author$project$Street$mapRender(
					function () {
						if (!result.$) {
							var place = result.a;
							return _Utils_Tuple2(
								_Utils_update(
									newmodel,
									{
										I: $elm$core$Maybe$Just(place.I),
										an: place.b2
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							var err = result.a;
							if (err.$ === 4) {
								return _Utils_Tuple2(newmodel, $elm$core$Platform$Cmd$none);
							} else {
								return A2(
									$author$project$Street$toast,
									A2($author$project$HttpErrorMessage$httpErrorMessage, err, 'omgekeerd geocoderen'),
									newmodel);
							}
						}
					}());
		}
	});
var $author$project$Street$update = F3(
	function (lift, msg, container) {
		var street = container.df;
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (model) {
					return _Utils_update(
						container,
						{df: model});
				},
				A2($author$project$Street$update_, msg, street)));
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Kunstwerk$updateDagVolgorde = F5(
	function (periodeID, dagID, direction, oldVolgorde, model) {
		var _v0 = model.k;
		if (_v0.$ === 1) {
			return model;
		} else {
			var kunstwerk = _v0.a;
			var _v1 = function () {
				if (!direction) {
					return _Utils_Tuple2(oldVolgorde - 1, $elm$core$List$minimum);
				} else {
					return _Utils_Tuple2(oldVolgorde + 1, $elm$core$List$maximum);
				}
			}();
			var newVolgorde = _v1.a;
			var minmax = _v1.b;
			var change = !$elm$core$List$isEmpty(
				A2(
					$elm$core$List$filter,
					function (periode) {
						if (!_Utils_eq(periode.cc, periodeID)) {
							return false;
						} else {
							if ($elm$core$List$length(periode.b0) <= 1) {
								return false;
							} else {
								var volgordes = A2(
									$elm$core$List$map,
									function (dag) {
										return dag.hd;
									},
									periode.b0);
								var same = function (volgorde) {
									return _Utils_eq(volgorde, oldVolgorde);
								};
								if (A2($elm$core$List$all, same, volgordes)) {
									return true;
								} else {
									var _v3 = minmax(volgordes);
									if (_v3.$ === 1) {
										return false;
									} else {
										var limit = _v3.a;
										return !_Utils_eq(oldVolgorde, limit);
									}
								}
							}
						}
					},
					kunstwerk.fP));
			var updateDag = function (dag) {
				return _Utils_eq(dag.cc, dagID) ? _Utils_update(
					dag,
					{d8: true, hd: newVolgorde}) : (_Utils_eq(dag.hd, newVolgorde) ? _Utils_update(
					dag,
					{d8: true, hd: oldVolgorde}) : dag);
			};
			var updatePeriode = function (periode) {
				return (change && _Utils_eq(periode.cc, periodeID)) ? _Utils_update(
					periode,
					{
						b0: A2($elm$core$List$map, updateDag, periode.b0)
					}) : periode;
			};
			return A2(
				$author$project$Session$dirty,
				model.W.d8 || change,
				A2($author$project$Page$Kunstwerk$pio, updatePeriode, model));
		}
	});
var $author$project$Page$Kunstwerk$updateDagField = F3(
	function (id, field, value) {
		return function (dag) {
			if (_Utils_eq(dag.cc, id)) {
				switch (field) {
					case 'dagen':
						return _Utils_update(
							dag,
							{b0: value, d8: true});
					case 'tijden':
						return _Utils_update(
							dag,
							{d8: true, gS: value});
					case 'opmerkingen':
						return _Utils_update(
							dag,
							{d8: true, fG: value});
					default:
						return dag;
				}
			} else {
				return dag;
			}
		};
	});
var $author$project$Page$Kunstwerk$updatePeriodeField = F3(
	function (id, field, value) {
		return function (periode) {
			if (_Utils_eq(periode.cc, id)) {
				var _v0 = A2($elm$core$String$split, '|', field);
				_v0$3:
				while (true) {
					if (_v0.b) {
						if (!_v0.b.b) {
							switch (_v0.a) {
								case 'periode':
									return _Utils_update(
										periode,
										{d8: true, fN: value});
								case 'opmerkingen':
									return _Utils_update(
										periode,
										{d8: true, fG: value});
								default:
									break _v0$3;
							}
						} else {
							if (((_v0.a === 'dag') && _v0.b.b.b) && (!_v0.b.b.b.b)) {
								var _v1 = _v0.b;
								var dagID_ = _v1.a;
								var _v2 = _v1.b;
								var dagField = _v2.a;
								var _v3 = $elm$core$String$toInt(dagID_);
								if (_v3.$ === 1) {
									return periode;
								} else {
									var dagID = _v3.a;
									return _Utils_update(
										periode,
										{
											b0: A2(
												$elm$core$List$map,
												A3($author$project$Page$Kunstwerk$updateDagField, dagID, dagField, value),
												periode.b0)
										});
								}
							} else {
								break _v0$3;
							}
						}
					} else {
						break _v0$3;
					}
				}
				return periode;
			} else {
				return periode;
			}
		};
	});
var $author$project$Page$Kunstwerk$updateField = F2(
	function (field, value) {
		return function (kunstwerk) {
			var coordinaat = kunstwerk.bZ;
			var telefoon = kunstwerk.gO;
			var brug = kunstwerk.dO;
			var sluis = kunstwerk.gx;
			var _v0 = A2($elm$core$String$split, '.', field);
			_v0$19:
			while (true) {
				if (_v0.b) {
					if (!_v0.b.b) {
						switch (_v0.a) {
							case 'naam':
								return _Utils_update(
									kunstwerk,
									{aY: value});
							case 'referentie':
								return _Utils_update(
									kunstwerk,
									{f6: value});
							case 'anwbBrugNummer':
								return _Utils_update(
									kunstwerk,
									{bP: value});
							case 'adres':
								return _Utils_update(
									kunstwerk,
									{dr: value});
							case 'postcode':
								return _Utils_update(
									kunstwerk,
									{fV: value});
							case 'woonplaats':
								return _Utils_update(
									kunstwerk,
									{cK: value});
							case 'marifoonkanaal':
								return _Utils_update(
									kunstwerk,
									{
										fk: A2(
											$elm$core$Maybe$withDefault,
											0,
											$elm$core$String$toInt(value))
									});
							case 'email':
								return _Utils_update(
									kunstwerk,
									{ef: value});
							case 'web':
								return _Utils_update(
									kunstwerk,
									{hm: value});
							case 'maxBreedte':
								return _Utils_update(
									kunstwerk,
									{
										fm: A2(
											$elm$core$Maybe$withDefault,
											0,
											$elm$core$String$toFloat(value))
									});
							case 'maxHoogte':
								return _Utils_update(
									kunstwerk,
									{
										fp: A2(
											$elm$core$Maybe$withDefault,
											0,
											$elm$core$String$toFloat(value))
									});
							case 'toelichting':
								return _Utils_update(
									kunstwerk,
									{gU: value});
							case 'opmerkingen':
								return _Utils_update(
									kunstwerk,
									{fG: value});
							default:
								break _v0$19;
						}
					} else {
						if (!_v0.b.b.b) {
							switch (_v0.a) {
								case 'telefoon':
									var _v1 = _v0.b;
									var part = _v1.a;
									return _Utils_update(
										kunstwerk,
										{
											gO: function () {
												switch (part) {
													case 'net':
														return _Utils_update(
															telefoon,
															{cq: value});
													case 'abonnee':
														return _Utils_update(
															telefoon,
															{bL: value});
													default:
														return telefoon;
												}
											}()
										});
								case 'brug':
									if (_v0.b.a === 'openingen') {
										var _v3 = _v0.b;
										var _v4 = $elm$core$String$toInt(value);
										if (_v4.$ === 1) {
											return kunstwerk;
										} else {
											var openingen = _v4.a;
											return _Utils_update(
												kunstwerk,
												{
													dO: _Utils_update(
														brug,
														{fD: openingen})
												});
										}
									} else {
										break _v0$19;
									}
								case 'sluis':
									switch (_v0.b.a) {
										case 'ingangen':
											var _v5 = _v0.b;
											var _v6 = $elm$core$String$toInt(value);
											if (_v6.$ === 1) {
												return kunstwerk;
											} else {
												var ingangen = _v6.a;
												return _Utils_update(
													kunstwerk,
													{
														gx: _Utils_update(
															sluis,
															{eM: ingangen})
													});
											}
										case 'maxDiepte':
											var _v7 = _v0.b;
											var _v8 = $elm$core$String$toFloat(value);
											if (_v8.$ === 1) {
												return kunstwerk;
											} else {
												var maxDiepte = _v8.a;
												return _Utils_update(
													kunstwerk,
													{
														gx: _Utils_update(
															sluis,
															{fn: maxDiepte})
													});
											}
										case 'maxLengte':
											var _v9 = _v0.b;
											var _v10 = $elm$core$String$toFloat(value);
											if (_v10.$ === 1) {
												return kunstwerk;
											} else {
												var maxLengte = _v10.a;
												return _Utils_update(
													kunstwerk,
													{
														gx: _Utils_update(
															sluis,
															{fq: maxLengte})
													});
											}
										default:
											break _v0$19;
									}
								default:
									break _v0$19;
							}
						} else {
							if ((_v0.a === 'periode') && (!_v0.b.b.b.b)) {
								var _v11 = _v0.b;
								var periodeID_ = _v11.a;
								var _v12 = _v11.b;
								var periodeField = _v12.a;
								var _v13 = $elm$core$String$toInt(periodeID_);
								if (_v13.$ === 1) {
									return kunstwerk;
								} else {
									var periodeID = _v13.a;
									return _Utils_update(
										kunstwerk,
										{
											fP: A2(
												$elm$core$List$map,
												A3($author$project$Page$Kunstwerk$updatePeriodeField, periodeID, periodeField, value),
												kunstwerk.fP)
										});
								}
							} else {
								break _v0$19;
							}
						}
					}
				} else {
					break _v0$19;
				}
			}
			return kunstwerk;
		};
	});
var $author$project$Page$Kunstwerk$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Kunstwerk$Mdc, msg_, model);
			case 3:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var field = msg_.a;
					var value = msg_.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							true,
							A2(
								$author$project$Page$Kunstwerk$kw,
								A2($author$project$Page$Kunstwerk$updateField, field, value),
								model)),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Field$update, $author$project$Page$Kunstwerk$Field, msg_, model);
				}
			case 4:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var lon = msg_.a;
					var lat = msg_.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							true,
							A2(
								$author$project$Page$Kunstwerk$kw,
								function (kunstwerk) {
									var coordinaat = kunstwerk.bZ;
									return _Utils_update(
										kunstwerk,
										{
											bZ: _Utils_update(
												coordinaat,
												{fb: lat, fj: lon})
										});
								},
								model)),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Street$update, $author$project$Page$Kunstwerk$Street, msg_, model);
				}
			case 1:
				var msg_ = msg.a;
				_v3$6:
				while (true) {
					switch (msg_.$) {
						case 11:
							if (msg_.c) {
								if (!msg_.b.$) {
									var key = msg_.a;
									var delID = msg_.b.a;
									switch (key) {
										case 'RemovePeriode':
											return _Utils_Tuple2(
												A2($author$project$Session$progress, true, model),
												A3($author$project$Api$deletePeriode, $author$project$Page$Kunstwerk$DeletePeriode, delID, model.W));
										case 'RemoveDag':
											return _Utils_Tuple2(
												A2($author$project$Session$progress, true, model),
												A3($author$project$Api$deleteDag, $author$project$Page$Kunstwerk$DeleteDag, delID, model.W));
										default:
											return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									}
								} else {
									if (msg_.a === 'delete') {
										var _v5 = model.k;
										if (_v5.$ === 1) {
											return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
										} else {
											var kunstwerk = _v5.a;
											return _Utils_Tuple2(
												A2($author$project$Session$progress, true, model),
												A3($author$project$Api$deleteKunstwerk, $author$project$Page$Kunstwerk$DeleteKunstwerk, kunstwerk.cn, model.W));
										}
									} else {
										break _v3$6;
									}
								}
							} else {
								break _v3$6;
							}
						case 4:
							if (msg_.a === 'arrow_back') {
								return A2($author$project$Session$back, 1, model);
							} else {
								break _v3$6;
							}
						case 3:
							switch (msg_.a) {
								case 'print':
									var _v6 = model.k;
									if (_v6.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										var kunstwerk = _v6.a;
										return _Utils_Tuple2(
											A2($author$project$Session$progress, true, model),
											A3(
												$author$project$Api$file,
												$author$project$Page$Kunstwerk$Pdf,
												'/api/pdf/kunstwerk/' + $elm$core$String$fromInt(kunstwerk.cc),
												model.W));
									}
								case 'save':
									var _v7 = model.W.d8;
									if (_v7) {
										return $author$project$Page$Kunstwerk$save(
											_Utils_Tuple2(
												A2($author$project$Session$progress, true, model),
												$elm$core$Platform$Cmd$none));
									} else {
										return A3($author$project$Session$toast, $author$project$Page$Kunstwerk$Session, 'Geen wijzigingen', model);
									}
								case 'delete':
									var _v8 = model.k;
									if (_v8.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										var kunstwerk = _v8.a;
										return _Utils_Tuple2(
											A4($author$project$Session$confirm, 'delete', $elm$core$Maybe$Nothing, 'Deze brug of sluis wordt verwijderd.', model),
											$elm$core$Platform$Cmd$none);
									}
								default:
									break _v3$6;
							}
						default:
							break _v3$6;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Kunstwerk$Session, msg_, model);
			case 5:
				var result = msg.a;
				if (!result.$) {
					var bytes = result.a;
					return _Utils_Tuple2(
						A2($author$project$Session$progress, false, model),
						A3($author$project$Api$download, 'Afdrukvoorbeeld.pdf', 'application/pdf', bytes));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Session$error,
							err,
							'ophalen afdrukvoorbeeld',
							A2($author$project$Session$progress, false, model)),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var result = msg.a;
				if (!result.$) {
					var kunstwerk = result.a;
					var volgordeUniek = function (dagen) {
						return !$elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (dag) {
									return A2(
										$elm$core$List$any,
										function (_v12) {
											var volgorde = _v12.hd;
											return _Utils_eq(volgorde, dag.hd);
										},
										A2(
											$elm$core$List$filter,
											function (_v11) {
												var id = _v11.cc;
												return !_Utils_eq(id, dag.cc);
											},
											dagen));
								},
								dagen));
					};
					return $author$project$Page$Kunstwerk$save(
						A3(
							$elm$core$Basics$apL,
							$author$project$Street$reverseGeocode($author$project$Page$Kunstwerk$Street),
							$elm$core$Maybe$Just(
								_Utils_Tuple2(kunstwerk.bZ.fj, kunstwerk.bZ.fb)),
							A2(
								$author$project$Page$Kunstwerk$pio,
								function (periode) {
									return volgordeUniek(periode.b0) ? periode : _Utils_update(
										periode,
										{
											b0: A2(
												$elm$core$List$indexedMap,
												F2(
													function (index, dag) {
														return _Utils_update(
															dag,
															{d8: true, hd: index});
													}),
												A2(
													$elm$core$List$sortBy,
													function ($) {
														return $.hd;
													},
													periode.b0))
										});
								},
								A2($author$project$Page$Kunstwerk$initKunstwerk, kunstwerk, model))));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'ophalen gegevens brug/sluis', model),
						$elm$core$Platform$Cmd$none);
				}
			case 7:
				var result = msg.a;
				if (!result.$) {
					var kunstwerk = result.a;
					return A3(
						$author$project$Session$toast,
						$author$project$Page$Kunstwerk$Session,
						'Opgeslagen',
						A2($author$project$Page$Kunstwerk$initKunstwerk, kunstwerk, model));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'bewaren gegevens brug/sluis', model),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				var result = msg.a;
				if (!result.$) {
					var kunstwerk = result.a;
					return A2(
						$author$project$Session$replaceUrl,
						'/anwbwa/app/kunstwerk?link=' + kunstwerk.cn,
						A2($author$project$Page$Kunstwerk$initKunstwerk, kunstwerk, model));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'aanmaken nieuwe brug/sluis', model),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				var result = msg.a;
				if (!result.$) {
					return A2(
						$author$project$Session$back,
						1,
						A2(
							$author$project$Session$dirty,
							false,
							A2(
								$author$project$Session$progress,
								false,
								_Utils_update(
									model,
									{k: $elm$core$Maybe$Nothing}))));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'verwijderen gegevens brug of sluis', model),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var kunstwerkID = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Session$progress, true, model),
					A3($author$project$Api$postPeriode, $author$project$Page$Kunstwerk$PostPeriode, kunstwerkID, model.W));
			case 15:
				var result = msg.a;
				if (!result.$) {
					var periode = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							$author$project$Page$Kunstwerk$updateFieldModel(
								A2(
									$author$project$Page$Kunstwerk$kw,
									function (kunstwerk) {
										return _Utils_update(
											kunstwerk,
											{
												fP: A2(
													$elm$core$List$cons,
													_Utils_update(
														periode,
														{ie: true}),
													kunstwerk.fP)
											});
									},
									model))),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							A3($author$project$Session$error, err, 'aanmaken nieuwe periode', model)),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var result = msg.a;
				if (!result.$) {
					var periode = result.a;
					var id = periode.cc;
					var model_ = A2(
						$author$project$Page$Kunstwerk$pio,
						function (periode_) {
							return _Utils_eq(periode_.cc, id) ? _Utils_update(
								periode,
								{ie: periode_.ie}) : periode_;
						},
						model);
					return _Utils_Tuple2(
						model_,
						function () {
							var _v18 = $author$project$Page$Kunstwerk$dirtyPeriodenDagen(model_);
							if (((!_v18.a.b) && (!_v18.b.b)) && _v18.c) {
								return $author$project$Page$Kunstwerk$putKunstwerk(model_);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'bewaren gegevens periode', model),
						$elm$core$Platform$Cmd$none);
				}
			case 17:
				var periodeID = msg.a;
				var periode = msg.b;
				return _Utils_Tuple2(
					A4(
						$author$project$Session$confirm,
						'RemovePeriode',
						$elm$core$Maybe$Just(periodeID),
						'Periode \'' + (periode + '\' wordt verwijderd.'),
						model),
					$elm$core$Platform$Cmd$none);
			case 18:
				var periodeID = msg.a;
				var result = msg.b;
				if (!result.$) {
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							$author$project$Page$Kunstwerk$updateFieldModel(
								A2(
									$author$project$Page$Kunstwerk$kw,
									function (kunstwerk) {
										return _Utils_update(
											kunstwerk,
											{
												fP: A2(
													$elm$core$List$filter,
													function (_v20) {
														var id = _v20.cc;
														return !_Utils_eq(id, periodeID);
													},
													kunstwerk.fP)
											});
									},
									model))),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							A3($author$project$Session$error, err, 'verwijderen periode', model)),
						$elm$core$Platform$Cmd$none);
				}
			case 19:
				var periodeID = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Session$progress, true, model),
					A3($author$project$Api$postDag, $author$project$Page$Kunstwerk$PostDag, periodeID, model.W));
			case 20:
				var result = msg.a;
				if (!result.$) {
					var dag = result.a;
					var periodeID = dag.fO;
					var newVolgorde = function () {
						var _v22 = model.k;
						if (_v22.$ === 1) {
							return 0;
						} else {
							var kunstwerk = _v22.a;
							var _v23 = $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (_v24) {
										var id = _v24.cc;
										return _Utils_eq(id, periodeID);
									},
									kunstwerk.fP));
							if (_v23.$ === 1) {
								return 0;
							} else {
								var periode = _v23.a;
								var _v25 = $elm$core$List$maximum(
									A2(
										$elm$core$List$map,
										function (_v26) {
											var volgorde = _v26.hd;
											return volgorde;
										},
										periode.b0));
								if (_v25.$ === 1) {
									return 0;
								} else {
									var maximum = _v25.a;
									return maximum + 1;
								}
							}
						}
					}();
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							$author$project$Page$Kunstwerk$updateFieldModel(
								A2(
									$author$project$Page$Kunstwerk$pio,
									function (periode) {
										return _Utils_eq(periode.cc, periodeID) ? _Utils_update(
											periode,
											{
												b0: _Utils_ap(
													periode.b0,
													_List_fromArray(
														[
															_Utils_update(
															dag,
															{hd: newVolgorde})
														]))
											}) : periode;
									},
									model))),
						_Utils_eq(dag.hd, newVolgorde) ? $elm$core$Platform$Cmd$none : A3(
							$author$project$Api$putDag,
							$author$project$Page$Kunstwerk$PutDag,
							_Utils_update(
								dag,
								{hd: newVolgorde}),
							model.W));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							A3($author$project$Session$error, err, 'aanmaken nieuwe periode', model)),
						$elm$core$Platform$Cmd$none);
				}
			case 21:
				var result = msg.a;
				if (!result.$) {
					var dag = result.a;
					var id = dag.cc;
					var periodeID = dag.fO;
					var model_ = A2(
						$author$project$Page$Kunstwerk$pio,
						function (periode) {
							return _Utils_eq(periode.cc, periodeID) ? _Utils_update(
								periode,
								{
									b0: A2(
										$elm$core$List$map,
										function (dag_) {
											return _Utils_eq(dag_.cc, id) ? dag : dag_;
										},
										periode.b0)
								}) : periode;
						},
						model);
					return _Utils_Tuple2(
						model_,
						function () {
							var _v28 = $author$project$Page$Kunstwerk$dirtyPeriodenDagen(model_);
							if (((!_v28.a.b) && (!_v28.b.b)) && _v28.c) {
								return $author$project$Page$Kunstwerk$putKunstwerk(model_);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'bewaren gegevens dag', model),
						$elm$core$Platform$Cmd$none);
				}
			case 22:
				var dagID = msg.a;
				var dagen = msg.b;
				return _Utils_Tuple2(
					A4(
						$author$project$Session$confirm,
						'RemoveDag',
						$elm$core$Maybe$Just(dagID),
						'Dagen \'' + (dagen + '\' worden verwijderd.'),
						model),
					$elm$core$Platform$Cmd$none);
			case 23:
				var dagID = msg.a;
				var result = msg.b;
				if (!result.$) {
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							$author$project$Page$Kunstwerk$updateFieldModel(
								A2(
									$author$project$Page$Kunstwerk$pio,
									function (periode) {
										return _Utils_update(
											periode,
											{
												b0: A2(
													$elm$core$List$filter,
													function (_v30) {
														var id = _v30.cc;
														return !_Utils_eq(id, dagID);
													},
													periode.b0)
											});
									},
									model))),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							A3($author$project$Session$error, err, 'verwijderen dagen', model)),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var show = !model.aH;
				return _Utils_Tuple2(
					A2(
						$author$project$Page$Kunstwerk$pio,
						function (periode) {
							return _Utils_update(
								periode,
								{ie: true});
						},
						_Utils_update(
							model,
							{aU: false, aH: true})),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					A2(
						$author$project$Page$Kunstwerk$pio,
						function (periode) {
							return _Utils_update(
								periode,
								{ie: false});
						},
						_Utils_update(
							model,
							{aU: true, aH: false})),
					$elm$core$Platform$Cmd$none);
			case 12:
				var periodeID = msg.a;
				var show = msg.b;
				return _Utils_Tuple2(
					A2(
						$author$project$Page$Kunstwerk$pio,
						function (periode) {
							return _Utils_eq(periode.cc, periodeID) ? _Utils_update(
								periode,
								{ie: show}) : periode;
						},
						_Utils_update(
							model,
							{aU: false, aH: false})),
					$elm$core$Platform$Cmd$none);
			case 13:
				var periodeID = msg.a;
				var dagID = msg.b;
				var direction = msg.c;
				var oldVolgorde = msg.d;
				return _Utils_Tuple2(
					A5($author$project$Page$Kunstwerk$updateDagVolgorde, periodeID, dagID, direction, oldVolgorde, model),
					$elm$core$Platform$Cmd$none);
			case 24:
				var value = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Kunstwerk$kw,
							function (k) {
								return _Utils_update(
									k,
									{fF: value});
							},
							model)),
					$elm$core$Platform$Cmd$none);
			case 25:
				var value = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Kunstwerk$kw,
							function (k) {
								return _Utils_update(
									k,
									{cj: value});
							},
							model)),
					$elm$core$Platform$Cmd$none);
			case 26:
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Kunstwerk$kw,
							function (k) {
								return _Utils_update(
									k,
									{dt: !k.dt});
							},
							model)),
					$elm$core$Platform$Cmd$none);
			case 27:
				var value = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Kunstwerk$kw,
							function (k) {
								return _Utils_update(
									k,
									{e5: value});
							},
							model)),
					$elm$core$Platform$Cmd$none);
			case 28:
				var value = msg.a;
				return _Utils_Tuple2(
					function () {
						var _v31 = $elm$core$String$toInt(value);
						if (_v31.$ === 1) {
							return model;
						} else {
							var soort = _v31.a;
							return A2(
								$author$project$Session$dirty,
								true,
								A2(
									$author$project$Page$Kunstwerk$kw,
									function (k) {
										var brug = k.dO;
										return _Utils_update(
											k,
											{
												dO: _Utils_update(
													brug,
													{cC: soort})
											});
									},
									model));
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 29:
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Kunstwerk$kw,
							function (k) {
								var brug = k.dO;
								return _Utils_update(
									k,
									{
										dO: _Utils_update(
											brug,
											{dF: !brug.dF})
									});
							},
							model)),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Session$PushUrl = function (a) {
	return {$: 8, a: a};
};
var $author$project$Session$pushUrl = F2(
	function (url, container) {
		return A2(
			$author$project$Session$maybeLeave,
			container,
			$author$project$Session$PushUrl(url));
	});
var $author$project$Page$Partial$Filter$values = F2(
	function (model, key) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Dict$get, key, model.bH));
	});
var $author$project$Page$Partial$Filter$select = function (model) {
	var selected = A3(
		model.gz,
		model.ao,
		$author$project$Page$Partial$Filter$values(model),
		A2(
			$elm$core$List$filter,
			A2(
				model.b4,
				model.ao,
				$author$project$Page$Partial$Filter$values(model)),
			model.bo));
	return _Utils_update(
		model,
		{C: 0, by: selected});
};
var $author$project$Page$Partial$Filter$update_ = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Partial$Filter$Mdc, msg_, model);
			case 2:
				var msg_ = msg.a;
				if ((msg_.$ === 5) && (msg_.a === 'textFilter')) {
					var val = msg_.b;
					return A3(
						$author$project$Field$update,
						$author$project$Page$Partial$Filter$Field,
						msg_,
						function () {
							var _v2 = model.dn(val);
							if (_v2) {
								return $author$project$Page$Partial$Filter$select(
									_Utils_update(
										model,
										{ao: val}));
							} else {
								return model;
							}
						}());
				} else {
					return A3($author$project$Field$update, $author$project$Page$Partial$Filter$Field, msg_, model);
				}
			case 6:
				var key = msg.a;
				var val = msg.b;
				return _Utils_Tuple2(
					$author$project$Page$Partial$Filter$select(
						_Utils_update(
							model,
							{
								bH: A3($elm$core$Dict$insert, key, val, model.bH)
							})),
					$elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					$author$project$Page$Partial$Filter$select(
						_Utils_update(
							model,
							{ao: ''})),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: 0}),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					function () {
						var _v3 = model.C;
						if (!_v3) {
							return model;
						} else {
							var page = _v3;
							return _Utils_update(
								model,
								{C: page - 1});
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: model.C + 1}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Partial$Filter$update = F3(
	function (lift, m, _v0) {
		var container = _v0.a;
		var session = container.W;
		var model = _v0.b;
		if (m.$ === 3) {
			var result = m.a;
			if (!result.$) {
				var items = result.a;
				return _Utils_Tuple2(
					_Utils_Tuple2(
						A2($author$project$Session$progress, false, container),
						$author$project$Page$Partial$Filter$select(
							_Utils_update(
								model,
								{bo: items}))),
					A2($elm$core$Platform$Cmd$map, lift, $elm$core$Platform$Cmd$none));
			} else {
				var err = result.a;
				return _Utils_Tuple2(
					_Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							A3($author$project$Session$error, err, 'ophalen lijst', container)),
						model),
					A2($elm$core$Platform$Cmd$map, lift, $elm$core$Platform$Cmd$none));
			}
		} else {
			var _v3 = A2($author$project$Page$Partial$Filter$update_, m, model);
			var model_ = _v3.a;
			var cmd_ = _v3.b;
			return _Utils_Tuple2(
				_Utils_Tuple2(container, model_),
				A2($elm$core$Platform$Cmd$map, lift, cmd_));
		}
	});
var $author$project$Page$Partial$Kunstwerken$update = F3(
	function (lift, m, container) {
		var session = container.W;
		var kunstwerken = container.e1;
		var msg = m;
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (_v1) {
					var top = _v1.a;
					var filter = _v1.b;
					return _Utils_update(
						top,
						{
							e1: {b4: filter}
						});
				},
				A3(
					$author$project$Page$Partial$Filter$update,
					$elm$core$Basics$identity,
					msg,
					_Utils_Tuple2(container, kunstwerken.b4))));
	});
var $author$project$Page$Kunstwerken$update = F2(
	function (msg, model) {
		var session = model.W;
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				_v1$2:
				while (true) {
					switch (msg_.$) {
						case 4:
							if (msg_.a === 'arrow_back') {
								return A2($author$project$Session$back, 1, model);
							} else {
								break _v1$2;
							}
						case 3:
							if (msg_.a === 'add') {
								return A2($author$project$Session$pushUrl, session.g6 + '?link=', model);
							} else {
								break _v1$2;
							}
						default:
							break _v1$2;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Kunstwerken$Session, msg_, model);
			case 2:
				var msg_ = msg.a;
				if (msg_.$ === 5) {
					var link = msg_.a.cn;
					return A2($author$project$Session$pushUrl, session.g6 + ('?link=' + link), model);
				} else {
					return A3($author$project$Page$Partial$Kunstwerken$update, $author$project$Page$Kunstwerken$Kunstwerken, msg_, model);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Login$GotRefdata = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Login$GotToken = function (a) {
	return {$: 4, a: a};
};
var $author$project$Data$Login$Login = F2(
	function (email, wachtwoord) {
		return {ef: email, hh: wachtwoord};
	});
var $author$project$Data$Password$Password = F3(
	function (email, token, wachtwoord) {
		return {ef: email, gX: token, hh: wachtwoord};
	});
var $author$project$Page$Login$PasswordChangeResponse = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Login$PasswordRequestAction = 1;
var $author$project$Page$Login$PasswordRequestResponse = function (a) {
	return {$: 6, a: a};
};
var $author$project$Data$Refdata$encodeBrugsoort = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Refdata$encodeLand = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(record.dW)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'sort',
				$elm$json$Json$Encode$int(record.gz))
			]));
};
var $author$project$Data$Refdata$encodeProvincie = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(record.dW)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Refdata$encodeSluissoort = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Refdata$encodeStatusGroep = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Refdata$encodeStatus = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'groep',
				$author$project$Data$Refdata$encodeStatusGroep(record.eC)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'volgorde',
				$elm$json$Json$Encode$int(record.hd))
			]));
};
var $author$project$Data$Refdata$encodeStatusByGroep = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'statussen',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeStatus, record.cE))
			]));
};
var $author$project$Data$Toewijzing$encodeToewijzingToewijzing = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'beschrijving',
				$elm$json$Json$Encode$string(record.bS)),
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(record.dW)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Refdata$encodeRefdata = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'brugsoorten',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeBrugsoort, record.dP)),
				_Utils_Tuple2(
				'landen',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeLand, record.e6)),
				_Utils_Tuple2(
				'provincies',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeProvincie, record.f2)),
				_Utils_Tuple2(
				'sluissoorten',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeSluissoort, record.gy)),
				_Utils_Tuple2(
				'statussen',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeStatus, record.cE)),
				_Utils_Tuple2(
				'statussenByGroep',
				A2($elm$json$Json$Encode$list, $author$project$Data$Refdata$encodeStatusByGroep, record.gD)),
				_Utils_Tuple2(
				'toewijzingstypen',
				A2($elm$json$Json$Encode$list, $author$project$Data$Toewijzing$encodeToewijzingToewijzing, record.gW))
			]));
};
var $author$project$Api$Refdata = {$: 4};
var $author$project$Api$getRefdata = F2(
	function (msg, session) {
		return A4($author$project$Api$get, msg, session, $author$project$Api$Refdata, $author$project$Data$Refdata$decodeRefdata);
	});
var $author$project$Route$homeString = '/anwbwa/app';
var $author$project$Route$parseString = function (urlString) {
	var _v0 = $elm$url$Url$fromString(urlString);
	if (_v0.$ === 1) {
		return $author$project$Route$Home;
	} else {
		var url = _v0.a;
		return $author$project$Route$parse(url);
	}
};
var $author$project$Api$Login = {$: 1};
var $author$project$Data$Login$Token = function (token) {
	return {gX: token};
};
var $author$project$Data$Login$decodeToken = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Data$Login$Token));
var $author$project$Data$Login$encodeLogin = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(record.ef)),
				_Utils_Tuple2(
				'wachtwoord',
				$elm$json$Json$Encode$string(record.hh))
			]));
};
var $author$project$Api$postLogin = F3(
	function (msg, login, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$Login,
			$author$project$Data$Login$decodeToken,
			$author$project$Data$Login$encodeLogin(login));
	});
var $author$project$Api$PasswordChange = {$: 3};
var $author$project$Api$GenericResponse = function (message) {
	return {fu: message};
};
var $author$project$Api$decodeGenericResponse = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$GenericResponse));
var $author$project$Data$Password$encodePassword = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(record.ef)),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(record.gX)),
				_Utils_Tuple2(
				'wachtwoord',
				$elm$json$Json$Encode$string(record.hh))
			]));
};
var $author$project$Api$postPasswordChange = F3(
	function (msg, password, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$PasswordChange,
			$author$project$Api$decodeGenericResponse,
			$author$project$Data$Password$encodePassword(password));
	});
var $author$project$Api$PasswordRequest = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$postPasswordRequest = F3(
	function (msg, email, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$PasswordRequest(email),
			$author$project$Api$decodeGenericResponse,
			$elm$json$Json$Encode$null);
	});
var $author$project$Page$Login$updateField = F3(
	function (field, value, model) {
		switch (field) {
			case 'email':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ef: value}),
					$elm$core$Platform$Cmd$none);
			case 'password':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aZ: value}),
					$elm$core$Platform$Cmd$none);
			case 'password2':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cv: value}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		var session = model.W;
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Login$Mdc, msg_, model);
			case 2:
				var msg_ = msg.a;
				_v1$3:
				while (true) {
					switch (msg_.$) {
						case 4:
							if (msg_.a === 'home') {
								return A2($author$project$Session$pushUrl, '/', model);
							} else {
								break _v1$3;
							}
						case 3:
							switch (msg_.a) {
								case 'send':
									return _Utils_Tuple2(
										A2($author$project$Session$progress, true, model),
										function () {
											var _v2 = model.cL;
											switch (_v2) {
												case 0:
													return A3(
														$author$project$Api$postLogin,
														$author$project$Page$Login$GotToken,
														A2($author$project$Data$Login$Login, model.ef, model.aZ),
														session);
												case 1:
													return A3($author$project$Api$postPasswordRequest, $author$project$Page$Login$PasswordRequestResponse, model.ef, session);
												default:
													return A3(
														$author$project$Api$postPasswordChange,
														$author$project$Page$Login$PasswordChangeResponse,
														A3($author$project$Data$Password$Password, model.ef, model.gX, model.aZ),
														session);
											}
										}());
								case 'edit':
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{cL: 1}),
										$elm$core$Platform$Cmd$none);
								default:
									break _v1$3;
							}
						default:
							break _v1$3;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Login$Session, msg_, model);
			case 3:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var field = msg_.a;
					var value = msg_.b;
					return A3($author$project$Page$Login$updateField, field, value, model);
				} else {
					return A3($author$project$Field$update, $author$project$Page$Login$Field, msg_, model);
				}
			case 4:
				var result = msg.a;
				if (!result.$) {
					var token = result.a;
					var _v5 = A2($author$project$Session$token, token.gX, model);
					var model_ = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						model_,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									A2($author$project$Api$getRefdata, $author$project$Page$Login$GotRefdata, model_.W)
								])));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'inloggen', model),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var result = msg.a;
				if (!result.$) {
					var refdata = result.a;
					var url = function () {
						var _v8 = $author$project$Route$parseString(session.g6);
						if (_v8.$ === 1) {
							return $author$project$Route$homeString;
						} else {
							return session.g6;
						}
					}();
					var _v7 = A2(
						$author$project$Session$pushUrl,
						url,
						_Utils_update(
							model,
							{
								W: _Utils_update(
									session,
									{
										h9: $elm$core$Maybe$Just(refdata)
									})
							}));
					var model_ = _v7.a;
					var cmd = _v7.b;
					return _Utils_Tuple2(
						A2($author$project$Session$progress, false, model_),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									$author$project$Session$store(
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'key',
												$elm$json$Json$Encode$string('refdata')),
												_Utils_Tuple2(
												'value',
												$elm$json$Json$Encode$string(
													A2(
														$elm$json$Json$Encode$encode,
														0,
														$author$project$Data$Refdata$encodeRefdata(refdata))))
											])))
								])));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'ophalen referentiedata', model),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							_Utils_update(
								model,
								{fu: response.fu})),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'nieuw wachtwoord aanvragen', model),
						$elm$core$Platform$Cmd$none);
				}
			case 7:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							_Utils_update(
								model,
								{fu: response.fu})),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'nieuw wachtwoord aanmaken', model),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Toewijzingen$PostMail = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Toewijzingen$PutToewijzingen = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$Mail = function (a) {
	return {$: 10, a: a};
};
var $author$project$Api$postMail = F3(
	function (msg, who, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$Mail(who),
			$author$project$Api$decodeGenericResponse,
			$elm$json$Json$Encode$null);
	});
var $author$project$Data$Toewijzing$encodeToewijzingBeheerder = function (record) {
	if (record.$ === 1) {
		return $elm$json$Json$Encode$null;
	} else {
		var beheerder = record.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(beheerder.ef)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(beheerder.cc)),
					_Utils_Tuple2(
					'naam',
					$elm$json$Json$Encode$string(beheerder.aY))
				]));
	}
};
var $author$project$Data$Toewijzing$encodeToewijzingCoordinaat = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'lat',
				$elm$json$Json$Encode$float(record.fb)),
				_Utils_Tuple2(
				'lon',
				$elm$json$Json$Encode$float(record.fj))
			]));
};
var $author$project$Data$Toewijzing$encodeMaybeString = function (maybeString) {
	if (maybeString.$ === 1) {
		return $elm$json$Json$Encode$null;
	} else {
		var string = maybeString.a;
		return $elm$json$Json$Encode$string(string);
	}
};
var $author$project$Data$Toewijzing$encodeToewijzingFout = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'beschrijving',
				$author$project$Data$Toewijzing$encodeMaybeString(record.bS)),
				_Utils_Tuple2(
				'code',
				$author$project$Data$Toewijzing$encodeMaybeString(record.dW)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$author$project$Data$Toewijzing$encodeMaybeString(record.aY))
			]));
};
var $author$project$Data$Toewijzing$encodeToewijzing = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'anwbID',
				$elm$json$Json$Encode$int(record.dw)),
				_Utils_Tuple2(
				'beheerder',
				$author$project$Data$Toewijzing$encodeToewijzingBeheerder(record.dA)),
				_Utils_Tuple2(
				'coordinaat',
				$author$project$Data$Toewijzing$encodeToewijzingCoordinaat(record.bZ)),
				_Utils_Tuple2(
				'fout',
				$author$project$Data$Toewijzing$encodeToewijzingFout(record.eu)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'toewijzing',
				$author$project$Data$Toewijzing$encodeToewijzingToewijzing(record.gV)),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(record.gX))
			]));
};
var $author$project$Api$putToewijzingen = F3(
	function (toewijzingen, msg, session) {
		return A5(
			$author$project$Api$put,
			msg,
			session,
			$author$project$Api$Toewijzingen,
			$author$project$Data$Toewijzing$list,
			A2($elm$json$Json$Encode$list, $author$project$Data$Toewijzing$encodeToewijzing, toewijzingen));
	});
var $author$project$Page$Toewijzingen$update = F2(
	function (msg, model) {
		var session = model.W;
		var filter = model.b4;
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Toewijzingen$Mdc, msg_, model);
			case 2:
				var msg_ = msg.a;
				_v1$3:
				while (true) {
					switch (msg_.$) {
						case 4:
							if (msg_.a === 'arrow_back') {
								return A2($author$project$Session$back, 1, model);
							} else {
								break _v1$3;
							}
						case 3:
							if (msg_.a === 'more_vert') {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{p: !model.p}),
									$elm$core$Platform$Cmd$none);
							} else {
								break _v1$3;
							}
						case 5:
							if (msg_.a === 'more_vert') {
								var index = msg_.b;
								return _Utils_Tuple2(
									A2(
										$author$project$Session$progress,
										true,
										_Utils_update(
											model,
											{p: false})),
									A3($author$project$Api$postMail, $author$project$Page$Toewijzingen$PostMail, index, session));
							} else {
								break _v1$3;
							}
						default:
							break _v1$3;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Toewijzingen$Session, msg_, model);
			case 3:
				var msg_ = msg.a;
				_v2$2:
				while (true) {
					switch (msg_.$) {
						case 5:
							var toewijzing = msg_.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										d5: true,
										am: $elm$core$Maybe$Just(toewijzing)
									}),
								$elm$core$Platform$Cmd$none);
						case 6:
							if ((msg_.a === 'pick') && (msg_.b === 'all')) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{d5: true}),
									$elm$core$Platform$Cmd$none);
							} else {
								break _v2$2;
							}
						default:
							break _v2$2;
					}
				}
				return A2(
					$elm$core$Tuple$mapFirst,
					function (_v3) {
						var top = _v3.a;
						var filter_ = _v3.b;
						return _Utils_update(
							top,
							{b4: filter_});
					},
					A3(
						$author$project$Page$Partial$Filter$update,
						$author$project$Page$Toewijzingen$Filter,
						msg_,
						_Utils_Tuple2(model, filter)));
			case 5:
				var naam = msg.a;
				var model_ = _Utils_update(
					model,
					{d5: false, am: $elm$core$Maybe$Nothing});
				var maybeToewijzingstype = function () {
					var _v6 = session.h9;
					if (_v6.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var refdata = _v6.a;
						return $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (item) {
									return _Utils_eq(item.aY, naam);
								},
								refdata.gW));
					}
				}();
				if (maybeToewijzingstype.$ === 1) {
					return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
				} else {
					var toewijzingstype = maybeToewijzingstype.a;
					var selected = function () {
						var _v5 = model.am;
						if (_v5.$ === 1) {
							return model.b4.by;
						} else {
							var toewijzing = _v5.a;
							return _List_fromArray(
								[toewijzing]);
						}
					}();
					var unchanged = A2(
						$elm$core$List$all,
						function (item) {
							return _Utils_eq(item.gV.cc, toewijzingstype.cc);
						},
						selected);
					if (unchanged) {
						return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
					} else {
						var updated = A2(
							$elm$core$List$map,
							function (item) {
								return _Utils_update(
									item,
									{gV: toewijzingstype});
							},
							selected);
						return _Utils_Tuple2(
							A2($author$project$Session$progress, true, model_),
							A3($author$project$Api$putToewijzingen, updated, $author$project$Page$Toewijzingen$PutToewijzingen, session));
					}
				}
			case 6:
				var result = msg.a;
				if (!result.$) {
					var updates = result.a;
					var items = A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								i,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (u) {
											return _Utils_eq(i.cc, u.cc);
										},
										updates)));
						},
						filter.bo);
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							_Utils_update(
								model,
								{
									b4: $author$project$Page$Partial$Filter$select(
										_Utils_update(
											filter,
											{bo: items}))
								})),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$progress,
							false,
							A3($author$project$Session$error, err, 'bijwerken toewijzingen', model)),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d5: false, am: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var result = msg.a;
				var model_ = A2($author$project$Session$progress, false, model);
				if (!result.$) {
					var response = result.a;
					return A3($author$project$Session$toast, $author$project$Page$Toewijzingen$Session, response.fu, model_);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'verzenden e-mail', model_),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Vaarweg$Pdf = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Vaarweg$PutVaarweg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Vaarweg$addBeheerder = F2(
	function (beheerder, vaarweg) {
		var beheerders = vaarweg.dC;
		return _Utils_update(
			vaarweg,
			{
				dC: A2($elm$core$List$cons, beheerder, beheerders)
			});
	});
var $author$project$Page$Vaarweg$addKunstwerk = F2(
	function (kunstwerk, vaarweg) {
		var kunstwerken = vaarweg.e1;
		return _Utils_update(
			vaarweg,
			{
				e1: A2($elm$core$List$cons, kunstwerk, kunstwerken)
			});
	});
var $author$project$Page$Vaarweg$delLink = F2(
	function (link_, list) {
		return A2(
			$elm$core$List$filter,
			function (_v0) {
				var link = _v0.cn;
				return !_Utils_eq(link, link_);
			},
			list);
	});
var $author$project$Page$Vaarweg$beheerderDel = F2(
	function (link, vaarweg) {
		var beheerders = vaarweg.dC;
		return _Utils_update(
			vaarweg,
			{
				dC: A2($author$project$Page$Vaarweg$delLink, link, beheerders)
			});
	});
var $author$project$Page$Vaarweg$editor = _Platform_outgoingPort(
	'editor',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Api$Beheerders = {$: 8};
var $author$project$Data$Beheerder$list = $elm$json$Json$Decode$list($author$project$Data$Beheerder$decodeBeheerderKort);
var $author$project$Api$getBeheerders = F2(
	function (msg, session) {
		return A4($author$project$Api$get, msg, session, $author$project$Api$Beheerders, $author$project$Data$Beheerder$list);
	});
var $author$project$Page$Partial$Beheerders$get = F2(
	function (lift, container) {
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A3($author$project$Page$Partial$Filter$get, $elm$core$Basics$identity, $author$project$Api$getBeheerders, container));
	});
var $author$project$Page$Vaarweg$fieldModel = function (maybeVaarweg) {
	if (maybeVaarweg.$ === 1) {
		return $author$project$Field$defaultModel(_List_Nil);
	} else {
		var details = maybeVaarweg.a.d4;
		return $author$project$Field$defaultModel(
			_List_fromArray(
				[
					A3($author$project$Page$simpleTextfield, true, 'Vaarwegtitel', 'naam'),
					A3($author$project$Page$simpleTextfield, true, 'Algemene tekst', 'algemeen'),
					A3($author$project$Page$simpleTextfield, false, 'Opmerkingen voor beheer', 'opmerkingen')
				]));
	}
};
var $author$project$Page$Vaarweg$initVaarweg = F2(
	function (vaarweg, model) {
		return A2(
			$author$project$Session$progress,
			false,
			_Utils_update(
				model,
				{
					cY: $author$project$Page$Vaarweg$fieldModel(
						$elm$core$Maybe$Just(vaarweg)),
					g7: $elm$core$Maybe$Just(vaarweg)
				}));
	});
var $author$project$Page$Vaarweg$kunstwerkDel = F2(
	function (link, vaarweg) {
		var kunstwerken = vaarweg.e1;
		return _Utils_update(
			vaarweg,
			{
				e1: A2($author$project$Page$Vaarweg$delLink, link, kunstwerken)
			});
	});
var $author$project$Data$Beheerder$encodeBeheerderKort = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'woonplaats',
				$elm$json$Json$Encode$string(record.cK)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Vaarweg$encodeDetailDetail = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'waterwegID',
				$elm$json$Json$Encode$int(record.hl)),
				_Utils_Tuple2(
				'labelID',
				$elm$json$Json$Encode$int(record.e4)),
				_Utils_Tuple2(
				'omschrijving',
				$elm$json$Json$Encode$string(record.fB)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn))
			]));
};
var $author$project$Data$Vaarweg$encodeDetail = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'volgorde',
				$elm$json$Json$Encode$int(record.hd)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'bewaar',
				$elm$json$Json$Encode$bool(record.dE)),
				_Utils_Tuple2(
				'detail',
				$author$project$Data$Vaarweg$encodeDetailDetail(record.d3))
			]));
};
var $author$project$Data$Kunstwerk$encodeKunstwerkKort = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'anwbBrugNummer',
				$elm$json$Json$Encode$string(record.bP)),
				_Utils_Tuple2(
				'coordinaat',
				$author$project$Data$Kunstwerk$encodeKunstwerkCoordinaat(record.bZ)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'kunstwerkType',
				$elm$json$Json$Encode$string(record.cj)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'vaarweg',
				$elm$json$Json$Encode$string(record.g7))
			]));
};
var $author$project$Data$Vaarweg$encodeVaarwegKaart = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'hoogte',
				$elm$json$Json$Encode$int(record.eJ)),
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(record.g6)),
				_Utils_Tuple2(
				'jpg',
				$elm$json$Json$Encode$string(record.eV)),
				_Utils_Tuple2(
				'legenda',
				$elm$json$Json$Encode$string(record.fd))
			]));
};
var $author$project$Data$Vaarweg$encodeVaarweg = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'algemeen',
				$elm$json$Json$Encode$string(record.dv)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'land',
				$elm$json$Json$Encode$string(record.e5)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'opmerkingen',
				$elm$json$Json$Encode$string(record.fG)),
				_Utils_Tuple2(
				'verwijderd',
				$elm$json$Json$Encode$bool(record.ha)),
				_Utils_Tuple2(
				'kaart',
				$author$project$Data$Vaarweg$encodeVaarwegKaart(record.eW)),
				_Utils_Tuple2(
				'details',
				A2($elm$json$Json$Encode$list, $author$project$Data$Vaarweg$encodeDetail, record.d4)),
				_Utils_Tuple2(
				'kunstwerken',
				A2($elm$json$Json$Encode$list, $author$project$Data$Kunstwerk$encodeKunstwerkKort, record.e1)),
				_Utils_Tuple2(
				'beheerders',
				A2($elm$json$Json$Encode$list, $author$project$Data$Beheerder$encodeBeheerderKort, record.dC)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'pdf',
				$elm$json$Json$Encode$string(record.fM))
			]));
};
var $author$project$Api$putVaarweg = F3(
	function (msg, vaarweg, session) {
		return A5(
			$author$project$Api$put,
			msg,
			session,
			$author$project$Api$Vaarwegen,
			$author$project$Data$Vaarweg$decodeVaarweg,
			$author$project$Data$Vaarweg$encodeVaarweg(vaarweg));
	});
var $author$project$Page$Vaarweg$toggleDetail = F2(
	function (link, vaarweg) {
		var details = vaarweg.d4;
		return _Utils_update(
			vaarweg,
			{
				d4: A2(
					$elm$core$List$map,
					function (detail) {
						return _Utils_eq(detail.cn, link) ? _Utils_update(
							detail,
							{dE: !detail.dE}) : detail;
					},
					details)
			});
	});
var $author$project$Page$Partial$Beheerders$update = F3(
	function (lift, m, container) {
		var session = container.W;
		var beheerders = container.dC;
		var msg = m;
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (_v1) {
					var top = _v1.a;
					var filter = _v1.b;
					return _Utils_update(
						top,
						{
							dC: {b4: filter}
						});
				},
				A3(
					$author$project$Page$Partial$Filter$update,
					$elm$core$Basics$identity,
					msg,
					_Utils_Tuple2(container, beheerders.b4))));
	});
var $author$project$Page$Vaarweg$updateField = F3(
	function (field, value, vaarweg) {
		var details = vaarweg.d4;
		var kaart = vaarweg.eW;
		switch (field) {
			case 'naam':
				return _Utils_update(
					vaarweg,
					{aY: value});
			case 'algemeen':
				return _Utils_update(
					vaarweg,
					{dv: value});
			case 'opmerkingen':
				return _Utils_update(
					vaarweg,
					{fG: value});
			case 'kaart.legenda':
				return _Utils_update(
					vaarweg,
					{
						eW: _Utils_update(
							kaart,
							{fd: value})
					});
			default:
				var _v1 = A2($elm$core$String$split, '.', field);
				if (((_v1.b && (_v1.a === 'omschrijving')) && _v1.b.b) && (!_v1.b.b.b)) {
					var _v2 = _v1.b;
					var link = _v2.a;
					var setOmschrijving = function (container) {
						var detail = container.d3;
						return (!_Utils_eq(container.cn, link)) ? container : _Utils_update(
							container,
							{
								d3: _Utils_update(
									detail,
									{fB: value})
							});
					};
					return _Utils_update(
						vaarweg,
						{
							d4: A2($elm$core$List$map, setOmschrijving, details)
						});
				} else {
					return vaarweg;
				}
		}
	});
var $author$project$Page$Vaarweg$vw = F2(
	function (updateVaarweg, model) {
		var _v0 = model.g7;
		if (_v0.$ === 1) {
			return model;
		} else {
			var vaarweg = _v0.a;
			return _Utils_update(
				model,
				{
					g7: $elm$core$Maybe$Just(
						updateVaarweg(vaarweg))
				});
		}
	});
var $author$project$Page$Vaarweg$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Vaarweg$Mdc, msg_, model);
			case 2:
				var msg_ = msg.a;
				_v1$4:
				while (true) {
					switch (msg_.$) {
						case 4:
							switch (msg_.a) {
								case 'close':
									return _Utils_Tuple2(
										model.bR ? _Utils_update(
											model,
											{bR: false}) : _Utils_update(
											model,
											{aO: false, aW: false}),
										$elm$core$Platform$Cmd$none);
								case 'arrow_back':
									return A2($author$project$Session$back, 1, model);
								default:
									break _v1$4;
							}
						case 3:
							switch (msg_.a) {
								case 'print':
									var _v2 = model.g7;
									if (_v2.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										var vaarweg = _v2.a;
										return _Utils_Tuple2(
											A2($author$project$Session$progress, true, model),
											A3(
												$author$project$Api$file,
												$author$project$Page$Vaarweg$Pdf,
												'/api/pdf/vaarweg/' + $elm$core$String$fromInt(vaarweg.cc),
												model.W));
									}
								case 'save':
									var _v3 = model.g7;
									if (_v3.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										var vaarweg = _v3.a;
										return _Utils_Tuple2(
											A2($author$project$Session$progress, true, model),
											A3($author$project$Api$putVaarweg, $author$project$Page$Vaarweg$PutVaarweg, vaarweg, model.W));
									}
								default:
									break _v1$4;
							}
						default:
							break _v1$4;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Vaarweg$Session, msg_, model);
			case 3:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var field = msg_.a;
					var value = msg_.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							true,
							A2(
								$author$project$Page$Vaarweg$vw,
								A2($author$project$Page$Vaarweg$updateField, field, value),
								model)),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Field$update, $author$project$Page$Vaarweg$Field, msg_, model);
				}
			case 4:
				var msg_ = msg.a;
				if (msg_.$ === 5) {
					var kunstwerk = msg_.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							true,
							A2(
								$author$project$Page$Vaarweg$vw,
								$author$project$Page$Vaarweg$addKunstwerk(kunstwerk),
								_Utils_update(
									model,
									{aW: false}))),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Page$Partial$Kunstwerken$update, $author$project$Page$Vaarweg$Kunstwerken, msg_, model);
				}
			case 7:
				var msg_ = msg.a;
				if (msg_.$ === 5) {
					var beheerder = msg_.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							true,
							A2(
								$author$project$Page$Vaarweg$vw,
								$author$project$Page$Vaarweg$addBeheerder(beheerder),
								_Utils_update(
									model,
									{aO: false}))),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Page$Partial$Beheerders$update, $author$project$Page$Vaarweg$Beheerders, msg_, model);
				}
			case 5:
				return A2(
					$author$project$Page$Partial$Kunstwerken$get,
					$author$project$Page$Vaarweg$Kunstwerken,
					_Utils_update(
						model,
						{aW: true}));
			case 6:
				var link = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Vaarweg$vw,
							$author$project$Page$Vaarweg$kunstwerkDel(link),
							model)),
					$elm$core$Platform$Cmd$none);
			case 8:
				return A2(
					$author$project$Page$Partial$Beheerders$get,
					$author$project$Page$Vaarweg$Beheerders,
					_Utils_update(
						model,
						{aO: true}));
			case 9:
				var link = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Vaarweg$vw,
							$author$project$Page$Vaarweg$beheerderDel(link),
							model)),
					$elm$core$Platform$Cmd$none);
			case 10:
				var result = msg.a;
				if (!result.$) {
					var bytes = result.a;
					return _Utils_Tuple2(
						A2($author$project$Session$progress, false, model),
						A3($author$project$Api$download, 'Afdrukvoorbeeld.pdf', 'application/pdf', bytes));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Session$error,
							err,
							'ophalen afdrukvoorbeeld',
							A2($author$project$Session$progress, false, model)),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var result = msg.a;
				if (!result.$) {
					var vaarweg = result.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							false,
							A2($author$project$Page$Vaarweg$initVaarweg, vaarweg, model)),
						$author$project$Page$Vaarweg$editor(0));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'ophalen vaarweggegevens', model),
						$elm$core$Platform$Cmd$none);
				}
			case 12:
				var result = msg.a;
				if (!result.$) {
					var vaarweg = result.a;
					return A3(
						$author$project$Session$toast,
						$author$project$Page$Vaarweg$Session,
						'Opgeslagen',
						A2(
							$author$project$Session$dirty,
							false,
							A2($author$project$Page$Vaarweg$initVaarweg, vaarweg, model)));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'bewaren vaarweggegevens', model),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var result = msg.a;
				if (!result.$) {
					var vaarweg = result.a;
					return A2(
						$author$project$Session$replaceUrl,
						'/anwbwa/app/vaarweg?link=' + vaarweg.cn,
						A2($author$project$Page$Vaarweg$initVaarweg, vaarweg, model));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'aanmaken nieuwe vaarweg', model),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var link = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Vaarweg$vw,
							$author$project$Page$Vaarweg$toggleDetail(link),
							model)),
					$author$project$Page$Vaarweg$editor(0));
			case 15:
				var code = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Vaarweg$vw,
							function (vaarweg) {
								return _Utils_update(
									vaarweg,
									{e5: code});
							},
							model)),
					$elm$core$Platform$Cmd$none);
			case 16:
				var _v11 = msg.a;
				var field = _v11.a;
				var value = _v11.b;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Vaarweg$vw,
							A2($author$project$Page$Vaarweg$updateField, field, value),
							model)),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Partial$Vaarwegen$update = F3(
	function (lift, m, container) {
		var session = container.W;
		var vaarwegen = container.g8;
		var msg = m;
		return A2(
			$elm$core$Tuple$mapSecond,
			function (cmd) {
				return A2($elm$core$Platform$Cmd$map, lift, cmd);
			},
			A2(
				$elm$core$Tuple$mapFirst,
				function (_v1) {
					var top = _v1.a;
					var filter = _v1.b;
					return _Utils_update(
						top,
						{
							g8: {b4: filter}
						});
				},
				A3(
					$author$project$Page$Partial$Filter$update,
					$elm$core$Basics$identity,
					msg,
					_Utils_Tuple2(container, vaarwegen.b4))));
	});
var $author$project$Page$Vaarwegen$update = F2(
	function (msg, model) {
		var session = model.W;
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				_v1$2:
				while (true) {
					switch (msg_.$) {
						case 4:
							if (msg_.a === 'arrow_back') {
								return A2($author$project$Session$back, 1, model);
							} else {
								break _v1$2;
							}
						case 3:
							if (msg_.a === 'add') {
								return A2($author$project$Session$pushUrl, session.g6 + '?link=', model);
							} else {
								break _v1$2;
							}
						default:
							break _v1$2;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Vaarwegen$Session, msg_, model);
			case 2:
				var msg_ = msg.a;
				if (msg_.$ === 5) {
					var link = msg_.a.cn;
					return A2($author$project$Session$pushUrl, session.g6 + ('?link=' + link), model);
				} else {
					return A3($author$project$Page$Partial$Vaarwegen$update, $author$project$Page$Vaarwegen$Vaarwegen, msg_, model);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Voorziening$DeleteVoorziening = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Voorziening$Pdf = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Page$Voorziening$PostStatus = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Voorziening$addVaarweg = F2(
	function (vaarweg, voorziening) {
		var vaarwegen = voorziening.g8;
		return _Utils_update(
			voorziening,
			{
				g8: A2($elm$core$List$cons, vaarweg, vaarwegen)
			});
	});
var $author$project$Api$deleteVoorziening = F3(
	function (msg, link, session) {
		return A3(
			$author$project$Api$delete,
			msg,
			session,
			$author$project$Api$Base(link));
	});
var $author$project$Session$flag = F2(
	function (item, model) {
		var _v0 = A2($elm$core$Dict$get, item, model.c_);
		if (_v0.$ === 1) {
			return '';
		} else {
			var string = _v0.a;
			return string;
		}
	});
var $author$project$Api$VoorzieningStatus = function (a) {
	return {$: 11, a: a};
};
var $author$project$Api$postVoorzieningStatus = F4(
	function (msg, id, status, session) {
		return A5(
			$author$project$Api$post,
			msg,
			session,
			$author$project$Api$VoorzieningStatus(id),
			$author$project$Data$Refdata$decodeStatus,
			$author$project$Data$Refdata$encodeStatus(status));
	});
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $author$project$Data$Voorziening$encodeVoorzieningBedrijfDataBootverhuurder = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'motorjacht',
				$elm$json$Json$Encode$bool(record.fy)),
				_Utils_Tuple2(
				'zeiljacht',
				$elm$json$Json$Encode$bool(record.hs)),
				_Utils_Tuple2(
				'openZeilboot',
				$elm$json$Json$Encode$bool(record.fC)),
				_Utils_Tuple2(
				'sloep',
				$elm$json$Json$Encode$bool(record.gw)),
				_Utils_Tuple2(
				'kano',
				$elm$json$Json$Encode$bool(record.eY))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningBedrijfData = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bootverhuurder',
				$author$project$Data$Voorziening$encodeVoorzieningBedrijfDataBootverhuurder(record.dK))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningBedrijfFax = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'abonnee',
				$elm$json$Json$Encode$string(record.bL)),
				_Utils_Tuple2(
				'net',
				$elm$json$Json$Encode$string(record.cq))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningBedrijfTelefoon = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'abonnee',
				$elm$json$Json$Encode$string(record.bL)),
				_Utils_Tuple2(
				'net',
				$elm$json$Json$Encode$string(record.cq))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningBedrijf = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'adres',
				$elm$json$Json$Encode$string(record.dr)),
				_Utils_Tuple2(
				'categorieen',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$bool, record.bW)),
				_Utils_Tuple2(
				'data',
				$author$project$Data$Voorziening$encodeVoorzieningBedrijfData(record.ae)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(record.ef)),
				_Utils_Tuple2(
				'fax',
				$author$project$Data$Voorziening$encodeVoorzieningBedrijfFax(record.el)),
				_Utils_Tuple2(
				'hiswaLid',
				$elm$json$Json$Encode$bool(record.eI)),
				_Utils_Tuple2(
				'land',
				$elm$json$Json$Encode$string(record.e5)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'postcode',
				$elm$json$Json$Encode$string(record.fV)),
				_Utils_Tuple2(
				'provincie',
				$elm$json$Json$Encode$int(record.f1)),
				_Utils_Tuple2(
				'tav',
				$elm$json$Json$Encode$string(record.gM)),
				_Utils_Tuple2(
				'telefoon',
				$author$project$Data$Voorziening$encodeVoorzieningBedrijfTelefoon(record.gO)),
				_Utils_Tuple2(
				'web',
				$elm$json$Json$Encode$string(record.hm)),
				_Utils_Tuple2(
				'woonplaats',
				$elm$json$Json$Encode$string(record.cK))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningConsul = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bezoek',
				$elm$json$Json$Encode$string(record.dG)),
				_Utils_Tuple2(
				'kring',
				$elm$json$Json$Encode$string(record.e$)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'provincie',
				$elm$json$Json$Encode$int(record.f1))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenTelefoonHavenkantoor = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'abonnee',
				$elm$json$Json$Encode$string(record.bL)),
				_Utils_Tuple2(
				'net',
				$elm$json$Json$Encode$string(record.cq))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenTelefoonHavenmeester = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'abonnee',
				$elm$json$Json$Encode$string(record.bL)),
				_Utils_Tuple2(
				'net',
				$elm$json$Json$Encode$string(record.cq))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenAlgemeenDataVuilwatertank = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'kosten',
				$elm$json$Json$Encode$float(record.ch))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenAlgemeenData = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'vuilwatertank',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenAlgemeenDataVuilwatertank(record.hg))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenAlgemeen = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'aanwezig',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$bool, record.aJ)),
				_Utils_Tuple2(
				'data',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenAlgemeenData(record.ae))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataBrandstof = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'diesel',
				$elm$json$Json$Encode$bool(record.d7)),
				_Utils_Tuple2(
				'benzine',
				$elm$json$Json$Encode$bool(record.dD)),
				_Utils_Tuple2(
				'gtl',
				$elm$json$Json$Encode$bool(record.eD))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataDrinkwater = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'kosten',
				$elm$json$Json$Encode$float(record.ch))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataLigplaatsen = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'aantal',
				$elm$json$Json$Encode$int(record.bK)),
				_Utils_Tuple2(
				'vrij',
				$elm$json$Json$Encode$int(record.hf))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataPassantenligplaatsen = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'aantal',
				$elm$json$Json$Encode$int(record.bK)),
				_Utils_Tuple2(
				'afwijkendTarief',
				$elm$json$Json$Encode$string(record.du)),
				_Utils_Tuple2(
				'diepgangMax',
				$elm$json$Json$Encode$float(record.d6)),
				_Utils_Tuple2(
				'lengteMax',
				$elm$json$Json$Encode$float(record.fe)),
				_Utils_Tuple2(
				'maxBreedte',
				$elm$json$Json$Encode$float(record.fm)),
				_Utils_Tuple2(
				'maxVerblijf',
				$elm$json$Json$Encode$int(record.ft)),
				_Utils_Tuple2(
				'tarief',
				$elm$json$Json$Encode$float(record.w)),
				_Utils_Tuple2(
				'tariefPassantAanvraag',
				$elm$json$Json$Encode$bool(record.gL))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairData = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'brandstof',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataBrandstof(record.dN)),
				_Utils_Tuple2(
				'drinkwater',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataDrinkwater(record.eb)),
				_Utils_Tuple2(
				'ligplaatsen',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataLigplaatsen(record.fh)),
				_Utils_Tuple2(
				'passantenligplaatsen',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairDataPassantenligplaatsen(record.fK))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimair = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'aanwezig',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$bool, record.aJ)),
				_Utils_Tuple2(
				'data',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimairData(record.ae))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataBotenlift = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'mast',
				$elm$json$Json$Encode$bool(record.co)),
				_Utils_Tuple2(
				'maxDiepgang',
				$elm$json$Json$Encode$float(record.aj)),
				_Utils_Tuple2(
				'maxGewicht',
				$elm$json$Json$Encode$int(record.K)),
				_Utils_Tuple2(
				'tarief',
				$elm$json$Json$Encode$float(record.w))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataHefkraan = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'mast',
				$elm$json$Json$Encode$bool(record.co)),
				_Utils_Tuple2(
				'maxDiepgang',
				$elm$json$Json$Encode$float(record.aj)),
				_Utils_Tuple2(
				'maxGewicht',
				$elm$json$Json$Encode$int(record.K)),
				_Utils_Tuple2(
				'tarief',
				$elm$json$Json$Encode$float(record.w))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataMastenkraan = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'maxGewicht',
				$elm$json$Json$Encode$int(record.K)),
				_Utils_Tuple2(
				'maxLengte',
				$elm$json$Json$Encode$float(record.fq)),
				_Utils_Tuple2(
				'tarief',
				$elm$json$Json$Encode$float(record.w))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataScheepshelling = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'maxDiepgang',
				$elm$json$Json$Encode$float(record.aj)),
				_Utils_Tuple2(
				'maxGewicht',
				$elm$json$Json$Encode$int(record.K)),
				_Utils_Tuple2(
				'tarief',
				$elm$json$Json$Encode$float(record.w))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataTrailerhelling = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'maxDiepgang',
				$elm$json$Json$Encode$float(record.aj)),
				_Utils_Tuple2(
				'maxGewicht',
				$elm$json$Json$Encode$int(record.K)),
				_Utils_Tuple2(
				'tarief',
				$elm$json$Json$Encode$float(record.w))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischData = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'botenlift',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataBotenlift(record.dL)),
				_Utils_Tuple2(
				'hefkraan',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataHefkraan(record.eG)),
				_Utils_Tuple2(
				'mastenkraan',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataMastenkraan(record.fl)),
				_Utils_Tuple2(
				'scheepshelling',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataScheepshelling(record.gl)),
				_Utils_Tuple2(
				'trailerhelling',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischDataTrailerhelling(record.g3))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnisch = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'aanwezig',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$bool, record.aJ)),
				_Utils_Tuple2(
				'data',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnischData(record.ae))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningen = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'algemeen',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenAlgemeen(record.dv)),
				_Utils_Tuple2(
				'primair',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenPrimair(record.fY)),
				_Utils_Tuple2(
				'technisch',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningenTechnisch(record.gN))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningJachthaven = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bijzHaveningang',
				$elm$json$Json$Encode$string(record.dH)),
				_Utils_Tuple2(
				'blauweVlag',
				$elm$json$Json$Encode$bool(record.dJ)),
				_Utils_Tuple2(
				'havenmeester',
				$elm$json$Json$Encode$string(record.eE)),
				_Utils_Tuple2(
				'kanaalMarifoon',
				$elm$json$Json$Encode$int(record.eX)),
				_Utils_Tuple2(
				'ligging',
				$elm$json$Json$Encode$string(record.ff)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY)),
				_Utils_Tuple2(
				'telefoonHavenkantoor',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenTelefoonHavenkantoor(record.gP)),
				_Utils_Tuple2(
				'telefoonHavenmeester',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenTelefoonHavenmeester(record.gQ)),
				_Utils_Tuple2(
				'voorzieningen',
				$author$project$Data$Voorziening$encodeVoorzieningJachthavenVoorzieningen(record.he))
			]));
};
var $author$project$Data$Voorziening$encodeCoordinaat = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'lat',
				$elm$json$Json$Encode$float(record.fb)),
				_Utils_Tuple2(
				'lon',
				$elm$json$Json$Encode$float(record.fj))
			]));
};
var $author$project$Data$Voorziening$encodeVoorzieningLocatie = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'coordinaat',
				$author$project$Data$Voorziening$encodeCoordinaat(record.bZ)),
				_Utils_Tuple2(
				'plaatsAlmanak',
				$elm$json$Json$Encode$string(record.fS)),
				_Utils_Tuple2(
				'waterkaart',
				$elm$json$Json$Encode$string(record.hk))
			]));
};
var $author$project$Data$Vaarweg$encodeVaarwegKort = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'naam',
				$elm$json$Json$Encode$string(record.aY))
			]));
};
var $author$project$Data$Vaarweg$enlist = $elm$json$Json$Encode$list($author$project$Data$Vaarweg$encodeVaarwegKort);
var $author$project$Data$Voorziening$encodeVoorziening = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'aangemaakt',
				$elm$json$Json$Encode$string(record.dp)),
				_Utils_Tuple2(
				'anwbID',
				$elm$json$Json$Encode$int(record.dw)),
				_Utils_Tuple2(
				'bedrijf',
				$author$project$Data$Voorziening$encodeVoorzieningBedrijf(record.dz)),
				_Utils_Tuple2(
				'consul',
				$author$project$Data$Voorziening$encodeVoorzieningConsul(record.d_)),
				_Utils_Tuple2(
				'eigenaar',
				$elm$json$Json$Encode$int(record.ee)),
				_Utils_Tuple2(
				'gebruikerID',
				$elm$json$Json$Encode$int(record.b9)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(record.cc)),
				_Utils_Tuple2(
				'indexNaam',
				$elm$json$Json$Encode$string(record.cd)),
				_Utils_Tuple2(
				'jachthaven',
				$author$project$Data$Voorziening$encodeVoorzieningJachthaven(record.eT)),
				_Utils_Tuple2(
				'lastChanged',
				$elm$json$Json$Encode$string(record.e7)),
				_Utils_Tuple2(
				'lastViewed',
				$elm$json$Json$Encode$string(record.e9)),
				_Utils_Tuple2(
				'link',
				$elm$json$Json$Encode$string(record.cn)),
				_Utils_Tuple2(
				'locatie',
				$author$project$Data$Voorziening$encodeVoorzieningLocatie(record.fi)),
				_Utils_Tuple2(
				'opmerkingen',
				$elm$json$Json$Encode$string(record.fG)),
				_Utils_Tuple2(
				'remarks',
				$elm$json$Json$Encode$string(record.f7)),
				_Utils_Tuple2(
				'vaarwegen',
				$author$project$Data$Vaarweg$enlist(record.g8)),
				_Utils_Tuple2(
				'verwijderd',
				$elm$json$Json$Encode$bool(record.ha))
			]));
};
var $author$project$Api$putVoorziening = F3(
	function (msg, voorziening, session) {
		return A5(
			$author$project$Api$put,
			msg,
			session,
			$author$project$Api$Voorzieningen,
			$author$project$Data$Voorziening$decodeVoorzieningStates,
			$author$project$Data$Voorziening$encodeVoorziening(voorziening));
	});
var $author$project$Page$Voorziening$statusLabel = function (naam) {
	switch (naam) {
		case 'edit':
			return 'In bewerking';
		case 'ready':
			return 'Gereed';
		case 'print':
			return 'Klaar om te printen';
		default:
			return 'Onbekend';
	}
};
var $author$project$Page$Voorziening$doubleDigit = function (string) {
	return ($elm$core$String$length(string) === 1) ? ('0' + string) : string;
};
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.de, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		d1: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		fx: month,
		hr: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).d1;
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fx;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hr;
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Voorziening$timeISOString = function (_v0) {
	var timePosix = _v0.cF;
	return $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $elm$time$Time$utc, timePosix)) + ('-' + (function () {
		var _v1 = A2($elm$time$Time$toMonth, $elm$time$Time$utc, timePosix);
		switch (_v1) {
			case 0:
				return '01';
			case 1:
				return '02';
			case 2:
				return '03';
			case 3:
				return '04';
			case 4:
				return '05';
			case 5:
				return '06';
			case 6:
				return '07';
			case 7:
				return '08';
			case 8:
				return '09';
			case 9:
				return '10';
			case 10:
				return '11';
			default:
				return '12';
		}
	}() + ('-' + ($author$project$Page$Voorziening$doubleDigit(
		$elm$core$String$fromInt(
			A2($elm$time$Time$toDay, $elm$time$Time$utc, timePosix))) + ('T' + ($author$project$Page$Voorziening$doubleDigit(
		$elm$core$String$fromInt(
			A2($elm$time$Time$toHour, $elm$time$Time$utc, timePosix))) + (':' + ($author$project$Page$Voorziening$doubleDigit(
		$elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, $elm$time$Time$utc, timePosix))) + (':' + ($author$project$Page$Voorziening$doubleDigit(
		$elm$core$String$fromInt(
			A2($elm$time$Time$toSecond, $elm$time$Time$utc, timePosix))) + 'Z'))))))))));
};
var $author$project$Page$Voorziening$toggleCategorie = F3(
	function (container, categorie, maybeVoorziening) {
		return A2(
			$elm$core$Maybe$map,
			function (voorziening) {
				var bedrijf = voorziening.dz;
				var jachthaven = voorziening.eT;
				var toggle = function (categorieen) {
					return A3(
						$elm$core$Dict$update,
						categorie,
						function (maybeSelected) {
							return A2(
								$elm$core$Maybe$map,
								function (selected) {
									return !selected;
								},
								maybeSelected);
						},
						categorieen);
				};
				if (container === 'bedrijf') {
					return _Utils_update(
						voorziening,
						{
							dz: _Utils_update(
								bedrijf,
								{
									bW: toggle(bedrijf.bW)
								})
						});
				} else {
					var kind = container;
					var toggleVoorziening = function (voorzieningen) {
						var algemeen = voorzieningen.dv;
						var primair = voorzieningen.fY;
						var technisch = voorzieningen.gN;
						switch (kind) {
							case 'algemeen':
								return _Utils_update(
									voorzieningen,
									{
										dv: _Utils_update(
											algemeen,
											{
												aJ: toggle(algemeen.aJ)
											})
									});
							case 'primair':
								return _Utils_update(
									voorzieningen,
									{
										fY: _Utils_update(
											primair,
											{
												aJ: toggle(primair.aJ)
											})
									});
							case 'technisch':
								return _Utils_update(
									voorzieningen,
									{
										gN: _Utils_update(
											technisch,
											{
												aJ: toggle(technisch.aJ)
											})
									});
							default:
								return voorzieningen;
						}
					};
					return _Utils_update(
						voorziening,
						{
							eT: _Utils_update(
								jachthaven,
								{
									he: toggleVoorziening(jachthaven.he)
								})
						});
				}
			},
			maybeVoorziening);
	});
var $author$project$Page$Voorziening$toggleCategorieField = F3(
	function (categorie, field, data) {
		var bootverhuurder = data.dK;
		if (categorie === 'bootverhuurder') {
			return _Utils_update(
				data,
				{
					dK: function () {
						switch (field) {
							case 'motorjacht':
								return _Utils_update(
									bootverhuurder,
									{fy: !bootverhuurder.fy});
							case 'zeiljacht':
								return _Utils_update(
									bootverhuurder,
									{hs: !bootverhuurder.hs});
							case 'openZeilboot':
								return _Utils_update(
									bootverhuurder,
									{fC: !bootverhuurder.fC});
							case 'sloep':
								return _Utils_update(
									bootverhuurder,
									{gw: !bootverhuurder.gw});
							case 'kano':
								return _Utils_update(
									bootverhuurder,
									{eY: !bootverhuurder.eY});
							default:
								return bootverhuurder;
						}
					}()
				});
		} else {
			return data;
		}
	});
var $author$project$Page$Voorziening$togglePrimairField = F2(
	function (list, data) {
		var brandstof = data.dN;
		var passantenligplaatsen = data.fK;
		_v0$2:
		while (true) {
			if ((list.b && list.b.b) && (!list.b.b.b)) {
				switch (list.a) {
					case 'brandstof':
						var _v1 = list.b;
						var field = _v1.a;
						return _Utils_update(
							data,
							{
								dN: function () {
									switch (field) {
										case 'diesel':
											return _Utils_update(
												brandstof,
												{d7: !brandstof.d7});
										case 'benzine':
											return _Utils_update(
												brandstof,
												{dD: !brandstof.dD});
										case 'gtl':
											return _Utils_update(
												brandstof,
												{eD: !brandstof.eD});
										default:
											return brandstof;
									}
								}()
							});
					case 'passantenligplaatsen':
						if (list.b.a === 'tariefPassantAanvraag') {
							var _v3 = list.b;
							return _Utils_update(
								data,
								{
									fK: _Utils_update(
										passantenligplaatsen,
										{gL: !passantenligplaatsen.gL})
								});
						} else {
							break _v0$2;
						}
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return data;
	});
var $author$project$Page$Voorziening$toggleTechnischField = F2(
	function (list, data) {
		var botenlift = data.dL;
		var hefkraan = data.eG;
		_v0$2:
		while (true) {
			if (((list.b && list.b.b) && (list.b.a === 'mast')) && (!list.b.b.b)) {
				switch (list.a) {
					case 'botenlift':
						var _v1 = list.b;
						return _Utils_update(
							data,
							{
								dL: _Utils_update(
									botenlift,
									{co: !botenlift.co})
							});
					case 'hefkraan':
						var _v2 = list.b;
						return _Utils_update(
							data,
							{
								eG: _Utils_update(
									hefkraan,
									{co: !hefkraan.co})
							});
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return data;
	});
var $author$project$Page$Voorziening$toggleField = F2(
	function (field, maybeVoorziening) {
		return A2(
			$elm$core$Maybe$map,
			function (voorziening) {
				var bedrijf = voorziening.dz;
				var jachthaven = voorziening.eT;
				var _v0 = A2($elm$core$String$split, '.', field);
				_v0$3:
				while (true) {
					if (_v0.b) {
						if (_v0.b.b) {
							if (!_v0.b.b.b) {
								switch (_v0.a) {
									case 'bedrijf':
										if (_v0.b.a === 'hiswaLid') {
											var _v1 = _v0.b;
											return _Utils_update(
												voorziening,
												{
													dz: _Utils_update(
														bedrijf,
														{eI: !bedrijf.eI})
												});
										} else {
											break _v0$3;
										}
									case 'jachthaven':
										if (_v0.b.a === 'blauweVlag') {
											var _v2 = _v0.b;
											return _Utils_update(
												voorziening,
												{
													eT: _Utils_update(
														jachthaven,
														{dJ: !jachthaven.dJ})
												});
										} else {
											break _v0$3;
										}
									default:
										break _v0$3;
								}
							} else {
								if ((_v0.a === 'categorie') && (!_v0.b.b.b.b)) {
									var _v3 = _v0.b;
									var categorie = _v3.a;
									var _v4 = _v3.b;
									var item = _v4.a;
									return _Utils_update(
										voorziening,
										{
											dz: _Utils_update(
												bedrijf,
												{
													ae: A3($author$project$Page$Voorziening$toggleCategorieField, categorie, item, bedrijf.ae)
												})
										});
								} else {
									break _v0$3;
								}
							}
						} else {
							break _v0$3;
						}
					} else {
						return voorziening;
					}
				}
				var kind = _v0.a;
				var list = _v0.b;
				var toggleVoorzieningField = function (voorzieningen) {
					var primair = voorzieningen.fY;
					var technisch = voorzieningen.gN;
					switch (kind) {
						case 'primair':
							return _Utils_update(
								voorzieningen,
								{
									fY: _Utils_update(
										primair,
										{
											ae: A2($author$project$Page$Voorziening$togglePrimairField, list, primair.ae)
										})
								});
						case 'technisch':
							return _Utils_update(
								voorzieningen,
								{
									gN: _Utils_update(
										technisch,
										{
											ae: A2($author$project$Page$Voorziening$toggleTechnischField, list, technisch.ae)
										})
								});
						default:
							return voorzieningen;
					}
				};
				return _Utils_update(
					voorziening,
					{
						eT: _Utils_update(
							jachthaven,
							{
								he: toggleVoorzieningField(jachthaven.he)
							})
					});
			},
			maybeVoorziening);
	});
var $author$project$Page$Voorziening$updateAlgemeenField = F3(
	function (list, value, data) {
		var vuilwatertank = data.hg;
		if ((((list.b && (list.a === 'vuilwatertank')) && list.b.b) && (list.b.a === 'kosten')) && (!list.b.b.b)) {
			var _v1 = list.b;
			return _Utils_update(
				data,
				{
					hg: _Utils_update(
						vuilwatertank,
						{
							ch: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toFloat(value))
						})
				});
		} else {
			return data;
		}
	});
var $author$project$Page$Voorziening$updateTelefoonField = F3(
	function (field, value, telefoon) {
		switch (field) {
			case 'net':
				return _Utils_update(
					telefoon,
					{cq: value});
			case 'abonnee':
				return _Utils_update(
					telefoon,
					{bL: value});
			default:
				return telefoon;
		}
	});
var $author$project$Page$Voorziening$updateBedrijfField = F3(
	function (list, value, bedrijf) {
		_v0$11:
		while (true) {
			if (list.b) {
				if (!list.b.b) {
					switch (list.a) {
						case 'naam':
							return _Utils_update(
								bedrijf,
								{aY: value});
						case 'tav':
							return _Utils_update(
								bedrijf,
								{gM: value});
						case 'adres':
							return _Utils_update(
								bedrijf,
								{dr: value});
						case 'postcode':
							return _Utils_update(
								bedrijf,
								{fV: value});
						case 'woonplaats':
							return _Utils_update(
								bedrijf,
								{cK: value});
						case 'provincie':
							return _Utils_update(
								bedrijf,
								{
									f1: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(value))
								});
						case 'land':
							return _Utils_update(
								bedrijf,
								{e5: value});
						case 'web':
							return _Utils_update(
								bedrijf,
								{hm: value});
						case 'email':
							return _Utils_update(
								bedrijf,
								{ef: value});
						default:
							break _v0$11;
					}
				} else {
					if (!list.b.b.b) {
						switch (list.a) {
							case 'telefoon':
								var _v1 = list.b;
								var field = _v1.a;
								return _Utils_update(
									bedrijf,
									{
										gO: A3($author$project$Page$Voorziening$updateTelefoonField, field, value, bedrijf.gO)
									});
							case 'fax':
								var _v2 = list.b;
								var field = _v2.a;
								return _Utils_update(
									bedrijf,
									{
										el: A3($author$project$Page$Voorziening$updateTelefoonField, field, value, bedrijf.el)
									});
							default:
								break _v0$11;
						}
					} else {
						break _v0$11;
					}
				}
			} else {
				break _v0$11;
			}
		}
		return bedrijf;
	});
var $author$project$Page$Voorziening$updateConsulBezoek = F3(
	function (field, value, consul) {
		var _v0 = A2($elm$core$String$split, '-', consul.dG);
		if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.b.b)) {
			var jaar = _v0.a;
			var _v1 = _v0.b;
			var maand = _v1.a;
			var _v2 = _v1.b;
			var dag = _v2.a;
			_v3$3:
			while (true) {
				if (field.b && (!field.b.b)) {
					switch (field.a) {
						case 'jaar':
							return value + ('-' + (maand + ('-' + dag)));
						case 'maand':
							return jaar + ('-' + (value + ('-' + dag)));
						case 'dag':
							return jaar + ('-' + (maand + ('-' + value)));
						default:
							break _v3$3;
					}
				} else {
					break _v3$3;
				}
			}
			return consul.dG;
		} else {
			return consul.dG;
		}
	});
var $author$project$Page$Voorziening$updateConsulField = F3(
	function (list, value, consul) {
		_v0$4:
		while (true) {
			if (list.b) {
				switch (list.a) {
					case 'naam':
						if (!list.b.b) {
							return _Utils_update(
								consul,
								{aY: value});
						} else {
							break _v0$4;
						}
					case 'bezoek':
						if (!list.b.b) {
							return _Utils_update(
								consul,
								{dG: value});
						} else {
							var field = list.b;
							return _Utils_update(
								consul,
								{
									dG: A3($author$project$Page$Voorziening$updateConsulBezoek, field, value, consul)
								});
						}
					case 'provincie':
						if (!list.b.b) {
							return _Utils_update(
								consul,
								{
									f1: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(value))
								});
						} else {
							break _v0$4;
						}
					default:
						break _v0$4;
				}
			} else {
				break _v0$4;
			}
		}
		return consul;
	});
var $author$project$Page$Voorziening$updateJachthavenField = F3(
	function (list, value, jachthaven) {
		_v0$7:
		while (true) {
			if (list.b) {
				if (!list.b.b) {
					switch (list.a) {
						case 'naam':
							return _Utils_update(
								jachthaven,
								{aY: value});
						case 'ligging':
							return _Utils_update(
								jachthaven,
								{ff: value});
						case 'havenmeester':
							return _Utils_update(
								jachthaven,
								{eE: value});
						case 'kanaalMarifoon':
							return _Utils_update(
								jachthaven,
								{
									eX: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(value))
								});
						case 'bijzHaveningang':
							return _Utils_update(
								jachthaven,
								{dH: value});
						default:
							break _v0$7;
					}
				} else {
					if (!list.b.b.b) {
						switch (list.a) {
							case 'telefoonHavenkantoor':
								var _v1 = list.b;
								var field = _v1.a;
								return _Utils_update(
									jachthaven,
									{
										gP: A3($author$project$Page$Voorziening$updateTelefoonField, field, value, jachthaven.gP)
									});
							case 'telefoonHavenmeester':
								var _v2 = list.b;
								var field = _v2.a;
								return _Utils_update(
									jachthaven,
									{
										gQ: A3($author$project$Page$Voorziening$updateTelefoonField, field, value, jachthaven.gQ)
									});
							default:
								break _v0$7;
						}
					} else {
						break _v0$7;
					}
				}
			} else {
				break _v0$7;
			}
		}
		return jachthaven;
	});
var $author$project$Page$Voorziening$updateLocatieField = F3(
	function (list, value, locatie) {
		_v0$2:
		while (true) {
			if (list.b && (!list.b.b)) {
				switch (list.a) {
					case 'plaatsAlmanak':
						return _Utils_update(
							locatie,
							{fS: value});
					case 'waterkaart':
						return _Utils_update(
							locatie,
							{hk: value});
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return locatie;
	});
var $author$project$Page$Voorziening$updatePrimairField = F3(
	function (list, value, data) {
		var drinkwater = data.eb;
		var ligplaatsen = data.fh;
		var passantenligplaatsen = data.fK;
		_v0$3:
		while (true) {
			if ((list.b && list.b.b) && (!list.b.b.b)) {
				switch (list.a) {
					case 'drinkwater':
						if (list.b.a === 'kosten') {
							var _v1 = list.b;
							return _Utils_update(
								data,
								{
									eb: _Utils_update(
										drinkwater,
										{
											ch: A2(
												$elm$core$Maybe$withDefault,
												0,
												$elm$core$String$toFloat(value))
										})
								});
						} else {
							break _v0$3;
						}
					case 'ligplaatsen':
						var _v2 = list.b;
						var field = _v2.a;
						return _Utils_update(
							data,
							{
								fh: function () {
									switch (field) {
										case 'aantal':
											return _Utils_update(
												ligplaatsen,
												{
													bK: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										case 'vrij':
											return _Utils_update(
												ligplaatsen,
												{
													hf: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										default:
											return ligplaatsen;
									}
								}()
							});
					case 'passantenligplaatsen':
						var _v4 = list.b;
						var field = _v4.a;
						return _Utils_update(
							data,
							{
								fK: function () {
									switch (field) {
										case 'aantal':
											return _Utils_update(
												passantenligplaatsen,
												{
													bK: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										case 'afwijkendTarief':
											return _Utils_update(
												passantenligplaatsen,
												{du: value});
										case 'diepgangMax':
											return _Utils_update(
												passantenligplaatsen,
												{
													d6: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'lengteMax':
											return _Utils_update(
												passantenligplaatsen,
												{
													fe: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'maxBreedte':
											return _Utils_update(
												passantenligplaatsen,
												{
													fm: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'tarief':
											return _Utils_update(
												passantenligplaatsen,
												{
													w: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										default:
											return passantenligplaatsen;
									}
								}()
							});
					default:
						break _v0$3;
				}
			} else {
				break _v0$3;
			}
		}
		return data;
	});
var $author$project$Page$Voorziening$updateTechnischField = F3(
	function (list, value, data) {
		var botenlift = data.dL;
		var hefkraan = data.eG;
		var scheepshelling = data.gl;
		var trailerhelling = data.g3;
		var mastenkraan = data.fl;
		_v0$5:
		while (true) {
			if ((list.b && list.b.b) && (!list.b.b.b)) {
				switch (list.a) {
					case 'botenlift':
						var _v1 = list.b;
						var field = _v1.a;
						return _Utils_update(
							data,
							{
								dL: function () {
									switch (field) {
										case 'maxDiepgang':
											return _Utils_update(
												botenlift,
												{
													aj: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'maxGewicht':
											return _Utils_update(
												botenlift,
												{
													K: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										default:
											return botenlift;
									}
								}()
							});
					case 'hefkraan':
						var _v3 = list.b;
						var field = _v3.a;
						return _Utils_update(
							data,
							{
								eG: function () {
									switch (field) {
										case 'maxDiepgang':
											return _Utils_update(
												hefkraan,
												{
													aj: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'maxGewicht':
											return _Utils_update(
												hefkraan,
												{
													K: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										default:
											return hefkraan;
									}
								}()
							});
					case 'scheepshelling':
						var _v5 = list.b;
						var field = _v5.a;
						return _Utils_update(
							data,
							{
								gl: function () {
									switch (field) {
										case 'maxDiepgang':
											return _Utils_update(
												scheepshelling,
												{
													aj: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'maxGewicht':
											return _Utils_update(
												scheepshelling,
												{
													K: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										default:
											return scheepshelling;
									}
								}()
							});
					case 'trailerhelling':
						var _v7 = list.b;
						var field = _v7.a;
						return _Utils_update(
							data,
							{
								g3: function () {
									switch (field) {
										case 'maxDiepgang':
											return _Utils_update(
												trailerhelling,
												{
													aj: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'maxGewicht':
											return _Utils_update(
												trailerhelling,
												{
													K: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										default:
											return trailerhelling;
									}
								}()
							});
					case 'mastenkraan':
						var _v9 = list.b;
						var field = _v9.a;
						return _Utils_update(
							data,
							{
								fl: function () {
									switch (field) {
										case 'maxLengte':
											return _Utils_update(
												mastenkraan,
												{
													fq: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toFloat(value))
												});
										case 'maxGewicht':
											return _Utils_update(
												mastenkraan,
												{
													K: A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(value))
												});
										default:
											return mastenkraan;
									}
								}()
							});
					default:
						break _v0$5;
				}
			} else {
				break _v0$5;
			}
		}
		return data;
	});
var $author$project$Page$Voorziening$updateField = F3(
	function (field, value, model) {
		var _v0 = model.i;
		if (_v0.$ === 1) {
			return model;
		} else {
			var voorziening = _v0.a;
			var locatie = voorziening.fi;
			var consul = voorziening.d_;
			var bedrijf = voorziening.dz;
			var jachthaven = voorziening.eT;
			return A2(
				$author$project$Session$dirty,
				true,
				_Utils_update(
					model,
					{
						i: $elm$core$Maybe$Just(
							function () {
								var _v1 = A2($elm$core$String$split, '.', field);
								_v1$7:
								while (true) {
									if (_v1.b) {
										switch (_v1.a) {
											case 'anwbID':
												if (!_v1.b.b) {
													return _Utils_update(
														voorziening,
														{
															dw: A2(
																$elm$core$Maybe$withDefault,
																0,
																$elm$core$String$toInt(value))
														});
												} else {
													break _v1$7;
												}
											case 'indexNaam':
												if (!_v1.b.b) {
													return _Utils_update(
														voorziening,
														{cd: value});
												} else {
													break _v1$7;
												}
											case 'opmerkingen':
												if (!_v1.b.b) {
													return _Utils_update(
														voorziening,
														{fG: value});
												} else {
													break _v1$7;
												}
											case 'locatie':
												var list = _v1.b;
												return _Utils_update(
													voorziening,
													{
														fi: A3($author$project$Page$Voorziening$updateLocatieField, list, value, locatie)
													});
											case 'consul':
												var list = _v1.b;
												return _Utils_update(
													voorziening,
													{
														d_: A3($author$project$Page$Voorziening$updateConsulField, list, value, consul)
													});
											case 'bedrijf':
												var list = _v1.b;
												return _Utils_update(
													voorziening,
													{
														dz: A3($author$project$Page$Voorziening$updateBedrijfField, list, value, bedrijf)
													});
											case 'jachthaven':
												var list = _v1.b;
												return _Utils_update(
													voorziening,
													{
														eT: A3($author$project$Page$Voorziening$updateJachthavenField, list, value, jachthaven)
													});
											default:
												break _v1$7;
										}
									} else {
										return voorziening;
									}
								}
								var kind = _v1.a;
								var list = _v1.b;
								var updateVoorzieningField = function (voorzieningen) {
									var algemeen = voorzieningen.dv;
									var primair = voorzieningen.fY;
									var technisch = voorzieningen.gN;
									switch (kind) {
										case 'algemeen':
											return _Utils_update(
												voorzieningen,
												{
													dv: _Utils_update(
														algemeen,
														{
															ae: A3($author$project$Page$Voorziening$updateAlgemeenField, list, value, algemeen.ae)
														})
												});
										case 'primair':
											return _Utils_update(
												voorzieningen,
												{
													fY: _Utils_update(
														primair,
														{
															ae: A3($author$project$Page$Voorziening$updatePrimairField, list, value, primair.ae)
														})
												});
										case 'technisch':
											return _Utils_update(
												voorzieningen,
												{
													gN: _Utils_update(
														technisch,
														{
															ae: A3($author$project$Page$Voorziening$updateTechnischField, list, value, technisch.ae)
														})
												});
										default:
											return voorzieningen;
									}
								};
								return _Utils_update(
									voorziening,
									{
										eT: _Utils_update(
											jachthaven,
											{
												he: updateVoorzieningField(jachthaven.he)
											})
									});
							}())
					}));
		}
	});
var $author$project$Page$Voorziening$vz = F2(
	function (updateVoorziening, model) {
		var _v0 = model.i;
		if (_v0.$ === 1) {
			return model;
		} else {
			var voorziening = _v0.a;
			return _Utils_update(
				model,
				{
					i: $elm$core$Maybe$Just(
						updateVoorziening(voorziening))
				});
		}
	});
var $author$project$Page$Voorziening$updateLocatie = F3(
	function (lon, lat, model) {
		return A2(
			$author$project$Session$dirty,
			true,
			A2(
				$author$project$Page$Voorziening$vz,
				function (voorziening) {
					var locatie = voorziening.fi;
					return _Utils_update(
						voorziening,
						{
							fi: _Utils_update(
								locatie,
								{
									bZ: {fb: lat, fj: lon}
								})
						});
				},
				model));
	});
var $author$project$Page$Voorziening$delLink = F2(
	function (link_, list) {
		return A2(
			$elm$core$List$filter,
			function (_v0) {
				var link = _v0.cn;
				return !_Utils_eq(link, link_);
			},
			list);
	});
var $author$project$Page$Voorziening$vaarwegDel = F2(
	function (link, voorziening) {
		var vaarwegen = voorziening.g8;
		return _Utils_update(
			voorziening,
			{
				g8: A2($author$project$Page$Voorziening$delLink, link, vaarwegen)
			});
	});
var $author$project$Page$Voorziening$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Voorziening$Mdc, msg_, model);
			case 16:
				var timePosix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cF: timePosix}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var msg_ = msg.a;
				_v1$10:
				while (true) {
					switch (msg_.$) {
						case 4:
							switch (msg_.a) {
								case 'close':
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{a7: false}),
										$elm$core$Platform$Cmd$none);
								case 'arrow_back':
									return A2($author$project$Session$back, 1, model);
								default:
									break _v1$10;
							}
						case 3:
							switch (msg_.a) {
								case 'power_settings_new':
									var _v2 = A2($author$project$Session$token, '', model);
									var model_ = _v2.a;
									var cmd_ = _v2.b;
									var _v3 = A2($author$project$Session$pushUrl, '/', model_);
									var model__ = _v3.a;
									var cmd__ = _v3.b;
									return _Utils_Tuple2(
										model__,
										$elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[cmd_, cmd__])));
								case 'save':
									if (model.W.d8) {
										var _v4 = model.i;
										if (_v4.$ === 1) {
											return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
										} else {
											var voorziening = _v4.a;
											return _Utils_Tuple2(
												A2($author$project$Session$progress, true, model),
												A3(
													$author$project$Api$putVoorziening,
													$author$project$Page$Voorziening$GotVoorziening(true),
													_Utils_update(
														voorziening,
														{
															e7: $author$project$Page$Voorziening$timeISOString(model)
														}),
													model.W));
										}
									} else {
										return A3($author$project$Session$toast, $author$project$Page$Voorziening$Session, 'Geen wijzigingen', model);
									}
								case 'help':
									var titel = A2($author$project$Session$flag, 'handleiding', model.W);
									return _Utils_Tuple2(
										A2($author$project$Session$progress, true, model),
										A3(
											$author$project$Api$arbitraryFile,
											$author$project$Page$Voorziening$Pdf(titel),
											'/anwbwa/resources/' + titel,
											model.W));
								case 'delete':
									var _v5 = model.i;
									if (_v5.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										return _Utils_Tuple2(
											A4($author$project$Session$confirm, 'delete', $elm$core$Maybe$Nothing, 'Deze voorziening wordt verwijderd.', model),
											$elm$core$Platform$Cmd$none);
									}
								case 'print':
									var _v6 = model.i;
									if (_v6.$ === 1) {
										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
									} else {
										var voorziening = _v6.a;
										var titel = 'voorziening-' + $elm$core$String$fromInt(voorziening.cc);
										var id = $elm$core$String$fromInt(voorziening.cc);
										return _Utils_Tuple2(
											A2($author$project$Session$progress, true, model),
											A3(
												$author$project$Api$file,
												$author$project$Page$Voorziening$Pdf(titel),
												'/api/pdf/voorziening/' + id,
												model.W));
									}
								case 'playlist_add_check':
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{a6: !model.a6}),
										$elm$core$Platform$Cmd$none);
								default:
									break _v1$10;
							}
						case 5:
							if (msg_.a === 'playlist_add_check') {
								var index = msg_.b;
								var postStatus = F2(
									function (id, statussen) {
										var _v9 = $elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (status) {
													return (status.eC.aY === 'Edit') && _Utils_eq(status.aY, index);
												},
												statussen));
										if (_v9.$ === 1) {
											return $elm$core$Platform$Cmd$none;
										} else {
											var status = _v9.a;
											return A4($author$project$Api$postVoorzieningStatus, $author$project$Page$Voorziening$PostStatus, id, status, model.W);
										}
									});
								return _Utils_Tuple2(
									A2(
										$author$project$Session$progress,
										true,
										_Utils_update(
											model,
											{a6: false})),
									function () {
										var _v7 = model.i;
										if (_v7.$ === 1) {
											return $elm$core$Platform$Cmd$none;
										} else {
											var voorziening = _v7.a;
											var _v8 = model.W.h9;
											if (_v8.$ === 1) {
												return $elm$core$Platform$Cmd$none;
											} else {
												var refdata = _v8.a;
												return A2(postStatus, voorziening.cc, refdata.cE);
											}
										}
									}());
							} else {
								break _v1$10;
							}
						case 11:
							if ((msg_.a === 'delete') && msg_.c) {
								var _v10 = model.i;
								if (_v10.$ === 1) {
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
								} else {
									var voorziening = _v10.a;
									return _Utils_Tuple2(
										A2($author$project$Session$progress, true, model),
										A3($author$project$Api$deleteVoorziening, $author$project$Page$Voorziening$DeleteVoorziening, voorziening.cn, model.W));
								}
							} else {
								break _v1$10;
							}
						default:
							break _v1$10;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Voorziening$Session, msg_, model);
			case 12:
				var result = msg.a;
				if (!result.$) {
					var status = result.a;
					return A3(
						$author$project$Session$toast,
						$author$project$Page$Voorziening$Session,
						'Status: ' + $author$project$Page$Voorziening$statusLabel(status.aY),
						A2($author$project$Session$progress, false, model));
				} else {
					var err = result.a;
					var suffix = function () {
						if (err.$ === 3) {
							var code = err.a;
							if (code === 401) {
								return '. Op dit moment kan alleen de redactie wijzigingen doorvoeren.';
							} else {
								return '';
							}
						} else {
							return '';
						}
					}();
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'status wijzigen' + suffix, model),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var titel = msg.a;
				var result = msg.b;
				if (!result.$) {
					var bytes = result.a;
					return _Utils_Tuple2(
						A2($author$project$Session$progress, false, model),
						A3($author$project$Api$download, titel, 'application/pdf', bytes));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Session$error,
							err,
							'ophalen document',
							A2($author$project$Session$progress, false, model)),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var lon = msg_.a;
					var lat = msg_.b;
					return _Utils_Tuple2(
						A3($author$project$Page$Voorziening$updateLocatie, lon, lat, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Street$update, $author$project$Page$Voorziening$Street, msg_, model);
				}
			case 3:
				var msg_ = msg.a;
				if (msg_.$ === 7) {
					var field = msg_.a;
					var value = msg_.b;
					return _Utils_Tuple2(
						A3($author$project$Page$Voorziening$updateField, field, value, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Field$update, $author$project$Page$Voorziening$Field, msg_, model);
				}
			case 5:
				var msg_ = msg.a;
				if (msg_.$ === 5) {
					var vaarweg = msg_.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Session$dirty,
							true,
							A2(
								$author$project$Page$Voorziening$vz,
								$author$project$Page$Voorziening$addVaarweg(vaarweg),
								_Utils_update(
									model,
									{a7: false}))),
						$elm$core$Platform$Cmd$none);
				} else {
					return A3($author$project$Page$Partial$Vaarwegen$update, $author$project$Page$Voorziening$Vaarwegen, msg_, model);
				}
			case 6:
				return A2(
					$author$project$Page$Partial$Vaarwegen$get,
					$author$project$Page$Voorziening$Vaarwegen,
					_Utils_update(
						model,
						{a7: true}));
			case 7:
				var link = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						A2(
							$author$project$Page$Voorziening$vz,
							$author$project$Page$Voorziening$vaarwegDel(link),
							model)),
					$elm$core$Platform$Cmd$none);
			case 13:
				var field = msg.a;
				var value = msg.b;
				return _Utils_Tuple2(
					A3($author$project$Page$Voorziening$updateField, field, value, model),
					$elm$core$Platform$Cmd$none);
			case 8:
				var field = msg.a;
				return _Utils_Tuple2(
					function () {
						if (field === 'noDate') {
							return _Utils_update(
								model,
								{br: !model.br});
						} else {
							return A2(
								$author$project$Session$dirty,
								true,
								_Utils_update(
									model,
									{
										i: A2($author$project$Page$Voorziening$toggleField, field, model.i)
									}));
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 9:
				var container = msg.a;
				var categorie = msg.b;
				return _Utils_Tuple2(
					A2(
						$author$project$Session$dirty,
						true,
						_Utils_update(
							model,
							{
								i: A3($author$project$Page$Voorziening$toggleCategorie, container, categorie, model.i)
							})),
					$elm$core$Platform$Cmd$none);
			case 10:
				var saved = msg.a;
				var result = msg.b;
				if (!result.$) {
					var states = result.a;
					var voorziening = A2($author$project$Session$rol, 4, model.W) ? states.dV : states.ec;
					var action = saved ? A2($author$project$Session$toast, $author$project$Page$Voorziening$Session, 'Opgeslagen') : A2(
						$author$project$Street$reverseGeocode,
						$author$project$Page$Voorziening$Street,
						$elm$core$Maybe$Just(
							_Utils_Tuple2(voorziening.fi.bZ.fj, voorziening.fi.bZ.fb)));
					return action(
						A2(
							$author$project$Session$dirty,
							false,
							A2(
								$author$project$Session$progress,
								false,
								_Utils_update(
									model,
									{
										i: $elm$core$Maybe$Just(voorziening),
										bI: $elm$core$Maybe$Just(states)
									}))));
				} else {
					var err = result.a;
					var string = saved ? ('bewaren voorzieninggegevens' + function () {
						if (err.$ === 3) {
							var code = err.a;
							if (code === 401) {
								return '. Op dit moment kan alleen de redactie wijzigingen doorvoeren.';
							} else {
								return '';
							}
						} else {
							return '';
						}
					}()) : 'ophalen voorzieninggegevens';
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, string, model),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var result = msg.a;
				if (!result.$) {
					return A2(
						$author$project$Session$back,
						1,
						A2(
							$author$project$Session$dirty,
							false,
							A2(
								$author$project$Session$progress,
								false,
								_Utils_update(
									model,
									{i: $elm$core$Maybe$Nothing}))));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						A3($author$project$Session$error, err, 'verwijderen voorzieninggegevens', model),
						$elm$core$Platform$Cmd$none);
				}
			case 15:
				var _v23 = model.bI;
				if (_v23.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var edit = _v23.a.ec;
					var coordinaat = edit.fi.bZ;
					var _v24 = _Utils_Tuple2(coordinaat.fj, coordinaat.fb);
					var lon = _v24.a;
					var lat = _v24.b;
					return A3(
						$author$project$Street$reverseGeocode,
						$author$project$Page$Voorziening$Street,
						$elm$core$Maybe$Just(
							_Utils_Tuple2(lon, lat)),
						A3($author$project$Page$Voorziening$updateLocatie, lon, lat, model));
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Voorzieningen$link = F2(
	function (link_, model) {
		var session = model.W;
		return A2($author$project$Session$pushUrl, session.g6 + ('?link=' + link_), model);
	});
var $author$project$Page$Voorzieningen$update = F2(
	function (msg, model) {
		var session = model.W;
		switch (msg.$) {
			case 1:
				var msg_ = msg.a;
				return A3($author$project$Material$update, $author$project$Page$Voorzieningen$Mdc, msg_, model);
			case 2:
				var msg_ = msg.a;
				_v1$2:
				while (true) {
					switch (msg_.$) {
						case 4:
							if (msg_.a === 'arrow_back') {
								return A2($author$project$Session$back, 1, model);
							} else {
								break _v1$2;
							}
						case 3:
							if (msg_.a === 'add') {
								return A2($author$project$Page$Voorzieningen$link, '', model);
							} else {
								break _v1$2;
							}
						default:
							break _v1$2;
					}
				}
				return A3($author$project$Session$update, $author$project$Page$Voorzieningen$Session, msg_, model);
			case 3:
				var msg_ = msg.a;
				if (msg_.$ === 5) {
					var voorziening = msg_.a;
					return A2($author$project$Page$Voorzieningen$link, voorziening.cn, model);
				} else {
					return A2(
						$elm$core$Tuple$mapFirst,
						function (_v3) {
							var top = _v3.a;
							var filter = _v3.b;
							return _Utils_update(
								top,
								{b4: filter});
						},
						A3(
							$author$project$Page$Partial$Filter$update,
							$author$project$Page$Voorzieningen$Filter,
							msg_,
							_Utils_Tuple2(model, model.b4)));
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$update = F2(
	function (topMsg, topModel) {
		var _v0 = _Utils_Tuple2(topMsg, topModel);
		_v0$11:
		while (true) {
			switch (_v0.a.$) {
				case 1:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							topModel,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								$author$project$Main$toSession(topModel).h$,
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							topModel,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 2:
					var url = _v0.a.a;
					var session = $author$project$Main$toSession(topModel);
					return A2(
						$author$project$Main$route,
						url,
						_Utils_update(
							session,
							{
								d8: false,
								hX: $author$project$Session$NoOp,
								g6: $elm$url$Url$toString(url)
							}));
				case 3:
					if (!_v0.b.$) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Home,
							$author$project$Main$HomeMsg,
							A2($author$project$Page$Home$update, msg, model));
					} else {
						break _v0$11;
					}
				case 4:
					if (_v0.b.$ === 1) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Login,
							$author$project$Main$LoginMsg,
							A2($author$project$Page$Login$update, msg, model));
					} else {
						break _v0$11;
					}
				case 5:
					if (_v0.b.$ === 2) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Toewijzingen,
							$author$project$Main$ToewijzingenMsg,
							A2($author$project$Page$Toewijzingen$update, msg, model));
					} else {
						break _v0$11;
					}
				case 6:
					if (_v0.b.$ === 3) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Voorzieningen,
							$author$project$Main$VoorzieningenMsg,
							A2($author$project$Page$Voorzieningen$update, msg, model));
					} else {
						break _v0$11;
					}
				case 7:
					if (_v0.b.$ === 4) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Voorziening,
							$author$project$Main$VoorzieningMsg,
							A2($author$project$Page$Voorziening$update, msg, model));
					} else {
						break _v0$11;
					}
				case 8:
					if (_v0.b.$ === 5) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Vaarwegen,
							$author$project$Main$VaarwegenMsg,
							A2($author$project$Page$Vaarwegen$update, msg, model));
					} else {
						break _v0$11;
					}
				case 9:
					if (_v0.b.$ === 6) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Vaarweg,
							$author$project$Main$VaarwegMsg,
							A2($author$project$Page$Vaarweg$update, msg, model));
					} else {
						break _v0$11;
					}
				case 10:
					if (_v0.b.$ === 7) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Kunstwerken,
							$author$project$Main$KunstwerkenMsg,
							A2($author$project$Page$Kunstwerken$update, msg, model));
					} else {
						break _v0$11;
					}
				case 11:
					if (_v0.b.$ === 8) {
						var msg = _v0.a.a;
						var model = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Kunstwerk,
							$author$project$Main$KunstwerkMsg,
							A2($author$project$Page$Kunstwerk$update, msg, model));
					} else {
						break _v0$11;
					}
				default:
					break _v0$11;
			}
		}
		return _Utils_Tuple2(topModel, $elm$core$Platform$Cmd$none);
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Main$document = F2(
	function (toMsg, html) {
		return {
			hw: _List_fromArray(
				[
					A2($elm$html$Html$map, toMsg, html)
				]),
			ik: 'ANWB Wateralmanak'
		};
	});
var $author$project$Internal$Button$Implementation$link = function (href) {
	return $author$project$Internal$Options$option(
		function (options) {
			return _Utils_update(
				options,
				{
					cn: $elm$core$Maybe$Just(href)
				});
		});
};
var $author$project$Material$Button$link = $author$project$Internal$Button$Implementation$link;
var $author$project$Page$Home$button = F3(
	function (model, link, caption) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A5(
					$author$project$Material$Button$view,
					$author$project$Page$Home$Mdc,
					'button-' + link,
					model.c6,
					_List_fromArray(
						[
							$author$project$Material$Button$link(link),
							$author$project$Material$Options$cs('field')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(caption)
						]))
				]));
	});
var $author$project$Internal$LayoutGrid$Implementation$span4 = A2($author$project$Internal$LayoutGrid$Implementation$span, $elm$core$Maybe$Nothing, 4);
var $author$project$Material$LayoutGrid$span4 = $author$project$Internal$LayoutGrid$Implementation$span4;
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Internal$Typography$Implementation$subtitle2 = $author$project$Internal$Options$cs('mdc-typography--subtitle2');
var $author$project$Material$Typography$subtitle2 = $author$project$Internal$Typography$Implementation$subtitle2;
var $author$project$Page$subtitle2 = function (value) {
	return A3(
		$author$project$Material$Options$styled,
		$elm$html$Html$h4,
		_List_fromArray(
			[
				$author$project$Material$Typography$subtitle2,
				A2($author$project$Material$Options$css, 'margin-bottom', '0')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(value)
			]));
};
var $author$project$Internal$LayoutGrid$Implementation$inner = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-layout-grid__inner'),
			options));
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Internal$LayoutGrid$Implementation$view = function (options) {
	return A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			A2(
				$author$project$Internal$Options$styled,
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$author$project$Internal$Options$cs('mdc-layout-grid'),
					options)),
			$elm$core$List$singleton),
		$author$project$Internal$LayoutGrid$Implementation$inner(_List_Nil));
};
var $author$project$Material$LayoutGrid$view = $author$project$Internal$LayoutGrid$Implementation$view;
var $author$project$Internal$TopAppBar$Implementation$fixedAdjust = $author$project$Internal$Options$cs('mdc-top-app-bar--fixed-adjust');
var $author$project$Material$TopAppBar$fixedAdjust = $author$project$Internal$TopAppBar$Implementation$fixedAdjust;
var $author$project$Internal$LinearProgress$Implementation$indeterminate = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{bm: true});
	});
var $author$project$Material$LinearProgress$indeterminate = $author$project$Internal$LinearProgress$Implementation$indeterminate;
var $author$project$Material$Options$nop = $author$project$Internal$Options$nop;
var $author$project$Internal$LinearProgress$Implementation$defaultConfig = {bT: 0, bU: false, cU: false, bm: false, cz: false, bG: 0};
var $author$project$Internal$LinearProgress$Implementation$view = F2(
	function (options, _v0) {
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$LinearProgress$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-linear-progress'),
					A2(
					$author$project$Internal$Options$when,
					config.bm,
					$author$project$Internal$Options$cs('mdc-linear-progress--indeterminate')),
					A2(
					$author$project$Internal$Options$when,
					config.cz,
					$author$project$Internal$Options$cs('mdc-linear-progress--reversed'))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-linear-progress__buffering-dots')
						]),
					_List_Nil),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-linear-progress__buffer'),
							A2(
							$author$project$Internal$Options$when,
							config.bU,
							A2(
								$author$project$Internal$Options$css,
								'transform',
								'scaleX(' + ($elm$core$String$fromFloat(config.bT) + ')')))
						]),
					_List_Nil),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-linear-progress__bar mdc-linear-progress__primary-bar'),
							A2(
							$author$project$Internal$Options$when,
							!config.bm,
							A2(
								$author$project$Internal$Options$css,
								'transform',
								'scaleX(' + ($elm$core$String$fromFloat(config.bG) + ')')))
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-linear-progress__bar-inner')
								]),
							_List_Nil)
						])),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-linear-progress__bar mdc-linear-progress__secondary-bar')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-linear-progress__bar-inner')
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Material$LinearProgress$view = $author$project$Internal$LinearProgress$Implementation$view;
var $author$project$Session$Action = function (a) {
	return {$: 3, a: a};
};
var $author$project$Session$Navigation = function (a) {
	return {$: 4, a: a};
};
var $author$project$Internal$TopAppBar$Implementation$alignEnd = $author$project$Internal$Options$cs('mdc-top-app-bar__section--align-end');
var $author$project$Material$TopAppBar$alignEnd = $author$project$Internal$TopAppBar$Implementation$alignEnd;
var $author$project$Internal$TopAppBar$Implementation$alignStart = $author$project$Internal$Options$cs('mdc-top-app-bar__section--align-start');
var $author$project$Material$TopAppBar$alignStart = $author$project$Internal$TopAppBar$Implementation$alignStart;
var $author$project$Internal$Icon$Implementation$node = function (ctor) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{cr: ctor});
		});
};
var $author$project$Internal$Icon$Implementation$anchor = $author$project$Internal$Icon$Implementation$node('a');
var $author$project$Material$Icon$anchor = $author$project$Internal$Icon$Implementation$anchor;
var $author$project$Internal$Menu$Model$Toggle = {$: 5};
var $author$project$Internal$Menu$Implementation$attach = F2(
	function (lift, idx) {
		return $author$project$Internal$Options$onClick(
			lift(
				A2($author$project$Internal$Msg$MenuMsg, idx, $author$project$Internal$Menu$Model$Toggle)));
	});
var $author$project$Material$Menu$attach = $author$project$Internal$Menu$Implementation$attach;
var $author$project$Internal$TopAppBar$Implementation$fixed = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{ax: true});
	});
var $author$project$Material$TopAppBar$fixed = $author$project$Internal$TopAppBar$Implementation$fixed;
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $author$project$Internal$Theme$Implementation$secondaryBg = $author$project$Internal$Options$cs('mdc-theme--secondary-bg');
var $author$project$Material$Theme$secondaryBg = $author$project$Internal$Theme$Implementation$secondaryBg;
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Internal$TopAppBar$Implementation$section = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$section,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-top-app-bar__section'),
			options));
};
var $author$project$Material$TopAppBar$section = $author$project$Internal$TopAppBar$Implementation$section;
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Internal$TopAppBar$Implementation$title = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$span,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-top-app-bar__title'),
			options));
};
var $author$project$Material$TopAppBar$title = $author$project$Internal$TopAppBar$Implementation$title;
var $author$project$Material$Icon$view = $author$project$Internal$Icon$Implementation$view;
var $author$project$Internal$TopAppBar$Model$Init = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$TopAppBar$Model$Resize = function (a) {
	return {$: 1, a: a};
};
var $author$project$Internal$TopAppBar$Model$Scroll = function (a) {
	return {$: 2, a: a};
};
var $author$project$Internal$TopAppBar$Implementation$cssClasses = {bX: 'mdc-top-app-bar--short-collapsed', bg: 'mdc-top-app-bar--dense', ax: 'mdc-top-app-bar--fixed', cx: 'mdc-top-app-bar--prominent', gq: 'mdc-top-app-bar--fixed-scrolled', a4: 'mdc-top-app-bar--short'};
var $author$project$Internal$TopAppBar$Model$defaultConfig = {bX: false, bg: false, ax: false, cx: false, a4: false};
var $author$project$Internal$TopAppBar$Implementation$getAppBarHeight = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'clientHeight']),
	$elm$json$Json$Decode$float);
var $author$project$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $author$project$Internal$TopAppBar$Implementation$getViewportScrollY = $author$project$DOM$target(
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['ownerDocument', 'defaultView', 'scrollY']),
		$elm$json$Json$Decode$float));
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Material$Options$data = $author$project$Internal$Options$data;
var $author$project$Material$Options$many = $author$project$Internal$Options$many;
var $author$project$Material$Options$on = $author$project$Internal$Options$on;
var $author$project$Internal$GlobalEvents$listener = F2(
	function (name, decoder) {
		return $author$project$Material$Options$many(
			_List_fromArray(
				[
					A2($author$project$Material$Options$on, name, decoder),
					A2($author$project$Material$Options$data, name, '{}')
				]));
	});
var $author$project$Internal$GlobalEvents$onResize = $author$project$Internal$GlobalEvents$listener('globalresize');
var $author$project$Internal$GlobalEvents$onScroll = $author$project$Internal$GlobalEvents$listener('globalscroll');
var $author$project$Internal$GlobalEvents$onTick = $author$project$Internal$GlobalEvents$listener('globaltick');
var $author$project$Internal$TopAppBar$Implementation$row = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-top-app-bar__row'),
			options));
};
var $author$project$Internal$TopAppBar$Implementation$topAppBar = F4(
	function (lift, model, options, sections) {
		var top = A2($elm$core$Maybe$withDefault, 0, model.gF);
		var lastScrollPosition = A2($elm$core$Maybe$withDefault, 0, model.bp);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$TopAppBar$Model$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$header,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-top-app-bar'),
					A2(
					$author$project$Internal$Options$when,
					config.bg,
					$author$project$Internal$Options$cs($author$project$Internal$TopAppBar$Implementation$cssClasses.bg)),
					A2(
					$author$project$Internal$Options$when,
					config.ax,
					$author$project$Internal$Options$cs($author$project$Internal$TopAppBar$Implementation$cssClasses.ax)),
					A2(
					$author$project$Internal$Options$when,
					config.ax && (lastScrollPosition > 0),
					$author$project$Internal$Options$cs($author$project$Internal$TopAppBar$Implementation$cssClasses.gq)),
					A2(
					$author$project$Internal$Options$when,
					config.cx,
					$author$project$Internal$Options$cs($author$project$Internal$TopAppBar$Implementation$cssClasses.cx)),
					A2(
					$author$project$Internal$Options$when,
					config.a4,
					$author$project$Internal$Options$cs($author$project$Internal$TopAppBar$Implementation$cssClasses.a4)),
					A2(
					$author$project$Internal$Options$when,
					config.bX || (config.a4 && (lastScrollPosition > 0)),
					$author$project$Internal$Options$cs($author$project$Internal$TopAppBar$Implementation$cssClasses.bX)),
					A2(
					$author$project$Internal$Options$when,
					(!config.ax) && (!config.a4),
					A2(
						$author$project$Internal$Options$css,
						'top',
						$elm$core$String$fromFloat(top) + 'px')),
					$author$project$Internal$GlobalEvents$onScroll(
					A2(
						$elm$json$Json$Decode$map,
						lift,
						A2(
							$elm$json$Json$Decode$map,
							function (scrollPosition) {
								return $author$project$Internal$TopAppBar$Model$Scroll(
									{a1: scrollPosition});
							},
							$author$project$Internal$TopAppBar$Implementation$getViewportScrollY))),
					$author$project$Internal$GlobalEvents$onResize(
					A2(
						$elm$json$Json$Decode$map,
						lift,
						A3(
							$elm$json$Json$Decode$map2,
							F2(
								function (scrollPosition, topAppBarHeight) {
									return $author$project$Internal$TopAppBar$Model$Resize(
										{a1: scrollPosition, E: topAppBarHeight});
								}),
							$author$project$Internal$TopAppBar$Implementation$getViewportScrollY,
							$author$project$Internal$TopAppBar$Implementation$getAppBarHeight))),
					A2(
					$author$project$Internal$Options$when,
					A2(
						$elm$core$List$any,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								_Utils_eq(model.bp, $elm$core$Maybe$Nothing),
								_Utils_eq(model.E, $elm$core$Maybe$Nothing)
							])),
					$author$project$Internal$GlobalEvents$onTick(
						A2(
							$elm$json$Json$Decode$map,
							lift,
							A3(
								$elm$json$Json$Decode$map2,
								F2(
									function (scrollPosition, topAppBarHeight) {
										return $author$project$Internal$TopAppBar$Model$Init(
											{a1: scrollPosition, E: topAppBarHeight});
									}),
								$author$project$Internal$TopAppBar$Implementation$getViewportScrollY,
								$author$project$Internal$TopAppBar$Implementation$getAppBarHeight))))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Internal$TopAppBar$Implementation$row, _List_Nil, sections)
				]));
	});
var $author$project$Internal$TopAppBar$Implementation$view = A3($author$project$Internal$Component$render, $author$project$Internal$TopAppBar$Implementation$getSet.ey, $author$project$Internal$TopAppBar$Implementation$topAppBar, $author$project$Internal$Msg$TopAppBarMsg);
var $author$project$Material$TopAppBar$view = $author$project$Internal$TopAppBar$Implementation$view;
var $author$project$Session$appBar = F4(
	function (model, title, navigationIcons, actionItems) {
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (_v0) {
				return A5(
					$author$project$Material$TopAppBar$view,
					$author$project$Session$Mdc,
					'my-top-app-bar',
					model.c6,
					_List_fromArray(
						[
							$author$project$Material$TopAppBar$fixed,
							A2($author$project$Material$Options$when, model.d8, $author$project$Material$Theme$secondaryBg)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Material$TopAppBar$section,
							_List_fromArray(
								[$author$project$Material$TopAppBar$alignStart]),
							_Utils_ap(
								A2(
									$elm$core$List$map,
									function (navigation) {
										return A2(
											$author$project$Material$Icon$view,
											_List_fromArray(
												[
													$author$project$Material$Options$onClick(
													$author$project$Session$Navigation(navigation)),
													$author$project$Material$Options$cs('mdc-top-app-bar__navigation-icon')
												]),
											navigation);
									},
									navigationIcons),
								_List_fromArray(
									[
										A2(
										$author$project$Material$TopAppBar$title,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(title)
											]))
									]))),
							A2(
							$author$project$Material$TopAppBar$section,
							_List_fromArray(
								[$author$project$Material$TopAppBar$alignEnd]),
							A2(
								$elm$core$List$map,
								function (_v1) {
									var action = _v1.a;
									var tooltip = _v1.b;
									var base_options = _List_fromArray(
										[
											$author$project$Material$Options$attribute(
											$elm$html$Html$Attributes$title(tooltip)),
											$author$project$Material$Options$cs('mdc-top-app-bar__action-item')
										]);
									var options = _Utils_ap(
										base_options,
										function () {
											if (tooltip === 'Handleiding') {
												return _List_fromArray(
													[
														$author$project$Material$Icon$anchor,
														$author$project$Material$Options$attribute(
														$elm$html$Html$Attributes$href(
															'/anwbwa/resources/' + A2($author$project$Session$flag, 'handleiding', model))),
														$author$project$Material$Options$attribute(
														$elm$html$Html$Attributes$target('handleiding'))
													]);
											} else {
												return _List_fromArray(
													[
														$author$project$Material$Options$onClick(
														$author$project$Session$Action(action))
													]);
											}
										}());
									return A3(
										$author$project$Material$Options$styled,
										$elm$html$Html$div,
										_List_fromArray(
											[
												A2($author$project$Material$Menu$attach, $author$project$Session$Mdc, 'menu.' + action),
												A2($author$project$Material$Options$css, 'user-select', 'none')
											]),
										_List_fromArray(
											[
												A2($author$project$Material$Icon$view, options, action)
											]));
								},
								actionItems))
						]));
			},
			_Utils_Tuple2(title, model.d8));
	});
var $author$project$Session$Confirm = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Internal$Dialog$Implementation$accept = $author$project$Internal$Options$cs('mdc-dialog__footer__button mdc-dialog__footer__button--accept');
var $author$project$Material$Dialog$accept = $author$project$Internal$Dialog$Implementation$accept;
var $author$project$Internal$Dialog$Implementation$backdrop = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-dialog__backdrop'),
			options));
};
var $author$project$Material$Dialog$backdrop = $author$project$Internal$Dialog$Implementation$backdrop;
var $author$project$Internal$Dialog$Implementation$body = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-dialog__body'),
			options));
};
var $author$project$Material$Dialog$body = $author$project$Internal$Dialog$Implementation$body;
var $author$project$Internal$Dialog$Implementation$cancel = $author$project$Internal$Options$cs('mdc-dialog__footer__button mdc-dialog__footer__button--cancel');
var $author$project$Material$Dialog$cancel = $author$project$Internal$Dialog$Implementation$cancel;
var $author$project$Internal$Dialog$Implementation$footer = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-dialog__footer'),
			options));
};
var $author$project$Material$Dialog$footer = $author$project$Internal$Dialog$Implementation$footer;
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Internal$Dialog$Implementation$header = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-dialog__header'),
			options));
};
var $author$project$Material$Dialog$header = $author$project$Internal$Dialog$Implementation$header;
var $author$project$Internal$Dialog$Implementation$open = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{L: true});
	});
var $author$project$Material$Dialog$open = $author$project$Internal$Dialog$Implementation$open;
var $author$project$Internal$Dialog$Implementation$surface = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-dialog__surface'),
			options));
};
var $author$project$Material$Dialog$surface = $author$project$Internal$Dialog$Implementation$surface;
var $author$project$Internal$Dialog$Implementation$title = $author$project$Internal$Options$cs('mdc-dialog__header__title');
var $author$project$Material$Dialog$title = $author$project$Internal$Dialog$Implementation$title;
var $author$project$Internal$Dialog$Model$AnimationEnd = {$: 3};
var $author$project$Internal$Dialog$Model$NoOp = {$: 0};
var $author$project$Internal$Dialog$Model$SetState = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$aside = _VirtualDom_node('aside');
var $author$project$Internal$Dialog$Implementation$close = $author$project$DOM$target(
	A2(
		$elm$json$Json$Decode$map,
		function (className) {
			var hasClass = function (_class) {
				return A2($elm$core$String$contains, ' ' + (_class + ' '), ' ' + (className + ' '));
			};
			return hasClass('mdc-dialog__backdrop') ? true : false;
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['className']),
			$elm$json$Json$Decode$string)));
var $author$project$Internal$Dialog$Implementation$defaultConfig = {ct: $elm$core$Maybe$Nothing, L: false};
var $author$project$DOM$className = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['className']),
	$elm$json$Json$Decode$string);
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Internal$Dialog$Implementation$transitionend = function () {
	var hasClass = F2(
		function (cs, className) {
			return A2(
				$elm$core$List$member,
				cs,
				A2($elm$core$String$split, ' ', className));
		});
	return A2(
		$elm$json$Json$Decode$andThen,
		function (className) {
			return A2(hasClass, 'mdc-dialog__surface', className) ? $elm$json$Json$Decode$succeed(0) : $elm$json$Json$Decode$fail('');
		},
		$author$project$DOM$target($author$project$DOM$className));
}();
var $author$project$Internal$Dialog$Implementation$dialog = F4(
	function (lift, model, options, nodes) {
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Dialog$Implementation$defaultConfig, options);
		var config = summary.dY;
		var stateChanged = !_Utils_eq(config.L, model.L);
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$aside,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-dialog'),
					A2(
					$author$project$Internal$Options$when,
					stateChanged,
					$author$project$Internal$GlobalEvents$onTick(
						$elm$json$Json$Decode$succeed(
							lift(
								$author$project$Internal$Dialog$Model$SetState(config.L))))),
					A3(
					$elm$core$Basics$composeL,
					$author$project$Internal$Options$when(model.L),
					$author$project$Internal$Options$many,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-dialog--open'),
							A2($author$project$Internal$Options$data, 'focustrap', '')
						])),
					A2(
					$author$project$Internal$Options$when,
					model.cO,
					$author$project$Internal$Options$cs('mdc-dialog--animating')),
					A2(
					$author$project$Internal$Options$on,
					'transitionend',
					A2(
						$elm$json$Json$Decode$map,
						function (_v0) {
							return lift($author$project$Internal$Dialog$Model$AnimationEnd);
						},
						$author$project$Internal$Dialog$Implementation$transitionend)),
					A2(
					$author$project$Internal$Options$on,
					'click',
					A2(
						$elm$json$Json$Decode$map,
						function (doClose) {
							return doClose ? A2(
								$elm$core$Maybe$withDefault,
								lift($author$project$Internal$Dialog$Model$NoOp),
								config.ct) : lift($author$project$Internal$Dialog$Model$NoOp);
						},
						$author$project$Internal$Dialog$Implementation$close))
				]),
			_List_Nil,
			nodes);
	});
var $author$project$Internal$Dialog$Implementation$view = A3($author$project$Internal$Component$render, $author$project$Internal$Dialog$Implementation$getSet.ey, $author$project$Internal$Dialog$Implementation$dialog, $author$project$Internal$Msg$DialogMsg);
var $author$project$Material$Dialog$view = $author$project$Internal$Dialog$Implementation$view;
var $author$project$Session$confirmDialog = function (model) {
	return A2(
		$elm$html$Html$Lazy$lazy,
		function (_v0) {
			var _v1 = model.aP;
			if (_v1.$ === 1) {
				return $elm$html$Html$text('');
			} else {
				var _v2 = _v1.a;
				var key = _v2.a;
				var maybeID = _v2.b;
				var prompt = _v2.c;
				return A5(
					$author$project$Material$Dialog$view,
					$author$project$Session$Mdc,
					'confirm-dialog',
					model.c6,
					_List_fromArray(
						[$author$project$Material$Dialog$open]),
					_List_fromArray(
						[
							A2(
							$author$project$Material$Dialog$surface,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Material$Dialog$header,
									_List_Nil,
									_List_fromArray(
										[
											A3(
											$author$project$Material$Options$styled,
											$elm$html$Html$h2,
											_List_fromArray(
												[$author$project$Material$Dialog$title]),
											_List_fromArray(
												[
													$elm$html$Html$text(prompt)
												]))
										])),
									A2(
									$author$project$Material$Dialog$body,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Doorgaan?')
										])),
									A2(
									$author$project$Material$Dialog$footer,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$Material$Button$view,
											$author$project$Session$Mdc,
											'confirm-cancel-button',
											model.c6,
											_List_fromArray(
												[
													$author$project$Material$Button$ripple,
													$author$project$Material$Dialog$cancel,
													$author$project$Material$Options$onClick(
													A3($author$project$Session$Confirm, key, maybeID, false))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Nee')
												])),
											A5(
											$author$project$Material$Button$view,
											$author$project$Session$Mdc,
											'confirm-accept-button',
											model.c6,
											_List_fromArray(
												[
													$author$project$Material$Button$ripple,
													$author$project$Material$Dialog$accept,
													$author$project$Material$Options$onClick(
													A3($author$project$Session$Confirm, key, maybeID, true))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Ja')
												]))
										]))
								])),
							A2($author$project$Material$Dialog$backdrop, _List_Nil, _List_Nil)
						]));
			}
		},
		model.aP);
};
var $author$project$Session$ClearError = {$: 2};
var $author$project$Internal$Dialog$Implementation$onClose = function (handler) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{
					ct: $elm$core$Maybe$Just(handler)
				});
		});
};
var $author$project$Material$Dialog$onClose = $author$project$Internal$Dialog$Implementation$onClose;
var $author$project$Internal$Dialog$Implementation$scrollable = $author$project$Internal$Options$cs('mdc-dialog__body--scrollable');
var $author$project$Material$Dialog$scrollable = $author$project$Internal$Dialog$Implementation$scrollable;
var $author$project$Session$errorDialog = function (model) {
	return A2(
		$elm$html$Html$Lazy$lazy,
		function (maybeError) {
			if (maybeError.$ === 1) {
				return $elm$html$Html$text('');
			} else {
				var err = maybeError.a;
				return A5(
					$author$project$Material$Dialog$view,
					$author$project$Session$Mdc,
					'error-dialog',
					model.c6,
					_List_fromArray(
						[
							A2($author$project$Material$Options$when, err !== '', $author$project$Material$Dialog$open),
							$author$project$Material$Dialog$onClose($author$project$Session$ClearError)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Material$Dialog$surface,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Material$Dialog$header,
									_List_Nil,
									_List_fromArray(
										[
											A3(
											$author$project$Material$Options$styled,
											$elm$html$Html$h2,
											_List_fromArray(
												[$author$project$Material$Dialog$title]),
											_List_fromArray(
												[
													$elm$html$Html$text('Foutmelding')
												]))
										])),
									A2(
									$author$project$Material$Dialog$body,
									_List_fromArray(
										[
											A2(
											$author$project$Material$Options$when,
											$elm$core$String$length(err) > 320,
											$author$project$Material$Dialog$scrollable)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(err)
										])),
									A2(
									$author$project$Material$Dialog$footer,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$Material$Button$view,
											$author$project$Session$Mdc,
											'error-accept-button',
											model.c6,
											_List_fromArray(
												[
													$author$project$Material$Button$ripple,
													$author$project$Material$Dialog$accept,
													$author$project$Material$Options$onClick($author$project$Session$ClearError)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Ok')
												]))
										]))
								])),
							A2($author$project$Material$Dialog$backdrop, _List_Nil, _List_Nil)
						]));
			}
		},
		$elm$core$List$head(model.y));
};
var $author$project$Session$Leave = function (a) {
	return {$: 6, a: a};
};
var $author$project$Session$onLeaveDialog = function (model) {
	var open = function () {
		var _v2 = model.hX;
		switch (_v2.$) {
			case 8:
				return model.d8;
			case 7:
				return model.d8;
			case 9:
				return model.d8;
			default:
				return false;
		}
	}();
	return A2(
		$elm$html$Html$Lazy$lazy,
		function (_v0) {
			if (!open) {
				return $elm$html$Html$text('');
			} else {
				return A5(
					$author$project$Material$Dialog$view,
					$author$project$Session$Mdc,
					'leaving-dialog',
					model.c6,
					_List_fromArray(
						[$author$project$Material$Dialog$open]),
					_List_fromArray(
						[
							A2(
							$author$project$Material$Dialog$surface,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Material$Dialog$header,
									_List_Nil,
									_List_fromArray(
										[
											A3(
											$author$project$Material$Options$styled,
											$elm$html$Html$h2,
											_List_fromArray(
												[$author$project$Material$Dialog$title]),
											_List_fromArray(
												[
													$elm$html$Html$text('Pagina verlaten?')
												]))
										])),
									A2(
									$author$project$Material$Dialog$body,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Wijzigingen zijn nog niet bewaard.')
										])),
									A2(
									$author$project$Material$Dialog$footer,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$Material$Button$view,
											$author$project$Session$Mdc,
											'leaving-cancel-button',
											model.c6,
											_List_fromArray(
												[
													$author$project$Material$Button$ripple,
													$author$project$Material$Dialog$cancel,
													$author$project$Material$Options$onClick(
													$author$project$Session$Leave(false))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Doorgaan met bewerken')
												])),
											A5(
											$author$project$Material$Button$view,
											$author$project$Session$Mdc,
											'leaving-accept-button',
											model.c6,
											_List_fromArray(
												[
													$author$project$Material$Button$ripple,
													$author$project$Material$Dialog$accept,
													$author$project$Material$Options$onClick(
													$author$project$Session$Leave(true))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Verlaten en wijzigingen annuleren')
												]))
										]))
								])),
							A2($author$project$Material$Dialog$backdrop, _List_Nil, _List_Nil)
						]));
			}
		},
		open);
};
var $author$project$Internal$Snackbar$Implementation$defaultConfig = {};
var $author$project$Internal$Snackbar$Implementation$snackbar = F4(
	function (lift, model, options, _v0) {
		var isActive = function () {
			var _v3 = model.cD;
			switch (_v3.$) {
				case 0:
					return false;
				case 1:
					return true;
				default:
					return false;
			}
		}();
		var contents = function () {
			var _v2 = model.cD;
			switch (_v2.$) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					var c = _v2.a;
					return $elm$core$Maybe$Just(c);
				default:
					var c = _v2.a;
					return $elm$core$Maybe$Just(c);
			}
		}();
		var multiline = _Utils_eq(
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.c8;
				},
				contents),
			$elm$core$Maybe$Just(true));
		var onDismiss = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.da;
			},
			contents);
		var actionOnBottom = _Utils_eq(
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.cM;
				},
				contents),
			$elm$core$Maybe$Just(true)) && multiline;
		var action = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.cL;
			},
			contents);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Snackbar$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-snackbar'),
					A2(
					$author$project$Internal$Options$when,
					isActive,
					$author$project$Internal$Options$cs('mdc-snackbar--active')),
					A2(
					$author$project$Internal$Options$when,
					multiline,
					$author$project$Internal$Options$cs('mdc-snackbar--multiline')),
					A2(
					$author$project$Internal$Options$when,
					actionOnBottom,
					$author$project$Internal$Options$cs('mdc-snackbar--action-on-bottom')),
					A2($author$project$Internal$Options$aria, 'live', 'assertive'),
					A2($author$project$Internal$Options$aria, 'atomic', 'true'),
					A2($author$project$Internal$Options$aria, 'hidden', 'true')
				]),
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-snackbar__text')
						]),
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (c) {
								return _List_fromArray(
									[
										$elm$html$Html$text(c.fu)
									]);
							},
							contents))),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-snackbar__action-wrapper')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$button,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-snackbar__action-button'),
									$author$project$Internal$Options$attribute(
									$elm$html$Html$Attributes$type_('button')),
									function () {
									if (!onDismiss.$) {
										var dismissHandler = onDismiss.a;
										return A2(
											$author$project$Internal$Options$on,
											'click',
											$elm$json$Json$Decode$succeed(dismissHandler));
									} else {
										return $author$project$Internal$Options$nop;
									}
								}()
								]),
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									function (actionString) {
										return _List_fromArray(
											[
												$elm$html$Html$text(actionString)
											]);
									},
									action)))
						]))
				]));
	});
var $author$project$Internal$Snackbar$Implementation$view = A3($author$project$Internal$Component$render, $author$project$Internal$Snackbar$Implementation$getSet.ey, $author$project$Internal$Snackbar$Implementation$snackbar, $author$project$Internal$Msg$SnackbarMsg);
var $author$project$Material$Snackbar$view = $author$project$Internal$Snackbar$Implementation$view;
var $author$project$Session$view_ = F4(
	function (model, title, navigationIcons, actionItems) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A5(
					$author$project$Material$Snackbar$view,
					$author$project$Session$Mdc,
					'my-snackbar',
					model.c6,
					_List_fromArray(
						[
							A2($author$project$Material$Options$css, 'z-index', '100')
						]),
					_List_Nil),
					$author$project$Session$errorDialog(model),
					$author$project$Session$onLeaveDialog(model),
					$author$project$Session$confirmDialog(model),
					A4($author$project$Session$appBar, model, title, navigationIcons, actionItems)
				]));
	});
var $author$project$Session$view = F5(
	function (lift, container, title, navigationIcons, actionItems) {
		return A2(
			$elm$html$Html$map,
			lift,
			A4($author$project$Session$view_, container.W, title, navigationIcons, actionItems));
	});
var $author$project$Page$view = F6(
	function (lift, container, title_, navigationIcons, actionItems, content) {
		var session = container.W;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A5($author$project$Session$view, lift, container, title_, navigationIcons, actionItems),
					A3(
					$author$project$Material$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[$author$project$Material$TopAppBar$fixedAdjust]),
					_List_fromArray(
						[
							A2(
							$author$project$Material$LinearProgress$view,
							_List_fromArray(
								[
									function () {
									var _v0 = session.h6;
									if (!_v0) {
										return $author$project$Material$Options$nop;
									} else {
										return $author$project$Material$LinearProgress$indeterminate;
									}
								}()
								]),
							_List_Nil),
							content
						]))
				]));
	});
var $author$project$Page$Home$view = function (model) {
	var loggedin = model.W.hV !== '';
	var actions = loggedin ? _List_fromArray(
		[
			_Utils_Tuple2('power_settings_new', 'Uitloggen')
		]) : _List_Nil;
	return A6(
		$author$project$Page$view,
		$author$project$Page$Home$Session,
		model,
		'ANWB Wateralmanak',
		_List_Nil,
		actions,
		A2(
			$author$project$Material$LayoutGrid$view,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Material$LayoutGrid$cell,
					_List_fromArray(
						[$author$project$Material$LayoutGrid$span4]),
					loggedin ? (A2($author$project$Session$rol, 0, model.W) ? _List_fromArray(
						[
							A3($author$project$Page$Home$button, model, '/anwbwa/app/voorziening', 'Voorzieningen'),
							A3($author$project$Page$Home$button, model, '/anwbwa/app/voorziening/toewijzing', 'Toewijzingen'),
							A3($author$project$Page$Home$button, model, '/anwbwa/app/vaarweg', 'Vaarwegen'),
							A3($author$project$Page$Home$button, model, '/anwbwa/app/kunstwerk', 'Bruggen en sluizen'),
							A3($author$project$Page$Home$button, model, '', ''),
							A3($author$project$Page$Home$button, model, '', 'Print alle voorzieningen'),
							A3($author$project$Page$Home$button, model, '', 'Print vaarwegen Nederland'),
							A3($author$project$Page$Home$button, model, '', 'Print vaarwegen België')
						]) : _List_fromArray(
						[
							$author$project$Page$subtitle2('\n                            Voor toegang tot uw pagina, volgt u de speciale link\n                            die naar u is verstuurd.\n                            ')
						])) : _List_fromArray(
						[
							A3($author$project$Page$Home$button, model, '/anwbwa/app/login', 'Login')
						]))
				])));
};
var $author$project$Page$Kunstwerk$AddPeriode = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Kunstwerk$AfstandBediend = {$: 26};
var $author$project$Page$Kunstwerk$HideAllDagen = {$: 10};
var $author$project$Page$Kunstwerk$KunstwerkType = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$Kunstwerk$Land = function (a) {
	return {$: 27, a: a};
};
var $author$project$Page$Kunstwerk$OpeningstijdenType = function (a) {
	return {$: 24, a: a};
};
var $author$project$Page$Kunstwerk$ShowAllDagen = {$: 11};
var $author$project$Internal$Typography$Implementation$body1 = $author$project$Internal$Options$cs('mdc-typography--body1');
var $author$project$Material$Typography$body1 = $author$project$Internal$Typography$Implementation$body1;
var $author$project$Internal$Button$Implementation$dense = $author$project$Internal$Options$cs('mdc-button--dense');
var $author$project$Material$Button$dense = $author$project$Internal$Button$Implementation$dense;
var $author$project$Internal$Button$Implementation$disabled = $author$project$Internal$Options$option(
	function (options) {
		return _Utils_update(
			options,
			{aQ: true});
	});
var $author$project$Material$Button$disabled = $author$project$Internal$Button$Implementation$disabled;
var $author$project$Page$Kunstwerk$button = F5(
	function (model, disabled, name, msg, caption) {
		return A5(
			$author$project$Material$Button$view,
			$author$project$Page$Kunstwerk$Mdc,
			name,
			model.c6,
			_List_fromArray(
				[
					$author$project$Material$Options$onClick(msg),
					A2($author$project$Material$Options$when, disabled, $author$project$Material$Button$disabled),
					$author$project$Material$Options$cs('field'),
					$author$project$Material$Button$dense
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(caption)
				]));
	});
var $author$project$Internal$LayoutGrid$Implementation$span4Phone = A2(
	$author$project$Internal$LayoutGrid$Implementation$span,
	$elm$core$Maybe$Just('phone'),
	4);
var $author$project$Material$LayoutGrid$span4Phone = $author$project$Internal$LayoutGrid$Implementation$span4Phone;
var $author$project$Internal$LayoutGrid$Implementation$span6Desktop = A2(
	$author$project$Internal$LayoutGrid$Implementation$span,
	$elm$core$Maybe$Just('desktop'),
	6);
var $author$project$Material$LayoutGrid$span6Desktop = $author$project$Internal$LayoutGrid$Implementation$span6Desktop;
var $author$project$Internal$LayoutGrid$Implementation$span8Tablet = A2(
	$author$project$Internal$LayoutGrid$Implementation$span,
	$elm$core$Maybe$Just('tablet'),
	8);
var $author$project$Material$LayoutGrid$span8Tablet = $author$project$Internal$LayoutGrid$Implementation$span8Tablet;
var $author$project$Page$half = _List_fromArray(
	[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]);
var $author$project$Internal$Typography$Implementation$headline5 = $author$project$Internal$Options$cs('mdc-typography--headline5');
var $author$project$Material$Typography$title = $author$project$Internal$Typography$Implementation$headline5;
var $author$project$Page$title = function (value) {
	return A3(
		$author$project$Material$Options$styled,
		$elm$html$Html$h2,
		_List_fromArray(
			[
				$author$project$Material$Typography$title,
				A2($author$project$Material$Options$css, 'margin-bottom', '0'),
				A2($author$project$Material$Options$css, 'margin-top', '.5rem')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(value)
			]));
};
var $author$project$Internal$Card$Implementation$view = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-card'),
			options));
};
var $author$project$Material$Card$view = $author$project$Internal$Card$Implementation$view;
var $author$project$Page$card = F3(
	function (maybeTitle, properties, content) {
		var cardTitle = function () {
			if (maybeTitle.$ === 1) {
				return $elm$html$Html$text('');
			} else {
				var value = maybeTitle.a;
				return $author$project$Page$title(value);
			}
		}();
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (_v0) {
				var title_ = _v0.a;
				var content_ = _v0.b;
				return A2(
					$author$project$Material$LayoutGrid$cell,
					properties,
					_List_fromArray(
						[
							A2(
							$author$project$Material$Card$view,
							_List_Nil,
							A2($elm$core$List$cons, title_, content_))
						]));
			},
			_Utils_Tuple2(cardTitle, content));
	});
var $author$project$Page$halfCard = F2(
	function (maybeTitle, content) {
		return A3($author$project$Page$card, maybeTitle, $author$project$Page$half, content);
	});
var $author$project$Internal$IconToggle$Implementation$icon = function (value) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{ca: value});
		});
};
var $author$project$Internal$IconToggle$Implementation$icon1 = function (value) {
	return $author$project$Internal$IconToggle$Implementation$icon(
		{aC: value, t: value});
};
var $author$project$Material$IconToggle$icon1 = $author$project$Internal$IconToggle$Implementation$icon1;
var $author$project$Internal$IconToggle$Implementation$defaultConfig = {
	ca: {aC: '', t: ''},
	az: $elm$core$Maybe$Nothing,
	e2: {aC: '', t: ''},
	t: false
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Internal$IconToggle$Implementation$iconToggle = F5(
	function (domId, lift, model, options, _v0) {
		var ripple = A5(
			$author$project$Internal$Ripple$Implementation$view,
			true,
			domId,
			A2($elm$core$Basics$composeL, lift, $elm$core$Basics$identity),
			model.U,
			_List_Nil);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$IconToggle$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			_Utils_eq(config.az, $elm$core$Maybe$Nothing) ? $elm$html$Html$i : $elm$html$Html$span,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-icon-toggle'),
					A2(
					$author$project$Internal$Options$when,
					_Utils_eq(config.az, $elm$core$Maybe$Nothing),
					$author$project$Internal$Options$cs('material-icons')),
					A2(
					$author$project$Internal$Options$aria,
					'label',
					config.t ? config.e2.t : config.e2.aC),
					$author$project$Internal$Options$many(
					_List_fromArray(
						[ripple.hS, ripple.h7]))
				]),
			_List_Nil,
			_List_fromArray(
				[
					(!_Utils_eq(config.az, $elm$core$Maybe$Nothing)) ? A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$i,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs(
							A2($elm$core$Maybe$withDefault, 'material-icons', config.az)),
							config.t ? $author$project$Internal$Options$cs(config.ca.t) : $author$project$Internal$Options$cs(config.ca.aC)
						]),
					_List_Nil) : $elm$html$Html$text(
					config.t ? config.ca.t : config.ca.aC),
					ripple.ih
				]));
	});
var $author$project$Internal$IconToggle$Implementation$view = F2(
	function (lift, index) {
		return A5(
			$author$project$Internal$Component$render,
			$author$project$Internal$IconToggle$Implementation$getSet.ey,
			$author$project$Internal$IconToggle$Implementation$iconToggle(index),
			$author$project$Internal$Msg$IconToggleMsg,
			lift,
			index);
	});
var $author$project$Material$IconToggle$view = $author$project$Internal$IconToggle$Implementation$view;
var $author$project$Page$Kunstwerk$iconButton = F7(
	function (model, disabled, id, name, msg, icon, help) {
		return A5(
			$author$project$Material$IconToggle$view,
			$author$project$Page$Kunstwerk$Mdc,
			name + ('-' + $elm$core$String$fromInt(id)),
			model.c6,
			_List_fromArray(
				[
					$author$project$Material$IconToggle$icon1(icon),
					$author$project$Material$Options$onClick(msg),
					$author$project$Material$Options$attribute(
					A2($elm$html$Html$Attributes$attribute, 'title', help)),
					$author$project$Material$Options$cs('field')
				]),
			_List_Nil);
	});
var $author$project$Material$LayoutGrid$inner = $author$project$Internal$LayoutGrid$Implementation$inner;
var $author$project$Page$spread = _List_fromArray(
	[$author$project$Material$LayoutGrid$span12]);
var $author$project$Page$spreadCard = F2(
	function (maybeTitle, content) {
		return A3($author$project$Page$card, maybeTitle, $author$project$Page$spread, content);
	});
var $author$project$Internal$Switch$Implementation$disabled = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{aQ: true});
	});
var $author$project$Material$Switch$disabled = $author$project$Internal$Switch$Implementation$disabled;
var $author$project$Internal$Switch$Implementation$on = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{bG: true});
	});
var $author$project$Material$Switch$on = $author$project$Internal$Switch$Implementation$on;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Internal$Switch$Model$NoOp = {$: 1};
var $author$project$Internal$Switch$Model$SetFocus = function (a) {
	return {$: 0, a: a};
};
var $author$project$Internal$Switch$Implementation$defaultConfig = {aQ: false, c3: '', fz: _List_Nil, bG: false};
var $author$project$Internal$Switch$Implementation$switch = F4(
	function (lift, model, options, _v0) {
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Switch$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-switch')
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Internal$Options$applyNativeControl,
					summary,
					$elm$html$Html$input,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-switch__native-control'),
							$author$project$Internal$Options$id(config.c3),
							$author$project$Internal$Options$attribute(
							$elm$html$Html$Attributes$type_('checkbox')),
							$author$project$Internal$Options$attribute(
							$elm$html$Html$Attributes$checked(config.bG)),
							$author$project$Internal$Options$onFocus(
							lift(
								$author$project$Internal$Switch$Model$SetFocus(true))),
							$author$project$Internal$Options$onBlur(
							lift(
								$author$project$Internal$Switch$Model$SetFocus(false))),
							A2(
							$author$project$Internal$Options$onWithOptions,
							'click',
							$elm$json$Json$Decode$succeed(
								{
									fu: lift($author$project$Internal$Switch$Model$NoOp),
									fW: true,
									gE: false
								})),
							A3(
							$elm$core$Basics$composeL,
							$author$project$Internal$Options$when(config.aQ),
							$author$project$Internal$Options$many,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-checkbox--disabled'),
									$author$project$Internal$Options$attribute(
									$elm$html$Html$Attributes$disabled(true))
								]))
						]),
					_List_Nil),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-switch__background')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-switch__knob')
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Internal$Switch$Implementation$view = F4(
	function (lift, index, store, options) {
		return A7(
			$author$project$Internal$Component$render,
			$author$project$Internal$Switch$Implementation$getSet.ey,
			$author$project$Internal$Switch$Implementation$switch,
			$author$project$Internal$Msg$SwitchMsg,
			lift,
			index,
			store,
			A2(
				$elm$core$List$cons,
				$author$project$Internal$Options$internalId(index),
				options));
	});
var $author$project$Material$Switch$view = $author$project$Internal$Switch$Implementation$view;
var $author$project$Page$Kunstwerk$switch = F7(
	function (model, disabled, id, name, isOn, msg, caption) {
		return A2(
			$author$project$Material$FormField$view,
			_List_Nil,
			_List_fromArray(
				[
					A5(
					$author$project$Material$Switch$view,
					$author$project$Page$Kunstwerk$Mdc,
					name + ('-' + $elm$core$String$fromInt(id)),
					model.c6,
					_List_fromArray(
						[
							A2($author$project$Material$Options$when, isOn, $author$project$Material$Switch$on),
							A2($author$project$Material$Options$when, disabled, $author$project$Material$Switch$disabled),
							$author$project$Material$Options$onClick(msg)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							function () {
							if (!disabled) {
								return $elm$html$Html$Events$onClick(msg);
							} else {
								return A2($elm$html$Html$Attributes$attribute, 'foo', 'bar');
							}
						}()
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(caption)
						]))
				]));
	});
var $author$project$Internal$Options$nativeControl = function (options) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{
					fz: _Utils_ap(config.fz, options)
				});
		});
};
var $author$project$Internal$Textfield$Implementation$nativeControl = $author$project$Internal$Options$nativeControl;
var $author$project$Material$Textfield$nativeControl = $author$project$Internal$Textfield$Implementation$nativeControl;
var $author$project$Internal$Textfield$Implementation$rows = function (value_) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{
					bv: $elm$core$Maybe$Just(value_)
				});
		});
};
var $author$project$Material$Textfield$rows = $author$project$Internal$Textfield$Implementation$rows;
var $author$project$Field$Blur = function (a) {
	return {$: 3, a: a};
};
var $author$project$Field$Change = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Field$Focus = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Field$Input = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Field$KeyDown = F4(
	function (a, b, c, d) {
		return {$: 4, a: a, b: b, c: c, d: d};
	});
var $author$project$Material$Options$id = $author$project$Internal$Options$id;
var $author$project$Internal$Textfield$Implementation$invalid = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{cf: true});
	});
var $author$project$Material$Textfield$invalid = $author$project$Internal$Textfield$Implementation$invalid;
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Internal$Textfield$Implementation$label = A2(
	$elm$core$Basics$composeL,
	$author$project$Internal$Options$option,
	F2(
		function (str, config) {
			return _Utils_update(
				config,
				{
					ck: $elm$core$Maybe$Just(str)
				});
		}));
var $author$project$Material$Textfield$label = $author$project$Internal$Textfield$Implementation$label;
var $author$project$Material$Options$onBlur = $author$project$Internal$Options$onBlur;
var $author$project$Material$Options$onFocus = $author$project$Internal$Options$onFocus;
var $author$project$Internal$Options$onInput = function (f) {
	return A2(
		$author$project$Internal$Options$on,
		'input',
		A2($elm$json$Json$Decode$map, f, $elm$html$Html$Events$targetValue));
};
var $author$project$Material$Options$onInput = $author$project$Internal$Options$onInput;
var $author$project$Internal$Textfield$Implementation$required = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{dc: true});
	});
var $author$project$Material$Textfield$required = $author$project$Internal$Textfield$Implementation$required;
var $author$project$Internal$Textfield$Implementation$textarea = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{O: true});
	});
var $author$project$Material$Textfield$textarea = $author$project$Internal$Textfield$Implementation$textarea;
var $author$project$Internal$Textfield$Implementation$value = function (value_) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{
					bG: $elm$core$Maybe$Just(value_)
				});
		});
};
var $author$project$Material$Textfield$value = $author$project$Internal$Textfield$Implementation$value;
var $author$project$Internal$Textfield$Model$Blur = {$: 0};
var $author$project$Internal$Textfield$Model$Focus = function (a) {
	return {$: 1, a: a};
};
var $author$project$Internal$Textfield$Model$Input = function (a) {
	return {$: 2, a: a};
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$html$Html$Attributes$cols = function (n) {
	return A2(
		_VirtualDom_attribute,
		'cols',
		$elm$core$String$fromInt(n));
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $author$project$Internal$Textfield$Model$Geometry = F3(
	function (width, height, labelWidth) {
		return {eH: height, hW: labelWidth, hn: width};
	});
var $author$project$DOM$childNode = function (idx) {
	return $elm$json$Json$Decode$at(
		_List_fromArray(
			[
				'childNodes',
				$elm$core$String$fromInt(idx)
			]));
};
var $author$project$DOM$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $author$project$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $author$project$DOM$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
var $author$project$Internal$Textfield$Implementation$decodeGeometry = $author$project$DOM$target(
	$author$project$DOM$parentElement(
		A4(
			$elm$json$Json$Decode$map3,
			$author$project$Internal$Textfield$Model$Geometry,
			A2($author$project$DOM$childNode, 2, $author$project$DOM$offsetWidth),
			A2($author$project$DOM$childNode, 2, $author$project$DOM$offsetHeight),
			A2($author$project$DOM$childNode, 1, $author$project$DOM$offsetWidth))));
var $author$project$Internal$Textfield$Implementation$defaultConfig = {
	dM: false,
	bf: $elm$core$Maybe$Nothing,
	d2: $elm$core$Maybe$Nothing,
	bg: false,
	aQ: false,
	bk: false,
	cb: true,
	c3: '',
	cf: false,
	e3: false,
	ck: $elm$core$Maybe$Nothing,
	cm: $elm$core$Maybe$Nothing,
	fz: _List_Nil,
	aE: false,
	a_: $elm$core$Maybe$Nothing,
	bs: $elm$core$Maybe$Nothing,
	dc: false,
	bv: $elm$core$Maybe$Nothing,
	O: false,
	cI: $elm$core$Maybe$Nothing,
	$9: $elm$core$Maybe$Just('text'),
	bG: $elm$core$Maybe$Nothing
};
var $author$project$Internal$Textfield$Model$defaultGeometry = {eH: 0, hW: 0, hn: 0};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Internal$Textfield$Implementation$textField = F5(
	function (domId, lift, model, options, _v0) {
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Textfield$Implementation$defaultConfig, options);
		var config = summary.dY;
		var focused = model.c$ && (!config.aQ);
		var isDirty = model.eP || A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$neq(''),
				config.bG));
		var isInvalid = config.cf || function () {
			var _v4 = config.a_;
			if (!_v4.$) {
				var pattern_ = _v4.a;
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A3(
						$elm$core$Maybe$map2,
						function (regex) {
							return A2(
								$elm$core$Basics$composeL,
								$elm$core$Basics$not,
								$elm$regex$Regex$contains(regex));
						},
						$elm$regex$Regex$fromString('^' + (pattern_ + '$')),
						model.bG));
			} else {
				return false;
			}
		}();
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-text-field'),
					$author$project$Internal$Options$cs('mdc-text-field--upgraded'),
					A2(
					$author$project$Internal$Options$when,
					focused,
					$author$project$Internal$Options$cs('mdc-text-field--focused')),
					A2(
					$author$project$Internal$Options$when,
					config.aQ,
					$author$project$Internal$Options$cs('mdc-text-field--disabled')),
					A2(
					$author$project$Internal$Options$when,
					config.bg,
					$author$project$Internal$Options$cs('mdc-text-field--dense')),
					A2(
					$author$project$Internal$Options$when,
					config.bk,
					$author$project$Internal$Options$cs('mdc-text-field--fullwidth')),
					A2(
					$author$project$Internal$Options$when,
					isInvalid,
					$author$project$Internal$Options$cs('mdc-text-field--invalid')),
					A2(
					$author$project$Internal$Options$when,
					config.O,
					$author$project$Internal$Options$cs('mdc-text-field--textarea')),
					A2(
					$author$project$Internal$Options$when,
					config.aE,
					$author$project$Internal$Options$cs('mdc-text-field--outlined')),
					A2(
					$author$project$Internal$Options$when,
					(!_Utils_eq(config.cm, $elm$core$Maybe$Nothing)) && _Utils_eq(config.cI, $elm$core$Maybe$Nothing),
					$author$project$Internal$Options$cs('mdc-text-field--with-leading-icon')),
					A2(
					$author$project$Internal$Options$when,
					!_Utils_eq(config.cI, $elm$core$Maybe$Nothing),
					$author$project$Internal$Options$cs('mdc-text-field--with-trailing-icon')),
					A2(
					$author$project$Internal$Options$when,
					config.dM,
					$author$project$Internal$Options$cs('mdc-text-field--box'))
				]),
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A4(
							$author$project$Internal$Options$applyNativeControl,
							summary,
							config.O ? $elm$html$Html$textarea : $elm$html$Html$input,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-text-field__input'),
									A2($author$project$Internal$Options$css, 'outline', 'none'),
									$author$project$Internal$Options$id(config.c3),
									config.aE ? A2(
									$author$project$Internal$Options$on,
									'focus',
									A2(
										$elm$json$Json$Decode$map,
										A2($elm$core$Basics$composeL, lift, $author$project$Internal$Textfield$Model$Focus),
										$author$project$Internal$Textfield$Implementation$decodeGeometry)) : A2(
									$author$project$Internal$Options$on,
									'focus',
									$elm$json$Json$Decode$succeed(
										lift(
											$author$project$Internal$Textfield$Model$Focus($author$project$Internal$Textfield$Model$defaultGeometry)))),
									$author$project$Internal$Options$onBlur(
									lift($author$project$Internal$Textfield$Model$Blur)),
									$author$project$Internal$Options$onInput(
									A2($elm$core$Basics$composeL, lift, $author$project$Internal$Textfield$Model$Input)),
									A3(
									$elm$core$Basics$composeL,
									A2(
										$elm$core$Basics$composeL,
										$author$project$Internal$Options$many,
										$elm$core$List$map($author$project$Internal$Options$attribute)),
									$elm$core$List$filterMap($elm$core$Basics$identity),
									_List_fromArray(
										[
											((!config.O) ? $elm$core$Maybe$Just : $elm$core$Basics$always($elm$core$Maybe$Nothing))(
											$elm$html$Html$Attributes$type_(
												A2($elm$core$Maybe$withDefault, 'text', config.$9))),
											(config.aQ ? $elm$core$Maybe$Just : $elm$core$Basics$always($elm$core$Maybe$Nothing))(
											$elm$html$Html$Attributes$disabled(true)),
											(config.dc ? $elm$core$Maybe$Just : $elm$core$Basics$always($elm$core$Maybe$Nothing))(
											A2(
												$elm$html$Html$Attributes$property,
												'required',
												$elm$json$Json$Encode$bool(true))),
											((!_Utils_eq(config.a_, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just : $elm$core$Basics$always($elm$core$Maybe$Nothing))(
											A2(
												$elm$html$Html$Attributes$property,
												'pattern',
												$elm$json$Json$Encode$string(
													A2($elm$core$Maybe$withDefault, '', config.a_)))),
											$elm$core$Maybe$Just(
											A2($elm$html$Html$Attributes$attribute, 'outline', 'medium none')),
											((!_Utils_eq(config.bG, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just : $elm$core$Basics$always($elm$core$Maybe$Nothing))(
											$elm$html$Html$Attributes$value(
												A2($elm$core$Maybe$withDefault, '', config.bG)))
										])),
									A2(
									$author$project$Internal$Options$when,
									!_Utils_eq(config.bs, $elm$core$Maybe$Nothing),
									$author$project$Internal$Options$attribute(
										$elm$html$Html$Attributes$placeholder(
											A2($elm$core$Maybe$withDefault, '', config.bs)))),
									A2(
									$author$project$Internal$Options$when,
									config.O && (!_Utils_eq(config.bv, $elm$core$Maybe$Nothing)),
									$author$project$Internal$Options$attribute(
										$elm$html$Html$Attributes$rows(
											A2($elm$core$Maybe$withDefault, 0, config.bv)))),
									A2(
									$author$project$Internal$Options$when,
									config.O && (!_Utils_eq(config.bf, $elm$core$Maybe$Nothing)),
									$author$project$Internal$Options$attribute(
										$elm$html$Html$Attributes$cols(
											A2($elm$core$Maybe$withDefault, 0, config.bf))))
								]),
							_List_Nil),
							((!config.bk) || config.O) ? A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$label,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-floating-label'),
									A2(
									$author$project$Internal$Options$when,
									focused || isDirty,
									$author$project$Internal$Options$cs('mdc-floating-label--float-above')),
									$author$project$Internal$Options$for(config.c3)
								]),
							function () {
								var _v1 = config.ck;
								if (!_v1.$) {
									var str = _v1.a;
									return _List_fromArray(
										[
											$elm$html$Html$text(str)
										]);
								} else {
									return _List_Nil;
								}
							}()) : $elm$html$Html$text('')
						]),
						((!config.aE) && (!config.O)) ? _List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-line-ripple'),
									A2(
									$author$project$Internal$Options$when,
									model.c$,
									$author$project$Internal$Options$cs('mdc-line-ripple--active'))
								]),
							_List_Nil)
						]) : _List_Nil,
						function () {
						if (config.aE) {
							var isRtl = false;
							var d = function () {
								var radius = 4;
								var labelScale = config.bg ? 0.923 : 0.75;
								var cornerWidth = radius + 1.2;
								var leadingStrokeLength = $elm$core$Basics$abs(11 - cornerWidth);
								var _v2 = A2($elm$core$Maybe$withDefault, $author$project$Internal$Textfield$Model$defaultGeometry, model.c0);
								var labelWidth = _v2.hW;
								var width = _v2.hn;
								var height = _v2.eH;
								var scaledLabelWidth = labelScale * labelWidth;
								var paddedLabelWidth = scaledLabelWidth + 8;
								var pathMiddle = A2(
									$elm$core$String$join,
									'',
									_List_fromArray(
										[
											'a',
											$elm$core$String$fromFloat(radius),
											',',
											$elm$core$String$fromFloat(radius),
											' 0 0 1 ',
											$elm$core$String$fromFloat(radius),
											',',
											$elm$core$String$fromFloat(radius),
											'v',
											$elm$core$String$fromFloat(height - (2 * cornerWidth)),
											'a',
											$elm$core$String$fromFloat(radius),
											',',
											$elm$core$String$fromFloat(radius),
											' 0 0 1 ',
											$elm$core$String$fromFloat(-radius),
											',',
											$elm$core$String$fromFloat(radius),
											'h',
											$elm$core$String$fromFloat((-width) + (2 * cornerWidth)),
											'a',
											$elm$core$String$fromFloat(radius),
											',',
											$elm$core$String$fromFloat(radius),
											' 0 0 1 ',
											$elm$core$String$fromFloat(-radius),
											',',
											$elm$core$String$fromFloat(-radius),
											'v',
											$elm$core$String$fromFloat((-height) + (2 * cornerWidth)),
											'a',
											$elm$core$String$fromFloat(radius),
											',',
											$elm$core$String$fromFloat(radius),
											' 0 0 1 ',
											$elm$core$String$fromFloat(radius),
											',',
											$elm$core$String$fromFloat(-radius)
										]));
								return (!isRtl) ? A2(
									$elm$core$String$join,
									'',
									_List_fromArray(
										[
											'M',
											$elm$core$String$fromFloat((cornerWidth + leadingStrokeLength) + paddedLabelWidth),
											',1h',
											$elm$core$String$fromFloat(((width - (2 * cornerWidth)) - paddedLabelWidth) - leadingStrokeLength),
											pathMiddle,
											'h',
											$elm$core$String$fromFloat(leadingStrokeLength)
										])) : A2(
									$elm$core$String$join,
									'',
									_List_fromArray(
										[
											'M',
											$elm$core$String$fromFloat((width - cornerWidth) - leadingStrokeLength),
											',1h',
											$elm$core$String$fromFloat(leadingStrokeLength),
											pathMiddle,
											'h',
											$elm$core$String$fromFloat(((width - (2 * cornerWidth)) - paddedLabelWidth) - leadingStrokeLength)
										]));
							}();
							return _List_fromArray(
								[
									A3(
									$author$project$Internal$Options$styled,
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Internal$Options$cs('mdc-notched-outline'),
											A2(
											$author$project$Internal$Options$when,
											focused || isDirty,
											$author$project$Internal$Options$cs('mdc-notched-outline--notched'))
										]),
									_List_fromArray(
										[
											A2(
											$elm$svg$Svg$svg,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$svg$Svg$path,
													_List_fromArray(
														[
															$elm$svg$Svg$Attributes$class('mdc-notched-outline__path'),
															$elm$svg$Svg$Attributes$d(d)
														]),
													_List_Nil)
												]))
										])),
									A3(
									$author$project$Internal$Options$styled,
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Internal$Options$cs('mdc-notched-outline__idle')
										]),
									_List_Nil)
								]);
						} else {
							return _List_Nil;
						}
					}(),
						function () {
						var icon = A2(
							$elm$core$Maybe$withDefault,
							config.cI,
							A2($elm$core$Maybe$map, $elm$core$Maybe$Just, config.cm));
						if (!icon.$) {
							var icon_ = icon.a;
							return _List_fromArray(
								[
									A3(
									$author$project$Internal$Options$styled,
									$elm$html$Html$i,
									_List_fromArray(
										[
											$author$project$Internal$Options$cs('material-icons mdc-text-field__icon'),
											A3(
											$elm$core$Basics$composeL,
											$author$project$Internal$Options$attribute,
											$elm$html$Html$Attributes$tabindex,
											config.cb ? 0 : (-1))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(icon_)
										]))
								]);
						} else {
							return _List_Nil;
						}
					}()
					])));
	});
var $author$project$Internal$Textfield$Implementation$view = F4(
	function (lift, domId, store, options) {
		return A7(
			$author$project$Internal$Component$render,
			$author$project$Internal$Textfield$Implementation$getSet.ey,
			$author$project$Internal$Textfield$Implementation$textField(domId),
			$author$project$Internal$Msg$TextfieldMsg,
			lift,
			domId,
			store,
			A2(
				$elm$core$List$cons,
				$author$project$Internal$Options$internalId(domId),
				options));
	});
var $author$project$Material$Textfield$view = $author$project$Internal$Textfield$Implementation$view;
var $author$project$Field$view_ = F6(
	function (multiline, model, field, value, options, html) {
		var fieldModel = A2($author$project$Field$getField, field, model);
		var formatted = fieldModel.b8(value);
		var typed = fieldModel.dl;
		var value_ = function () {
			var _v0 = fieldModel.c$;
			if (!_v0) {
				return formatted;
			} else {
				return typed;
			}
		}();
		return A5(
			$author$project$Material$Textfield$view,
			$author$project$Field$Mdc,
			'textfield-' + field,
			model.c6,
			_Utils_ap(
				options,
				_List_fromArray(
					[
						$author$project$Material$Textfield$value(value_),
						$author$project$Material$Textfield$label(fieldModel.e2),
						A2(
						$author$project$Material$Options$when,
						!A2($author$project$Field$valid, fieldModel, value_),
						$author$project$Material$Textfield$invalid),
						A2($author$project$Material$Options$when, fieldModel.dc, $author$project$Material$Textfield$required),
						$author$project$Material$Options$onInput(
						$author$project$Field$Input(field)),
						$author$project$Material$Options$onChange(
						A2($author$project$Field$Change, field, formatted)),
						A2($author$project$Material$Options$when, multiline, $author$project$Material$Textfield$textarea),
						$author$project$Material$Textfield$nativeControl(
						_List_fromArray(
							[
								$author$project$Material$Options$id('textfield-native-' + field),
								$author$project$Material$Options$onFocus(
								A2($author$project$Field$Focus, field, formatted)),
								$author$project$Material$Options$onBlur(
								$author$project$Field$Blur(field)),
								A2(
								$author$project$Material$Options$on,
								'keydown',
								A2(
									$elm$json$Json$Decode$map,
									A3($author$project$Field$KeyDown, field, formatted, multiline),
									$elm$html$Html$Events$keyCode))
							]))
					])),
			_Utils_ap(html, _List_Nil));
	});
var $author$project$Field$viewMultiline = F6(
	function (lift, model, field, value, options, html) {
		return A2(
			$elm$html$Html$map,
			lift,
			A6($author$project$Field$view_, true, model, field, value, options, html));
	});
var $author$project$Page$textarea_ = F7(
	function (lift, container, field, value, rows, height, properties) {
		return A6(
			$author$project$Field$viewMultiline,
			lift,
			container.cY,
			field,
			value,
			_Utils_ap(
				properties,
				_List_fromArray(
					[
						$author$project$Material$Textfield$rows(rows),
						$author$project$Material$Textfield$nativeControl(
						_List_fromArray(
							[
								A2($author$project$Material$Options$css, 'height', height)
							]))
					])),
			_List_Nil);
	});
var $author$project$Page$textarea = F5(
	function (lift, container, field, value, properties) {
		return A7($author$project$Page$textarea_, lift, container, field, value, 2, '70px', properties);
	});
var $author$project$Field$view = F6(
	function (lift, model, field, value, options, html) {
		return A2(
			$elm$html$Html$map,
			lift,
			A6($author$project$Field$view_, false, model, field, value, options, html));
	});
var $author$project$Page$textfield_ = F5(
	function (lift, model, field, value, properties) {
		return A6($author$project$Field$view, lift, model, field, value, properties, _List_Nil);
	});
var $author$project$Page$textfield = F5(
	function (lift, container, field, value, properties) {
		return A5(
			$author$project$Page$textfield_,
			lift,
			container.cY,
			field,
			value,
			A2(
				$elm$core$List$cons,
				$author$project$Material$Options$cs('full-width'),
				properties));
	});
var $author$project$Page$Component$Land$view = F5(
	function (mmsg, _v0, landcode, msg, options) {
		var mdc = _v0.c6;
		var session = _v0.W;
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (_v1) {
				var _v2 = session.h9;
				if (_v2.$ === 1) {
					return $elm$html$Html$text('');
				} else {
					var refdata = _v2.a;
					return A5(
						$author$project$Material$Select$view,
						mmsg,
						'select-land',
						mdc,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$Material$Options$cs('field'),
									$author$project$Material$Select$label('Land *'),
									A2($author$project$Material$Options$when, landcode !== '', $author$project$Material$Select$preselected),
									$author$project$Material$Options$onChange(msg)
								]),
							options),
						A2(
							$elm$core$List$map,
							function (_v3) {
								var code = _v3.dW;
								var naam = _v3.aY;
								return A2(
									$author$project$Material$Select$option,
									_List_fromArray(
										[
											$author$project$Material$Select$value(code),
											A2(
											$author$project$Material$Options$when,
											_Utils_eq(code, landcode),
											$author$project$Material$Select$selected)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(naam)
										]));
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.aY;
								},
								refdata.e6)));
				}
			},
			landcode);
	});
var $author$project$Street$Select = function (a) {
	return {$: 5, a: a};
};
var $author$project$Internal$Menu$Implementation$anchorCorner = function (value) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{G: value});
		});
};
var $author$project$Material$Menu$anchorCorner = $author$project$Internal$Menu$Implementation$anchorCorner;
var $author$project$Internal$Menu$Implementation$anchorMargin = function (value) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{j: value});
		});
};
var $author$project$Material$Menu$anchorMargin = $author$project$Internal$Menu$Implementation$anchorMargin;
var $author$project$Internal$Textfield$Implementation$fullwidth = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{bk: true});
	});
var $author$project$Material$Textfield$fullwidth = $author$project$Internal$Textfield$Implementation$fullwidth;
var $author$project$Internal$Menu$Implementation$li = F2(
	function (options, childs) {
		return {be: childs, aR: false, aD: options};
	});
var $author$project$Material$Menu$li = $author$project$Internal$Menu$Implementation$li;
var $author$project$Internal$Menu$Implementation$decodeKey = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['key']),
	$elm$json$Json$Decode$string);
var $author$project$Internal$Menu$Implementation$decodeKeyCode = $elm$html$Html$Events$keyCode;
var $author$project$Internal$Menu$Implementation$onSelect = function (msg) {
	var trigger = F2(
		function (key, keyCode) {
			var isSpace = (key === 'Space') || (keyCode === 32);
			var isEnter = (key === 'Enter') || (keyCode === 13);
			return (isSpace || isEnter) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
		});
	return $author$project$Internal$Options$many(
		_List_fromArray(
			[
				$author$project$Internal$Options$onClick(msg),
				A2(
				$author$project$Internal$Options$on,
				'keyup',
				A2(
					$elm$json$Json$Decode$andThen,
					$elm$core$Basics$identity,
					A3($elm$json$Json$Decode$map2, trigger, $author$project$Internal$Menu$Implementation$decodeKey, $author$project$Internal$Menu$Implementation$decodeKeyCode)))
			]));
};
var $author$project$Material$Menu$onSelect = $author$project$Internal$Menu$Implementation$onSelect;
var $author$project$Internal$Textfield$Implementation$box = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{dM: true});
	});
var $author$project$Material$Textfield$box = $author$project$Internal$Textfield$Implementation$box;
var $author$project$Street$whiteTransparentBackground = A2($author$project$Material$Options$css, 'background-color', 'rgba(255, 255, 255, 0.77)');
var $author$project$Street$ordinateTextField = F3(
	function (field, value, model) {
		return A6(
			$author$project$Field$view,
			$author$project$Street$Field,
			model.cY,
			field,
			$elm$core$String$fromFloat(value),
			_List_fromArray(
				[
					$author$project$Material$Textfield$box,
					$author$project$Street$whiteTransparentBackground,
					A2($author$project$Material$Options$css, 'margin-right', '.5em')
				]),
			_List_Nil);
	});
var $author$project$Internal$Textfield$Implementation$placeholder = function (value_) {
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{
					bs: $elm$core$Maybe$Just(value_)
				});
		});
};
var $author$project$Material$Textfield$placeholder = $author$project$Internal$Textfield$Implementation$placeholder;
var $author$project$Internal$Menu$Implementation$topLeftCorner = {c: false, H: false, z: false, f: false};
var $author$project$Material$Menu$topLeftCorner = $author$project$Internal$Menu$Implementation$topLeftCorner;
var $author$project$Internal$Menu$Implementation$ul = F2(
	function (options, items) {
		return {bo: items, aD: options};
	});
var $author$project$Material$Menu$ul = $author$project$Internal$Menu$Implementation$ul;
var $author$project$Internal$Menu$Model$CloseDelayed = {$: 6};
var $author$project$Internal$Menu$Model$Init = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Internal$Menu$Model$KeyDown = F4(
	function (a, b, c, d) {
		return {$: 8, a: a, b: b, c: c, d: d};
	});
var $author$project$Internal$Menu$Model$KeyUp = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$Internal$Menu$Model$NoOp = {$: 0};
var $author$project$Internal$Menu$Model$SetFocus = function (a) {
	return {$: 10, a: a};
};
var $author$project$Internal$Menu$Implementation$horizontalOffset = F3(
	function (config, corner, geometry) {
		var isRightAligned = corner.f;
		var avoidHorizontalOverlap = config.G.f;
		return isRightAligned ? (avoidHorizontalOverlap ? (geometry.r.hn - config.j.fc) : config.j.f) : (avoidHorizontalOverlap ? (geometry.r.hn - config.j.f) : config.j.fc);
	});
var $author$project$Internal$Menu$Implementation$menuMaxHeight = F3(
	function (config, corner, geometry) {
		var isBottomAligned = corner.c;
		return config.G.c ? (isBottomAligned ? (geometry.q.g0 + config.j.g0) : (geometry.q.c - config.j.c)) : 0;
	});
var $author$project$Internal$Menu$Implementation$originCorner = F2(
	function (config, geometry) {
		var isRtl = false;
		var isFlipRtl = config.G.z;
		var isBottomAligned = config.G.c;
		var flipRtl = false;
		var center = false;
		var avoidHorizontalOverlap = config.G.f;
		var isAlignedRight = (avoidHorizontalOverlap && (!isRtl)) || ((!avoidHorizontalOverlap) && (isFlipRtl && isRtl));
		var availableTop = isBottomAligned ? ((geometry.q.g0 + geometry.r.eH) + config.j.c) : (geometry.q.g0 + config.j.g0);
		var topOverflow = geometry.p.eH - availableTop;
		var availableRight = isAlignedRight ? (geometry.q.f - config.j.f) : ((geometry.q.f + geometry.r.hn) - config.j.fc);
		var rightOverflow = geometry.p.hn - availableRight;
		var availableLeft = isAlignedRight ? ((geometry.q.fc + geometry.r.hn) + config.j.f) : (geometry.q.fc + config.j.fc);
		var leftOverflow = geometry.p.hn - availableLeft;
		var right = ((leftOverflow < 0) && (isAlignedRight && isRtl)) || ((avoidHorizontalOverlap && ((!isAlignedRight) && (leftOverflow < 0))) || ((rightOverflow > 0) && (_Utils_cmp(leftOverflow, rightOverflow) < 0)));
		var availableBottom = isBottomAligned ? (geometry.q.c - config.j.c) : ((geometry.q.c + geometry.r.eH) + config.j.g0);
		var bottomOverflow = geometry.p.eH - availableBottom;
		var bottom = (bottomOverflow > 0) && (_Utils_cmp(topOverflow, bottomOverflow) < 0);
		return {c: bottom, H: center, z: flipRtl, f: right};
	});
var $author$project$Internal$Menu$Implementation$verticalOffset = F3(
	function (config, corner, geometry) {
		var marginToEdge = 32;
		var isBottomAligned = corner.c;
		var avoidVerticalOverlap = config.G.c;
		var canOverlapVertically = !avoidVerticalOverlap;
		return isBottomAligned ? ((canOverlapVertically && (_Utils_cmp(geometry.p.eH, geometry.q.g0 + geometry.r.eH) > 0)) ? (-(A2($elm$core$Basics$min, geometry.p.eH, geometry.hb.eH - marginToEdge) - (geometry.q.g0 + geometry.r.eH))) : (avoidVerticalOverlap ? (geometry.r.eH - config.j.g0) : (-config.j.c))) : ((canOverlapVertically && (_Utils_cmp(geometry.p.eH, geometry.q.c + geometry.r.eH) > 0)) ? (-(A2($elm$core$Basics$min, geometry.p.eH, geometry.hb.eH - marginToEdge) - (geometry.q.g0 + geometry.r.eH))) : (avoidVerticalOverlap ? (geometry.r.eH + config.j.c) : config.j.g0));
	});
var $author$project$Internal$Menu$Implementation$autoPosition = F2(
	function (config, geometry) {
		var corner = A2($author$project$Internal$Menu$Implementation$originCorner, config, geometry);
		var horizontalAlignment = corner.f ? 'right' : 'left';
		var horizontalAlignment_ = ((geometry.r.hn / geometry.p.hn) > 0.67) ? 'center' : horizontalAlignment;
		var horizontalOffset_ = A3($author$project$Internal$Menu$Implementation$horizontalOffset, config, corner, geometry);
		var maxMenuHeight = A3($author$project$Internal$Menu$Implementation$menuMaxHeight, config, corner, geometry);
		var verticalAlignment = corner.c ? 'bottom' : 'top';
		var verticalOffset_ = A3($author$project$Internal$Menu$Implementation$verticalOffset, config, corner, geometry);
		var position = {
			c: (verticalAlignment === 'bottom') ? $elm$core$Maybe$Just(
				$elm$core$String$fromFloat(verticalOffset_) + 'px') : $elm$core$Maybe$Nothing,
			fc: (horizontalAlignment === 'left') ? $elm$core$Maybe$Just(
				$elm$core$String$fromFloat(horizontalOffset_) + 'px') : $elm$core$Maybe$Nothing,
			f: (horizontalAlignment === 'right') ? $elm$core$Maybe$Just(
				$elm$core$String$fromFloat(horizontalOffset_) + 'px') : $elm$core$Maybe$Nothing,
			g0: (verticalAlignment === 'top') ? $elm$core$Maybe$Just(
				$elm$core$String$fromFloat(verticalOffset_) + 'px') : $elm$core$Maybe$Nothing
		};
		var verticalAlignment_ = function () {
			if ((!config.G.c) && ($elm$core$Basics$abs(verticalOffset_ / geometry.p.eH) > 0.1)) {
				var verticalOffsetPercent = $elm$core$Basics$abs(verticalOffset_ / geometry.p.eH) * 100;
				var originPercent = corner.c ? (100 - verticalOffsetPercent) : verticalOffsetPercent;
				return $elm$core$String$fromFloat(
					$elm$core$Basics$round(originPercent * 100) / 100) + '%';
			} else {
				return verticalAlignment;
			}
		}();
		return {
			fo: (!(!maxMenuHeight)) ? ($elm$core$String$fromFloat(maxMenuHeight) + 'px') : '',
			fU: position,
			g5: horizontalAlignment_ + (' ' + verticalAlignment)
		};
	});
var $author$project$Internal$Menu$Model$Geometry = F4(
	function (viewport, viewportDistance, anchor, menu) {
		return {r: anchor, p: menu, hb: viewport, q: viewportDistance};
	});
var $author$project$Internal$Menu$Model$Viewport = F2(
	function (width, height) {
		return {eH: height, hn: width};
	});
var $author$project$Internal$Menu$Implementation$decodeGeometry = function () {
	var decodeViewportDistance = F2(
		function (decodedViewport, decodedAnchorRect) {
			return $elm$json$Json$Decode$succeed(
				{c: (decodedViewport.eH - decodedAnchorRect.g0) - decodedAnchorRect.eH, fc: decodedAnchorRect.fc, f: (decodedViewport.hn - decodedAnchorRect.fc) - decodedAnchorRect.hn, g0: decodedAnchorRect.g0});
		});
	var decodeViewport = $author$project$DOM$target(
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['ownerDocument', 'defaultView']),
			A3(
				$elm$json$Json$Decode$map2,
				$author$project$Internal$Menu$Model$Viewport,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['innerWidth']),
					$elm$json$Json$Decode$float),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['innerHeight']),
					$elm$json$Json$Decode$float))));
	var decodeMenu = A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (offsetWidth, offsetHeight) {
				return {eH: offsetHeight, hn: offsetWidth};
			}),
		$author$project$DOM$offsetWidth,
		$author$project$DOM$offsetHeight);
	var anchorRect = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['parentRect']),
		A5(
			$elm$json$Json$Decode$map4,
			F4(
				function (top, left, width, height) {
					return {eH: height, fc: left, g0: top, hn: width};
				}),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['top']),
				$elm$json$Json$Decode$float),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['left']),
				$elm$json$Json$Decode$float),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['width']),
				$elm$json$Json$Decode$float),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['height']),
				$elm$json$Json$Decode$float)));
	var anchor = function (_v1) {
		var width = _v1.hn;
		var height = _v1.eH;
		return $elm$json$Json$Decode$succeed(
			{eH: height, hn: width});
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			var decodedViewport = _v0.a;
			var decodedeAnchorRect = _v0.b;
			return $author$project$DOM$target(
				A4(
					$elm$json$Json$Decode$map3,
					$author$project$Internal$Menu$Model$Geometry(decodedViewport),
					A2(decodeViewportDistance, decodedViewport, decodedeAnchorRect),
					anchor(decodedeAnchorRect),
					decodeMenu));
		},
		A3(
			$elm$json$Json$Decode$map2,
			F2(
				function (x, y) {
					return _Utils_Tuple2(x, y);
				}),
			decodeViewport,
			anchorRect));
}();
var $author$project$Internal$Menu$Implementation$decodeMeta = A5(
	$elm$json$Json$Decode$map4,
	F4(
		function (altKey, ctrlKey, metaKey, shiftKey) {
			return {bN: altKey, b_: ctrlKey, cp: metaKey, cB: shiftKey};
		}),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['altKey']),
		$elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['ctrlKey']),
		$elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['metaKey']),
		$elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['shiftKey']),
		$elm$json$Json$Decode$bool));
var $author$project$Internal$List$Implementation$defaultConfig = {cr: $elm$core$Maybe$Nothing};
var $author$project$Internal$Menu$Implementation$defaultMargin = {c: 0, fc: 0, f: 0, g0: 0};
var $author$project$Internal$Menu$Implementation$defaultConfig = {G: $author$project$Internal$Menu$Implementation$topLeftCorner, j: $author$project$Internal$Menu$Implementation$defaultMargin, aV: $elm$core$Maybe$Nothing, L: false, D: false};
var $author$project$Internal$Menu$Model$defaultGeometry = {
	r: {eH: 0, hn: 0},
	p: {eH: 0, hn: 0},
	hb: {eH: 0, hn: 0},
	q: {c: 0, fc: 0, f: 0, g0: 0}
};
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Internal$GlobalEvents$encodeTickConfig = function (tickConfig) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'targetRect',
				$elm$json$Json$Encode$bool(tickConfig.gK)),
				_Utils_Tuple2(
				'parentRect',
				$elm$json$Json$Encode$bool(tickConfig.fJ))
			]));
};
var $author$project$Internal$GlobalEvents$listenerWithValue = F3(
	function (name, value, decoder) {
		return $author$project$Material$Options$many(
			_List_fromArray(
				[
					A2($author$project$Material$Options$on, name, decoder),
					A2(
					$author$project$Material$Options$data,
					name,
					A2($elm$json$Json$Encode$encode, 0, value))
				]));
	});
var $author$project$Internal$GlobalEvents$onTickWith = function (config) {
	return A2(
		$author$project$Internal$GlobalEvents$listenerWithValue,
		'globaltick',
		$author$project$Internal$GlobalEvents$encodeTickConfig(config));
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Internal$List$Implementation$ul = function (options) {
	var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$List$Implementation$defaultConfig, options);
	var config = summary.dY;
	return A4(
		$author$project$Internal$Options$apply,
		summary,
		A2($elm$core$Maybe$withDefault, $elm$html$Html$ul, config.cr),
		_List_fromArray(
			[
				$author$project$Internal$Options$cs('mdc-list')
			]),
		_List_Nil);
};
var $author$project$Internal$Menu$Implementation$menu = F4(
	function (lift, model, options, ulNode) {
		var numItems = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$Basics$not,
					function ($) {
						return $.aR;
					}),
				ulNode.bo));
		var numDividersBeforeIndex = function (i) {
			return $elm$core$List$length(
				A2(
					$elm$core$List$filter,
					function ($) {
						return $.aR;
					},
					A2($elm$core$List$take, i + 1, ulNode.bo)));
		};
		var isOpen = model.cO ? (model.L && (!_Utils_eq(model.c0, $elm$core$Maybe$Nothing))) : model.L;
		var geometry = A2($elm$core$Maybe$withDefault, $author$project$Internal$Menu$Model$defaultGeometry, model.c0);
		var focusedItemAtIndex = model.R;
		var preventDefaultOnKeyDown = F3(
			function (_v1, key, keyCode) {
				var altKey = _v1.bN;
				var ctrlKey = _v1.b_;
				var metaKey = _v1.cp;
				var shiftKey = _v1.cB;
				var lastItemIndex = numItems - 1;
				var isTab = (key === 'Tab') || (keyCode === 9);
				var isSpace = (key === 'Space') || (keyCode === 32);
				var isArrowUp = (key === 'ArrowUp') || (keyCode === 38);
				var isArrowDown = (key === 'ArrowDown') || (keyCode === 40);
				return (altKey || (ctrlKey || metaKey)) ? $elm$json$Json$Decode$fail('') : ((shiftKey && (isTab && _Utils_eq(
					A2($elm$core$Maybe$withDefault, 0, focusedItemAtIndex),
					lastItemIndex))) ? $elm$json$Json$Decode$succeed(
					lift($author$project$Internal$Menu$Model$NoOp)) : ((isArrowUp || (isArrowDown || isSpace)) ? $elm$json$Json$Decode$succeed(
					lift($author$project$Internal$Menu$Model$NoOp)) : $elm$json$Json$Decode$fail('')));
			});
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Menu$Implementation$defaultConfig, options);
		var config = summary.dY;
		var _v0 = A2($author$project$Internal$Menu$Implementation$autoPosition, config, geometry);
		var position = _v0.fU;
		var transformOrigin = _v0.g5;
		var maxHeight = _v0.fo;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-menu'),
					A2(
					$author$project$Internal$Options$when,
					model.cO && (!A2($elm$core$Maybe$withDefault, false, model.D)),
					model.L ? $author$project$Internal$Options$cs('mdc-menu--animating-open') : $author$project$Internal$Options$cs('mdc-menu--animating-closed')),
					A3(
					$elm$core$Basics$composeL,
					$author$project$Internal$Options$when(isOpen),
					$author$project$Internal$Options$many,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-menu--open'),
							A2($author$project$Internal$Options$data, 'focustrap', ''),
							A2(
							$author$project$Internal$Options$onWithOptions,
							'click',
							$elm$json$Json$Decode$succeed(
								{
									fu: lift($author$project$Internal$Menu$Model$CloseDelayed),
									fW: false,
									gE: true
								}))
						])),
					A3(
					$elm$core$Basics$composeL,
					$author$project$Internal$Options$when(isOpen || model.cO),
					$author$project$Internal$Options$many,
					_List_fromArray(
						[
							A2($author$project$Internal$Options$css, 'position', 'absolute'),
							A2($author$project$Internal$Options$css, 'transform-origin', transformOrigin),
							A2(
							$author$project$Internal$Options$when,
							!_Utils_eq(position.g0, $elm$core$Maybe$Nothing),
							A2(
								$author$project$Internal$Options$css,
								'top',
								A2($elm$core$Maybe$withDefault, '', position.g0))),
							A2(
							$author$project$Internal$Options$when,
							!_Utils_eq(position.fc, $elm$core$Maybe$Nothing),
							A2(
								$author$project$Internal$Options$css,
								'left',
								A2($elm$core$Maybe$withDefault, '', position.fc))),
							A2(
							$author$project$Internal$Options$when,
							!_Utils_eq(position.c, $elm$core$Maybe$Nothing),
							A2(
								$author$project$Internal$Options$css,
								'bottom',
								A2($elm$core$Maybe$withDefault, '', position.c))),
							A2(
							$author$project$Internal$Options$when,
							!_Utils_eq(position.f, $elm$core$Maybe$Nothing),
							A2(
								$author$project$Internal$Options$css,
								'right',
								A2($elm$core$Maybe$withDefault, '', position.f))),
							A2($author$project$Internal$Options$css, 'max-height', maxHeight)
						])),
					A2(
					$author$project$Internal$Options$when,
					model.cO && _Utils_eq(model.c0, $elm$core$Maybe$Nothing),
					A2(
						$author$project$Internal$GlobalEvents$onTickWith,
						{fJ: true, gK: false},
						A2(
							$elm$json$Json$Decode$map,
							A2(
								$elm$core$Basics$composeL,
								lift,
								$author$project$Internal$Menu$Model$Init(
									{aV: config.aV, D: config.D})),
							$author$project$Internal$Menu$Implementation$decodeGeometry))),
					A2(
					$author$project$Internal$Options$on,
					'keyup',
					A2(
						$elm$json$Json$Decode$map,
						lift,
						A4($elm$json$Json$Decode$map3, $author$project$Internal$Menu$Model$KeyUp, $author$project$Internal$Menu$Implementation$decodeMeta, $author$project$Internal$Menu$Implementation$decodeKey, $author$project$Internal$Menu$Implementation$decodeKeyCode))),
					A2(
					$author$project$Internal$Options$on,
					'keydown',
					A2(
						$elm$json$Json$Decode$map,
						lift,
						A4(
							$elm$json$Json$Decode$map3,
							$author$project$Internal$Menu$Model$KeyDown(numItems),
							$author$project$Internal$Menu$Implementation$decodeMeta,
							$author$project$Internal$Menu$Implementation$decodeKey,
							$author$project$Internal$Menu$Implementation$decodeKeyCode)))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Internal$List$Implementation$ul,
					A2(
						$elm$core$List$cons,
						$author$project$Internal$Options$cs('mdc-menu__items'),
						ulNode.aD),
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, item) {
								var itemSummary = A2($author$project$Internal$Options$collect, $author$project$Internal$List$Implementation$defaultConfig, item.aD);
								var focusIndex = i - numDividersBeforeIndex(i);
								var hasFocus = _Utils_eq(
									$elm$core$Maybe$Just(focusIndex),
									focusedItemAtIndex);
								var autoFocus = (hasFocus && model.L) ? A2($author$project$Internal$Options$data, 'autofocus', '') : $author$project$Internal$Options$nop;
								return item.aR ? A5(
									$author$project$Internal$Options$apply,
									itemSummary,
									$elm$html$Html$hr,
									_List_fromArray(
										[
											$author$project$Internal$Options$cs('mdc-list-divider')
										]),
									_List_Nil,
									item.be) : A5(
									$author$project$Internal$Options$apply,
									itemSummary,
									$elm$html$Html$li,
									_List_fromArray(
										[
											$author$project$Internal$Options$cs('mdc-list-item'),
											$author$project$Internal$Options$attribute(
											A2($elm$html$Html$Attributes$attribute, 'tabindex', '0')),
											A2(
											$author$project$Internal$Options$on,
											'focus',
											$elm$json$Json$Decode$succeed(
												lift(
													$author$project$Internal$Menu$Model$SetFocus(focusIndex)))),
											autoFocus
										]),
									_List_Nil,
									item.be);
							}),
						ulNode.bo))
				]));
	});
var $author$project$Internal$Menu$Implementation$view = A3($author$project$Internal$Component$render, $author$project$Internal$Menu$Implementation$getSet.ey, $author$project$Internal$Menu$Implementation$menu, $author$project$Internal$Msg$MenuMsg);
var $author$project$Material$Menu$view = $author$project$Internal$Menu$Implementation$view;
var $author$project$Street$view_ = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative'),
				A2($elm$html$Html$Attributes$style, 'border-radius', '4px')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('elm-street')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('place'),
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'top', '.5em'),
						A2($elm$html$Html$Attributes$style, 'left', '.5em'),
						A2($elm$html$Html$Attributes$style, 'width', 'calc(100% - 1em)'),
						$elm$html$Html$Attributes$class('mdc-menu-anchor')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$Material$Options$styled,
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($author$project$Material$Menu$attach, $author$project$Street$Mdc, 'places-menu')
							]),
						_List_fromArray(
							[
								A6(
								$author$project$Field$view,
								$author$project$Street$Field,
								model.cY,
								'place',
								model.an,
								_List_fromArray(
									[
										$author$project$Material$Textfield$fullwidth,
										$author$project$Street$whiteTransparentBackground,
										$author$project$Material$Textfield$placeholder('Zoeken'),
										$author$project$Material$Options$attribute(
										$elm$html$Html$Attributes$title(model.an)),
										A2($author$project$Material$Options$css, 'padding', '0 1em'),
										A2($author$project$Material$Options$css, 'border-radius', '4px'),
										A2($author$project$Material$Options$css, 'background-color', 'rgba(255, 255, 255, .9)')
									]),
								_List_Nil),
								function () {
								var _v0 = $elm$core$List$length(model.aG);
								if (!_v0) {
									return $elm$html$Html$text('');
								} else {
									return A5(
										$author$project$Material$Menu$view,
										$author$project$Street$Mdc,
										'places-menu',
										model.c6,
										_List_fromArray(
											[
												$author$project$Material$Menu$anchorCorner($author$project$Material$Menu$topLeftCorner),
												$author$project$Material$Menu$anchorMargin(
												{c: 0, fc: 0, f: 0, g0: 56}),
												A2($author$project$Material$Options$css, 'max-width', '20rem')
											]),
										A2(
											$author$project$Material$Menu$ul,
											_List_Nil,
											A2(
												$elm$core$List$map,
												function (place) {
													return A2(
														$author$project$Material$Menu$li,
														_List_fromArray(
															[
																$author$project$Material$Menu$onSelect(
																$author$project$Street$Select(place))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(place.b2)
															]));
												},
												model.aG)));
								}
							}()
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('lonlat'),
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'bottom', '0'),
						A2($elm$html$Html$Attributes$style, 'left', '.5em')
					]),
				_List_fromArray(
					[
						A3($author$project$Street$ordinateTextField, 'lat', model.fb, model),
						A3($author$project$Street$ordinateTextField, 'lon', model.fj, model)
					])),
				A2(
				$author$project$Material$Icon$view,
				_List_fromArray(
					[
						$author$project$Material$Options$id('icon-visor'),
						A2($author$project$Material$Options$css, 'position', 'absolute'),
						A2($author$project$Material$Options$css, 'top', '50%'),
						A2($author$project$Material$Options$css, 'left', '50%'),
						A2($author$project$Material$Options$css, 'transform', 'translate(-50%, -50%)'),
						A2($author$project$Material$Options$css, 'user-select', 'none')
					]),
				'gps_not_fixed')
			]));
};
var $author$project$Street$view = F2(
	function (lift, model) {
		return A2(
			$elm$html$Html$map,
			lift,
			$author$project$Street$view_(model));
	});
var $author$project$Page$Kunstwerk$BrugBeweegbaar = {$: 29};
var $author$project$Page$Kunstwerk$BrugSoort = function (a) {
	return {$: 28, a: a};
};
var $author$project$Page$Kunstwerk$selectRefdata = F5(
	function (lijst, index, caption, waarde, model) {
		var _v0 = model.W.h9;
		if (_v0.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var refdata = _v0.a;
			return A5(
				$author$project$Material$Select$view,
				$author$project$Page$Kunstwerk$Mdc,
				'select-' + index,
				model.c6,
				_List_fromArray(
					[
						$author$project$Material$Options$cs('field'),
						$author$project$Material$Options$cs('top'),
						$author$project$Material$Select$label(caption),
						A2($author$project$Material$Options$when, waarde > 0, $author$project$Material$Select$preselected),
						$author$project$Material$Options$onChange($author$project$Page$Kunstwerk$BrugSoort)
					]),
				A2(
					$elm$core$List$map,
					function (_v1) {
						var id = _v1.cc;
						var naam = _v1.aY;
						return A2(
							$author$project$Material$Select$option,
							_List_fromArray(
								[
									$author$project$Material$Select$value(
									$elm$core$String$fromInt(id)),
									A2(
									$author$project$Material$Options$when,
									_Utils_eq(id, waarde),
									$author$project$Material$Select$selected)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(naam)
								]));
					},
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.aY;
						},
						lijst(refdata))));
		}
	});
var $author$project$Page$Kunstwerk$viewBrugOfSluis = F2(
	function (kunstwerk, model) {
		var _v0 = kunstwerk.cj;
		switch (_v0) {
			case 'b':
				return _List_fromArray(
					[
						A5(
						$author$project$Page$Kunstwerk$selectRefdata,
						function ($) {
							return $.dP;
						},
						'brugsoort',
						'Type brug *',
						kunstwerk.dO.cC,
						model),
						A5(
						$author$project$Page$textfield,
						$author$project$Page$Kunstwerk$Field,
						model,
						'brug.openingen',
						$elm$core$String$fromInt(kunstwerk.dO.fD),
						_List_fromArray(
							[
								$author$project$Material$Options$cs('left-half')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A7($author$project$Page$Kunstwerk$switch, model, false, 0, 'switch-BrugBeweegbaar', kunstwerk.dO.dF, $author$project$Page$Kunstwerk$BrugBeweegbaar, 'Beweegbare brug')
							]))
					]);
			case 's':
				return _List_fromArray(
					[
						A5(
						$author$project$Page$Kunstwerk$selectRefdata,
						function ($) {
							return $.gy;
						},
						'sluissoort',
						'Type sluis *',
						kunstwerk.gx.cC,
						model),
						A5(
						$author$project$Page$textfield,
						$author$project$Page$Kunstwerk$Field,
						model,
						'sluis.ingangen',
						$elm$core$String$fromInt(kunstwerk.gx.eM),
						_List_fromArray(
							[
								$author$project$Material$Options$cs('left-third')
							])),
						A5(
						$author$project$Page$textfield,
						$author$project$Page$Kunstwerk$Field,
						model,
						'sluis.maxDiepte',
						$elm$core$String$fromFloat(kunstwerk.gx.fn),
						_List_fromArray(
							[
								$author$project$Material$Options$cs('left-third')
							])),
						A5(
						$author$project$Page$textfield,
						$author$project$Page$Kunstwerk$Field,
						model,
						'sluis.maxLengte',
						$elm$core$String$fromFloat(kunstwerk.gx.fq),
						_List_fromArray(
							[
								$author$project$Material$Options$cs('right-third')
							]))
					]);
			default:
				return _List_Nil;
		}
	});
var $author$project$Page$Kunstwerk$AddDag = function (a) {
	return {$: 19, a: a};
};
var $author$project$Page$Kunstwerk$RemovePeriode = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Page$Kunstwerk$ShowDagen = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Page$Kunstwerk$DagVolgorde = F4(
	function (a, b, c, d) {
		return {$: 13, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$Kunstwerk$Down = 1;
var $author$project$Page$Kunstwerk$RemoveDag = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$Page$Kunstwerk$Up = 0;
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Internal$Typography$Implementation$subtitle1 = $author$project$Internal$Options$cs('mdc-typography--subtitle1');
var $author$project$Material$Typography$subtitle1 = $author$project$Internal$Typography$Implementation$subtitle1;
var $author$project$Page$subtitle1 = function (value) {
	return A3(
		$author$project$Material$Options$styled,
		$elm$html$Html$h3,
		_List_fromArray(
			[
				$author$project$Material$Typography$subtitle1,
				A2($author$project$Material$Options$css, 'margin-bottom', '0')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(value)
			]));
};
var $author$project$Page$textareaLarge = F5(
	function (lift, container, field, value, properties) {
		return A7($author$project$Page$textarea_, lift, container, field, value, 4, '140px', properties);
	});
var $author$project$Page$Kunstwerk$viewDag = F3(
	function (model, periode, _v0) {
		var periodeID = _v0.fO;
		var id = _v0.cc;
		var dagen = _v0.b0;
		var tijden = _v0.gS;
		var opmerkingen = _v0.fG;
		var volgorde = _v0.hd;
		return A2(
			$author$project$Page$spreadCard,
			$elm$core$Maybe$Nothing,
			_List_fromArray(
				[
					A2(
					$author$project$Material$LayoutGrid$inner,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Material$LayoutGrid$cell,
							$author$project$Page$half,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex'),
											$elm$html$Html$Attributes$class('top')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex'),
													A2($elm$html$Html$Attributes$style, 'width', '100%'),
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													$author$project$Page$subtitle1(periode)
												])),
											A7(
											$author$project$Page$Kunstwerk$iconButton,
											model,
											false,
											id,
											'button-DagVolgorde-Up',
											A4($author$project$Page$Kunstwerk$DagVolgorde, periodeID, id, 0, volgorde),
											'arrow_upward',
											'Omhoog'),
											A7(
											$author$project$Page$Kunstwerk$iconButton,
											model,
											false,
											id,
											'button-DagVolgorde-Down',
											A4($author$project$Page$Kunstwerk$DagVolgorde, periodeID, id, 1, volgorde),
											'arrow_downward',
											'Omlaag'),
											A7(
											$author$project$Page$Kunstwerk$iconButton,
											model,
											false,
											id,
											'button-RemoveDag',
											A2($author$project$Page$Kunstwerk$RemoveDag, id, dagen),
											'delete_outline',
											'Dagen verwijderen')
										])),
									A5(
									$author$project$Page$textfield,
									$author$project$Page$Kunstwerk$Field,
									model,
									A3($author$project$Page$Kunstwerk$dagFieldName, periodeID, id, 'dagen'),
									dagen,
									_List_Nil),
									A5(
									$author$project$Page$textfield,
									$author$project$Page$Kunstwerk$Field,
									model,
									A3($author$project$Page$Kunstwerk$dagFieldName, periodeID, id, 'tijden'),
									tijden,
									_List_fromArray(
										[
											$author$project$Material$Options$cs('bottom')
										]))
								])),
							A2(
							$author$project$Material$LayoutGrid$cell,
							$author$project$Page$half,
							_List_fromArray(
								[
									A5(
									$author$project$Page$textareaLarge,
									$author$project$Page$Kunstwerk$Field,
									model,
									A3($author$project$Page$Kunstwerk$dagFieldName, periodeID, id, 'opmerkingen'),
									opmerkingen,
									_List_fromArray(
										[
											$author$project$Material$Options$cs('top'),
											$author$project$Material$Options$cs('bottom')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Kunstwerk$viewPeriode = F2(
	function (model, _v0) {
		var id = _v0.cc;
		var periode = _v0.fN;
		var showDagen = _v0.ie;
		var opmerkingen = _v0.fG;
		var dagen = _v0.b0;
		return A2(
			$author$project$Page$spreadCard,
			$elm$core$Maybe$Nothing,
			_List_fromArray(
				[
					A2(
					$author$project$Material$LayoutGrid$inner,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$author$project$Material$LayoutGrid$cell,
								$author$project$Page$half,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex'),
												$elm$html$Html$Attributes$class('top')
											]),
										_List_fromArray(
											[
												A5(
												$author$project$Page$textfield,
												$author$project$Page$Kunstwerk$Field,
												model,
												A2($author$project$Page$Kunstwerk$periodeFieldName, id, 'periode'),
												periode,
												_List_fromArray(
													[
														$author$project$Material$Options$cs('flex'),
														A2($author$project$Material$Options$css, 'width', '100%'),
														$author$project$Material$Options$cs('top')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'width', '.5rem')
													]),
												_List_Nil),
												A7(
												$author$project$Page$Kunstwerk$iconButton,
												model,
												false,
												id,
												'button-RemovePeriode',
												A2($author$project$Page$Kunstwerk$RemovePeriode, id, periode),
												'delete',
												'Periode verwijderen')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex'),
												$elm$html$Html$Attributes$class('bottom')
											]),
										_Utils_ap(
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('field')
														]),
													_List_fromArray(
														[
															A7(
															$author$project$Page$Kunstwerk$switch,
															model,
															false,
															id,
															'switch-ShowDagen',
															showDagen,
															A2($author$project$Page$Kunstwerk$ShowDagen, id, !showDagen),
															'Dagen')
														]))
												]),
											function () {
												if (!showDagen) {
													return _List_Nil;
												} else {
													return _List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	A2($elm$html$Html$Attributes$style, 'margin-left', 'auto'),
																	$elm$html$Html$Attributes$class('bottom')
																]),
															_List_fromArray(
																[
																	A7(
																	$author$project$Page$Kunstwerk$iconButton,
																	model,
																	false,
																	id,
																	'button-AddDag',
																	$author$project$Page$Kunstwerk$AddDag(id),
																	'add_circle_outline',
																	'Dagen toevoegen')
																]))
														]);
												}
											}()))
									])),
								A2(
								$author$project$Material$LayoutGrid$cell,
								$author$project$Page$half,
								_List_fromArray(
									[
										A5(
										$author$project$Page$textarea,
										$author$project$Page$Kunstwerk$Field,
										model,
										A2($author$project$Page$Kunstwerk$periodeFieldName, id, 'opmerkingen'),
										opmerkingen,
										_List_fromArray(
											[
												$author$project$Material$Options$cs('top'),
												$author$project$Material$Options$cs('bottom')
											]))
									]))
							]),
						function () {
							if (!showDagen) {
								return _List_Nil;
							} else {
								return A2(
									$elm$core$List$map,
									function (dag) {
										return A3($author$project$Page$Kunstwerk$viewDag, model, periode, dag);
									},
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.hd;
										},
										dagen));
							}
						}()))
				]));
	});
var $author$project$Page$Kunstwerk$viewKunstwerk = function (model) {
	return A2(
		$author$project$Material$LayoutGrid$view,
		_List_fromArray(
			[
				$author$project$Material$Options$cs('capped')
			]),
		function () {
			var _v0 = model.k;
			if (_v0.$ === 1) {
				return _List_fromArray(
					[
						A3(
						$author$project$Material$Options$styled,
						$elm$html$Html$div,
						_List_fromArray(
							[$author$project$Material$Typography$body1]),
						_List_fromArray(
							[
								$elm$html$Html$text('Laden...')
							]))
					]);
			} else {
				var kunstwerk = _v0.a;
				return _Utils_ap(
					_List_fromArray(
						[
							A2(
							$author$project$Page$spreadCard,
							$elm$core$Maybe$Nothing,
							_List_fromArray(
								[
									A2(
									$author$project$Material$LayoutGrid$inner,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$Material$LayoutGrid$cell,
											$author$project$Page$spread,
											_List_fromArray(
												[
													A5($author$project$Page$textfield, $author$project$Page$Kunstwerk$Field, model, 'naam', kunstwerk.aY, _List_Nil)
												])),
											A2(
											$author$project$Material$LayoutGrid$cell,
											$author$project$Page$half,
											_List_fromArray(
												[
													A5($author$project$Page$textfield, $author$project$Page$Kunstwerk$Field, model, 'referentie', kunstwerk.f6, _List_Nil),
													A5($author$project$Page$textfield, $author$project$Page$Kunstwerk$Field, model, 'adres', kunstwerk.dr, _List_Nil),
													A5(
													$author$project$Page$textfield,
													$author$project$Page$Kunstwerk$Field,
													model,
													'postcode',
													kunstwerk.fV,
													_List_fromArray(
														[
															$author$project$Material$Options$cs('small-part')
														])),
													A5(
													$author$project$Page$textfield,
													$author$project$Page$Kunstwerk$Field,
													model,
													'woonplaats',
													kunstwerk.cK,
													_List_fromArray(
														[
															$author$project$Material$Options$cs('large-part')
														])),
													A5($author$project$Page$Component$Land$view, $author$project$Page$Kunstwerk$Mdc, model, kunstwerk.e5, $author$project$Page$Kunstwerk$Land, _List_Nil),
													A5($author$project$Page$textarea, $author$project$Page$Kunstwerk$Field, model, 'toelichting', kunstwerk.gU, _List_Nil)
												])),
											A2(
											$author$project$Material$LayoutGrid$cell,
											$author$project$Page$half,
											_List_fromArray(
												[
													A5(
													$author$project$Page$textfield,
													$author$project$Page$Kunstwerk$Field,
													model,
													'anwbBrugNummer',
													kunstwerk.bP,
													_List_fromArray(
														[
															$author$project$Material$Options$cs('small-part')
														])),
													A5(
													$author$project$Page$textfield,
													$author$project$Page$Kunstwerk$Field,
													model,
													'marifoonkanaal',
													$elm$core$String$fromInt(kunstwerk.fk),
													_List_fromArray(
														[
															$author$project$Material$Options$cs('large-part')
														])),
													A5(
													$author$project$Page$textfield,
													$author$project$Page$Kunstwerk$Field,
													model,
													'telefoon.net',
													kunstwerk.gO.cq,
													_List_fromArray(
														[
															$author$project$Material$Options$cs('small-part')
														])),
													A5(
													$author$project$Page$textfield,
													$author$project$Page$Kunstwerk$Field,
													model,
													'telefoon.abonnee',
													kunstwerk.gO.bL,
													_List_fromArray(
														[
															$author$project$Material$Options$cs('large-part')
														])),
													A5($author$project$Page$textfield, $author$project$Page$Kunstwerk$Field, model, 'email', kunstwerk.ef, _List_Nil),
													A5($author$project$Page$textfield, $author$project$Page$Kunstwerk$Field, model, 'web', kunstwerk.hm, _List_Nil),
													A5($author$project$Page$textarea, $author$project$Page$Kunstwerk$Field, model, 'opmerkingen', kunstwerk.fG, _List_Nil)
												]))
										]))
								])),
							A2(
							$author$project$Page$halfCard,
							$elm$core$Maybe$Just('Locatie *'),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'height', '24rem'),
											A2($elm$html$Html$Attributes$style, 'margin-top', '.5rem')
										]),
									_List_fromArray(
										[
											A2($author$project$Street$view, $author$project$Page$Kunstwerk$Street, model.df)
										]))
								])),
							A2(
							$author$project$Page$halfCard,
							$elm$core$Maybe$Just('Kenmerken'),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'min-height', '24.5rem')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Material$LayoutGrid$inner,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$author$project$Material$LayoutGrid$cell,
													$author$project$Page$half,
													_List_fromArray(
														[
															A5(
															$author$project$Page$textfield,
															$author$project$Page$Kunstwerk$Field,
															model,
															'maxBreedte',
															$elm$core$String$fromFloat(kunstwerk.fm),
															_List_Nil),
															function () {
															var kunstwerkTypeRadio = F2(
																function (radioValue, radioCaption) {
																	return A2(
																		$author$project$Material$FormField$view,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A5(
																				$author$project$Material$RadioButton$view,
																				$author$project$Page$Kunstwerk$Mdc,
																				'kunstwerkType',
																				model.c6,
																				_List_fromArray(
																					[
																						A2(
																						$author$project$Material$Options$when,
																						_Utils_eq(kunstwerk.cj, radioValue),
																						$author$project$Material$RadioButton$selected),
																						$author$project$Material$Options$onClick(
																						$author$project$Page$Kunstwerk$KunstwerkType(radioValue))
																					]),
																				_List_Nil),
																				A3(
																				$author$project$Material$Options$styled,
																				$elm$html$Html$label,
																				_List_fromArray(
																					[
																						$author$project$Material$Options$onClick(
																						$author$project$Page$Kunstwerk$KunstwerkType(radioValue))
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(radioCaption)
																					]))
																			]));
																});
															return A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field'),
																		$elm$html$Html$Attributes$class('bottom')
																	]),
																_List_fromArray(
																	[
																		A2(kunstwerkTypeRadio, 'b', 'Brug'),
																		A2(kunstwerkTypeRadio, 's', 'Sluis')
																	]));
														}()
														])),
													A2(
													$author$project$Material$LayoutGrid$cell,
													$author$project$Page$half,
													_List_fromArray(
														[
															A5(
															$author$project$Page$textfield,
															$author$project$Page$Kunstwerk$Field,
															model,
															'maxHoogte',
															$elm$core$String$fromFloat(kunstwerk.fp),
															_List_Nil),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('field'),
																	$elm$html$Html$Attributes$class('bottom')
																]),
															_List_fromArray(
																[
																	A7($author$project$Page$Kunstwerk$switch, model, false, 0, 'switch-AfstandBediend', kunstwerk.dt, $author$project$Page$Kunstwerk$AfstandBediend, 'Op afstand bediend')
																]))
														])),
													A2(
													$author$project$Material$LayoutGrid$cell,
													$author$project$Page$spread,
													A2($author$project$Page$Kunstwerk$viewBrugOfSluis, kunstwerk, model))
												]))
										]))
								])),
							A2(
							$author$project$Page$spreadCard,
							$elm$core$Maybe$Just('Openingstijden'),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex')
										]),
									_List_fromArray(
										[
											A5($author$project$Page$Kunstwerk$button, model, model.aU, 'button-hideAllDdagen', $author$project$Page$Kunstwerk$HideAllDagen, 'Dagen verbergen'),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'width', '.5rem')
												]),
											_List_Nil),
											A5($author$project$Page$Kunstwerk$button, model, model.aH, 'button-showAllDagen', $author$project$Page$Kunstwerk$ShowAllDagen, 'Dagen tonen'),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'width', '.5rem')
												]),
											_List_Nil),
											function () {
											var openingstijdenTypeRadio = F2(
												function (radioValue, radioCaption) {
													return A2(
														$author$project$Material$FormField$view,
														_List_Nil,
														_List_fromArray(
															[
																A5(
																$author$project$Material$RadioButton$view,
																$author$project$Page$Kunstwerk$Mdc,
																'openingstijdenType',
																model.c6,
																_List_fromArray(
																	[
																		A2(
																		$author$project$Material$Options$when,
																		_Utils_eq(kunstwerk.fF, radioValue),
																		$author$project$Material$RadioButton$selected),
																		$author$project$Material$Options$onClick(
																		$author$project$Page$Kunstwerk$OpeningstijdenType(radioValue))
																	]),
																_List_Nil),
																A3(
																$author$project$Material$Options$styled,
																$elm$html$Html$label,
																_List_fromArray(
																	[
																		$author$project$Material$Options$onClick(
																		$author$project$Page$Kunstwerk$OpeningstijdenType(radioValue))
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(radioCaption)
																	]))
															]));
												});
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field')
													]),
												_List_fromArray(
													[
														A2(openingstijdenTypeRadio, 'p', 'Perioden eerst'),
														A2(openingstijdenTypeRadio, 'd', 'Dagen eerst')
													]));
										}(),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'margin-left', 'auto')
												]),
											_List_fromArray(
												[
													A7(
													$author$project$Page$Kunstwerk$iconButton,
													model,
													false,
													1,
													'button-AddPeriode',
													$author$project$Page$Kunstwerk$AddPeriode(kunstwerk.cc),
													'add_circle',
													'Periode toevoegen')
												]))
										]))
								]))
						]),
					A2(
						$elm$core$List$map,
						function (periode) {
							return A2($author$project$Page$Kunstwerk$viewPeriode, model, periode);
						},
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.dp;
							},
							kunstwerk.fP)));
			}
		}());
};
var $author$project$Page$Kunstwerk$view = function (model) {
	var pageTitle = function () {
		var _v1 = model.k;
		if (_v1.$ === 1) {
			return 'Brug/sluis';
		} else {
			var naam = _v1.a.aY;
			if (naam === '') {
				return 'Nieuwe brug/sluis';
			} else {
				return naam;
			}
		}
	}();
	return A6(
		$author$project$Page$view,
		$author$project$Page$Kunstwerk$Session,
		model,
		pageTitle,
		_List_fromArray(
			['arrow_back']),
		function () {
			var _v0 = model.k;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				return _List_fromArray(
					[
						_Utils_Tuple2('print', 'Afdrukvoorbeeld'),
						_Utils_Tuple2('delete', 'Verwijderen'),
						_Utils_Tuple2('save', 'Opslaan')
					]);
			}
		}(),
		$author$project$Page$Kunstwerk$viewKunstwerk(model));
};
var $author$project$Page$Partial$Filter$Clear = {$: 7};
var $author$project$Page$Partial$Filter$FirstPage = {$: 8};
var $author$project$Page$Partial$Filter$Next = {$: 10};
var $author$project$Page$Partial$Filter$Pick = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Partial$Filter$Previous = {$: 9};
var $author$project$Internal$Chip$Implementation$defaultConfig = {bd: false, cm: $elm$core$Maybe$Nothing, cs: $elm$core$Maybe$Nothing, by: false, cI: $elm$core$Maybe$Nothing};
var $author$project$Internal$Chip$Implementation$chipset = F2(
	function (options, nodes) {
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Chip$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-chip-set')
				]),
			_List_Nil,
			nodes);
	});
var $author$project$Material$Chip$chipset = $author$project$Internal$Chip$Implementation$chipset;
var $author$project$Internal$Chip$Implementation$choice = $author$project$Internal$Options$cs('mdc-chip-set--choice');
var $author$project$Material$Chip$choice = $author$project$Internal$Chip$Implementation$choice;
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $author$project$Internal$Chip$Implementation$decodeKey = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['key']),
	$elm$json$Json$Decode$string);
var $author$project$Internal$Chip$Implementation$decodeKeyCode = $elm$html$Html$Events$keyCode;
var $author$project$Internal$Chip$Implementation$onClick = function (msg) {
	var trigger = F2(
		function (key, keyCode) {
			var isEnter = (key === 'Enter') || (keyCode === 13);
			return isEnter ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
		});
	return $author$project$Internal$Options$many(
		_List_fromArray(
			[
				$author$project$Internal$Options$onClick(msg),
				A2(
				$author$project$Internal$Options$on,
				'keyup',
				A2(
					$elm$json$Json$Decode$andThen,
					$elm$core$Basics$identity,
					A3($elm$json$Json$Decode$map2, trigger, $author$project$Internal$Chip$Implementation$decodeKey, $author$project$Internal$Chip$Implementation$decodeKeyCode)))
			]));
};
var $author$project$Material$Chip$onClick = $author$project$Internal$Chip$Implementation$onClick;
var $author$project$Internal$Chip$Model$Click = function (a) {
	return {$: 1, a: a};
};
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Internal$Chip$Implementation$chip = F5(
	function (domId, lift, model, options, nodes) {
		var ripple = A5(
			$author$project$Internal$Ripple$Implementation$view,
			false,
			domId,
			A2($elm$core$Basics$composeL, lift, $author$project$Internal$Chip$Model$RippleMsg),
			model.U,
			_List_Nil);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Chip$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-chip'),
					A2(
					$author$project$Internal$Options$when,
					config.by,
					$author$project$Internal$Options$cs('mdc-chip--selected')),
					$author$project$Internal$Options$cs('mdc-js-ripple-effect'),
					$author$project$Internal$Options$many(
					_List_fromArray(
						[ripple.hS, ripple.h7])),
					A2(
					$elm$core$Maybe$withDefault,
					$author$project$Internal$Options$nop,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Internal$Options$onClick, lift),
							$author$project$Internal$Chip$Model$Click),
						config.cs)),
					$author$project$Internal$Options$attribute(
					$elm$html$Html$Attributes$tabindex(0))
				]),
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (icon) {
								return _List_fromArray(
									[
										A2(
										$author$project$Internal$Icon$Implementation$view,
										_List_fromArray(
											[
												$author$project$Internal$Options$cs('mdc-chip__icon mdc-chip__icon--leading'),
												A2(
												$author$project$Internal$Options$when,
												config.by && config.bd,
												$author$project$Internal$Options$cs('mdc-chip__icon--leading-hidden')),
												A2($author$project$Internal$Options$css, 'font-size', '20px')
											]),
										icon)
									]);
							},
							config.cm)),
						_List_fromArray(
						[
							config.bd ? A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-chip__checkmark')
								]),
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$svg,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$class('mdc-chip__checkmark-svg'),
											$elm$svg$Svg$Attributes$viewBox('-2 -3 30 30')
										]),
									_List_fromArray(
										[
											A2(
											$elm$svg$Svg$path,
											_List_fromArray(
												[
													$elm$svg$Svg$Attributes$class('mdc-chip__checkmark-path'),
													$elm$svg$Svg$Attributes$fill('none'),
													$elm$svg$Svg$Attributes$stroke('white'),
													$elm$svg$Svg$Attributes$d('M1.73,12.91 8.1,19.28 22.79,4.59')
												]),
											_List_Nil)
										]))
								])) : $elm$html$Html$text('')
						]),
						_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-chip__text')
								]),
							nodes)
						]),
						A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (icon) {
								return _List_fromArray(
									[
										A2(
										$author$project$Internal$Icon$Implementation$view,
										_List_fromArray(
											[
												$author$project$Internal$Options$cs('mdc-chip__icon mdc-chip__icon--trailing'),
												$author$project$Internal$Options$attribute(
												$elm$html$Html$Attributes$tabindex(0)),
												$author$project$Internal$Options$role('button')
											]),
										icon)
									]);
							},
							config.cI)),
						_List_fromArray(
						[ripple.ih])
					])));
	});
var $author$project$Internal$Chip$Implementation$view = F2(
	function (lift, domId) {
		return A5(
			$author$project$Internal$Component$render,
			$author$project$Internal$Chip$Implementation$getSet.ey,
			$author$project$Internal$Chip$Implementation$chip(domId),
			$author$project$Internal$Msg$ChipMsg,
			lift,
			domId);
	});
var $author$project$Material$Chip$view = $author$project$Internal$Chip$Implementation$view;
var $author$project$Page$Partial$Filter$view_ = F2(
	function (model, exclude) {
		var icon = F4(
			function (picto, tooltip, msg, disabled) {
				return A2(
					$author$project$Material$Icon$view,
					_List_fromArray(
						[
							$author$project$Material$Options$onClick(msg),
							$author$project$Material$Options$attribute(
							$elm$html$Html$Attributes$title(tooltip)),
							$author$project$Material$Options$cs('button'),
							A2(
							$author$project$Material$Options$when,
							disabled,
							$author$project$Material$Options$cs('disabled'))
						]),
					picto);
			});
		return A2(
			$author$project$Material$LayoutGrid$view,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2(
					$author$project$Material$LayoutGrid$cell,
					_List_fromArray(
						[$author$project$Material$LayoutGrid$span12]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A5(
									$author$project$Page$textfield,
									$author$project$Page$Partial$Filter$Field,
									model,
									'textFilter',
									model.ao,
									_List_fromArray(
										[
											A2($author$project$Material$Options$css, 'width', 'calc(100% - 36px - 36px - 36px - 36px)')
										])),
									A4(icon, 'cancel', 'Wissen', $author$project$Page$Partial$Filter$Clear, model.ao === ''),
									A4(icon, 'first_page', 'Eerste pagina', $author$project$Page$Partial$Filter$FirstPage, !model.C),
									A4(icon, 'chevron_left', 'Vorige pagina', $author$project$Page$Partial$Filter$Previous, !model.C),
									A4(icon, 'chevron_right', 'Volgende pagina', $author$project$Page$Partial$Filter$Next, false)
								]))
						])),
				_Utils_ap(
					A2(
						model.cS,
						model.c6,
						$author$project$Page$Partial$Filter$values(model)),
					_List_fromArray(
						[
							A2(
							$author$project$Material$LayoutGrid$cell,
							_List_fromArray(
								[$author$project$Material$LayoutGrid$span12]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$Lazy$lazy,
									function (list) {
										return A2(
											$author$project$Material$Chip$chipset,
											_List_Nil,
											$elm$core$List$singleton(
												A3(
													$elm$html$Html$Keyed$node,
													'span',
													_List_Nil,
													A2(
														$elm$core$List$map,
														function (item) {
															var id = item.cc;
															var naam = item.aY;
															var key = $elm$core$String$fromInt(id);
															return _Utils_Tuple2(
																key,
																A5(
																	$author$project$Material$Chip$view,
																	$author$project$Page$Partial$Filter$Mdc,
																	'item.' + key,
																	model.c6,
																	_List_fromArray(
																		[
																			$author$project$Material$Chip$choice,
																			$author$project$Material$Chip$onClick(
																			$author$project$Page$Partial$Filter$Pick(item))
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(
																			model.cQ(item))
																		])));
														},
														A2(
															$elm$core$List$take,
															model.cu,
															A2($elm$core$List$drop, model.C * model.cu, list))))));
									},
									A2(
										$elm$core$List$filter,
										function (item) {
											return !A2(
												$elm$core$List$any,
												function (_v0) {
													var id = _v0.cc;
													return _Utils_eq(id, item.cc);
												},
												exclude);
										},
										model.by))
								]))
						]))));
	});
var $author$project$Page$Partial$Filter$view = F3(
	function (lift, _v0, exclude) {
		var filter = _v0.b4;
		return A2(
			$elm$html$Html$map,
			lift,
			A2($author$project$Page$Partial$Filter$view_, filter, exclude));
	});
var $author$project$Page$Partial$Kunstwerken$view_ = F2(
	function (model, exclude) {
		return A3($author$project$Page$Partial$Filter$view, $elm$core$Basics$identity, model, exclude);
	});
var $author$project$Page$Partial$Kunstwerken$view = F3(
	function (lift, _v0, exclude) {
		var kunstwerken = _v0.e1;
		return A2(
			$elm$html$Html$map,
			lift,
			A2($author$project$Page$Partial$Kunstwerken$view_, kunstwerken, exclude));
	});
var $author$project$Page$Kunstwerken$view = function (model) {
	return A6(
		$author$project$Page$view,
		$author$project$Page$Kunstwerken$Session,
		model,
		'Bruggen en/of sluizen',
		_List_fromArray(
			['arrow_back']),
		_List_fromArray(
			[
				_Utils_Tuple2('add', 'Nieuwe brug of sluis aanmaken')
			]),
		A3($author$project$Page$Partial$Kunstwerken$view, $author$project$Page$Kunstwerken$Kunstwerken, model, _List_Nil));
};
var $author$project$Internal$Typography$Implementation$body2 = $author$project$Internal$Options$cs('mdc-typography--body2');
var $author$project$Material$Typography$body2 = $author$project$Internal$Typography$Implementation$body2;
var $author$project$Page$body2 = function (value) {
	return A3(
		$author$project$Material$Options$styled,
		$elm$html$Html$h4,
		_List_fromArray(
			[
				$author$project$Material$Typography$body2,
				A2($author$project$Material$Options$css, 'margin-top', '0'),
				A2($author$project$Material$Options$css, 'margin-bottom', '.5em')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(value)
			]));
};
var $author$project$Internal$Textfield$Implementation$email = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{
				$9: $elm$core$Maybe$Just('email')
			});
	});
var $author$project$Material$Textfield$email = $author$project$Internal$Textfield$Implementation$email;
var $author$project$Internal$Button$Implementation$onClick = function (handler) {
	return $author$project$Internal$Options$option(
		function (options) {
			return _Utils_update(
				options,
				{
					cs: $elm$core$Maybe$Just(handler)
				});
		});
};
var $author$project$Material$Button$onClick = $author$project$Internal$Button$Implementation$onClick;
var $author$project$Internal$Textfield$Implementation$password = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{
				$9: $elm$core$Maybe$Just('password')
			});
	});
var $author$project$Material$Textfield$password = $author$project$Internal$Textfield$Implementation$password;
var $author$project$Internal$Button$Implementation$raised = $author$project$Internal$Options$cs('mdc-button--raised');
var $author$project$Material$Button$raised = $author$project$Internal$Button$Implementation$raised;
var $author$project$Page$Login$view = function (model) {
	var send = _List_fromArray(
		[
			_Utils_Tuple2('send', 'Versturen')
		]);
	var fieldView = function (key) {
		var _v5 = function () {
			switch (key) {
				case 'email':
					return _Utils_Tuple2(model.ef, $author$project$Material$Textfield$email);
				case 'password':
					return _Utils_Tuple2(model.aZ, $author$project$Material$Textfield$password);
				case 'password2':
					return _Utils_Tuple2(model.cv, $author$project$Material$Textfield$password);
				default:
					return _Utils_Tuple2('', $author$project$Material$Options$nop);
			}
		}();
		var value = _v5.a;
		var textFieldType = _v5.b;
		return A6(
			$author$project$Field$view,
			$author$project$Page$Login$Field,
			model.cY,
			key,
			value,
			_List_fromArray(
				[textFieldType]),
			_List_Nil);
	};
	var _v0 = function () {
		var _v1 = model.cL;
		switch (_v1) {
			case 0:
				return _Utils_Tuple3(
					'Login',
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2('edit', 'Nieuw wachtwoord aanvragen'),
						send),
					_List_fromArray(
						['email', 'password']));
			case 1:
				return _Utils_Tuple3(
					'Nieuw wachtwoord aanvragen',
					send,
					_List_fromArray(
						['email']));
			default:
				return _Utils_Tuple3(
					'Nieuw wachtwoord aanmaken',
					send,
					_List_fromArray(
						['email', 'password', 'password2']));
		}
	}();
	var pageTitle = _v0.a;
	var actions = _v0.b;
	var fields = _v0.c;
	var _v2 = function () {
		var _v3 = model.fu;
		if (_v3 === '') {
			return _Utils_Tuple2(
				_List_Nil,
				_Utils_ap(
					A2(
						$elm$core$List$map,
						function (key) {
							return fieldView(key);
						},
						fields),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'flex')
								]),
							A2(
								$elm$core$List$cons,
								function () {
									var _v4 = model.cL;
									if (!_v4) {
										return A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Page$Login$Session(
														$author$project$Session$Action('edit'))),
													$elm$html$Html$Attributes$href('.')
												]),
											_List_fromArray(
												[
													$author$project$Page$body2('Nieuw wachtwoord aanvragen')
												]));
									} else {
										return $elm$html$Html$text('');
									}
								}(),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'flex-grow', '1')
											]),
										_List_Nil),
										A5(
										$author$project$Material$Button$view,
										$author$project$Page$Login$Mdc,
										'button-send',
										model.c6,
										_List_fromArray(
											[
												$author$project$Material$Button$onClick(
												$author$project$Page$Login$Session(
													$author$project$Session$Action('send'))),
												$author$project$Material$Button$raised
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('OK')
											]))
									])))
						])));
		} else {
			return _Utils_Tuple2(
				_List_fromArray(
					['home']),
				_List_fromArray(
					[
						$author$project$Page$subtitle2(model.fu)
					]));
		}
	}();
	var navigation = _v2.a;
	var content = _v2.b;
	return A6(
		$author$project$Page$view,
		$author$project$Page$Login$Session,
		model,
		pageTitle,
		navigation,
		actions,
		A2(
			$author$project$Material$LayoutGrid$view,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Material$LayoutGrid$cell,
					_List_fromArray(
						[$author$project$Material$LayoutGrid$span4]),
					content)
				])));
};
var $author$project$Session$ActionMenu = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Internal$Menu$Implementation$quickOpen = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{D: true});
	});
var $author$project$Material$Menu$quickOpen = $author$project$Internal$Menu$Implementation$quickOpen;
var $author$project$Internal$Menu$Implementation$topRightCorner = {c: false, H: false, z: false, f: true};
var $author$project$Material$Menu$topRightCorner = $author$project$Internal$Menu$Implementation$topRightCorner;
var $author$project$Session$actionMenu_ = F3(
	function (model, action, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-menu-anchor')
				]),
			$elm$core$List$singleton(
				A5(
					$author$project$Material$Menu$view,
					$author$project$Session$Mdc,
					'menu.' + action,
					model.c6,
					_List_fromArray(
						[
							$author$project$Material$Menu$quickOpen,
							$author$project$Material$Menu$anchorCorner($author$project$Material$Menu$topRightCorner),
							$author$project$Material$Menu$anchorMargin(
							{c: 0, fc: 0, f: 1, g0: 0})
						]),
					A2(
						$author$project$Material$Menu$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (_v0) {
								var index = _v0.a;
								var caption = _v0.b;
								return A2(
									$author$project$Material$Menu$li,
									_List_fromArray(
										[
											$author$project$Material$Menu$onSelect(
											A2($author$project$Session$ActionMenu, action, index))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(caption)
										]));
							},
							items)))));
	});
var $author$project$Session$actionMenu = F4(
	function (lift, container, action, items) {
		return A2(
			$elm$html$Html$map,
			lift,
			A3($author$project$Session$actionMenu_, container.W, action, items));
	});
var $author$project$Page$Toewijzingen$Close = {$: 4};
var $author$project$Page$Toewijzingen$Toewijzingstype = function (a) {
	return {$: 5, a: a};
};
var $author$project$Internal$List$Implementation$activated = $author$project$Internal$Options$cs('mdc-list-item--activated');
var $author$project$Material$List$activated = $author$project$Internal$List$Implementation$activated;
var $author$project$Internal$List$Implementation$li = function (options) {
	var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$List$Implementation$defaultConfig, options);
	var config = summary.dY;
	return A4(
		$author$project$Internal$Options$apply,
		summary,
		A2($elm$core$Maybe$withDefault, $elm$html$Html$li, config.cr),
		_List_fromArray(
			[
				$author$project$Internal$Options$cs('mdc-list-item')
			]),
		_List_Nil);
};
var $author$project$Material$List$li = $author$project$Internal$List$Implementation$li;
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Internal$List$Implementation$text = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$span,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-list-item__text'),
			options));
};
var $author$project$Material$List$text = $author$project$Internal$List$Implementation$text;
var $author$project$Material$List$ul = $author$project$Internal$List$Implementation$ul;
var $author$project$Page$Toewijzingen$dialog = function (model) {
	var activated = function (toewijzingstype) {
		var _v1 = model.am;
		if (_v1.$ === 1) {
			return $author$project$Material$Options$nop;
		} else {
			var toewijzing = _v1.a;
			return A2(
				$author$project$Material$Options$when,
				_Utils_eq(toewijzing.gV.aY, toewijzingstype),
				$author$project$Material$List$activated);
		}
	};
	return A5(
		$author$project$Material$Dialog$view,
		$author$project$Page$Toewijzingen$Mdc,
		'dialog.toewijzingstype',
		model.c6,
		_List_fromArray(
			[
				A2($author$project$Material$Options$when, model.d5, $author$project$Material$Dialog$open),
				$author$project$Material$Dialog$onClose($author$project$Page$Toewijzingen$Close)
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Material$Dialog$surface,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Material$Dialog$header,
						_List_Nil,
						_List_fromArray(
							[
								A3(
								$author$project$Material$Options$styled,
								$elm$html$Html$h2,
								_List_fromArray(
									[$author$project$Material$Dialog$title]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v0 = model.am;
											if (_v0.$ === 1) {
												return 'Wijzig alle items in de filterset (op alle pagina\'s)';
											} else {
												var toewijzing = _v0.a;
												return toewijzing.aY;
											}
										}())
									]))
							])),
						A2(
						$author$project$Material$Dialog$body,
						_List_fromArray(
							[
								A2($author$project$Material$Options$when, false, $author$project$Material$Dialog$scrollable)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Kies een toewijzingstype:')
									])),
								A2(
								$author$project$Material$List$ul,
								_List_fromArray(
									[
										A2($author$project$Material$Options$css, 'border', '1px solid rgba(0, 0, 0, .1)')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Material$List$li,
										_List_fromArray(
											[
												$author$project$Material$Options$onClick(
												$author$project$Page$Toewijzingen$Toewijzingstype('eigenbeheer')),
												activated('eigenbeheer')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Material$List$text,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Voorziening')
													]))
											])),
										A2(
										$author$project$Material$List$li,
										_List_fromArray(
											[
												$author$project$Material$Options$onClick(
												$author$project$Page$Toewijzingen$Toewijzingstype('consul')),
												activated('consul')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Material$List$text,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Consul')
													]))
											])),
										A2(
										$author$project$Material$List$li,
										_List_fromArray(
											[
												$author$project$Material$Options$onClick(
												$author$project$Page$Toewijzingen$Toewijzingstype('anwb')),
												activated('anwb')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Material$List$text,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('ANWB')
													]))
											]))
									]))
							])),
						A2(
						$author$project$Material$Dialog$footer,
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$Material$Button$view,
								$author$project$Page$Toewijzingen$Mdc,
								'button.accept.toewijzingstype',
								model.c6,
								_List_fromArray(
									[
										$author$project$Material$Button$ripple,
										$author$project$Material$Dialog$cancel,
										$author$project$Material$Options$onClick($author$project$Page$Toewijzingen$Close)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Annuleren')
									]))
							]))
					])),
				A2($author$project$Material$Dialog$backdrop, _List_Nil, _List_Nil)
			]));
};
var $author$project$Page$Toewijzingen$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$Toewijzingen$dialog(model),
				A6(
				$author$project$Page$view,
				$author$project$Page$Toewijzingen$Session,
				model,
				'Toewijzingen',
				_List_fromArray(
					['arrow_back']),
				_List_fromArray(
					[
						_Utils_Tuple2('more_vert', 'Acties')
					]),
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							var _v0 = model.p;
							if (!_v0) {
								return $elm$html$Html$text('');
							} else {
								return A4(
									$author$project$Session$actionMenu,
									$author$project$Page$Toewijzingen$Session,
									model,
									'more_vert',
									_List_fromArray(
										[
											_Utils_Tuple2('consuls', 'E-mail coördinerend consuls'),
											_Utils_Tuple2('eigenaars', 'E-mail vaarwegbeheerders')
										]));
							}
						}(),
							A3($author$project$Page$Partial$Filter$view, $author$project$Page$Toewijzingen$Filter, model, _List_Nil)
						])))
			]));
};
var $author$project$Page$Partial$Beheerders$view_ = F2(
	function (model, exclude) {
		return A3($author$project$Page$Partial$Filter$view, $elm$core$Basics$identity, model, exclude);
	});
var $author$project$Page$Partial$Beheerders$view = F3(
	function (lift, _v0, exclude) {
		var beheerders = _v0.dC;
		return A2(
			$elm$html$Html$map,
			lift,
			A2($author$project$Page$Partial$Beheerders$view_, beheerders, exclude));
	});
var $author$project$Page$inner = F2(
	function (properties, content) {
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (content_) {
				return A2(
					$author$project$Material$LayoutGrid$cell,
					properties,
					_List_fromArray(
						[
							A2($author$project$Material$LayoutGrid$inner, _List_Nil, content_)
						]));
			},
			content);
	});
var $author$project$Page$halfInner = function (content) {
	return A2($author$project$Page$inner, $author$project$Page$half, content);
};
var $author$project$Page$Vaarweg$Land = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$body1 = function (value) {
	return A3(
		$author$project$Material$Options$styled,
		$elm$html$Html$h3,
		_List_fromArray(
			[
				$author$project$Material$Typography$body1,
				A2($author$project$Material$Options$css, 'margin-top', '0'),
				A2($author$project$Material$Options$css, 'margin-bottom', '.5em')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(value)
			]));
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Page$Vaarweg$viewEditor = F2(
	function (field, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editor'),
					A2($elm$html$Html$Attributes$attribute, 'field', field),
					A2(
					$elm$html$Html$Attributes$attribute,
					'value',
					A3(
						$elm$core$String$replace,
						'\\n',
						'',
						A3(
							$elm$core$String$replace,
							'\\\'',
							'\'',
							A3($elm$core$String$replace, '\\\"', '\"', value))))
				]),
			_List_Nil);
	});
var $author$project$Page$Vaarweg$viewAlgemeen = function (_v0) {
	var naam = _v0.a.aY;
	var land = _v0.a.e5;
	var algemeen = _v0.a.dv;
	var opmerkingen = _v0.a.fG;
	var model = _v0.b;
	return _List_fromArray(
		[
			A5($author$project$Page$textfield, $author$project$Page$Vaarweg$Field, model, 'naam', naam, _List_Nil),
			A5($author$project$Page$Component$Land$view, $author$project$Page$Vaarweg$Mdc, model, land, $author$project$Page$Vaarweg$Land, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field-margin')
				]),
			_List_fromArray(
				[
					$author$project$Page$body1('Algemene tekst:'),
					A2($author$project$Page$Vaarweg$viewEditor, 'algemeen', algemeen)
				])),
			A5($author$project$Page$textarea, $author$project$Page$Vaarweg$Field, model, 'opmerkingen', opmerkingen, _List_Nil)
		]);
};
var $author$project$Page$Vaarweg$BeheerderDel = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Vaarweg$BeheerderFab = {$: 8};
var $author$project$Internal$Fab$Implementation$defaultConfig = {U: false};
var $author$project$Internal$Fab$Implementation$fab = F5(
	function (domId, lift, model, options, icon) {
		var rippleInterface = A5(
			$author$project$Internal$Ripple$Implementation$view,
			false,
			domId,
			A2($elm$core$Basics$composeL, lift, $author$project$Internal$Fab$Model$RippleMsg),
			model.U,
			_List_Nil);
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Fab$Implementation$defaultConfig, options);
		var config = summary.dY;
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$button,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-fab'),
					$author$project$Internal$Options$cs('material-icons'),
					A3(
					$elm$core$Basics$composeL,
					$author$project$Internal$Options$when(config.U),
					$author$project$Internal$Options$many,
					_List_fromArray(
						[rippleInterface.hS, rippleInterface.h7]))
				]),
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-fab__icon')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(icon)
								]))
						]),
						config.U ? _List_fromArray(
						[rippleInterface.ih]) : _List_Nil
					])));
	});
var $author$project$Internal$Fab$Implementation$view = F2(
	function (lift, domId) {
		return A5(
			$author$project$Internal$Component$render,
			$author$project$Internal$Fab$Implementation$getSet.ey,
			$author$project$Internal$Fab$Implementation$fab(domId),
			$author$project$Internal$Msg$FabMsg,
			lift,
			domId);
	});
var $author$project$Material$Fab$view = $author$project$Internal$Fab$Implementation$view;
var $author$project$Page$Component$Join$joinFab = F4(
	function (mdc, mmsg, index, msg) {
		return A5(
			$author$project$Material$Fab$view,
			mmsg,
			index,
			mdc,
			_List_fromArray(
				[
					$author$project$Material$Options$onClick(msg),
					A2($author$project$Material$Options$css, 'position', 'absolute'),
					A2($author$project$Material$Options$css, 'right', '0'),
					A2($author$project$Material$Options$css, 'bottom', '.5rem'),
					A2($author$project$Material$Options$css, 'z-index', '1'),
					A2($author$project$Material$Options$css, 'opacity', '.8')
				]),
			'add');
	});
var $author$project$Internal$Menu$Implementation$bottomStartCorner = {c: true, H: false, z: true, f: false};
var $author$project$Material$Menu$bottomStartCorner = $author$project$Internal$Menu$Implementation$bottomStartCorner;
var $author$project$Page$Component$Join$joinItem = F6(
	function (mdc, mmsg, actions, link, caption, enabled) {
		return A3(
			$author$project$Material$Options$styled,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Material$Options$cs('mdc-menu-anchor')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Material$List$li,
					_List_fromArray(
						[
							A2($author$project$Material$Menu$attach, mmsg, 'menu.' + link)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Material$List$text,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(caption)
								]))
						])),
					function () {
					if (!enabled) {
						return $elm$html$Html$text('');
					} else {
						return A5(
							$author$project$Material$Menu$view,
							mmsg,
							'menu.' + link,
							mdc,
							_List_fromArray(
								[
									$author$project$Material$Menu$quickOpen,
									$author$project$Material$Menu$anchorCorner($author$project$Material$Menu$bottomStartCorner),
									$author$project$Material$Menu$anchorMargin(
									{c: 0, fc: 32, f: 0, g0: 0})
								]),
							A2(
								$author$project$Material$Menu$ul,
								_List_Nil,
								A2(
									$elm$core$List$map,
									function (_v1) {
										var action = _v1.a;
										var msg = _v1.b;
										return A2(
											$author$project$Material$Menu$li,
											_List_fromArray(
												[
													$author$project$Material$Menu$onSelect(
													msg(link))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(action)
												]));
									},
									actions)));
					}
				}()
				]));
	});
var $author$project$Page$Component$Join$joinList = F6(
	function (mdc, mmsg, actions, getCaption, list, enabled) {
		return A2(
			$author$project$Material$List$ul,
			_List_Nil,
			$elm$core$List$singleton(
				A3(
					$elm$html$Html$Keyed$node,
					'span',
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (item) {
							return _Utils_Tuple2(
								function ($) {
									return $.cn;
								}(item),
								A6(
									$author$project$Page$Component$Join$joinItem,
									mdc,
									mmsg,
									actions,
									function ($) {
										return $.cn;
									}(item),
									getCaption(item),
									enabled));
						},
						list))));
	});
var $author$project$Page$Component$Join$view = F8(
	function (mmsg, mdc, index, fab, actions, getCaption, list, enabled) {
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (list_) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'position', 'relative'),
							A2($elm$html$Html$Attributes$style, 'min-height', '4rem')
						]),
					_List_fromArray(
						[
							function () {
							if (!enabled) {
								return $elm$html$Html$text('');
							} else {
								return A4($author$project$Page$Component$Join$joinFab, mdc, mmsg, 'fab-' + index, fab);
							}
						}(),
							A6(
							$author$project$Page$Component$Join$joinList,
							mdc,
							mmsg,
							actions,
							getCaption,
							A2($elm$core$List$sortBy, getCaption, list_),
							enabled)
						]));
			},
			list);
	});
var $author$project$Page$Vaarweg$viewBeheerders = function (_v0) {
	var beheerders = _v0.a.dC;
	var model = _v0.b;
	return _List_fromArray(
		[
			A8(
			$author$project$Page$Component$Join$view,
			$author$project$Page$Vaarweg$Mdc,
			model.c6,
			'beheerders',
			$author$project$Page$Vaarweg$BeheerderFab,
			_List_fromArray(
				[
					_Utils_Tuple2('Verwijderen', $author$project$Page$Vaarweg$BeheerderDel)
				]),
			function (_v1) {
				var naam = _v1.aY;
				var woonplaats = _v1.cK;
				return (woonplaats === '') ? naam : (naam + (' - ' + woonplaats));
			},
			beheerders,
			true)
		]);
};
var $author$project$Page$Vaarweg$viewDetailCards = function (_v0) {
	var vaarweg = _v0.a;
	var details = vaarweg.d4;
	var model = _v0.b;
	return A2(
		$elm$core$List$map,
		function (_v2) {
			var link = _v2.cn;
			var naam = _v2.aY;
			var detail = _v2.d3;
			return A2(
				$elm$html$Html$Lazy$lazy,
				function (omschrijving) {
					return A2(
						$author$project$Material$LayoutGrid$cell,
						$author$project$Page$half,
						_List_fromArray(
							[
								A2(
								$author$project$Material$Card$view,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Page$subtitle1(naam),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-margin')
											]),
										_List_fromArray(
											[
												A2($author$project$Page$Vaarweg$viewEditor, 'omschrijving.' + link, omschrijving)
											]))
									]))
							]));
				},
				detail.fB);
		},
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.aY;
			},
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var bewaar = _v1.dE;
					return bewaar;
				},
				details)));
};
var $author$project$Page$Vaarweg$Detail = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Vaarweg$detailSelected = F2(
	function (link, vaarweg) {
		return A2(
			$elm$core$List$any,
			function (detail) {
				return detail.dE && _Utils_eq(detail.cn, link);
			},
			vaarweg.d4);
	});
var $author$project$Internal$Chip$Implementation$filter = $author$project$Internal$Options$cs('mdc-chip-set--filter');
var $author$project$Material$Chip$filter = $author$project$Internal$Chip$Implementation$filter;
var $author$project$Internal$Chip$Implementation$selected = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{by: true});
	});
var $author$project$Material$Chip$selected = $author$project$Internal$Chip$Implementation$selected;
var $author$project$Page$Vaarweg$viewDetailChips = function (_v0) {
	var vaarweg = _v0.a;
	var details = vaarweg.d4;
	var model = _v0.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$Lazy$lazy,
			function (_v1) {
				return A2(
					$author$project$Material$Chip$chipset,
					_List_fromArray(
						[
							$author$project$Material$Chip$filter,
							A2($author$project$Material$Options$css, 'margin-top', '.5rem')
						]),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var link = _v2.cn;
							var naam = _v2.aY;
							return A5(
								$author$project$Material$Chip$view,
								$author$project$Page$Vaarweg$Mdc,
								'detail.' + link,
								model.c6,
								_List_fromArray(
									[
										$author$project$Material$Chip$onClick(
										$author$project$Page$Vaarweg$Detail(link)),
										A2(
										$author$project$Material$Options$when,
										A2($author$project$Page$Vaarweg$detailSelected, link, vaarweg),
										$author$project$Material$Chip$selected)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(naam)
									]));
						},
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.aY;
							},
							details)));
			},
			details)
		]);
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Page$Vaarweg$viewKaart = function (_v0) {
	var kaart = _v0.a.eW;
	var model = _v0.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field-margin')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'max-width', '100%'),
							$elm$html$Html$Attributes$src(
							'/anwbwa/resources/kaartjes/' + A3($elm$core$String$replace, '.png', '.jpg', kaart.g6))
						]),
					_List_Nil),
					$author$project$Page$body1('Legenda:'),
					A2($author$project$Page$Vaarweg$viewEditor, 'kaart.legenda', kaart.fd)
				]))
		]);
};
var $author$project$Page$Vaarweg$KunstwerkDel = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Vaarweg$KunstwerkFab = {$: 5};
var $author$project$Page$Vaarweg$viewKunstwerken = function (_v0) {
	var kunstwerken = _v0.a.e1;
	var model = _v0.b;
	return _List_fromArray(
		[
			A8(
			$author$project$Page$Component$Join$view,
			$author$project$Page$Vaarweg$Mdc,
			model.c6,
			'kunstwerken',
			$author$project$Page$Vaarweg$KunstwerkFab,
			_List_fromArray(
				[
					_Utils_Tuple2('Verwijderen', $author$project$Page$Vaarweg$KunstwerkDel)
				]),
			function (_v1) {
				var naam = _v1.aY;
				var anwbBrugNummer = _v1.bP;
				return (anwbBrugNummer === '') ? naam : (naam + (' - ' + anwbBrugNummer));
			},
			kunstwerken,
			true)
		]);
};
var $author$project$Page$Vaarweg$viewVaarweg = function (model) {
	var _v0 = model.g7;
	if (_v0.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		var vaarweg = _v0.a;
		return A2(
			$author$project$Material$LayoutGrid$view,
			_List_fromArray(
				[
					$author$project$Material$Options$cs('capped'),
					$author$project$Material$Options$attribute(
					A2($elm$html$Html$Attributes$attribute, 'id', 'main'))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$author$project$Page$halfCard,
						$elm$core$Maybe$Just('Algemeen'),
						$author$project$Page$Vaarweg$viewAlgemeen(
							_Utils_Tuple2(vaarweg, model))),
						$author$project$Page$halfInner(
						_List_fromArray(
							[
								A2(
								$author$project$Page$spreadCard,
								$elm$core$Maybe$Just('Bruggen en/of sluizen'),
								$author$project$Page$Vaarweg$viewKunstwerken(
									_Utils_Tuple2(vaarweg, model))),
								A2(
								$author$project$Page$spreadCard,
								$elm$core$Maybe$Just('Beheerders'),
								$author$project$Page$Vaarweg$viewBeheerders(
									_Utils_Tuple2(vaarweg, model)))
							])),
						A2(
						$author$project$Page$spreadCard,
						$elm$core$Maybe$Just('Kaart'),
						$author$project$Page$Vaarweg$viewKaart(
							_Utils_Tuple2(vaarweg, model))),
						A2(
						$author$project$Page$spreadCard,
						$elm$core$Maybe$Just('Details'),
						$author$project$Page$Vaarweg$viewDetailChips(
							_Utils_Tuple2(vaarweg, model)))
					]),
				$author$project$Page$Vaarweg$viewDetailCards(
					_Utils_Tuple2(vaarweg, model))));
	}
};
var $author$project$Page$Vaarweg$view = function (model) {
	if (model.aW) {
		var exclude = function () {
			var _v0 = model.g7;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var kunstwerken = _v0.a.e1;
				return kunstwerken;
			}
		}();
		return A6(
			$author$project$Page$view,
			$author$project$Page$Vaarweg$Session,
			model,
			'Kies een brug of sluis',
			_List_fromArray(
				['close']),
			_List_Nil,
			A3($author$project$Page$Partial$Kunstwerken$view, $author$project$Page$Vaarweg$Kunstwerken, model, exclude));
	} else {
		if (model.aO) {
			var exclude = function () {
				var _v1 = model.g7;
				if (_v1.$ === 1) {
					return _List_Nil;
				} else {
					var beheerders = _v1.a.dC;
					return beheerders;
				}
			}();
			return A6(
				$author$project$Page$view,
				$author$project$Page$Vaarweg$Session,
				model,
				'Kies een beheerder',
				_List_fromArray(
					['close']),
				_List_Nil,
				A3($author$project$Page$Partial$Beheerders$view, $author$project$Page$Vaarweg$Beheerders, model, exclude));
		} else {
			var pageTitle = function () {
				var _v2 = model.g7;
				if (_v2.$ === 1) {
					return 'Vaarweg';
				} else {
					var naam = _v2.a.aY;
					if (naam === '') {
						return 'Nieuwe vaarweg';
					} else {
						return naam;
					}
				}
			}();
			return A6(
				$author$project$Page$view,
				$author$project$Page$Vaarweg$Session,
				model,
				pageTitle,
				_List_fromArray(
					['arrow_back']),
				_List_fromArray(
					[
						_Utils_Tuple2('print', 'Afdrukvoorbeeld'),
						_Utils_Tuple2('save', 'Opslaan')
					]),
				$author$project$Page$Vaarweg$viewVaarweg(model));
		}
	}
};
var $author$project$Page$Partial$Vaarwegen$view_ = F2(
	function (model, exclude) {
		return A3($author$project$Page$Partial$Filter$view, $elm$core$Basics$identity, model, exclude);
	});
var $author$project$Page$Partial$Vaarwegen$view = F3(
	function (lift, _v0, exclude) {
		var vaarwegen = _v0.g8;
		return A2(
			$elm$html$Html$map,
			lift,
			A2($author$project$Page$Partial$Vaarwegen$view_, vaarwegen, exclude));
	});
var $author$project$Page$Vaarwegen$view = function (model) {
	return A6(
		$author$project$Page$view,
		$author$project$Page$Vaarwegen$Session,
		model,
		'Vaarwegen',
		_List_fromArray(
			['arrow_back']),
		_List_fromArray(
			[
				_Utils_Tuple2('add', 'Nieuwe vaarweg aanmaken')
			]),
		A3($author$project$Page$Partial$Vaarwegen$view, $author$project$Page$Vaarwegen$Vaarwegen, model, _List_Nil));
};
var $author$project$Page$Voorziening$NoOp = {$: 0};
var $author$project$Page$Voorziening$TakeLocatie = {$: 15};
var $author$project$Page$Voorziening$VaarwegDel = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Voorziening$VaarwegFab = {$: 6};
var $author$project$Internal$Textfield$Implementation$disabled = $author$project$Internal$Options$option(
	function (config) {
		return _Utils_update(
			config,
			{aQ: true});
	});
var $author$project$Material$Textfield$disabled = $author$project$Internal$Textfield$Implementation$disabled;
var $author$project$Internal$Options$onDoubleClick = function (msg) {
	return A2(
		$author$project$Internal$Options$on,
		'dblclick',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Material$Options$onDoubleClick = $author$project$Internal$Options$onDoubleClick;
var $author$project$Page$Voorziening$change = F4(
	function (model, name, value, maybeCaption) {
		var chosen = function () {
			var _v4 = model.i;
			if (_v4.$ === 1) {
				return '';
			} else {
				var voorziening = _v4.a;
				return value(voorziening);
			}
		}();
		var caption = function () {
			if (maybeCaption.$ === 1) {
				return function (string) {
					return string;
				};
			} else {
				var _function = maybeCaption.a;
				return _function;
			}
		}();
		var _v0 = function () {
			if (!A2($author$project$Session$rol, 4, model.W)) {
				return _Utils_Tuple2(_List_Nil, _List_Nil);
			} else {
				var _v1 = model.bI;
				if (_v1.$ === 1) {
					return _Utils_Tuple2(_List_Nil, _List_Nil);
				} else {
					var states = _v1.a;
					var _v2 = _Utils_Tuple2(
						value(states.ec),
						value(states.fa));
					var edit = _v2.a;
					var lastYear = _v2.b;
					return _Utils_eq(edit, lastYear) ? (_Utils_eq(chosen, edit) ? _Utils_Tuple2(_List_Nil, _List_Nil) : _Utils_Tuple2(
						_List_fromArray(
							[
								$author$project$Material$Options$cs('change-redacted'),
								$author$project$Material$Options$attribute(
								$elm$html$Html$Attributes$title(
									'bewerkt door redacteur\nwas vorig jaar: ' + caption(lastYear)))
							]),
						_List_Nil)) : (_Utils_eq(chosen, edit) ? _Utils_Tuple2(
						_List_fromArray(
							[
								$author$project$Material$Options$cs('change-taken'),
								$author$project$Material$Options$attribute(
								$elm$html$Html$Attributes$title(
									'voorstel van gebruiker overgenomen\nwas vorig jaar: ' + caption(lastYear)))
							]),
						_List_Nil) : (_Utils_eq(chosen, lastYear) ? _Utils_Tuple2(
						_List_fromArray(
							[
								$author$project$Material$Options$cs('change-proposed'),
								$author$project$Material$Options$attribute(
								$elm$html$Html$Attributes$title(
									'voorstel door gebruiker: ' + caption(edit)))
							]),
						_List_fromArray(
							[
								$author$project$Material$Options$onDoubleClick(
								A3($author$project$Field$Change, name, chosen, edit))
							])) : _Utils_Tuple2(
						_List_fromArray(
							[
								$author$project$Material$Options$cs('change-overruled'),
								$author$project$Material$Options$attribute(
								$elm$html$Html$Attributes$title(
									'overruled door redacteur\nwas vorig jaar: ' + (caption(lastYear) + ('\nvoorstel van gebruiker was: ' + caption(edit)))))
							]),
						_List_Nil)));
				}
			}
		}();
		var options = _v0.a;
		var nativeControl = _v0.b;
		return _Utils_Tuple3(chosen, options, nativeControl);
	});
var $author$project$Page$Voorziening$textfieldChange = F5(
	function (textFieldFunction, model, name, value, properties) {
		var _v0 = A4($author$project$Page$Voorziening$change, model, name, value, $elm$core$Maybe$Nothing);
		var value_ = _v0.a;
		var options = _v0.b;
		var nativeControl = _v0.c;
		var extra_properties = _Utils_ap(
			options,
			function () {
				if (!nativeControl.b) {
					return _List_Nil;
				} else {
					return _List_fromArray(
						[
							$author$project$Material$Textfield$nativeControl(nativeControl)
						]);
				}
			}());
		return A5(
			textFieldFunction,
			$author$project$Page$Voorziening$Field,
			model,
			name,
			value_,
			_Utils_ap(properties, extra_properties));
	});
var $author$project$Page$textfieldLarge = F5(
	function (lift, container, field, value, properties) {
		return A5(
			$author$project$Page$textfield_,
			lift,
			container.cY,
			field,
			value,
			A2(
				$elm$core$List$cons,
				$author$project$Material$Options$cs('large-part'),
				properties));
	});
var $author$project$Page$Voorziening$textfieldLarge = F4(
	function (model, name, value, properties) {
		return A5($author$project$Page$Voorziening$textfieldChange, $author$project$Page$textfieldLarge, model, name, value, properties);
	});
var $author$project$Page$textfieldSmall = F5(
	function (lift, container, field, value, properties) {
		return A5(
			$author$project$Page$textfield_,
			lift,
			container.cY,
			field,
			value,
			A2(
				$elm$core$List$cons,
				$author$project$Material$Options$cs('small-part'),
				properties));
	});
var $author$project$Page$Voorziening$textfieldSmall = F4(
	function (model, name, value, properties) {
		return A5($author$project$Page$Voorziening$textfieldChange, $author$project$Page$textfieldSmall, model, name, value, properties);
	});
var $author$project$Page$Voorziening$anwb = F2(
	function (model, _v0) {
		var anwbID = _v0.dw;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A5(
						$author$project$Page$textfieldSmall,
						$author$project$Page$Voorziening$Field,
						model,
						'anwbID',
						$elm$core$String$fromInt(anwbID),
						_List_fromArray(
							[$author$project$Material$Textfield$disabled])),
						A4(
						$author$project$Page$Voorziening$textfieldLarge,
						model,
						'indexNaam',
						function (v) {
							return v.cd;
						},
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$author$project$Page$Voorziening$textfieldSmall,
						model,
						'locatie.waterkaart',
						function (v) {
							return v.fi.hk;
						},
						_List_fromArray(
							[
								A2(
								$author$project$Material$Options$when,
								!A2($author$project$Session$rol, 0, model.W),
								$author$project$Material$Textfield$disabled)
							])),
						A4(
						$author$project$Page$Voorziening$textfieldLarge,
						model,
						'locatie.plaatsAlmanak',
						function (v) {
							return v.fi.fS;
						},
						_List_Nil)
					]))
			]);
	});
var $author$project$Page$Voorziening$Toggle = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Voorziening$jmd = F2(
	function (field, value) {
		var _v0 = A2($elm$core$String$split, '-', value);
		if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.b.b)) {
			var jaar = _v0.a;
			var _v1 = _v0.b;
			var maand = _v1.a;
			var _v2 = _v1.b;
			var dag = _v2.a;
			switch (field) {
				case 'jaar':
					return jaar;
				case 'maand':
					return maand;
				case 'dag':
					return dag;
				default:
					return '';
			}
		} else {
			return '';
		}
	});
var $author$project$Page$Voorziening$UpdateField = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Page$Component$Provincie$name = F2(
	function (_v0, id_) {
		var session = _v0.W;
		var _v1 = session.h9;
		if (_v1.$ === 1) {
			return '';
		} else {
			var refdata = _v1.a;
			var _v2 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v3) {
						var id = _v3.cc;
						return _Utils_eq(id, id_);
					},
					refdata.f2));
			if (_v2.$ === 1) {
				return $elm$core$String$fromInt(id_);
			} else {
				var naam = _v2.a.aY;
				return naam;
			}
		}
	});
var $author$project$Page$Component$Provincie$view = F5(
	function (mmsg, _v0, maybeId, msg, options) {
		var mdc = _v0.c6;
		var session = _v0.W;
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (_v1) {
				var _v2 = session.h9;
				if (_v2.$ === 1) {
					return $elm$html$Html$text('');
				} else {
					var refdata = _v2.a;
					return A5(
						$author$project$Material$Select$view,
						mmsg,
						'select-provincie',
						mdc,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$Material$Options$cs('field'),
									$author$project$Material$Select$label('Provincie *'),
									A2(
									$author$project$Material$Options$when,
									!_Utils_eq(maybeId, $elm$core$Maybe$Nothing),
									$author$project$Material$Select$preselected),
									$author$project$Material$Options$onChange(msg)
								]),
							options),
						A2(
							$elm$core$List$map,
							function (_v3) {
								var id = _v3.cc;
								var naam = _v3.aY;
								return A2(
									$author$project$Material$Select$option,
									_List_fromArray(
										[
											$author$project$Material$Select$value(
											$elm$core$String$fromInt(id)),
											A2(
											$author$project$Material$Options$when,
											_Utils_eq(
												id,
												A2($elm$core$Maybe$withDefault, 0, maybeId)),
											$author$project$Material$Select$selected)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(naam)
										]));
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.aY;
								},
								refdata.f2)));
				}
			},
			maybeId);
	});
var $author$project$Page$Voorziening$provincie = F3(
	function (field, value, model) {
		var _v0 = A4(
			$author$project$Page$Voorziening$change,
			model,
			field,
			function (v) {
				return $elm$core$String$fromInt(
					value(v));
			},
			$elm$core$Maybe$Just(
				function (string) {
					var _v1 = $elm$core$String$toInt(string);
					if (_v1.$ === 1) {
						return string;
					} else {
						var _int = _v1.a;
						return A2($author$project$Page$Component$Provincie$name, model, _int);
					}
				}));
		var value_ = _v0.a;
		var options = _v0.b;
		return A5(
			$author$project$Page$Component$Provincie$view,
			$author$project$Page$Voorziening$Mdc,
			model,
			$elm$core$String$toInt(value_),
			$author$project$Page$Voorziening$UpdateField(field),
			options);
	});
var $author$project$Page$Voorziening$textfield = F4(
	function (model, name, value, properties) {
		return A5($author$project$Page$Voorziening$textfieldChange, $author$project$Page$textfield, model, name, value, properties);
	});
var $author$project$Page$Voorziening$anwbConsul = function (model) {
	return _Utils_ap(
		_List_fromArray(
			[
				A4(
				$author$project$Page$Voorziening$textfield,
				model,
				'consul.naam',
				function (v) {
					return v.d_.aY;
				},
				_List_Nil),
				A3(
				$author$project$Page$Voorziening$provincie,
				'consul.provincie',
				function (v) {
					return v.d_.f1;
				},
				model)
			]),
		model.br ? _List_fromArray(
			[
				A4(
				$author$project$Page$Voorziening$textfield,
				model,
				'consul.bezoek.dag',
				function (v) {
					return A2($author$project$Page$Voorziening$jmd, 'dag', v.d_.dG);
				},
				_List_fromArray(
					[
						$author$project$Material$Options$attribute(
						$elm$html$Html$Attributes$title('01-31'))
					])),
				A4(
				$author$project$Page$Voorziening$textfield,
				model,
				'consul.bezoek.maand',
				function (v) {
					return A2($author$project$Page$Voorziening$jmd, 'maand', v.d_.dG);
				},
				_List_fromArray(
					[
						$author$project$Material$Options$attribute(
						$elm$html$Html$Attributes$title('01-12'))
					])),
				A4(
				$author$project$Page$Voorziening$textfield,
				model,
				'consul.bezoek.jaar',
				function (v) {
					return A2($author$project$Page$Voorziening$jmd, 'jaar', v.d_.dG);
				},
				_List_fromArray(
					[
						$author$project$Material$Options$attribute(
						$elm$html$Html$Attributes$title('4 cijfers'))
					]))
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$author$project$Page$Voorziening$textfield,
						model,
						'consul.bezoek',
						function (v) {
							return v.d_.dG;
						},
						_List_fromArray(
							[
								$author$project$Material$Textfield$nativeControl(
								_List_fromArray(
									[
										$author$project$Material$Options$attribute(
										$elm$html$Html$Attributes$type_('date'))
									])),
								A2($author$project$Material$Options$css, 'width', 'calc(100% - 36px)')
							])),
						A2(
						$author$project$Material$Icon$view,
						_List_fromArray(
							[
								$author$project$Material$Options$onClick(
								$author$project$Page$Voorziening$Toggle('noDate')),
								$author$project$Material$Options$cs('button'),
								$author$project$Material$Options$attribute(
								$elm$html$Html$Attributes$title('Dag, maand, jaar apart invoeren.'))
							]),
						'unfold_more')
					]))
			]));
};
var $author$project$Page$Voorziening$anwbContent = F2(
	function (model, voorziening) {
		return A2(
			$author$project$Material$LayoutGrid$inner,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Material$LayoutGrid$cell,
					_List_fromArray(
						[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]),
					A2($author$project$Page$Voorziening$anwb, model, voorziening)),
					A2(
					$author$project$Material$LayoutGrid$cell,
					_List_fromArray(
						[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]),
					$author$project$Page$Voorziening$anwbConsul(model))
				]));
	});
var $author$project$Page$Voorziening$land = F3(
	function (field, value, model) {
		var _v0 = A4(
			$author$project$Page$Voorziening$change,
			model,
			field,
			function (v) {
				return value(v);
			},
			$elm$core$Maybe$Nothing);
		var value_ = _v0.a;
		var options = _v0.b;
		return A5(
			$author$project$Page$Component$Land$view,
			$author$project$Page$Voorziening$Mdc,
			model,
			value_,
			$author$project$Page$Voorziening$UpdateField(field),
			options);
	});
var $author$project$Page$Voorziening$boolChange = F3(
	function (model, name, value) {
		return A3(
			$author$project$Page$Voorziening$change,
			model,
			name,
			function (v) {
				return value(v) ? 'Ja' : 'Nee';
			});
	});
var $author$project$Data$Voorziening$label = function (input) {
	switch (input) {
		case 'gemeente':
			return 'Gemeente';
		case 'jachthaven':
			return 'Jachthaven';
		case 'jachtwerf':
			return 'Jachtwerf';
		case 'nautischeBoekhandel':
			return 'Nautische Boekhandel';
		case 'overig':
			return 'Overig';
		case 'vaarschool':
			return 'Vaarschool';
		case 'waterEnOfRecreatieschap':
			return 'Water en/of Recreatieschap';
		case 'watersportvereniging':
			return 'Watersportvereniging';
		case 'hiswaLid':
			return 'Lid HISWA';
		case 'blauweVlag':
			return 'Blauwe Vlag';
		case 'afspuitplaats':
			return 'Afspuitplaats';
		case 'bilgewater':
			return 'Bilgewater';
		case 'fietsverhuur':
			return 'Fietsverhuur';
		case 'jollen':
			return 'Jollensteiger';
		case 'kampeerterrein':
			return 'Kampeerterrein';
		case 'waterligging':
			return 'Aan het water gelegen';
		case 'oliestort':
			return 'Stortplaats afgewerkte olie';
		case 'restaurant':
			return 'Restaurant';
		case 'speelplaats':
			return 'Speelplaats';
		case 'stortplaats':
			return 'Stortplaats chemisch toilet';
		case 'supermarkt':
			return 'Supermarkt (op loopafstand)';
		case 'telefooncel':
			return 'Telefooncel';
		case 'vuilniscontainers':
			return 'Vuilniscontainers';
		case 'vuilwatertank':
			return 'Aftappunt vuilwatertank';
		case 'wasserette':
			return 'Wasserette';
		case 'watersporwinkel':
			return 'Watersporwinkel';
		case 'wifi':
			return 'Wifi';
		case 'zwembad':
			return 'Zwembad';
		case 'aed':
			return 'AED';
		case 'bootverhuurder':
			return 'Bootverhuurder';
		case 'motorjacht':
			return 'Motorjacht';
		case 'zeiljacht':
			return 'Zeiljacht';
		case 'openZeilboot':
			return 'Open zeilboot';
		case 'sloep':
			return 'Sloep';
		case 'kano':
			return 'Kano';
		case 'brandstof':
			return 'Brandstof';
		case 'diesel':
			return 'Diesel';
		case 'benzine':
			return 'Benzine';
		case 'gtl':
			return 'GTL';
		case 'douches':
			return 'Douches';
		case 'drinkwater':
			return 'Drinkwater';
		case 'elektraSteiger':
			return 'Elektra op steiger';
		case 'elektraTerrein':
			return 'Elektra op terrein';
		case 'gasflessenVerkoop':
			return 'Gasflessenverkoop';
		case 'gehandicapten':
			return 'Gehandicaptenvoorzieningen';
		case 'laadpuntElektrisch':
			return 'Laadpunt elektroboten';
		case 'ligplaatsen':
			return 'Ligplaatsen';
		case 'tariefPassantAanvraag':
			return 'Passantentarief op aanvraag';
		case 'passantenligplaatsen':
			return 'Passantenligplaatsen';
		case 'toiletten':
			return 'Toiletten';
		case 'wastafels':
			return 'Wastafels';
		case 'botenlift':
			return 'Botenlift';
		case 'mast':
			return 'Met staande mast mogelijk';
		case 'hefkraan':
			return 'Hefkraan';
		case 'mastenkraan':
			return 'Mastenkraan';
		case 'reparatieBoot':
			return 'Bootreparatie';
		case 'reparatieElektronica':
			return 'Reparatie elektronica';
		case 'reparatieTuigage':
			return 'Reparatie tuigage';
		case 'reparatieWal':
			return 'Reparatie op de wal mogelijk';
		case 'reparatieZeil':
			return 'Reparatie zeil';
		case 'reparatieMotor':
			return 'Motorreparatie';
		case 'reparatieBinnenboordmotor':
			return 'Reparatie binnenboordmotor';
		case 'reparatieBuitenboordmotor':
			return 'Reparatie buitenboordmotor';
		case 'scheepshelling':
			return 'Scheepshelling';
		case 'trailerhelling':
			return 'Trailerhelling';
		case 'campers':
			return 'Campers';
		case 'digitaalBetalen':
			return 'Digitaal betalen';
		case 'bootverhuur':
			return 'Bootverhuur';
		case 'sanitair':
			return 'Sanitair';
		default:
			return '';
	}
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Page$Voorziening$switch_ = F4(
	function (model, field, value, properties) {
		var _v0 = A4($author$project$Page$Voorziening$boolChange, model, field, value, $elm$core$Maybe$Nothing);
		var value_ = _v0.a;
		var options = _v0.b;
		var selected = value_ === 'Ja';
		var _v1 = $elm_community$list_extra$List$Extra$last(
			A2($elm$core$String$split, '.', field));
		if (_v1.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var subfield = _v1.a;
			return A2(
				$author$project$Material$FormField$view,
				_Utils_ap(properties, options),
				_List_fromArray(
					[
						A5(
						$author$project$Material$Switch$view,
						$author$project$Page$Voorziening$Mdc,
						field,
						model.c6,
						_List_fromArray(
							[
								A2($author$project$Material$Options$when, selected, $author$project$Material$Switch$on),
								$author$project$Material$Options$onClick(
								$author$project$Page$Voorziening$Toggle(field))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Page$Voorziening$Toggle(field))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2($author$project$Session$flag, subfield, model.W))
									]),
								_List_Nil),
								$elm$html$Html$text(
								$author$project$Data$Voorziening$label(subfield))
							]))
					]));
		}
	});
var $author$project$Page$Voorziening$switch = F3(
	function (model, field, value) {
		return A4(
			$author$project$Page$Voorziening$switch_,
			model,
			field,
			value,
			_List_fromArray(
				[
					$author$project$Material$Options$cs('field')
				]));
	});
var $author$project$Page$Voorziening$bedrijfAdresExtra = function (model) {
	return _List_fromArray(
		[
			A3(
			$author$project$Page$Voorziening$switch,
			model,
			'bedrijf.hiswaLid',
			function (v) {
				return v.dz.eI;
			}),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'bedrijf.web',
			function (v) {
				return v.dz.hm;
			},
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfieldSmall,
					model,
					'bedrijf.telefoon.net',
					function (v) {
						return v.dz.gO.cq;
					},
					_List_Nil),
					A4(
					$author$project$Page$Voorziening$textfieldLarge,
					model,
					'bedrijf.telefoon.abonnee',
					function (v) {
						return v.dz.gO.bL;
					},
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfieldSmall,
					model,
					'bedrijf.fax.net',
					function (v) {
						return v.dz.el.cq;
					},
					_List_Nil),
					A4(
					$author$project$Page$Voorziening$textfieldLarge,
					model,
					'bedrijf.fax.abonnee',
					function (v) {
						return v.dz.el.bL;
					},
					_List_Nil)
				])),
			A3(
			$author$project$Page$Voorziening$land,
			'bedrijf.land',
			function (v) {
				return v.dz.e5;
			},
			model)
		]);
};
var $author$project$Page$Voorziening$bedrijfNaw = function (model) {
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'bedrijf.naam',
			function (v) {
				return v.dz.aY;
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'bedrijf.tav',
			function (v) {
				return v.dz.gM;
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'bedrijf.adres',
			function (v) {
				return v.dz.dr;
			},
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfieldSmall,
					model,
					'bedrijf.postcode',
					function (v) {
						return v.dz.fV;
					},
					_List_Nil),
					A4(
					$author$project$Page$Voorziening$textfieldLarge,
					model,
					'bedrijf.woonplaats',
					function (v) {
						return v.dz.cK;
					},
					_List_Nil)
				])),
			A3(
			$author$project$Page$Voorziening$provincie,
			'bedrijf.provincie',
			function (v) {
				return v.dz.f1;
			},
			model)
		]);
};
var $author$project$Page$Voorziening$bedrijfContent = function (model) {
	return A2(
		$author$project$Material$LayoutGrid$inner,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Material$LayoutGrid$cell,
				_List_fromArray(
					[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]),
				$author$project$Page$Voorziening$bedrijfNaw(model)),
				A2(
				$author$project$Material$LayoutGrid$cell,
				_List_fromArray(
					[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]),
				$author$project$Page$Voorziening$bedrijfAdresExtra(model))
			]));
};
var $author$project$Internal$Checkbox$Model$Checked = 0;
var $author$project$Internal$Checkbox$Model$Unchecked = 1;
var $author$project$Internal$Checkbox$Implementation$checked = function (value) {
	var state = value ? 0 : 1;
	return $author$project$Internal$Options$option(
		function (config) {
			return _Utils_update(
				config,
				{
					cD: $elm$core$Maybe$Just(state)
				});
		});
};
var $author$project$Material$Checkbox$checked = $author$project$Internal$Checkbox$Implementation$checked;
var $author$project$Internal$Checkbox$Model$AnimationEnd = {$: 3};
var $author$project$Internal$Checkbox$Model$Init = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Internal$Checkbox$Model$NoOp = {$: 0};
var $author$project$Internal$Checkbox$Model$SetFocus = function (a) {
	return {$: 2, a: a};
};
var $author$project$Internal$Checkbox$Implementation$defaultConfig = {aQ: false, c3: '', fz: _List_Nil, cD: $elm$core$Maybe$Nothing};
var $author$project$Internal$Checkbox$Implementation$checkbox = F4(
	function (lift, model, options, _v0) {
		var animationClass = function (animation) {
			if (!animation.$) {
				switch (animation.a) {
					case 0:
						var _v2 = animation.a;
						return $author$project$Internal$Options$cs('mdc-checkbox--anim-unchecked-checked');
					case 1:
						var _v3 = animation.a;
						return $author$project$Internal$Options$cs('mdc-checkbox--anim-unchecked-indeterminate');
					case 2:
						var _v4 = animation.a;
						return $author$project$Internal$Options$cs('mdc-checkbox--anim-checked-unchecked');
					case 3:
						var _v5 = animation.a;
						return $author$project$Internal$Options$cs('mdc-checkbox--anim-checked-indeterminate');
					case 4:
						var _v6 = animation.a;
						return $author$project$Internal$Options$cs('mdc-checkbox--anim-indeterminate-checked');
					default:
						var _v7 = animation.a;
						return $author$project$Internal$Options$cs('mdc-checkbox--anim-indeterminate-unchecked');
				}
			} else {
				return $author$project$Internal$Options$nop;
			}
		};
		var summary = A2($author$project$Internal$Options$collect, $author$project$Internal$Checkbox$Implementation$defaultConfig, options);
		var config = summary.dY;
		var configState = config.cD;
		var currentState = A2($elm$core$Maybe$withDefault, configState, model.cl);
		var stateChangedOrUninitialized = _Utils_eq(model.cl, $elm$core$Maybe$Nothing) || (!_Utils_eq(currentState, configState));
		return A5(
			$author$project$Internal$Options$apply,
			summary,
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Internal$Options$cs('mdc-checkbox mdc-checkbox--upgraded'),
					A2(
					$author$project$Internal$Options$when,
					_Utils_eq(currentState, $elm$core$Maybe$Nothing),
					$author$project$Internal$Options$cs('mdc-checkbox--indeterminate')),
					A2(
					$author$project$Internal$Options$when,
					_Utils_eq(
						currentState,
						$elm$core$Maybe$Just(0)),
					$author$project$Internal$Options$cs('mdc-checkbox--checked')),
					A2(
					$author$project$Internal$Options$when,
					config.aQ,
					$author$project$Internal$Options$cs('mdc-checkbox--disabled')),
					animationClass(model.bO),
					A2(
					$author$project$Internal$Options$when,
					stateChangedOrUninitialized,
					$author$project$Internal$GlobalEvents$onTick(
						$elm$json$Json$Decode$succeed(
							lift(
								A2($author$project$Internal$Checkbox$Model$Init, model.cl, configState))))),
					A2(
					$author$project$Internal$Options$when,
					!_Utils_eq(model.bO, $elm$core$Maybe$Nothing),
					A2(
						$author$project$Internal$Options$on,
						'animationend',
						$elm$json$Json$Decode$succeed(
							lift($author$project$Internal$Checkbox$Model$AnimationEnd))))
				]),
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Internal$Options$applyNativeControl,
					summary,
					$elm$html$Html$input,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-checkbox__native-control'),
							A3(
							$elm$core$Basics$composeL,
							$author$project$Internal$Options$many,
							$elm$core$List$map($author$project$Internal$Options$attribute),
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Attributes$id(config.c3),
									A2(
									$elm$html$Html$Attributes$property,
									'indeterminate',
									$elm$json$Json$Encode$bool(
										_Utils_eq(currentState, $elm$core$Maybe$Nothing))),
									$elm$html$Html$Attributes$checked(
									_Utils_eq(
										currentState,
										$elm$core$Maybe$Just(0))),
									$elm$html$Html$Attributes$disabled(config.aQ)
								])),
							A2(
							$author$project$Internal$Options$onWithOptions,
							'click',
							$elm$json$Json$Decode$succeed(
								{
									fu: lift($author$project$Internal$Checkbox$Model$NoOp),
									fW: true,
									gE: false
								})),
							A2(
							$author$project$Internal$Options$onWithOptions,
							'change',
							$elm$json$Json$Decode$succeed(
								{
									fu: lift($author$project$Internal$Checkbox$Model$NoOp),
									fW: true,
									gE: false
								})),
							$author$project$Internal$Options$onFocus(
							lift(
								$author$project$Internal$Checkbox$Model$SetFocus(true))),
							$author$project$Internal$Options$onBlur(
							lift(
								$author$project$Internal$Checkbox$Model$SetFocus(false)))
						]),
					_List_Nil),
					A3(
					$author$project$Internal$Options$styled,
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Internal$Options$cs('mdc-checkbox__background')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$svg,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('mdc-checkbox__checkmark'),
									$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
								]),
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$path,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$class('mdc-checkbox__checkmark-path'),
											$elm$svg$Svg$Attributes$fill('none'),
											$elm$svg$Svg$Attributes$stroke('white'),
											$elm$svg$Svg$Attributes$d('M1.73,12.91 8.1,19.28 22.79,4.59')
										]),
									_List_Nil)
								])),
							A3(
							$author$project$Internal$Options$styled,
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Internal$Options$cs('mdc-checkbox__mixedmark')
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Internal$Checkbox$Implementation$view = F4(
	function (lift, index, store, options) {
		return A7(
			$author$project$Internal$Component$render,
			$author$project$Internal$Checkbox$Implementation$getSet.ey,
			$author$project$Internal$Checkbox$Implementation$checkbox,
			$author$project$Internal$Msg$CheckboxMsg,
			lift,
			index,
			store,
			A2(
				$elm$core$List$cons,
				$author$project$Internal$Options$internalId(index),
				options));
	});
var $author$project$Material$Checkbox$view = $author$project$Internal$Checkbox$Implementation$view;
var $author$project$Page$Voorziening$checkbox_ = F4(
	function (model, field, value, properties) {
		var _v0 = A4($author$project$Page$Voorziening$boolChange, model, field, value, $elm$core$Maybe$Nothing);
		var value_ = _v0.a;
		var options = _v0.b;
		var selected = value_ === 'Ja';
		var _v1 = $elm_community$list_extra$List$Extra$last(
			A2($elm$core$String$split, '.', field));
		if (_v1.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var subfield = _v1.a;
			return A2(
				$author$project$Material$FormField$view,
				_Utils_ap(properties, options),
				_List_fromArray(
					[
						A5(
						$author$project$Material$Checkbox$view,
						$author$project$Page$Voorziening$Mdc,
						field,
						model.c6,
						_List_fromArray(
							[
								$author$project$Material$Checkbox$checked(selected),
								$author$project$Material$Options$onClick(
								$author$project$Page$Voorziening$Toggle(field))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Page$Voorziening$Toggle(field))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										A2(
											$author$project$Session$flag,
											A3($elm$core$String$replace, '.', '_', field),
											model.W))
									]),
								_List_Nil),
								$elm$html$Html$text(
								$author$project$Data$Voorziening$label(subfield))
							]))
					]));
		}
	});
var $author$project$Page$Voorziening$checkbox = F3(
	function (model, field, value) {
		return A4($author$project$Page$Voorziening$checkbox_, model, field, value, _List_Nil);
	});
var $author$project$Page$Voorziening$bootverhuurderContent = function (model) {
	var data = function (v) {
		return v.dz.ae.dK;
	};
	return _List_fromArray(
		[
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'categorie.bootverhuurder.motorjacht',
			function (v) {
				return data(v).fy;
			}),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'categorie.bootverhuurder.zeiljacht',
			function (v) {
				return data(v).hs;
			}),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'categorie.bootverhuurder.openZeilboot',
			function (v) {
				return data(v).fC;
			}),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'categorie.bootverhuurder.sloep',
			function (v) {
				return data(v).gw;
			}),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'categorie.bootverhuurder.kano',
			function (v) {
				return data(v).eY;
			})
		]);
};
var $author$project$Page$Voorziening$botenliftContent = function (model) {
	var data = function (v) {
		return v.eT.he.gN.ae.dL;
	};
	return _List_fromArray(
		[
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'technisch.botenlift.mast',
			function (v) {
				return data(v).co;
			}),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.botenlift.maxDiepgang',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).aj);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.botenlift.maxGewicht',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).K);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$brandstofContent = function (model) {
	var data = function (v) {
		return v.eT.he.fY.ae.dN;
	};
	return _List_fromArray(
		[
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'primair.brandstof.diesel',
			function (v) {
				return data(v).d7;
			}),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'primair.brandstof.benzine',
			function (v) {
				return data(v).dD;
			}),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'primair.brandstof.gtl',
			function (v) {
				return data(v).eD;
			})
		]);
};
var $author$project$Page$Voorziening$Categorie = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm_community$dict_extra$Dict$Extra$mapKeys = F2(
	function (keyMapper, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, acc) {
					return A3(
						$elm$core$Dict$insert,
						keyMapper(k),
						v,
						acc);
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Page$Voorziening$categorieenChips_ = F4(
	function (container, dict, model, properties) {
		var _v0 = model.i;
		if (_v0.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var voorziening = _v0.a;
			return A2(
				$author$project$Material$Chip$chipset,
				A2($elm$core$List$cons, $author$project$Material$Chip$filter, properties),
				A2(
					$elm$core$List$cons,
					$author$project$Page$body2('Selecteer wat van toepassing is (blauw=aan; grijs=uit):'),
					$elm$core$List$singleton(
						A3(
							$elm$html$Html$Keyed$node,
							'span',
							_List_Nil,
							$elm$core$Dict$toList(
								A2(
									$elm_community$dict_extra$Dict$Extra$mapKeys,
									function (categorie) {
										return $author$project$Data$Voorziening$label(categorie);
									},
									A2(
										$elm$core$Dict$map,
										F2(
											function (categorie, _v1) {
												var field = container + ('.categorieen.' + categorie);
												var _v2 = A4(
													$author$project$Page$Voorziening$boolChange,
													model,
													field,
													function (v) {
														var _v3 = A2(
															$elm$core$Dict$get,
															categorie,
															dict(v));
														if (_v3.$ === 1) {
															return false;
														} else {
															var bool = _v3.a;
															return bool;
														}
													},
													$elm$core$Maybe$Nothing);
												var value_ = _v2.a;
												var options = _v2.b;
												var selected = value_ === 'Ja';
												return A5(
													$author$project$Material$Chip$view,
													$author$project$Page$Voorziening$Mdc,
													field,
													model.c6,
													_Utils_ap(
														options,
														_List_fromArray(
															[
																A2($author$project$Material$Options$when, selected, $author$project$Material$Chip$selected),
																$author$project$Material$Chip$onClick(
																A2($author$project$Page$Voorziening$Categorie, container, categorie))
															])),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$img,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$src(
																	A2($author$project$Session$flag, categorie, model.W)),
																	A2($elm$html$Html$Attributes$style, 'width', '20px'),
																	A2($elm$html$Html$Attributes$style, 'height', '20px')
																]),
															_List_Nil),
															$elm$html$Html$text(
															$author$project$Data$Voorziening$label(categorie))
														]));
											}),
										dict(voorziening))))))));
		}
	});
var $author$project$Page$Voorziening$categorieenChips = F3(
	function (container, dict, model) {
		return A4(
			$author$project$Page$Voorziening$categorieenChips_,
			container,
			dict,
			model,
			_List_fromArray(
				[
					$author$project$Material$Options$cs('field-margin')
				]));
	});
var $author$project$Internal$Card$Implementation$backgroundImage = function (url) {
	return A2($author$project$Internal$Options$css, 'background-image', 'url(' + (url + ')'));
};
var $author$project$Material$Card$backgroundImage = $author$project$Internal$Card$Implementation$backgroundImage;
var $author$project$Internal$Card$Implementation$media = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-card__media'),
			options));
};
var $author$project$Material$Card$media = $author$project$Internal$Card$Implementation$media;
var $author$project$Internal$Card$Implementation$mediaContent = function (options) {
	return A2(
		$author$project$Internal$Options$styled,
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$author$project$Internal$Options$cs('mdc-card__media-content'),
			options));
};
var $author$project$Material$Card$mediaContent = $author$project$Internal$Card$Implementation$mediaContent;
var $author$project$Internal$Card$Implementation$square = $author$project$Internal$Options$cs('mdc-card__media--square');
var $author$project$Material$Card$square = $author$project$Internal$Card$Implementation$square;
var $author$project$Material$Typography$subheading1 = $author$project$Material$Typography$subtitle1;
var $author$project$Page$Voorziening$dataCard_ = F4(
	function (categorie_, aanwezig_, content, model) {
		return A2(
			$elm$html$Html$Lazy$lazy,
			function (_v0) {
				var categorie = _v0.a;
				var aanwezig = _v0.b;
				var _v1 = A2($elm$core$Dict$get, categorie, aanwezig);
				if (_v1.$ === 1) {
					return $elm$html$Html$text('');
				} else {
					var value = _v1.a;
					return (!value) ? $elm$html$Html$text('') : A2(
						$author$project$Material$LayoutGrid$cell,
						_List_fromArray(
							[$author$project$Material$LayoutGrid$span4]),
						_List_fromArray(
							[
								A2(
								$author$project$Material$Card$view,
								_List_Nil,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$author$project$Material$Card$media,
											_List_fromArray(
												[
													$author$project$Material$Card$square,
													$author$project$Material$Card$backgroundImage(
													A2($author$project$Session$flag, categorie, model.W)),
													A2($author$project$Material$Options$css, 'width', '40%'),
													A2($author$project$Material$Options$css, 'left', '30%')
												]),
											_List_fromArray(
												[
													A2(
													$author$project$Material$Card$mediaContent,
													_List_fromArray(
														[
															A2($author$project$Material$Options$css, 'background-color', 'rgba(255,255,255,0.6)')
														]),
													_List_Nil)
												])),
											A3(
											$author$project$Material$Options$styled,
											$elm$html$Html$h3,
											_List_fromArray(
												[
													$author$project$Material$Typography$subheading1,
													A2($author$project$Material$Options$css, 'margin-bottom', '0')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Data$Voorziening$label(categorie))
												]))
										]),
									content(model)))
							]));
				}
			},
			_Utils_Tuple2(categorie_, aanwezig_));
	});
var $author$project$Page$Voorziening$dataCardCategorie = F4(
	function (categorie, _v0, content, model) {
		var categorieen = _v0.bW;
		return A4($author$project$Page$Voorziening$dataCard_, categorie, categorieen, content, model);
	});
var $author$project$Page$Voorziening$dataCardVoorziening = F4(
	function (categorie, _v0, content, model) {
		var aanwezig = _v0.aJ;
		return A4($author$project$Page$Voorziening$dataCard_, categorie, aanwezig, content, model);
	});
var $author$project$Page$Voorziening$drinkwaterContent = function (model) {
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.drinkwater.kosten',
			function (v) {
				return $elm$core$String$fromFloat(v.eT.he.fY.ae.eb.ch);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$hefkraanContent = function (model) {
	var data = function (v) {
		return v.eT.he.gN.ae.eG;
	};
	return _List_fromArray(
		[
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'technisch.hefkraan.mast',
			function (v) {
				return data(v).co;
			}),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.hefkraan.maxDiepgang',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).aj);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.hefkraan.maxGewicht',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).K);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$jachthavenAdresExtra = function (model) {
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'jachthaven.havenmeester',
			function (v) {
				return v.eT.eE;
			},
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$title('Het algemene e-mailadres voor info aan klanten')
				]),
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfield,
					model,
					'bedrijf.email',
					function (v) {
						return v.dz.ef;
					},
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfieldSmall,
					model,
					'jachthaven.telefoonHavenkantoor.net',
					function (v) {
						return v.eT.gP.cq;
					},
					_List_Nil),
					A4(
					$author$project$Page$Voorziening$textfieldLarge,
					model,
					'jachthaven.telefoonHavenkantoor.abonnee',
					function (v) {
						return v.eT.gP.bL;
					},
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfieldSmall,
					model,
					'jachthaven.telefoonHavenmeester.net',
					function (v) {
						return v.eT.gQ.cq;
					},
					_List_Nil),
					A4(
					$author$project$Page$Voorziening$textfieldLarge,
					model,
					'jachthaven.telefoonHavenmeester.abonnee',
					function (v) {
						return v.eT.gQ.bL;
					},
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A4(
					$author$project$Page$Voorziening$textfieldSmall,
					model,
					'jachthaven.kanaalMarifoon',
					function (v) {
						return $elm$core$String$fromInt(v.eT.eX);
					},
					_List_Nil),
					A3(
					$author$project$Page$Voorziening$switch,
					model,
					'jachthaven.blauweVlag',
					function (v) {
						return v.eT.dJ;
					})
				]))
		]);
};
var $author$project$Page$Voorziening$textarea = F3(
	function (model, name, value) {
		return A5($author$project$Page$Voorziening$textfieldChange, $author$project$Page$textarea, model, name, value, _List_Nil);
	});
var $author$project$Page$Voorziening$jachthavenNaw = function (model) {
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'jachthaven.naam',
			function (v) {
				return v.eT.aY;
			},
			_List_Nil),
			A3(
			$author$project$Page$Voorziening$textarea,
			model,
			'jachthaven.ligging',
			function (v) {
				return v.eT.ff;
			}),
			A3(
			$author$project$Page$Voorziening$textarea,
			model,
			'jachthaven.bijzHaveningang',
			function (v) {
				return v.eT.dH;
			})
		]);
};
var $author$project$Page$Voorziening$jachthavenContent = function (model) {
	return A2(
		$author$project$Material$LayoutGrid$inner,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Material$LayoutGrid$cell,
				_List_fromArray(
					[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]),
				$author$project$Page$Voorziening$jachthavenNaw(model)),
				A2(
				$author$project$Material$LayoutGrid$cell,
				_List_fromArray(
					[$author$project$Material$LayoutGrid$span6Desktop, $author$project$Material$LayoutGrid$span8Tablet, $author$project$Material$LayoutGrid$span4Phone]),
				$author$project$Page$Voorziening$jachthavenAdresExtra(model))
			]));
};
var $author$project$Page$Voorziening$ligplaatsenContent = function (model) {
	var data = function (v) {
		return v.eT.he.fY.ae.fh;
	};
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.ligplaatsen.aantal',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).bK);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.ligplaatsen.vrij',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).hf);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$mastenkraanContent = function (model) {
	var data = function (v) {
		return v.eT.he.gN.ae.fl;
	};
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.mastenkraan.maxLengte',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).fq);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.mastenkraan.maxGewicht',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).K);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$passantenligplaatsenContent = function (model) {
	var data = function (v) {
		return v.eT.he.fY.ae.fK;
	};
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.passantenligplaatsen.aantal',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).bK);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.passantenligplaatsen.diepgangMax',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).d6);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.passantenligplaatsen.lengteMax',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).fe);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.passantenligplaatsen.maxBreedte',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).fm);
			},
			_List_Nil),
			A3(
			$author$project$Page$Voorziening$checkbox,
			model,
			'primair.passantenligplaatsen.tariefPassantAanvraag',
			function (v) {
				return data(v).gL;
			}),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.passantenligplaatsen.tarief',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).w);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'primair.passantenligplaatsen.afwijkendTarief',
			function (v) {
				return data(v).du;
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$scheepshellingContent = function (model) {
	var data = function (v) {
		return v.eT.he.gN.ae.gl;
	};
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.scheepshelling.maxDiepgang',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).aj);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.scheepshelling.maxGewicht',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).K);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$trailerhellingContent = function (model) {
	var data = function (v) {
		return v.eT.he.gN.ae.g3;
	};
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.trailerhelling.maxDiepgang',
			function (v) {
				return $elm$core$String$fromFloat(
					data(v).aj);
			},
			_List_Nil),
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'technisch.trailerhelling.maxGewicht',
			function (v) {
				return $elm$core$String$fromInt(
					data(v).K);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$vuilwatertankContent = function (model) {
	return _List_fromArray(
		[
			A4(
			$author$project$Page$Voorziening$textfield,
			model,
			'algemeen.vuilwatertank.kosten',
			function (v) {
				return $elm$core$String$fromFloat(v.eT.he.dv.ae.hg.ch);
			},
			_List_Nil)
		]);
};
var $author$project$Page$Voorziening$view = function (model) {
	if (model.a7) {
		var exclude = function () {
			var _v0 = model.i;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var vaarwegen = _v0.a.g8;
				return vaarwegen;
			}
		}();
		return A6(
			$author$project$Page$view,
			$author$project$Page$Voorziening$Session,
			model,
			'Kies een vaarweg',
			_List_fromArray(
				['close']),
			_List_Nil,
			A3($author$project$Page$Partial$Vaarwegen$view, $author$project$Page$Voorziening$Vaarwegen, model, exclude));
	} else {
		var pageTitle = function () {
			var _v7 = model.i;
			if (_v7.$ === 1) {
				return 'Voorziening';
			} else {
				var bedrijf = _v7.a.dz;
				var _v8 = bedrijf.aY;
				if (_v8 === '') {
					return 'Nieuwe voorziening';
				} else {
					return bedrijf.aY;
				}
			}
		}();
		var isSuperUser = A2($author$project$Session$rol, 0, model.W);
		var isGebruiker = A2($author$project$Session$rol, 3, model.W);
		var base_actions = _List_fromArray(
			[
				_Utils_Tuple2('save', 'Opslaan'),
				_Utils_Tuple2('playlist_add_check', 'Status')
			]);
		var _v1 = A2($author$project$Session$rol, 0, model.W) ? _Utils_Tuple2(
			_List_fromArray(
				['arrow_back']),
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2('print', 'Afdrukvoorbeeld'),
						_Utils_Tuple2('delete', 'Verwijderen')
					]),
				base_actions)) : _Utils_Tuple2(
			_List_Nil,
			_Utils_ap(
				base_actions,
				_List_fromArray(
					[
						_Utils_Tuple2('power_settings_new', 'Uitloggen')
					])));
		var navigation = _v1.a;
		var intermediary_actions = _v1.b;
		var actions = A2($author$project$Session$rol, 3, model.W) ? intermediary_actions : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('help', 'Handleiding'),
			intermediary_actions);
		return A6(
			$author$project$Page$view,
			$author$project$Page$Voorziening$Session,
			model,
			pageTitle,
			navigation,
			actions,
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						(!model.a6) ? $elm$html$Html$text('') : A4(
						$author$project$Session$actionMenu,
						$author$project$Page$Voorziening$Session,
						model,
						'playlist_add_check',
						isSuperUser ? _List_fromArray(
							[
								_Utils_Tuple2(
								'print',
								$author$project$Page$Voorziening$statusLabel('print'))
							]) : _List_fromArray(
							[
								_Utils_Tuple2(
								'edit',
								$author$project$Page$Voorziening$statusLabel('edit')),
								_Utils_Tuple2(
								'ready',
								$author$project$Page$Voorziening$statusLabel('ready'))
							])),
						function () {
						var _v2 = model.i;
						if (_v2.$ === 1) {
							return $elm$html$Html$text('');
						} else {
							var voorziening = _v2.a;
							var bedrijf = voorziening.dz;
							var jachthaven = voorziening.eT;
							var vaarwegen = voorziening.g8;
							var _v3 = jachthaven.he;
							var algemeen = _v3.dv;
							var primair = _v3.fY;
							var technisch = _v3.gN;
							var spreadOrHalfCard = isSuperUser ? $author$project$Page$halfCard : $author$project$Page$spreadCard;
							return A2(
								$author$project$Material$LayoutGrid$view,
								_List_fromArray(
									[
										$author$project$Material$Options$cs('capped')
									]),
								_List_fromArray(
									[
										isGebruiker ? $elm$html$Html$text('') : A2(
										$author$project$Page$spreadCard,
										$elm$core$Maybe$Just('ANWB'),
										_List_fromArray(
											[
												A2($author$project$Page$Voorziening$anwbContent, model, voorziening)
											])),
										A2(
										$author$project$Page$spreadCard,
										$elm$core$Maybe$Just('Bedrijf'),
										_List_fromArray(
											[
												$author$project$Page$Voorziening$bedrijfContent(model),
												A3(
												$author$project$Page$Voorziening$categorieenChips,
												'bedrijf',
												function (v) {
													return v.dz.bW;
												},
												model),
												A2(
												$author$project$Material$LayoutGrid$inner,
												_List_Nil,
												_List_fromArray(
													[
														A4($author$project$Page$Voorziening$dataCardCategorie, 'bootverhuurder', bedrijf, $author$project$Page$Voorziening$bootverhuurderContent, model)
													]))
											])),
										function () {
										if (isGebruiker) {
											return $elm$html$Html$text('');
										} else {
											var _v4 = A4(
												$author$project$Page$Voorziening$change,
												model,
												'',
												function (v) {
													return 'Breedtegraad ' + ($elm$core$String$fromFloat(v.fi.bZ.fb) + (' Lengtegraad ' + $elm$core$String$fromFloat(v.fi.bZ.fj)));
												},
												$elm$core$Maybe$Nothing);
											var options = _v4.b;
											var nativeControl = _v4.c;
											return A2(
												$author$project$Page$halfCard,
												$elm$core$Maybe$Just('Locatie *'),
												_List_fromArray(
													[
														A3(
														$author$project$Material$Options$styled,
														$elm$html$Html$div,
														_Utils_ap(
															options,
															_List_fromArray(
																[
																	A2($author$project$Material$Options$css, 'height', '24rem'),
																	A2($author$project$Material$Options$css, 'margin-top', '.5rem'),
																	$author$project$Material$Options$onDoubleClick(
																	function () {
																		if (!nativeControl.b) {
																			return $author$project$Page$Voorziening$NoOp;
																		} else {
																			return $author$project$Page$Voorziening$TakeLocatie;
																		}
																	}())
																])),
														_List_fromArray(
															[
																A2($author$project$Street$view, $author$project$Page$Voorziening$Street, model.df)
															]))
													]));
										}
									}(),
										A2(
										spreadOrHalfCard,
										isGebruiker ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just('Opmerkingen'),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												isGebruiker ? _List_Nil : _List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'min-height', '24.5rem')
													]),
												_List_fromArray(
													[
														isGebruiker ? $elm$html$Html$text('') : A3(
														$author$project$Page$Voorziening$textarea,
														model,
														'opmerkingen',
														function (v) {
															return v.fG;
														}),
														isGebruiker ? $elm$html$Html$text('') : A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																A2($elm$html$Html$Attributes$style, 'height', '1rem')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('')
															])),
														$author$project$Page$title('Vaarwegen'),
														A8(
														$author$project$Page$Component$Join$view,
														$author$project$Page$Voorziening$Mdc,
														model.c6,
														'vaarwegen',
														$author$project$Page$Voorziening$VaarwegFab,
														_List_fromArray(
															[
																_Utils_Tuple2('Verwijderen', $author$project$Page$Voorziening$VaarwegDel)
															]),
														function (_v6) {
															var naam = _v6.aY;
															return naam;
														},
														vaarwegen,
														isSuperUser)
													]))
											])),
										A2(
										$author$project$Page$spreadCard,
										$elm$core$Maybe$Just('Jachthaven'),
										_List_fromArray(
											[
												$author$project$Page$Voorziening$jachthavenContent(model)
											])),
										A2(
										$author$project$Page$spreadCard,
										$elm$core$Maybe$Just('Primaire voorzieningen'),
										_List_fromArray(
											[
												A3(
												$author$project$Page$Voorziening$categorieenChips,
												'primair',
												function (v) {
													return v.eT.he.fY.aJ;
												},
												model),
												A2(
												$author$project$Material$LayoutGrid$inner,
												_List_Nil,
												_List_fromArray(
													[
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'brandstof', primair, $author$project$Page$Voorziening$brandstofContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'drinkwater', primair, $author$project$Page$Voorziening$drinkwaterContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'ligplaatsen', primair, $author$project$Page$Voorziening$ligplaatsenContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'passantenligplaatsen', primair, $author$project$Page$Voorziening$passantenligplaatsenContent, model)
													]))
											])),
										A2(
										$author$project$Page$spreadCard,
										$elm$core$Maybe$Just('Algemene voorzieningen (op de haven)'),
										_List_fromArray(
											[
												A3(
												$author$project$Page$Voorziening$categorieenChips,
												'algemeen',
												function (v) {
													return v.eT.he.dv.aJ;
												},
												model),
												A2(
												$author$project$Material$LayoutGrid$inner,
												_List_Nil,
												_List_fromArray(
													[
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'vuilwatertank', algemeen, $author$project$Page$Voorziening$vuilwatertankContent, model)
													]))
											])),
										A2(
										$author$project$Page$spreadCard,
										$elm$core$Maybe$Just('Technische voorzieningen'),
										_List_fromArray(
											[
												A3(
												$author$project$Page$Voorziening$categorieenChips,
												'technisch',
												function (v) {
													return v.eT.he.gN.aJ;
												},
												model),
												A2(
												$author$project$Material$LayoutGrid$inner,
												_List_Nil,
												_List_fromArray(
													[
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'botenlift', technisch, $author$project$Page$Voorziening$botenliftContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'hefkraan', technisch, $author$project$Page$Voorziening$hefkraanContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'mastenkraan', technisch, $author$project$Page$Voorziening$mastenkraanContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'scheepshelling', technisch, $author$project$Page$Voorziening$scheepshellingContent, model),
														A4($author$project$Page$Voorziening$dataCardVoorziening, 'trailerhelling', technisch, $author$project$Page$Voorziening$trailerhellingContent, model)
													]))
											]))
									]));
						}
					}()
					])));
	}
};
var $author$project$Page$Voorzieningen$view = function (model) {
	return A6(
		$author$project$Page$view,
		$author$project$Page$Voorzieningen$Session,
		model,
		'Voorzieningen',
		_List_fromArray(
			['arrow_back']),
		_List_fromArray(
			[
				_Utils_Tuple2('add', 'Nieuwe voorziening aanmaken')
			]),
		A3($author$project$Page$Partial$Filter$view, $author$project$Page$Voorzieningen$Filter, model, _List_Nil));
};
var $author$project$Main$view = function (topModel) {
	switch (topModel.$) {
		case 0:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$HomeMsg,
				$author$project$Page$Home$view(model));
		case 1:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$LoginMsg,
				$author$project$Page$Login$view(model));
		case 2:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$ToewijzingenMsg,
				$author$project$Page$Toewijzingen$view(model));
		case 3:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$VoorzieningenMsg,
				$author$project$Page$Voorzieningen$view(model));
		case 4:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$VoorzieningMsg,
				$author$project$Page$Voorziening$view(model));
		case 5:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$VaarwegenMsg,
				$author$project$Page$Vaarwegen$view(model));
		case 6:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$VaarwegMsg,
				$author$project$Page$Vaarweg$view(model));
		case 7:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$KunstwerkenMsg,
				$author$project$Page$Kunstwerken$view(model));
		default:
			var model = topModel.a;
			return A2(
				$author$project$Main$document,
				$author$project$Main$KunstwerkMsg,
				$author$project$Page$Kunstwerk$view(model));
	}
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{hQ: $author$project$Main$init, h1: $author$project$Main$UrlChange, h2: $author$project$Main$UrlRequest, ij: $author$project$Main$subscriptions, iq: $author$project$Main$update, ix: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));