import { Elm } from './Main.elm';
import registerServiceWorker from './registerServiceWorker';

import aed from './Images/Icons/SVG/aed.svg';
import afspuitplaats from './Images/Icons/SVG/afspuitplaats.svg';
import brandstof from './Images/Icons/SVG/brandstof.svg';
import bilgewater from './Images/Icons/SVG/bilgewater.svg';
import blauweVlag from './Images/Icons/SVG/blauwe_vlag.svg';
import botenlift from './Images/Icons/SVG/botenlift.svg';
import technisch_botenlift_mast from './Images/Icons/SVG/botenlift_mast.svg';
import primair_brandstof_diesel from './Images/Icons/SVG/brandstof_diesel.svg';
import douches from './Images/Icons/SVG/douches.svg';
import drinkwater from './Images/Icons/SVG/drinkwater.svg';
import elektraSteiger from './Images/Icons/SVG/elektra_steiger.svg';
import elektraTerrein from './Images/Icons/SVG/elektra_steiger.svg';
import gasflessenVerkoop from './Images/Icons/SVG/gasflessen_verkoop.svg';
import gehandicapten from './Images/Icons/SVG/gehandicapten.svg';
import primair_brandstof_benzine from './Images/Icons/SVG/brandstof_benzine.svg';
import primair_brandstof_gtl from './Images/Icons/SVG/brandstof_gtl.svg';
import fietsverhuur from './Images/Icons/SVG/fietsverhuur.svg';
import hefkraan from './Images/Icons/SVG/hefkraan.svg';
import hiswaLid from './Images/Icons/SVG/lid_hiswa.svg';
import jachthaven from './Images/Icons/SVG/cat_jachthaven.svg';
import bootverhuurder from './Images/Icons/SVG/cat_jachtverhuurder.svg';
import jachtwerf from './Images/Icons/SVG/cat_jachtwerf.svg';
import gemeente from './Images/Icons/SVG/cat_gemeente.svg';
import waterEnOfRecreatieschap from './Images/Icons/SVG/cat_waterEnOfRecreatieschap.svg';
import overig from './Images/Icons/SVG/cat_overig.svg';
import jollen from './Images/Icons/SVG/jollen.svg';
import kampeerterrein from './Images/Icons/SVG/kampeerterrein.svg';
import laadpuntElektrisch from './Images/Icons/SVG/oplaadpuntelektroboten.svg';
import ligplaatsen from './Images/Icons/SVG/ligplaatsen.svg';
import mastenkraan from './Images/Icons/SVG/mastenkraan.svg';
import nautischeBoekhandel from './Images/Icons/SVG/cat_nautischeboekhandel.svg';
import oliestort from './Images/Icons/SVG/oliestort.svg';
import passantenligplaatsen from './Images/Icons/SVG/passantenligplaatsen.svg';
import reparatieBoot from './Images/Icons/SVG/reparatie.svg';
import reparatieElektronica from './Images/Icons/SVG/reparatie_elektra.svg';
import reparatieTuigage from './Images/Icons/SVG/reparatie_tuigage.svg';
import reparatieWal from './Images/Icons/SVG/reparatie_wal.svg';
import reparatieZeil from './Images/Icons/SVG/reparatie_zeil.svg';
import reparatieMotor from './Images/Icons/SVG/reparatie_motor.svg';
import reparatieBinnenboordmotor from './Images/Icons/SVG/reparatie_motor_binnenboord.svg';
import reparatieBuitenboordmotor from './Images/Icons/SVG/reparatie_motor_buitenboord.svg';
import restaurant from './Images/Icons/SVG/restaurant.svg';
import scheepshelling from './Images/Icons/SVG/scheepshelling.svg';
import speelplaats from './Images/Icons/SVG/speelplaats.svg';
import stortplaats from './Images/Icons/SVG/stortplaats.svg';
import supermarkt from './Images/Icons/SVG/supermarkt.svg';
import technisch_hefkraan_mast from './Images/Icons/SVG/hefkraan_mast.svg';
import telefooncel from './Images/Icons/SVG/telefooncel.svg';
import toiletten from './Images/Icons/SVG/toiletten.svg';
import trailerhelling from './Images/Icons/SVG/trailerhelling.svg';
import vaarschool from './Images/Icons/SVG/cat_vaarschool.svg';
import vuilniscontainers from './Images/Icons/SVG/vuilniscontainers.svg';
import vuilwatertank from './Images/Icons/SVG/vuilwatertank.svg';
import wasserette from './Images/Icons/SVG/wasserette.svg';
import wastafels from './Images/Icons/SVG/wastafels.svg';
import watersportvereniging from './Images/Icons/SVG/cat_watersportvereniging.svg';
import watersporwinkel from './Images/Icons/SVG/watersporwinkel.svg';
import wifi from './Images/Icons/SVG/wifi.svg';
import zwembad from './Images/Icons/SVG/zwembad.svg';
import campers from './Images/Icons/SVG/camperplaats.svg';
import digitaalBetalen from './Images/Icons/SVG/digitaal_betaling_mogelijk.svg';
import sanitair from './Images/Icons/SVG/sanitair.svg';

var app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    hiswaLid: hiswaLid,
    blauweVlag: blauweVlag,
    jachthaven: jachthaven,
    bootverhuurder: bootverhuurder,
    jachtwerf: jachtwerf,
    nautischeBoekhandel: nautischeBoekhandel,
    vaarschool: vaarschool,
    watersportvereniging: watersportvereniging,
    afspuitplaats: afspuitplaats,
    bilgewater: bilgewater,
    fietsverhuur: fietsverhuur,
    jollen: jollen,
    kampeerterrein: kampeerterrein,
    oliestort: oliestort,
    restaurant: restaurant,
    speelplaats: speelplaats,
    stortplaats: stortplaats,
    supermarkt: supermarkt,
    telefooncel: telefooncel,
    vuilniscontainers: vuilniscontainers,
    vuilwatertank: vuilwatertank,
    wasserette: wasserette,
    watersporwinkel: watersporwinkel,
    wifi: wifi,
    zwembad: zwembad,
    aed: aed,
    brandstof: brandstof,
    primair_brandstof_diesel: primair_brandstof_diesel,
    primair_brandstof_benzine: primair_brandstof_benzine,
    primair_brandstof_gtl: primair_brandstof_gtl,
    douches: douches,
    drinkwater: drinkwater,
    elektraSteiger: elektraSteiger,
    elektraTerrein: elektraTerrein,
    gasflessenVerkoop: gasflessenVerkoop,
    gehandicapten: gehandicapten,
    laadpuntElektrisch,
    ligplaatsen: ligplaatsen,
    passantenligplaatsen: passantenligplaatsen,
    toiletten: toiletten,
    wastafels: wastafels,
    botenlift: botenlift,
    technisch_botenlift_mast: technisch_botenlift_mast,
    hefkraan: hefkraan,
    technisch_hefkraan_mast: technisch_hefkraan_mast,
    mastenkraan: mastenkraan,
    reparatieBoot: reparatieBoot,
    reparatieElektronica: reparatieElektronica,
    reparatieTuigage: reparatieTuigage,
    reparatieWal: reparatieWal,
    reparatieZeil: reparatieZeil,
    reparatieMotor: reparatieMotor,
    reparatieBinnenboordmotor: reparatieBinnenboordmotor,
    reparatieBuitenboordmotor: reparatieBuitenboordmotor,
    scheepshelling: scheepshelling,
    trailerhelling: trailerhelling,
    jwt: localStorage.getItem("jwt"),
    refdata: localStorage.getItem("refdata"),
    handleiding: 'Handleiding Wateralm-enq 2019 consuls.pdf',
    campers,
    digitaalBetalen,
    gemeente,
    waterEnOfRecreatieschap,
    overig,
    sanitair,
  }
});

(function storage() {

  app.ports.store.subscribe(function (msg) {
    if (msg.value === null) {
      localStorage.removeItem(msg.key);
    } else {
      localStorage.setItem(msg.key, msg.value);
    }
  });

  // app.ports.storeCache.subscribe(function(val) {
  //   if (val === null) {
  //     localStorage.removeItem(storageKey);
  //   } else {
  //     localStorage.setItem(storageKey, JSON.stringify(val));
  //   }
  //   // Report that the new session was stored succesfully.
  //   setTimeout(function() { app.ports.onStoreChange.send(val); }, 0);
  // });

  // // Whenever localStorage changes in another tab, report it if necessary.
  // window.addEventListener("storage", function(event) {
  //   if (event.storageArea === localStorage && event.key === storageKey) {
  //     app.ports.onStoreChange.send(event.newValue);
  //   }
  // }, false);
})();

registerServiceWorker();

export default app;
