import app from './app.js';

import ElmStreet from '../elm-street/src/elm-street.js';
ElmStreet.init(app);

import HtmlEditors from './html-editors.js';
HtmlEditors.init(app);

// mdc
import 'material-components-web/dist/material-components-web.css';
import '../elm-mdc/src/elm-mdc.js';

import './main.css';
