import tinyMCE from 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';

import 'tinymce/plugins/table';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';

import './html-editors.css';
// import up from './Images/Icons/tabellen/up.jpg';
// import down from './Images/Icons/tabellen/down.jpg';
// import line from './Images/Icons/tabellen/line.jpg';

var app;

export default {
  init: (theapp) => {
    app = theapp;
    app.ports.editor.subscribe(() => {
      const parents = document.getElementsByClassName('editor');
      Array.prototype.forEach.call(parents, (parent) => {
        // Elm verhuist de hier (in JavaScript) gemaakte children niet mee.
        Array.prototype.forEach.call(parent.childNodes, (node) => {
          node.remove();
        });
      });
      setTimeout(() => {
        const parents = document.getElementsByClassName('editor');
        // Wacht tot alle editor elements hopelijk in de DOM zitten
        // Mocht dit een probleem worden, dan zullen de editors in Elm
        // geteld moeten worden, zodat je hier kan weten wanneer ze
        // er allemaal zijn.
        Array.prototype.forEach.call(parents, (parent) => {
          editor(parent);
        });
      }, 500);
    });
  }
}
    
function sendEditorValue(field, value) {
  app.ports.editorValue.send(
      [ field
      , value
      ]
  );
}


function editor(parent) {

  const field = parent.getAttribute('field');
  const value = parent.getAttribute('value');

  // Nieuw plekje aanmaken voor nieuwe editor
  const target = document.createElement('div');
  parent.appendChild(target);
  target.innerHTML = value;

  var active = false;
  target.onclick = () => {
      if (!active) {
        init();
      }
  };
  function init() {
    const previousValue = target.innerHTML;
    const settings =
      { target: target
      , setup: (editor) => {
          editor.on('blur', () => {
            const send = editor.isDirty();
            close(editor);
            if (send) {
                sendEditorValue(field, target.innerHTML);
            }
          });
          editor.on('keydown', (e) => {
            if (e.code === 'Escape') {
              close(editor);
              target.innerHTML = previousValue;
            }
          });
        }
      };
    toggleActive();
    tinyMCE.init(Object.assign(settings, baseSettings));
  }
  function close(editor) {
    // destroy() renders
    // Uncaught TypeError: Cannot read property 'childNodes' of null
    // which cannot be catched here. Not neat , but no harm.
    // https://github.com/tinymce/tinymce/issues/4703
    // https://community.tiny.cloud/communityQuestion?id=9064N00000098cxQAA
    editor.destroy();
    toggleActive();
  }
  function toggleActive() {
      [parent, target].forEach((e) => {
          e.classList[active ? 'remove' : 'add']('active');
      });
      active = !active;
  }
}

const baseSettings =
  { inline: true
  , auto_focus: true
  , plugins: "table,spellchecker,searchreplace,paste,charmap,paste,searchreplace,code"
  , menubar: false
  , toolbar: `
      | bold italic underline charmap styleselect
      | removeformat code
      | undo redo 
      | cut copy paste pastetext
      | searchreplace
      | table tablecellprops
      | align
  `
  , toolbar_drawer: 'floating'
  , style_formats:
      [ { title: 'Bruggen'
        , inline: 'span'
        , classes: 'bruggen'
        }
      , { title: 'Website'
        , inline: 'span'
        , classes: 'website'
        }
      , { title: 'Klein lettertype'
        , inline: 'span'
        , classes: 'smallFont'
        }
      , { title: 'Grijze scheidingslijn'
        , block: 'td'
        , classes: 'grijzelijn'
        }
      , { title: 'Blauwe scheidingslijn'
        , block: 'td'
        , classes: 'blauwelijn'
        }
      , { title: 'Blauwe scheidingslijn dubbel'
        , block: 'td'
        , classes: 'blauwelijn_dubbel'
        }
      , { title: 'Blauwe scheidingslijn boven'
        , block: 'td'
        , classes: 'blauwelijn_boven'
        }
      , { title: 'Blauwe scheidingslijn rechts'
        , block: 'td'
        , classes: 'blauwelijn_rechts'
        }
      , { title: 'Pijl omhoog'
        , block: 'td'
        , classes: 'pijl_omhoog'
        }
      , { title: 'Pijl tussenstuk'
        , block: 'td'
        , classes: 'pijl_tussenstuk'
        }
      , { title: 'Pijl omlaag'
        , block: 'td'
        , classes: 'pijl_omlaag'
        }
      , { title: 'Getijdentabel'
        , block: 'tr'
        , classes: 'getijdentabel'
        }
      ]
  , entity_encoding: "raw" // weet niet waarom, maar OK
  // , force_p_newlines: false // bestaat niet meer
  , forced_root_block: '' // weet niet waarom, maar OK
  // , add_unload_trigger: false // bestaat niet meer
  // , remove_linebreaks: true // bestaat niet meer
  // , inline_styles: false // default value van nu premium plugin Enhanced Media Embed
  , convert_fonts_to_spans: false // weet niet waarom, maar OK
  // , convert_newlines_to_brs: false // bestaat niet meer
  // , valid_elements: "" // zie de noodzaak niet; geen mogelijkheid andere elements in te voeren
    //     +"b,"
    //     +"strong,"
    //     +"br,"
    //     +"i,"
    //     +"em,"
    //     +"li,"
    //     +"table[align<center?left?right|bgcolor|border|cellpadding|cellspacing|class"
    //       +"|dir<ltr?rtl|frame|height|id|lang|onclick|ondblclick|onkeydown|onkeypress"
    //       +"|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|rules"
    //       +"|style|summary|title|width],"
    //     +"td[abbr|align<center?char?justify?left?right|axis|bgcolor|char|charoff|class"
    //       +"|colspan|dir<ltr?rtl|headers|height|id|lang|nowrap<nowrap|onclick"
    //       +"|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove"
    //       +"|onmouseout|onmouseover|onmouseup|rowspan|scope<col?colgroup?row?rowgroup"
    //       +"|style|title|valign<baseline?bottom?middle?top|width],"
    //     +"tfoot[align<center?char?justify?left?right|char|charoff|class|dir<ltr?rtl|id"
    //       +"|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown"
    //       +"|onmousemove|onmouseout|onmouseover|onmouseup|style|title"
    //       +"|valign<baseline?bottom?middle?top],"
    //     +"th[abbr|align<center?char?justify?left?right|axis|bgcolor|char|charoff|class"
    //       +"|colspan|dir<ltr?rtl|headers|height|id|lang|nowrap<nowrap|onclick"
    //       +"|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove"
    //       +"|onmouseout|onmouseover|onmouseup|rowspan|scope<col?colgroup?row?rowgroup"
    //       +"|style|title|valign<baseline?bottom?middle?top|width],"
    //     +"thead[align<center?char?justify?left?right|char|charoff|class|dir<ltr?rtl|id"
    //       +"|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown"
    //       +"|onmousemove|onmouseout|onmouseover|onmouseup|style|title"
    //       +"|valign<baseline?bottom?middle?top],"
    //     +"tr[abbr|align<center?char?justify?left?right|bgcolor|char|charoff|class"
    //       +"|rowspan|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress"
    //       +"|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style"
    //       +"|title|valign<baseline?bottom?middle?top],"
    //     +"span[class|style],"
    //     +"u"
};
